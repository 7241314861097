import React, { useState } from 'react';
import './DestinationListPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import router from '../../utils/router';
import serviceFactory from '../../services/serviceFactory';
import DestinationService from '../../services/destination/destination.service';
import SpireTable from '../../components/spireTable/SpireTable';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';

const DestinationListPage: React.FC = () => {
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const [destinationTotal, setDestinationTotal] = useState<number>(0);
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [sortField, setSortField] = useState<string>('id');
	const [destinationList, setDestinationList] = useState<Api.Destination.Res.Details[]>([]);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			pageQuery.sort = {
				field: 'modifiedOn',
				order: 'DESC'
			};
			let res = await destinationService.getDestinationByPage(pageQuery);
			res.data.forEach((item) => {
				if (item.campaignDefinition == '' || item.campaignDefinition == null) {
					item.campaignDefinition = 'N/A';
				}
			});
			setDestinationList(res.data);
			setDestinationTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/destination-list/manage-destinations?di=' + data.id).catch();
	}

	return (
		<Page className={'rsDestinationListPage'}>
			<SubHeader
				header={'Destinations'}
				buttonOptions={[{ name: 'Add Resort', path: '/dashboard/destination-list/create-resort' }]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Destination List', link: '/dashboard/destination-list' }
				]}
			/>
			<SpireTable
				table={{
					placeholder: 'Search by destination id, name',
					filterQuery: [
						{ column: 'id', value: '' },
						{ column: 'name', value: '', conjunction: 'OR' }
					]
				}}
				columns={[
					{
						id: 'id',
						label: 'Destination Id',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'id' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'name',
						label: 'Destination Name',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'campaignDefinition',
						label: 'Campaign Type',
						align: 'left',
						className: 'userCellCampaign',
						sort: sortField === 'campaignDefinition' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'address1',
						label: 'Street Address',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'address1' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'city',
						label: 'City',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'city' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'state',
						label: 'State',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'state' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'zip',
						label: 'Zip Code',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'zip' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'description',
						label: 'Destination Description',
						align: 'left',
						className: 'userCell destinationDescriptionColumn',
						sort: sortField === 'description' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					}
				]}
				data={destinationList}
				total={destinationTotal}
				sortField={'id'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default DestinationListPage;
