import * as React from 'react';
import { useState } from 'react';
import './ReviewListPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import { WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import ReviewPopout from '../../popups/reviewPopout/ReviewPopout';
import ReviewService from '../../services/review/review.service';
import SpireTable from '../../components/spireTable/SpireTable';

const ReviewListPage: React.FC = () => {
	let reviewService = serviceFactory.get<ReviewService>('ReviewService');
	const [reviewList, setReviewList] = useState<Api.Review.Res.Get[]>([]);
	const [sortField, setSortField] = useState<string>('status');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('ASC');
	const [reviewTotal, setReviewTotal] = useState<number>(0);
	const [showSlideOutMenu, setShowSlideOutMenu] = useState<boolean>(false);
	const [review, setReview] = useState<Api.Review.Res.Get>();
	const [newPageQuery, setNewPageQuery] = useState<RedSky.PageQuery>();

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			let res = await reviewService.getReviewByPage(pageQuery);
			setReviewList(res.data);
			setReviewTotal(res.total);
			setNewPageQuery(pageQuery);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function rejectReview(reviewId: number) {
		try {
			await reviewService.verify(reviewId);
			await reviewService.unPublish(reviewId);
			rsToastify.success('Review successfully unpublished.', 'Review Rejected!');
			if (newPageQuery) return getData(newPageQuery);
		} catch (e) {
			console.error(e);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed to update review'), 'Update Failed');
		}
	}

	async function approveReview(reviewId: number) {
		try {
			await reviewService.verify(reviewId);
			await reviewService.publish(reviewId);
			rsToastify.success('Review successfully published.', 'Review Approved!');
			if (newPageQuery) return getData(newPageQuery);
		} catch (e) {
			console.error(e);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed to update review'), 'Update Failed');
		}
	}

	function renderStatus(data: Api.Review.Res.Get) {
		if (data.status === 'APPROVED') {
			return 'Approved';
		} else if (data.status === 'REJECTED') {
			return 'Rejected';
		} else if (data.status === 'PENDING') {
			return 'Pending';
		} else {
			return data.status;
		}
	}

	function renderMessage(data: Api.Review.Res.Get) {
		if (data.message.length > 60) {
			return data.message.substring(0, 60 - 3) + '...';
		} else {
			return data.message;
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handleSlideOut(reviewInfo: Api.Review.Res.Get) {
		setReview(reviewInfo);
		setShowSlideOutMenu(true);
	}

	function renderSpireTable() {
		return (
			<SpireTable
				table={{
					placeholder: 'Search by name',
					filterQuery: [
						{ column: 'guestDetails.firstName', value: '' },
						{ column: 'guestDetails.lastName', value: '', conjunction: 'OR' }
					]
				}}
				columns={[
					{
						id: 'guestDetails.firstName',
						label: 'First Name',
						align: 'left',
						className: 'reviewCell',
						sort: sortField === 'guestDetails.firstName' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'NESTED_TEXT',
						nested: 'guest.firstName'
					},
					{
						id: 'guestDetails.lastName',
						label: 'Last Name',
						align: 'left',
						className: 'reviewCell',
						sort: sortField === 'guestDetails.lastName' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'NESTED_TEXT',
						nested: 'guest.lastName'
					},
					{
						id: 'guestDetails.accountNumber',
						label: 'Member Number',
						align: 'left',
						className: 'reviewCell',
						sort: sortField === 'guestDetails.accountNumber' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'NESTED_TEXT',
						nested: 'guest.accountNumber'
					},
					{
						id: 'rating',
						label: 'Rating',
						align: 'left',
						className: 'reviewCell',
						sort: sortField === 'rating' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'createdOn',
						label: 'Created On',
						align: 'left',
						className: 'reviewCell',
						sort: sortField === 'createdOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},

					{
						id: 'status',
						label: 'Status',
						align: 'left',
						className: 'reviewCell',
						sort: sortField === 'status' ? sortOrder : 'ASC',
						filterType: 'NORMAL',
						cellType: 'HANDLER',
						handler: renderStatus
					},
					{
						id: 'message',
						label: 'Review',
						align: 'left',
						className: 'reviewCell reviewColumn',
						sort: sortField === 'message' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'HANDLER',
						handler: renderMessage
					},
					{
						id: 'approve',
						label: 'Approve',
						align: 'left',
						className: 'reviewCell hideSortArrow',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'BUTTON',
						look: 'containedPrimary',
						onClick: approveReview
					},
					{
						id: 'reject',
						label: 'Reject',
						align: 'left',
						className: 'reviewCell hideSortArrow',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'BUTTON',
						look: 'containedSecondary',
						onClick: rejectReview
					}
				]}
				total={reviewTotal}
				data={reviewList}
				sortField={'status'}
				onGetData={getData}
				rowOnClick={handleSlideOut}
				changeSort={changeSort}
			/>
		);
	}

	return (
		<Page className={'rsReviewListPage'}>
			<SubHeader
				header={'Manage Spire Reviews'}
				fallbackPath={'/dashboard'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Review List', link: '/dashboard/review-list' }
				]}
			/>
			{renderSpireTable()}
			<ReviewPopout
				review={review}
				approveReview={approveReview}
				rejectReview={rejectReview}
				isOpened={showSlideOutMenu}
				onClose={() => setShowSlideOutMenu(false)}
			/>
		</Page>
	);
};

export default ReviewListPage;

export function reviewListPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.POINTS_WRITE]);
}
