import React, { FormEvent, useEffect, useState } from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import Input from '@bit/redsky.framework.rs.input';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import Button from '@bit/redsky.framework.rs.button';
import './LoginPage.scss';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Box from '../../components/box/Box';
import UserService from '../../services/user/user.service';
import serviceFactory from '../../services/serviceFactory';
import RequestResetPassword from '../../components/requestResetPassword/RequestResetPassword';
import router from '../../utils/router';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { StringUtils, WebUtils } from '../../utils/utils';

const LoginPage: React.FC = () => {
	let userService = serviceFactory.get<UserService>('UserService');
	const [isAttemptingLogin, setIsAttemptingLogin] = useState<boolean>(false);
	const [loginErrorMessage, setLoginErrorMessage] = useState<string>('');
	const [loginForm, setLoginForm] = useState(
		new RsFormGroup([
			new RsFormControl('password', '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')]),
			new RsFormControl('email', '', [
				new RsValidator(RsValidatorEnum.EMAIL, 'Email is invalid'),
				new RsValidator(RsValidatorEnum.REQ, 'Email is required')
			])
		])
	);

	const params: { guid: string } = router.getPageUrlParams([
		{ key: 'appUserToken', default: '', type: 'string', alias: 'guid' }
	]);

	useEffect(() => {
		async function verifyGuid() {
			try {
				if (!params.guid) return;
				setLoginErrorMessage('');
				await userService.loginUserByToken(`${params.guid}`);
			} catch (e) {
				if (e.response?.data?.err === 'LOGIN_EXPIRED') {
					setLoginErrorMessage('Login verification has expired and a verification email has been sent');
					return;
				}
			}
		}
		verifyGuid().catch(console.error);
	}, []);

	async function signInAdmin(e: FormEvent) {
		e.preventDefault();

		if (!(await loginForm.isValid())) {
			setLoginErrorMessage('Please fix login inputs.');
			setLoginForm(loginForm.clone());
			return;
		}

		try {
			setLoginErrorMessage('');
			setIsAttemptingLogin(true);
			let loginValues = loginForm.toModel<{ email: string; password: string }>();
			await userService.loginUserByPassword(loginValues.email, loginValues.password);
		} catch (e) {
			setIsAttemptingLogin(false);
			if (e.response.status === 401) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(StringUtils.snakeCaseToHuman(e), 'Incorrect login credentials'),
					'Unauthorized Login'
				);
				setLoginErrorMessage(
					WebUtils.getRsErrorMessage(StringUtils.snakeCaseToHuman(e), 'Incorrect username or password')
				);
				return;
			}
			if (e.response.data.err === 'LOGIN_EXPIRED') {
				rsToastify.error(
					'Login verification has expired and a verification email has been sent',
					'Login Expired!'
				);
				setLoginErrorMessage('Login verification has expired and a verification email has been sent');
				return;
			}
			if (e.response.status === 403) {
				setLoginErrorMessage('Unauthorized');
			} else {
				setLoginErrorMessage('Invalid Username or Password');
			}
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Invalid username or password'), 'Unable to Login');
		}
	}

	return (
		<Page className="rsLoginPage">
			<Box className="loggedOutTitleBar" p={'0 20px'} display={'flex'} alignItems={'center'}>
				<Label className="spireLabel" variant={'h6'}>
					SPIRE
				</Label>
			</Box>
			<Box className="signInWrapper">
				<Box
					className="signInContainer"
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
					p={'22px 68px'}
				>
					<Box className="titleContainer" display={'flex'} flexDirection={'column'} alignItems={'center'}>
						<Label className="signInHeader" variant={'h1'}>
							Spire Loyalty Admin Center
						</Label>
						<Label className="signInSubheader" variant={'h4'}>
							Sign In
						</Label>
					</Box>
					<form className="signInForm" action={'#'} onSubmit={signInAdmin}>
						<Input
							className="signInInput"
							placeholder="Email Address *"
							type={'text'}
							look={'outlined'}
							control={loginForm.get('email')}
							updateControl={(updateControl) => setLoginForm(loginForm.clone().update(updateControl))}
							unStyled
						/>
						<Input
							className="signInInput"
							placeholder="Password *"
							type={'password'}
							look={'outlined'}
							control={loginForm.get('password')}
							unStyled
							updateControl={(updateControl) => setLoginForm(loginForm.clone().update(updateControl))}
						/>
						<Button
							className="signInButton"
							look={'containedPrimary'}
							type="submit"
							disabled={isAttemptingLogin}
						>
							{isAttemptingLogin ? 'SIGNING IN...' : 'SIGN IN'}
						</Button>
						<Label className="errorText" variant={'body1'}>
							{loginErrorMessage}
						</Label>
					</form>
					<RequestResetPassword />
				</Box>
			</Box>
		</Page>
	);
};

export default LoginPage;
