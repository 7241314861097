import { Box, Page } from '@bit/redsky.framework.rs.996';
import React, { useState, useEffect } from 'react';
import SubHeader from '../../components/subHeader/SubHeader';
import serviceFactory from '../../services/serviceFactory';
import disableRoute from '../../customHooks/useMiddleware';
import './RewardCategoryListPage.scss';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import RewardService from '../../services/reward/reward.service';
import { WebUtils } from '../../utils/utils';
import router from '../../utils/router';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpireTable from '../../components/spireTable/SpireTable';

const RewardCategoryListPage: React.FC = () => {
	const rewardService = serviceFactory.get<RewardService>('RewardService');
	const [categories, setCategories] = useState<Api.Reward.Category.Res.Get[]>([]);
	const [sortField, setSortField] = useState<string>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [categoryTotal, setCategoryTotal] = useState<number>(0);

	useEffect(() => {
		disableRoute.useMiddleware();
	}, []);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			let res = await rewardService.getAllCategoriesPaged(pageQuery);
			setCategories(res.data);
			if (res.total) setCategoryTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/reward-list/category-list/manage-category?ci=' + data.id).catch(console.error);
	}

	return (
		<Page className="rsRewardCategoryListPage">
			<SubHeader
				header="Manage Reward Categories"
				buttonOptions={[
					{
						name: 'Create new category',
						path: '/dashboard/reward-list/category-list/create-category'
					}
				]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Category List', link: '/dashboard/reward-list/category-list' }
				]}
			/>
			<Box className="layoutContainer">
				<SpireTable
					table={{
						placeholder: 'Category Name',
						filterQuery: [{ column: 'name', value: '' }]
					}}
					columns={[
						{
							id: 'mainImage',
							label: 'Main Image',
							align: 'left',
							className: 'userCellHeader thumbnailHeader hideSortArrow',
							sort: undefined,
							filterType: 'NONE',
							cellType: 'IMAGE'
						},
						{
							id: 'name',
							label: 'Category Name',
							align: 'left',
							className: 'userCellHeader nameHeader',
							sort: sortField === 'name' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'isFeatured',
							label: 'Featured',
							align: 'left',
							className: 'userCellHeader FeaturedHeader',
							sort: sortField === 'isFeatured' ? sortOrder : 'DESC',
							filterType: 'CHECKBOX',
							cellType: 'BOOLEAN',
							textOptions: {
								false: 'Not Featured',
								true: 'Featured'
							}
						},
						{
							id: 'isActive',
							label: 'Active',
							align: 'left',
							className: 'userCellHeader activeHeader',
							sort: sortField === 'isActive' ? sortOrder : 'DESC',
							filterType: 'CHECKBOX',
							cellType: 'BOOLEAN',
							textOptions: {
								false: 'Inactive',
								true: 'Active'
							}
						},
						{
							id: 'createdOn',
							label: 'Date Created',
							align: 'left',
							className: 'userCellHeader createdOnHeader',
							sort: sortField === 'createdOn' ? sortOrder : 'DESC',
							filterType: 'SINGLE_DATE',
							filterName: 'createdOn',
							cellType: 'DATE'
						}
					]}
					data={categories}
					total={categoryTotal}
					sortField={'name'}
					onGetData={getData}
					rowOnClick={handleTableClick}
					changeSort={changeSort}
				/>
			</Box>
		</Page>
	);
};

export default RewardCategoryListPage;

export function rewardCategoryListPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_REWARDS_READ]);
}
