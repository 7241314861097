import * as React from 'react';
import './IconTile.scss';
import Box from '../box/Box';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import LabelCheckbox from '../labelCheckbox/LabelCheckbox';
import { useEffect, useState } from 'react';
import { useMultiColoredIcon } from '../../customHooks/useMultiColoredIcon';

interface IconTileProps {
	amenityId: number;
	amenityIds: number[];
	icon: string;
	title: string;
	onSelect: () => void;
	onDeselect: () => void;
}

const IconTile: React.FC<IconTileProps> = (props) => {
	const [selected, setSelected] = useState<number>(0);
	const [isChecked, setIsChecked] = useState<boolean>(false);

	useEffect(() => {
		let selectedAmenity = props.amenityIds.find((amenity) => amenity === props.amenityId);
		if (selectedAmenity) {
			setIsChecked(true);
		}
		console.log(props.icon);
	}, []);

	return (
		<Box className={'rsIconTile iconSelection'}>
			{useMultiColoredIcon(props.icon, 35)}
			<Label variant={'caption'}>{props.title}</Label>
			<LabelCheckbox
				value={selected}
				text={''}
				isChecked={isChecked}
				onSelect={() => {
					setSelected(props.amenityId);
					props.onSelect();
				}}
				onDeselect={() => {
					setSelected(-1);
					props.onDeselect();
				}}
			/>
		</Box>
	);
};

export default IconTile;
