import * as React from 'react';
import './AmenityTile.scss';
import Paper from '../paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import { useMultiColoredIcon } from '../../customHooks/useMultiColoredIcon';

interface AmenityTileProps {
	amenity: Model.Amenity;
	onClick: () => void;
}

const AmenityTile: React.FC<AmenityTileProps> = (props) => {
	return (
		<Paper className={'rsAmenityTile'} onClick={props.onClick}>
			{useMultiColoredIcon(props.amenity.icon, 35)}
			<Label variant={'caption'}>{props.amenity.title}</Label>
		</Paper>
	);
};

export default AmenityTile;
