import * as React from 'react';
import './SubHeaderDestination.scss';
import BackButton from '../backButton/BackButton';
import Label from '@bit/redsky.framework.rs.label';
import BreadCrumb from '../breadCrumb/BreadCrumb';
import Box from '../box/Box';
import LinkButton from '../linkButton/LinkButton';
import LabelButton from '../../components/labelButton/LabelButton';
import router from '../../utils/router';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import { popupController } from '@bit/redsky.framework.rs.996';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import useWindowScrollChange from '../../customHooks/useWindowScrollChange';
import classNames from 'classnames';
import serviceFactory from '../../services/serviceFactory';
import DestinationService from '../../services/destination/destination.service';

interface ButtonObj {
	name: string;
	path: string;
	iconName?: string;
}

interface SubHeaderDestinationProps {
	header: string;
	buttonOptions?: ButtonObj[];
	fallbackPath?: string;
	crumbs?: { label: string; link: string }[];
	isValid: boolean;
	save: () => void;
	isCreate: boolean;
	destinationId: number | undefined;
	destinationIsActive: number | undefined;
	setDestination: (res: any) => void;
}

const SubHeaderDestination: React.FC<SubHeaderDestinationProps> = (props) => {
	const {
		header,
		crumbs,
		isValid,
		save,
		isCreate,
		destinationId,
		destinationIsActive,
		setDestination,
		buttonOptions
	} = props;
	const scrollDirection = useWindowScrollChange();
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');

	function getAppBarHeight() {
		let appBar = document.querySelector('.rsAppBar');
		if (!appBar) return '0px';
		if (appBar.scrollHeight > 99) {
			return '99px';
		} else return appBar.scrollHeight + 'px';
	}

	function renderButtons() {
		if (!buttonOptions) return;
		return buttonOptions.map((item, index) => {
			return <LinkButton key={index} path={item.path} label={item.name} iconName={item.iconName} />;
		});
	}

	return (
		<div
			className={classNames('rsSubHeaderDestination', { moveUp: scrollDirection === 'DOWN' })}
			style={{ top: getAppBarHeight() }}
		>
			<div className="inner-box">
				<Box>
					<BackButton name={'Back to dashboard'} fallbackPath="/dashboard" />
					<Label variant={'h1'}>{header}</Label>
					{crumbs && <BreadCrumb crumbs={crumbs} />}
				</Box>
				{!!buttonOptions && (
					<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
						{renderButtons()}
					</Box>
				)}
				<Box display={'flex'} alignItems="center">
					<LabelButton
						className={'saveButton'}
						look={isValid ? 'containedPrimary' : 'containedSecondary'}
						disabled={!isValid}
						variant={'button'}
						label={'Save'}
						onClick={save}
					/>
					<LabelButton
						className={'cancelButton'}
						look={'containedSecondary'}
						variant={'button'}
						label={'Cancel'}
						onClick={async () => {
							await router.navigate('/dashboard/destination-list');
						}}
					/>
					{isCreate ? null : (
						<LabelButton
							look={'containedPrimary'}
							variant={'button'}
							label={destinationIsActive ? 'Deactivate' : 'Activate'}
							onClick={async () => {
								popupController.open(SpinningLoaderPopup, {});
								try {
									let response = await destinationService.update({
										id: destinationId || 0,
										isActive: destinationIsActive ? 0 : 1
									});
									if (response) {
										setDestination(response);
									}
									popupController.close(SpinningLoaderPopup);
								} catch (e) {
									rsToastify.error(
										WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'),
										'Server Error'
									);
									popupController.closeAll();
								}
							}}
						/>
					)}
				</Box>
			</div>
		</div>
	);
};

export default SubHeaderDestination;
