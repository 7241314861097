import { RsResponseData } from '@bit/redsky.framework.rs.http';
import http from '../../utils/http';
import { Service } from '../Service';
import { WebUtils } from '../../utils/utils';

export default class RewardService extends Service {
	async createReward(data: Api.Reward.Req.Create): Promise<Api.Reward.Res.Create> {
		let res = await http.post<RsResponseData<Api.Reward.Res.Create>>('reward', data);
		return res.data.data;
	}

	async createUpdatePointsCost(data: Api.PointsCost.Req.Create) {
		return await http.put<RsResponseData<Api.PointsCost.Res.Get>>('company/platform-and-variables', data);
	}

	async getPointsCost(): Promise<Api.PointsCost.Res.Get> {
		const response = await http.get<RsResponseData<Api.PointsCost.Res.Get>>('company/platform-and-variables');
		return response.data.data;
	}

	async getAllRewards(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Reward.Res.Get[]>> {
		let response = await http.get<RedSky.RsPagedResponseData<Api.Reward.Res.Get[]>>(
			'/reward/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async getRewardById(id: number): Promise<Api.Reward.Res.Get> {
		const response = await http.get<RsResponseData<Api.Reward.Res.Get>>('reward', { id });
		return response.data.data;
	}

	async updateReward(data: Api.Reward.Req.Create): Promise<Api.AccommodationCategory.Res.Get> {
		let response = await http.put<RsResponseData<Api.AccommodationCategory.Res.Get>>('reward', data);
		return response.data.data;
	}

	async deleteReward(id: number): Promise<number> {
		let response = await http.delete<RsResponseData<number>>('reward', { id });
		return response.data.data;
	}

	async toggleRewardActiveStatus(id: number): Promise<number> {
		let response = await http.put<RsResponseData<number>>('reward/updateActiveStatus', { id });
		return response.data.data;
	}

	async createCategory(category: Api.Reward.Category.Req.Create): Promise<Api.Reward.Category.Res.Get> {
		let res = await http.post<RsResponseData<Api.Reward.Category.Res.Get>>('reward/category', category);
		return res.data.data;
	}

	async getAllActiveCategories(): Promise<RedSky.RsPagedResponseData<Api.Reward.Category.Res.Get[]>> {
		let pageQuery: RedSky.PageQuery = {
			pagination: { page: 1, perPage: 100 },
			sort: { field: 'name', order: 'ASC' },
			filter: { searchTerm: [{ column: 'isActive', value: 1 }], matchType: 'like' }
		};
		const response = await http.get<RedSky.RsPagedResponseData<Api.Reward.Category.Res.Get[]>>(
			'reward/category/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async getAllCategories(): Promise<RedSky.RsPagedResponseData<Api.Reward.Category.Res.Get[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Reward.Category.Res.Get[]>>(
			'reward/category/paged'
		);
		return response.data;
	}

	async getAllCategoriesPaged(
		pageQuery: RedSky.PageQuery
	): Promise<RedSky.RsPagedResponseData<Api.Reward.Category.Res.Get[]>> {
		let response = await http.get<RedSky.RsPagedResponseData<Api.Reward.Category.Res.Get[]>>(
			'/reward/category/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async getCategoryById(id: number): Promise<Api.Reward.Category.Res.Get> {
		const response = await http.get<RsResponseData<Api.Reward.Category.Res.Get>>('reward/category', { id });
		return response.data.data;
	}

	async updateCategory(data: Api.Reward.Category.Req.Update): Promise<Api.Reward.Category.Res.Update> {
		const res = await http.put<RsResponseData<Api.Reward.Category.Res.Update>>('reward/category', data);
		return res.data.data;
	}

	async deleteCategory(categoryId: number): Promise<number> {
		let response = await http.delete<RsResponseData<number>>('reward/category', { id: categoryId });
		return response.data.data;
	}

	async getAllVendors(): Promise<RedSky.RsPagedResponseData<Api.Vendor.Res.Get[]>> {
		let response = await http.get<RedSky.RsPagedResponseData<Api.Vendor.Res.Get[]>>('/vendor/paged');
		return response.data;
	}

	async getVendorsInSelectFormat() {
		let allVendor = await this.getAllVendors();
		return allVendor.data.map((vendor) => {
			if (vendor.destinationId) {
				return { value: 'd' + vendor.destinationId, label: vendor.name, selected: false };
			} else if (vendor.brandId) {
				return { value: 'a' + vendor.brandId, label: vendor.name, selected: false };
			}
			return { value: 0, label: '', selected: false };
		});
	}

	async getAllVouchers(
		pageQuery: RedSky.PageQuery
	): Promise<RedSky.RsPagedResponseData<Api.Reward.Voucher.Res.Get[]>> {
		let response = await http.get<RedSky.RsPagedResponseData<Api.Reward.Voucher.Res.Get[]>>(
			'/reward/voucher/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async createVoucher(rewardId: number, codes: string[]): Promise<Api.Reward.Res.Create> {
		let res = await http.post<RsResponseData<Api.Reward.Res.Create>>('reward/voucher', { rewardId, codes });
		return res.data.data;
	}

	async deleteVoucher(rewardId: number, code: string): Promise<Api.Reward.Voucher.Res.Delete> {
		let res = await http.delete<RsResponseData<Api.Reward.Voucher.Res.Delete>>('reward/voucher', {
			rewardId,
			code
		});
		return res.data.data;
	}

	async activateVoucher(rewardId: number, code: string): Promise<Api.Reward.Voucher.Res.Delete> {
		let res = await http.put<RsResponseData<Api.Reward.Voucher.Res.Delete>>('reward/voucher', {
			rewardId,
			code
		});
		return res.data.data;
	}

	async deleteCategoryImage(data: Api.Media.Req.Delete): Promise<RsResponseData<Api.Media.Req.Delete>> {
		return await http.delete<Api.Media.Req.Delete>('media', data);
	}
}
