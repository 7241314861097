import React, { useEffect, useState } from 'react';
import { View } from '@bit/redsky.framework.rs.996';
import './App.scss';
import './icons/siteIcons/style.css';
import './icons/cmsIcons/style.css';

// The following components need to be added to the top level dom since they are full screen overlays
import popupController from '@bit/redsky.framework.rs.996/dist/popupController';
import useLoginState, { LoginStatus } from './customHooks/useLoginState';
import AppBar from './components/appBar/AppBar';
import Footer from './components/footer/Footer';
import { useSetCustomToast } from './customHooks/useSetCustomToast';
import { useLoadInitialPath } from './utils/router';
import { ToastContainer, rsToastify } from '@bit/redsky.framework.rs.toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { clearPersistentState } from './state/globalState';
import SessionManagementPopup, {
	SessionManagementPopupProps
} from './popups/sessionManagementPopup/SessionManagementPopup';
import serviceFactory from './services/serviceFactory';
import RewardService from './services/reward/reward.service';

function App() {
	const loginStatus = useLoginState();
	useLoadInitialPath(loginStatus);
	useSetCustomToast();
	const rewardService = serviceFactory.get<RewardService>('RewardService');
	const [sessionTime, setSessionTime] = useState<Api.PointsCost.Res.Get>();

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			console.log('9783 set promoted properties'); // to know when my new update has been deploy
		}
		getPlatformVariable();
	}, []);

	const getPlatformVariable = async () => {
		try {
			const res = await rewardService.getPointsCost();
			setSessionTime(res);
		} catch (error) {
			console.log('check platform error', error);
		}
	};

	const [adminToken, setadminToken] = useRecoilState<string>(globalState.adminToken);

	let inactivityTimer: any = null;
	let expirationTimer: any = null;
	let expirationTimerSessionManagementPopup: any = null;

	const handleInteraction = () => {
		resetTimers();
	};

	const resetTimers = () => {
		clearTimeout(inactivityTimer);
		clearTimeout(expirationTimer);
		clearTimeout(expirationTimerSessionManagementPopup);

		inactivityTimer = setTimeout(
			() => {
				popupController.open<SessionManagementPopupProps>(SessionManagementPopup, {
					handleInteraction: () => {
						popupController.close(SessionManagementPopup);
						handleInteraction();
					}
				});
				// rsToastify.success('your session will expire in 1 minutes');
			},
			sessionTime && sessionTime.sessionNotificationsOpenTime
				? sessionTime.sessionNotificationsOpenTime * 60 * 1000
				: 840000
		);

		expirationTimerSessionManagementPopup = setTimeout(
			() => {
				popupController.close(SessionManagementPopup);
			},
			sessionTime && sessionTime.sessionNotificationsCloseTime && sessionTime.sessionNotificationsOpenTime
				? sessionTime.sessionNotificationsOpenTime * 60 * 1000 +
						sessionTime.sessionNotificationsCloseTime * 1000
				: 845000
		);

		expirationTimer = setTimeout(
			() => {
				// Redirect the user to the login page or do any other desired action her
				// rsToastify.success('Session expired');
				//  userService.logout();
				clearPersistentState();
				localStorage.clear();
				window.location.assign('/');
			},
			sessionTime && sessionTime.sessionExpireTime ? sessionTime.sessionExpireTime * 60 * 1000 : 900000
		);
	};

	useEffect(() => {
		if (!adminToken) return;
		// Reset the timers when the user interacts with the website
		handleInteraction();

		document.addEventListener('mousemove', handleInteraction);
		document.addEventListener('keypress', handleInteraction);
		document.addEventListener('click', handleInteraction);
		document.addEventListener('scroll', handleInteraction);

		resetTimers();

		// Clear the event listeners and timers when the component unmounts
		return () => {
			document.removeEventListener('mousemove', handleInteraction);
			document.removeEventListener('keypress', handleInteraction);
			document.removeEventListener('click', handleInteraction);
			document.removeEventListener('scroll', handleInteraction);
			clearTimeout(inactivityTimer);
			clearTimeout(expirationTimer);
			clearTimeout(expirationTimerSessionManagementPopup);
		};
	}, [adminToken]);

	function renderViewsBasedOnLoginStatus() {
		switch (loginStatus) {
			case LoginStatus.UNKNOWN:
				return null;
			case LoginStatus.LOGGED_OUT:
				return (
					<>
						<View key="login" id="login" default initialPath="/" />
					</>
				);
			case LoginStatus.LOGGED_IN:
				return (
					<div className="loggedInView">
						<AppBar />
						<View key="admin" id="admin" default initialPath="/dashboard" />
						<Footer />
					</div>
				);
		}
	}

	return (
		<div className={`App `}>
			{renderViewsBasedOnLoginStatus()}
			{popupController.instance}
			<ToastContainer />
		</div>
	);
}

export default App;
