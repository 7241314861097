import * as React from 'react';
import { useState } from 'react';
import './DestinationCategoryPopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Icon from '@bit/redsky.framework.rs.icon';
import LabelInput from '../../components/labelInput/LabelInput';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import LabelButton from '../../components/labelButton/LabelButton';
import Paper from '../../components/paper/Paper';
import SpinningLoaderPopup from '../spinningLoaderPopup/SpinningLoaderPopup';

export interface DestinationCategoryPopupProps extends PopupProps {
	title?: string;
	description?: string;
	onSave: (title?: any, description?: any) => void;
}

const DestinationCategoryPopup: React.FC<DestinationCategoryPopupProps> = (props) => {
	const [CategoryForm, setCategoryForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('description', props.description || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Must have a description'),
				new RsValidator(RsValidatorEnum.MAX, 'limit 500 characters', 500)
			]),
			new RsFormControl('title', props.title || '', [])
		])
	);

	async function checkFormIsValid(): Promise<boolean> {
		let isValid = await CategoryForm.isValid();
		setCategoryForm(CategoryForm.clone());
		return isValid;
	}

	async function save() {
		try {
			if (await checkFormIsValid()) {
				props.onSave(CategoryForm.get('title').value, CategoryForm.get('description').value);
				popupController.close(DestinationCategoryPopup);
				popupController.close(SpinningLoaderPopup);
			} else {
				popupController.close(SpinningLoaderPopup);
				rsToastify.error('Missing Category information.', 'Missing Information!');
			}
		} catch (e) {
			popupController.close(SpinningLoaderPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unexpected server error!'), 'Error!');
		}
	}

	return (
		<Popup {...props}>
			<Paper className={'rsTitleDescriptionPopup'} borderRadius={'4px'} width={'550px'} padding={'25px'}>
				<Icon
					iconImg={'icon-close'}
					cursorPointer
					onClick={() => popupController.close(DestinationCategoryPopup)}
				/>
				<LabelInput
					title={'title'}
					labelVariant={'h2'}
					inputType={'text'}
					initialValue={props.description || ''}
					control={CategoryForm.get('title')}
					updateControl={(control) => setCategoryForm(CategoryForm.clone().update(control))}
					isRequired
				/>
				<LabelInput
					title={'Description'}
					labelVariant={'h2'}
					inputType={'textarea'}
					initialValue={props.description || ''}
					control={CategoryForm.get('description')}
					updateControl={(control) => setCategoryForm(CategoryForm.clone().update(control))}
					isRequired
				/>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Save'}
					onClick={() => {
						save();
					}}
				/>
			</Paper>
		</Popup>
	);
};

export default DestinationCategoryPopup;
