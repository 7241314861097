import * as React from 'react';
import './ImageManager.scss';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import LabelCheckbox from '../labelCheckbox/LabelCheckbox';
import Icon from '@bit/redsky.framework.rs.icon';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ChangeEvent, useEffect, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import UploadService from '../../services/upload/upload.service';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import SpinningLoaderPopup, { SpinningLoaderPopupProps } from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import LabelRadioButton from '../labelRadioButton/LabelRadioButton';
import Label from '@bit/redsky.framework.rs.label';
import LabelButton from '../labelButton/LabelButton';
import AddImagePopup, { AddImagePopupProps, TitleDescription } from '../../popups/addImagePopup/AddImagePopup';
import TitleDescriptionPopup, {
	TitleDescriptionPopupProps
} from '../../popups/titleDescriptionPopup/TitleDescriptionPopup';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';

interface ImageManagerProps {
	data: Api.Media[];
	handleMediaData: (
		mediaData: {
			id: number;
			mediaIndex?: number | null;
			isPrimary: 1 | 0;
			image: string;
			title: string;
			description: string;
		}[],
		deleteImg: number[]
	) => void;
	handleMediaDataReorder?: (
		mediaData: {
			id: number;
			mediaIndex?: number | null;
			isPrimary: 1 | 0;
			image: string;
			title: string;
			description: string;
		}[]
	) => void;
	id: string;
}

const ImageManager: React.FC<ImageManagerProps> = (props) => {
	const [mediaDetails, setMediaDetails] = useState<
		{
			id: number;
			mediaIndex?: number | null;
			isPrimary: 1 | 0;
			image: string;
			title: string;
			description: string;
		}[]
	>([]);
	const [primaryImage, setPrimaryImage] = useState<number>(0);
	const [deleteImages, setDeleteImages] = useState<number[]>([]);

	useEffect(() => {
		if (!ObjectUtils.isArrayWithData(props.data)) return;
		setMediaDetails(
			props.data
				.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex)
				.map((img: Api.Media, index: number) => {
					if (img.isPrimary) {
						setPrimaryImage(img.id);
					}
					return {
						id: img.id,
						mediaIndex: img.mediaIndex,
						isPrimary: img.isPrimary,
						image: img.urls?.imageKit?.toString() || img.urls?.thumb?.toString(),
						title: img.title,
						description: img.description
					};
				})
		);
	}, [props.data]);

	useEffect(() => {
		props.handleMediaData(mediaDetails, deleteImages);
	}, [mediaDetails, deleteImages, primaryImage]);

	function uploadImage(newMedia: Api.Media.Res.Get, titleDescription?: TitleDescription) {
		setMediaDetails([
			...mediaDetails,
			{
				id: newMedia.id,
				isPrimary: newMedia.isPrimary,
				image: newMedia.urls.imageKit.toString(),
				title: !!titleDescription ? titleDescription.title : '',
				description: !!titleDescription ? titleDescription.description : ''
			}
		]);
	}

	function onChange(sourceId: any, sourceIndex: any, targetIndex: any, targetId: any) {
		const newData: any = [];

		if (sourceIndex === 0) {
			const nextState = swap(mediaDetails, sourceIndex, sourceIndex);
			nextState.map((items, index) => {
				newData.push({
					...items,
					mediaIndex: index
				});
			});
		} else {
			const nextState = swap(mediaDetails, sourceIndex, targetIndex);
			nextState.map((items, index) => {
				newData.push({
					...items,
					mediaIndex: index
				});
			});
		}

		setMediaDetails(newData);

		if (props.handleMediaDataReorder) {
			props.handleMediaDataReorder(newData);
		}
	}

	function renderImages() {
		return (
			<Box className={'imagesDisplay'}>
				{props.id === 'destinationImages' ? (
					<GridContextProvider onChange={onChange}>
						<GridDropZone
							id="items"
							boxesPerRow={4}
							rowHeight={260}
							style={{ height: 310 * Math.ceil(mediaDetails.length / 4) }}
						>
							{mediaDetails
								.sort((a: any, b: any) => b.isPrimary - a.isPrimary)
								.map((image, index) => (
									<GridItem key={image.id} style={{ lineHeight: '100px' }} className="griditemUI">
										<div className={'imageContainer'} key={image.id}>
											<img
												draggable="false"
												className={'uploadedImg'}
												src={image.image + '?tr=w-250,tr=h-auto'}
												alt={'reward'}
												onClick={() => {
													popupController.open<TitleDescriptionPopupProps>(
														TitleDescriptionPopup,
														{
															title: image.title,
															description: image.description,
															onSave(title, description) {
																let newMediaDetails = mediaDetails.filter(
																	(item) => item.id !== image.id
																);
																setMediaDetails([
																	...newMediaDetails,
																	{
																		id: image.id,
																		isPrimary: image.isPrimary,
																		image: image.image,
																		title: !!title ? title : '',
																		description: !!description ? description : ''
																	}
																]);
															}
														}
													);
												}}
											/>
											<Box className={'imageCheckbox'}>
												<LabelRadioButton
													radioName={'primaryImg'}
													value={image.id}
													checked={!!image.isPrimary}
													text={'Primary'}
													onSelect={(value) => {
														setPrimaryImage(image.id);
														setMediaDetails((prev) =>
															prev.map((media) => {
																return {
																	...media,
																	isPrimary: media.id === image.id ? 1 : 0
																};
															})
														);
													}}
												/>
											</Box>
											<Icon
												className={mediaDetails.length >= 1 ? 'deleteImage' : 'none'}
												iconImg={'icon-close'}
												onClick={(event) => {
													event.stopPropagation();
													if (primaryImage === image.id) {
														rsToastify.error('Cannot remove primary image.', 'Error!');
													} else {
														removeImage(image.id);
													}
												}}
											/>
										</div>
									</GridItem>
								))}
						</GridDropZone>
					</GridContextProvider>
				) : (
					<>
						{mediaDetails.map((image, index) => {
							return (
								<Box
									className={'imageContainer'}
									key={image.id}
									onClick={() => {
										popupController.open<TitleDescriptionPopupProps>(TitleDescriptionPopup, {
											title: image.title,
											description: image.description,
											onSave(title, description) {
												let newMediaDetails = mediaDetails.filter(
													(item) => item.id !== image.id
												);
												setMediaDetails([
													...newMediaDetails,
													{
														id: image.id,
														isPrimary: image.isPrimary,
														image: image.image,
														title: !!title ? title : '',
														description: !!description ? description : ''
													}
												]);
											}
										});
									}}
								>
									<img
										className={'uploadedImg'}
										src={image.image + '?tr=w-250,tr=h-auto'}
										alt={'reward'}
									/>
									<Box className={'imageCheckbox'}>
										<LabelRadioButton
											radioName={'primaryImg'}
											value={image.id}
											checked={!!image.isPrimary}
											text={'Primary'}
											onSelect={(value) => {
												setPrimaryImage(image.id);
												setMediaDetails((prev) =>
													prev.map((media) => {
														return { ...media, isPrimary: media.id === image.id ? 1 : 0 };
													})
												);
											}}
										/>
									</Box>
									<Icon
										className={mediaDetails.length >= 1 ? 'deleteImage' : 'none'}
										iconImg={'icon-close'}
										onClick={(event) => {
											event.stopPropagation();
											if (primaryImage === image.id) {
												rsToastify.error('Cannot remove primary image.', 'Error!');
											} else {
												removeImage(image.id);
											}
										}}
									/>
								</Box>
							);
						})}
					</>
				)}
			</Box>
		);
	}

	function removeImage(imageId: number) {
		setMediaDetails(mediaDetails.filter((image) => image.id !== imageId));
		setDeleteImages([...deleteImages, imageId]);
	}

	return (
		<Box className={'rsImageManager'}>
			<LabelButton
				className={'uploadBtn'}
				look={'containedPrimary'}
				variant={'button'}
				label={'Upload Files'}
				onClick={() => {
					popupController.open<AddImagePopupProps>(AddImagePopup, {
						onSave: uploadImage
					});
				}}
			/>
			{mediaDetails.length < 1 ? (
				<Label variant={'caption'} color={'red'}>
					Please upload an image
				</Label>
			) : (
				renderImages()
			)}
		</Box>
	);
};

export default ImageManager;
