import * as React from 'react';
import './ImageTile.scss';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import LabelRadioButton from '../labelRadioButton/LabelRadioButton';

interface ImageTileProps {
	mediaId: number;
	imgUrl: string | undefined;
	title?: string;
	description?: string;
	onDelete?: () => void;
	onClick?: () => void;
	isPrimary: boolean;
	onChangePrimary?: (value: number) => void;
}

const ImageTile: React.FC<ImageTileProps> = (props) => {
	return (
		<Box className="rsImageTile" display={'flex'} alignItems={'center'} onClick={props.onClick}>
			<Icon
				className={'deleteIcon'}
				iconImg={'icon-trash'}
				cursorPointer
				onClick={(event) => {
					if (props.onDelete) props.onDelete();
					event?.stopPropagation();
				}}
			/>
			<img src={props.imgUrl} alt={''} />
			<Box marginLeft={'25px'} width={'300px'}>
				<Label variant={'h4'}>{props.title || ''}</Label>
				<Label variant={'body2'}>{props.description || ''}</Label>
			</Box>
			<LabelRadioButton
				radioName={'primaryImg'}
				value={props.mediaId}
				checked={props.isPrimary}
				text={'Primary'}
				onSelect={(value) => {
					if (props.onChangePrimary) props.onChangePrimary(props.mediaId);
				}}
			/>
		</Box>
	);
};

export default ImageTile;
