import { useEffect, useState } from 'react';
import globalState, { clearPersistentState } from '../state/globalState';
import serviceFactory from '../services/serviceFactory';
import UserService from '../services/user/user.service';
import { useRecoilState, useRecoilValue } from 'recoil';
import router from '../utils/router';
import { WebUtils } from '../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';

export enum LoginStatus {
	UNKNOWN,
	LOGGED_OUT,
	LOGGED_IN
}

/* Can only be used in App.tsx */
export default function useLoginState() {
	const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.UNKNOWN);
	const [adminToken, setAdminToken] = useRecoilState<string>(globalState.adminToken);
	const userService = serviceFactory.get<UserService>('UserService');
	const user = useRecoilValue<Api.User.Res.Get | undefined>(globalState.user);

	useEffect(() => {
		if (loginStatus === LoginStatus.UNKNOWN) return;
		router.tryToLoadInitialPath();
	}, [loginStatus]);

	useEffect(() => {
		// Determine if our token is valid or not
		if (loginStatus === LoginStatus.UNKNOWN) return;

		if (!user) {
			setLoginStatus(LoginStatus.LOGGED_OUT);
		} else {
			setLoginStatus(LoginStatus.LOGGED_IN);
			setAdminToken(user.token);
		}
	}, [loginStatus, setAdminToken, user]);

	useEffect(() => {
		async function initialStartup() {
			if (!adminToken) {
				setLoginStatus(LoginStatus.LOGGED_OUT);
				return;
			}

			try {
				await userService.loginUserByToken(adminToken);
				setLoginStatus(LoginStatus.LOGGED_IN);
			} catch (e) {
				clearPersistentState();
				setLoginStatus(LoginStatus.LOGGED_OUT);
				rsToastify.error(
					WebUtils.getRsErrorMessage(
						e,
						'Login verification has expired, please login again to verify your identity'
					),
					'Login Error'
				);
				router.navigate('/').catch(console.error);
			}
		}
		initialStartup().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loginStatus;
}
