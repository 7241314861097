import './TransactionReport.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { Page } from '@bit/redsky.framework.rs.996';
import Button from '@bit/redsky.framework.rs.button';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import Label from '@bit/redsky.framework.rs.label';

import SubHeader from '../../components/subHeader/SubHeader';
import serviceFactory from '../../services/serviceFactory';
import TransactionService from '../../services/transactions/transactions.service';
import { WebUtils } from '../../utils/utils';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';

const TransactionReportListPage: React.FC = () => {
	const transactionService = serviceFactory.get<TransactionService>('TransactionService');
	const gridRef = useRef<any>();
	const [rowData, setRowData] = useState<Api.Transaction.Res.Transaction[]>([]);

	async function getData(filter?: Api.Transaction.Req.Filter) {
		try {
			let res = await transactionService.getAll(filter);
			setRowData(res);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	const columnDefs = [
		// {
		// 	field: 'id',
		// 	headerCheckboxSelection: true,
		// 	headerCheckboxSelectionFilteredOnly: true,
		// 	checkboxSelection: true,
		// 	sortable: true
		// },
		{
			// field: 'transactionDate',
			field: 'createdOn',
			headerName: 'Date Created',
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			sortable: true,
			// type: ['dateColumn'],
			cellRenderer: (params: any) =>
				`${new Date(params.value).getMonth() + 1}/${new Date(params.value).getDate()}/${new Date(
					params.value
				).getFullYear()}`,
			filter: 'agDateColumnFilter',
			// add extra parameters for the date filter
			filterParams: {
				comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
					var dateAsString = cellValue;
					if (dateAsString == null) return -1;
					var dateParts = moment(dateAsString).format('DD-MM-YYYY').split('-');
					var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
					if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
						return 0;
					}
					if (cellDate < filterLocalDateAtMidnight) {
						return -1;
					}
					if (cellDate > filterLocalDateAtMidnight) {
						return 1;
					}
				}
			}
		},
		{ field: 'companyName', headerName: 'Company Name', sortable: true },
		{
			field: 'isAffirmCheckout',
			headerName: 'Affirm Checkout',
			sortable: true,
			cellRenderer: (params: any) => {
				return params.value ? 'YES' : 'NO';
			}
		},
		{ field: 'businessDestination', headerName: 'Business/Destination', sortable: true, filter: true },
		// { field: 'merchantCompany', filter: true, sortable: true },
		{ field: 'firstName', headerName: 'First Name', filter: true, sortable: true },
		{ field: 'lastName', headerName: 'Last Name', filter: true, sortable: true },
		{ field: 'transactionType', headerName: 'Transaction Type', filter: true, sortable: true },
		{ field: 'transactionStatus', headerName: 'Transaction Status', sortable: true },
		{ field: 'points', headerName: 'Points', sortable: true },
		{ field: 'perPointCost', headerName: 'Per Point Cost', sortable: true },
		{ field: 'redeemValue', headerName: 'Redeem Value', sortable: true },
		{ field: 'totaltoMerchant', headerName: 'Total to Merchant', sortable: true },
		{ field: 'totalTransaction', headerName: 'Total Transaction', sortable: true },
		{ field: 'transactionTaxPackages', headerName: 'Transaction Tax/Packages', sortable: true },
		{ field: 'transactionSubtotal', headerName: 'Transaction Subtotal', sortable: true },
		{ field: 'spireMargin', headerName: 'Spire Margin', sortable: true }
		// { field: 'pointsPerDollar', sortable: true },
		// { field: 'costPerPoint', sortable: true },
		// { field: 'taxAndFeeTotalInCents', headerName: 'Transaction Tax Amount', sortable: true },
		// { field: 'subtotalInCents', headerName: 'Transaction Subtotal', sortable: true },
		// { field: 'grandTotalCents', headerName: 'Total Transaction Amount', sortable: true }
		// { field: 'priceDetail.accommodationTotalInCents', headerName: 'Total Cost To Merchant', sortable: true }
	];

	useEffect(() => {
		getData();
	}, []);

	const onDownloadCSV = useCallback(() => {
		// console.log(gridRef.current.api.getModel().rowsToDisplay.map((item:any) => item.data))
		gridRef.current.api.exportDataAsCsv({
			fileName: `Transaction Report ${new Date().toLocaleString()}.csv`,
			onlySelected: !!gridRef.current.api.getSelectedRows().length,
			skipRowGroups: !gridRef.current.api.getSelectedRows().length
		});
	}, []);

	const onDownloadPDF = () => {
		const pdf = new jsPDF('l', 'pt', 'a4');
		const columns = [
			'Date Created',
			// 'Merchant Brand',
			// "Transaction Status",
			// 'Merchant Business',
			// 'Merchant Company',
			'Company Name',
			'Business/Destination',
			'First Name',
			'Last Name',
			'Transaction Type',
			'Transaction Status',
			'Points',
			'Per Point Cost',
			'Redeem Value',
			'Total to Merchant',
			'Total Transaction',
			'Transaction Tax/Packages',
			'Transaction Subtotal',
			'Spire Margin'
			// 'Transaction Date',
			// 'Points Per Dollar',
			// 'Cost Per Point',
			// 'Transaction Tax Amount',
			// 'Transaction Subtotal',
			// 'Total Transaction Amount'
		];
		var rows = [];

		const selectedRows = gridRef.current.api.getSelectedRows();
		const visibleRows = gridRef.current.api.getModel().rowsToDisplay.map((item: any) => item.data);
		const data = selectedRows && selectedRows.length ? selectedRows : visibleRows;

		for (let i = 0; i < data.length; i++) {
			var temp = [
				moment(data[i].createdOn).format('DD/MM/YYYY').split('-'),
				data[i].companyName,
				data[i].businessDestination,
				data[i].firstName,
				data[i].lastName,
				data[i].transactionType,
				data[i].transactionStatus,
				data[i].points,
				data[i].perPointCost,
				data[i].redeemValue,
				data[i].totaltoMerchant,
				data[i].totalTransaction,
				data[i].transactionTaxPackages,
				data[i].transactionSubtotal,
				data[i].spireMargin
			];
			rows.push(temp);
		}
		pdf.text('Transaction Report', 235, 40);
		// @ts-ignore: Unreachable code error
		pdf.autoTable(columns, rows, {
			startY: 65,
			theme: 'grid',
			styles: {
				font: 'times',
				halign: 'center',
				cellPadding: 3.5,
				lineWidth: 0.5,
				lineColor: [0, 0, 0],
				textColor: [0, 0, 0]
			},
			headStyles: {
				textColor: [0, 0, 0],
				fontStyle: 'normal',
				lineWidth: 0.5,
				lineColor: [0, 0, 0],
				fillColor: [166, 204, 247]
			},
			alternateRowStyles: {
				fillColor: [212, 212, 212],
				textColor: [0, 0, 0],
				lineWidth: 0.5,
				lineColor: [0, 0, 0]
			},
			rowStyles: {
				lineWidth: 0.5,
				lineColor: [0, 0, 0]
			},
			tableLineColor: [0, 0, 0]
		});
		pdf.save(`Transaction Report ${new Date().toLocaleString()}`);
	};

	const onQuickFilterChanged = useCallback(() => {
		gridRef.current.api.setQuickFilter(
			// @ts-ignore: Unreachable code error
			document.getElementById('quickFilter')?.value
		);
	}, []);

	return (
		<Page className={'rsTransactionsPage'}>
			{/* {console.log(rowData)}/ */}
			<SubHeader
				header={'Transactions'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Transactions', link: '/dashboard/transaction-report' }
				]}
			/>
			<div className="tableHeaderBtnContainer">
				<div style={{ marginBottom: '5px' }}>
					<input
						type="text"
						onInput={onQuickFilterChanged}
						id="quickFilter"
						className="quickFilter"
						placeholder="Search..."
					/>
				</div>
				<Button look={'containedPrimary'} onClick={onDownloadCSV}>
					<Label variant={'h4'}>Download CSV</Label>
				</Button>
				<Button look={'containedPrimary'} onClick={onDownloadPDF}>
					<Label variant={'h4'}>Download PDF</Label>
				</Button>
			</div>
			<div
				className="ag-theme-alpine"
				style={{
					height: '500px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={rowData}
					columnDefs={columnDefs}
					pagination={true}
					onPaginationChanged={(event: any) => {
						console.log(event);
					}}
					paginationAutoPageSize={true}
					animateRows={true}
					maxBlocksInCache={10}
					rowSelection="multiple"
				/>
			</div>
		</Page>
	);
};

export default TransactionReportListPage;
