import * as React from 'react';
import './AddNewAddressPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import router from '../../utils/router';
import Box from '../../components/box/Box';
import SubHeader from '../../components/subHeader/SubHeader';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import { US_States, WebUtils } from '../../utils/utils';
import LabelButton from '../../components/labelButton/LabelButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import Input from '@bit/redsky.framework.rs.input';
import Label from '@bit/redsky.framework.rs.label';
import debounce from 'lodash.debounce';
import serviceFactory from '../../services/serviceFactory';
import UserAddressService from '../../services/userAddress/userAddress.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { useEffect, useState } from 'react';
import UserService from '../../services/user/user.service';
import CountryService from '../../services/country/country.service';

interface AddNewAddressPageProps {}

export declare type OptionType = {
	value: string | number;
	label: string | number;
};

const AddNewAddressPage: React.FC<AddNewAddressPageProps> = () => {
	const userService = serviceFactory.get<UserService>('UserService');
	const userAddressService = serviceFactory.get<UserAddressService>('UserAddressService');
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const [countryOptions, setCountryOptions] = useState<OptionType[]>([]);
	const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
	const [userId, setUserId] = useState<number>(0);
	let selectedState: string = '';
	const params = router.getPageUrlParams<{ userId: number }>([
		{ key: 'ui', default: 0, type: 'integer', alias: 'userId' }
	]);
	const [form, setUserForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('firstName', '', [new RsValidator(RsValidatorEnum.REQ, 'First Name is required')]),
			new RsFormControl('lastName', '', [new RsValidator(RsValidatorEnum.REQ, 'Last Name is required')]),
			new RsFormControl('address1', '', [new RsValidator(RsValidatorEnum.REQ, 'Address is required')]),
			new RsFormControl('address2', '', []),
			new RsFormControl('city', '', [new RsValidator(RsValidatorEnum.REQ, 'City is required')]),
			new RsFormControl('state', '', [new RsValidator(RsValidatorEnum.REQ, 'State is required')]),
			new RsFormControl('zip', '', [new RsValidator(RsValidatorEnum.REQ, 'Zip is required')]),
			new RsFormControl('country', 'US', [new RsValidator(RsValidatorEnum.REQ, 'Country is required')])
		])
	);

	useEffect(() => {
		async function getUserId(id: number) {
			try {
				setUserId(id);
			} catch (e) {
				console.error(e);
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error retrieving user.'), 'Server Error');
			}
		}
		getUserId(params.userId).catch(console.error);
	}, []);

	useEffect(() => {
		async function getCountries() {
			try {
				let countries = await countryService.getAllCountries();
				setCountryOptions(formatStateOrCountryListForSelect(countries.countries));
			} catch (e) {
				console.error('getCountries', e);
				throw rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get a list of countries.'),
					'Server Error'
				);
			}
		}
		getCountries().catch(console.error);
	}, []);

	useEffect(() => {
		async function getStates() {
			try {
				let response = await countryService.getStates(`${form.get('country').value}` || 'US');
				if (response.states) {
					let newStates = formatStateOrCountryListForSelect(response.states);
					setStateOptions(newStates);
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);
			}
		}
		getStates().catch(console.error);
	}, [countryOptions, form.get('country').value]);

	function formatStateOrCountryListForSelect(statesOrCountries: { name: string; isoCode: string }[]) {
		return statesOrCountries.map((item: { name: string; isoCode: string }) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	function updateUserForm(control: RsFormControl) {
		setUserForm(form.clone().update(control));
	}

	async function saveNewAddress() {
		if (!(await form.isValid()) && selectedState === '')
			return rsToastify.error('Check ALL fields and try again.', 'Missing or Incorrect Information');
		let newAddress: any = form.toModel();
		if (newAddress.address2 === '') delete newAddress.address2;
		let userDetails = await userService.getUserById(userId);
		newAddress.userId = params.userId;
		newAddress.type = 'SHIPPING';
		//newAddress.state = selectedState;
		newAddress.name = newAddress.firstName + ' ' + newAddress.lastName;
		newAddress.isDefault = 0;
		if (userDetails.address.length === 0) newAddress.isDefault = 1;
		try {
			await userAddressService.create(newAddress);
			rsToastify.success('Created Address', 'Success');
			router.navigate(`/dashboard/user-list/manage-user?ui=${params.userId}`).catch(console.error);
		} catch (e) {
			rsToastify.error('Unable to create new address', 'Unable to create');
		}
	}

	let searchDebounced = debounce(async (control: RsFormControl) => {
		form.clone().update(control);
	}, 500);

	return (
		<Page className={'rsAddNewAddressPage'}>
			<SubHeader
				header={'Add New Address'}
				crumbs={[
					{ label: 'Dashboard', link: `/dashboard` },
					{ label: 'User List', link: `/dashboard/user-list` },
					{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${params.userId}` },
					{
						label: 'Create Address',
						link: `/dashboard/user-list/manage-user/create-address?ui=${params.userId}`
					}
				]}
			/>
			<div className={'inputContainer'}>
				<Box marginBottom={30}>
					<Label variant={'h2'}>First Name</Label>
					<Input
						noAutocomplete
						placeholder={'First Name'}
						type={'text'}
						look={'outlined'}
						color={'#858585'}
						control={form.get('firstName')}
						updateControl={updateUserForm}
					/>
				</Box>
				<Box marginBottom={30}>
					<Label variant={'h2'}>Last Name</Label>
					<Input
						noAutocomplete
						placeholder={'Last Name'}
						type={'text'}
						look={'outlined'}
						color={'#858585'}
						control={form.get('lastName')}
						updateControl={updateUserForm}
					/>
				</Box>
				<Box marginBottom={30}>
					<Label variant={'h2'}>Address Line 1</Label>
					<Input
						noAutocomplete
						placeholder={'Address Line 1'}
						type={'text'}
						look={'outlined'}
						color={'#858585'}
						control={form.get('address1')}
						updateControl={updateUserForm}
					/>
				</Box>
				<Box marginBottom={30}>
					<Label variant={'h2'}>Address Line 2</Label>
					<Input
						noAutocomplete
						placeholder={'Address Line 2'}
						type={'text'}
						look={'outlined'}
						color={'#858585'}
						control={form.get('address2')}
						updateControl={updateUserForm}
					/>
				</Box>
				<Box className={'cityState'} display={'flex'} justifyContent={'space-between'}>
					<Box marginBottom={30}>
						<Label variant={'h2'}>City</Label>
						<Input
							noAutocomplete
							placeholder={'City'}
							type={'text'}
							look={'outlined'}
							color={'#858585'}
							control={form.get('city')}
							updateControl={updateUserForm}
						/>
					</Box>
					<Box marginBottom={30}>
						<LabelSelect
							className={'labelSelect'}
							variant={'h2'}
							title={'State'}
							options={stateOptions}
							control={form.get('state')}
							updateControl={(control) => {
								setUserForm(form.clone().update(control));
							}}
						/>
					</Box>
					<Box marginBottom={30}>
						<Label variant={'h2'}>Zip</Label>
						<Input
							noAutocomplete
							placeholder={'Zip'}
							type={'text'}
							look={'outlined'}
							color={'#858585'}
							control={form.get('zip')}
							updateControl={updateUserForm}
						/>
					</Box>

					<Box marginBottom={30}>
						<LabelSelect
							className={'labelSelect'}
							variant={'h2'}
							title={'Country'}
							options={countryOptions}
							control={form.get('country')}
							updateControl={(control) => {
								setUserForm(form.clone().update(control));
							}}
						/>
					</Box>
				</Box>
			</div>

			{/*<LabelInput title={'Name'} onChange={(value) => (newAddress.name = value)} inputType={'text'} />*/}
			{/*<LabelInput*/}
			{/*	title={'Address Line 1'}*/}
			{/*	onChange={(value) => (newAddress.address1 = value)}*/}
			{/*	inputType={'text'}*/}
			{/*/>*/}
			{/*<LabelInput*/}
			{/*	title={'Address Line 2'}*/}
			{/*	onChange={(value) => (newAddress.address2 = value)}*/}
			{/*	inputType={'text'}*/}
			{/*/>*/}
			{/*<Box className={'cityState'} display={'flex'} justifyContent={'space-between'}>*/}
			{/*	<LabelInput title={'City'} onChange={(value) => (newAddress.city = value)} inputType={'text'} />*/}
			{/*	<LabelSelect*/}
			{/*		title={'State'}*/}
			{/*		options={US_States}*/}
			{/*		onChange={(value) => (newAddress.state = value)}*/}
			{/*		currentValue={''}*/}
			{/*	/>*/}
			{/*	<LabelInput title={'Zip'} onChange={(value) => (newAddress.zip = value)} inputType={'number'} />*/}
			{/*</Box>*/}

			<LabelButton
				look={'containedPrimary'}
				variant={'button'}
				label={'Save New Address'}
				onClick={saveNewAddress}
			/>
		</Page>
	);
};

export default AddNewAddressPage;
