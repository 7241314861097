import * as React from 'react';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import { useState } from 'react';
import './ManageCampaignAuditReport.scss';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import SpireTable from '../../components/spireTable/SpireTable';
import Box from '../../components/box/Box';
import LabelButton from '../../components/labelButton/LabelButton';
import router from '../../utils/router';
import BrandService from '../../services/brand/brand.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import UserPointService from '../../services/userPoint/userPoint.service';
import CampaignService from '../../services/campaign/campaign.service';

interface ManageCampaignAuditReportPageProps {}

const ManageCampaignAuditReport: React.FC<ManageCampaignAuditReportPageProps> = () => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const CampaignService = serviceFactory.get<CampaignService>('CampaignService');
	const [campaignAuditReport, setCampaignAuditReport] = useState<Api.User.Res.UserPointReport[]>([]);
	const [sortField, setSortField] = useState<string>('id');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [userPointTotal, setUserPointTotal] = useState<number>(0);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await CampaignService.getCampaignAuditReport(pageQuery);
			setCampaignAuditReport(res.data);
			setUserPointTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/brand-list/brand-details?bi=' + data.id).catch(console.error);
	}

	function renderOldField(data: any) {
		if (data.oldField) {
			return JSON.stringify(data.oldField);
		} else {
			return 'Any ';
		}
	}

	return (
		<Page className={'rsManageCampaignAuditReport'}>
			<Box className={'subNavContainer'}>
				<SubHeader
					header={'Campaign Audit Report'}
					crumbs={[
						{ label: 'Dashboard', link: '/dashboard' },
						{ label: 'Campaign Audit Report', link: `/dashboard/campaign-audit-report` }
					]}
				/>
				{ObjectUtils.isArrayWithData(user?.businessAccesses.companyIds) && (
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Campaign Audit Report'}
						onClick={() => {
							router.navigate('/dashboard/campaign-audit-report').catch(console.error);
							// popupController.open<BrandsReportPopupProps>(BrandsReportPopup);
						}}
					/>
				)}
			</Box>
			<SpireTable
				table={{
					placeholder: 'Search by Id or Campaign Id',
					filterQuery: [
						{ column: 'id', value: '' },
						{ column: 'campaignId', value: '', conjunction: 'OR' }
					]
				}}
				columns={[
					{
						id: 'id',
						label: 'ID',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'id' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'campaignId',
						label: 'Campaign Id',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'campaignId' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'userId',
						label: 'User Id',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'campaignName',
						label: 'Campaign Name',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'type',
						label: 'State Change',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'oldField.*',
						label: 'Old Field',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: renderOldField
					},
					{
						id: 'newField',
						label: 'New Field',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: renderOldField
					}
				]}
				data={campaignAuditReport}
				total={userPointTotal}
				sortField={'id'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default ManageCampaignAuditReport;

export function manageBrandsPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_READ]);
}
