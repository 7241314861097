import * as React from 'react';
import './ManageExperienceIcons.scss';
import Box from '../../components/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import LabelButton from '../../components/labelButton/LabelButton';
import { popupController } from '@bit/redsky.framework.rs.996';
import { useEffect, useState } from 'react';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import IconFeatureTile from '../../components/iconFeatureTile/IconFeatureTile';
import ConfirmDeletePopup, { ConfirmDeletePopupProps } from '../../popups/confirmDeletePopup/ConfirmDeletePopup';
import IconFeaturePopup, { IconFeaturePopupProps } from '../../popups/iconFeaturePopup/IconFeaturePopup';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import ExperienceService from '../../services/experience/experience.service';

interface ManageExperienceIconsProps {}

const ManageExperienceIcons: React.FC<ManageExperienceIconsProps> = () => {
	const experienceService = serviceFactory.get<ExperienceService>('ExperienceService');
	const [experienceIcons, setExperienceIcons] = useState<Api.Experience.Res.Get[]>([]);
	const [reloadPage, setReloadPage] = useState<number>(1);

	useEffect(() => {
		async function getAllExperienceIcons() {
			try {
				const experiences = await experienceService.getAllExperiences();
				setExperienceIcons(experiences);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'No results found.'), 'Error!');
			}
		}
		getAllExperienceIcons().catch(console.error);
	}, [reloadPage]);

	async function deleteExperienceIcon(id: number) {
		try {
			await experienceService.deleteExperience({ id });
			rsToastify.success('Icon deleted successfully', 'Success');
			setReloadPage(reloadPage + 1);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'No results found.'), 'Error!');
		}
	}

	async function saveIconInfo(icon: string, title: string, id?: number) {
		if (id) {
			await experienceService.updateExperience({
				id: id,
				icon,
				title
			});
			setReloadPage(reloadPage + 1);
			popupController.closeAll();
		} else {
			await experienceService.createExperience({ title, icon });
			setReloadPage(reloadPage + 1);
			popupController.closeAll();
		}
	}

	function renderExperienceIcons() {
		if (!ObjectUtils.isArrayWithData(experienceIcons)) return;
		return experienceIcons.map((item) => {
			return (
				<IconFeatureTile
					key={item.id}
					title={item.title}
					icon={item.icon}
					onClick={() => {
						popupController.open<IconFeaturePopupProps>(IconFeaturePopup, {
							id: item.id,
							icon: item.icon,
							title: item.title,
							onSave: saveIconInfo
						});
					}}
					onDelete={() => {
						popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
							onDelete: () => {
								deleteExperienceIcon(item.id).catch(console.error);
							}
						});
					}}
				/>
			);
		});
	}

	return (
		<Box className={'rsManageExperienceIcons'}>
			<SubHeader
				header={'Manage Experience Icons'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Experience Icons', link: '/dashboard/experience-icons' }
				]}
			/>
			<Box display={'flex'} justifyContent={'space-around'}>
				<Label variant={'h1'}>Experience Icons</Label>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Add Experience'}
					onClick={() =>
						popupController.open<IconFeaturePopupProps>(IconFeaturePopup, {
							icon: '',
							title: '',
							onSave: saveIconInfo
						})
					}
				/>
			</Box>
			<Box className={'iconContainer'}>{renderExperienceIcons()}</Box>
		</Box>
	);
};

export default ManageExperienceIcons;

export function manageExperienceIconsGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_READ]);
}
