import React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './LoginVerifyExpiredPage.scss';
import Box from '../../components/box/Box';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Button from '@bit/redsky.framework.rs.button/dist/Button';
import router from '../../utils/router';

const LoginVerifyExpiredPage: React.FC = () => {
	function navigateHome() {
		router.navigate('/');
	}

	return (
		<Page className="rsLoginVerifyExpiredPage">
			<Box
				className="loginExpiredContainer"
				display={'flex'}
				flexDirection={'column'}
				alignItems={'center'}
				p={'22px 68px'}
				marginTop={'100px'}
			>
				<Box className="titleContainer" display={'flex'} flexDirection={'column'} alignItems={'center'}>
					<Label className="loginExpiredHeader" variant={'h1'}>
						Oops! It looks like your verification link has expired.
					</Label>
					<Label className="loginExpiredSubheader" variant={'h4'}>
						Please sign in again to generate a new link or if you have already verified, just sign in.
					</Label>
				</Box>
				<Button className="signInButton" look={'containedPrimary'} onClick={navigateHome}>
					SIGN IN
				</Button>
			</Box>
		</Page>
	);
};

export default LoginVerifyExpiredPage;
