import * as React from 'react';
import iconJson from '../icons/cmsIcons/selection.json';
import Icon from '@bit/redsky.framework.rs.icon';

export function useMultiColoredIcon(iconName: string, size: number) {
	const newSelectedIcon = iconJson.icons.find((icon) => icon.icon.tags[0] === iconName);
	if (newSelectedIcon && newSelectedIcon.icon.isMulticolor) {
		return (
			<span className={`cms-${iconName} rsIcon`} style={{ fontSize: `${size}px` }}>
				{newSelectedIcon.icon.paths.map((path: any, index: number) => {
					return <span key={path} className={`path${index + 1}`} />;
				})}
			</span>
		);
	} else {
		return <Icon iconImg={`cms-${iconName}`} size={size} />;
	}
}
