import * as React from 'react';
import './DestinationPackagePopup.scss';
import serviceFactory from '../../services/serviceFactory';
import { useEffect, useState } from 'react';
import AddImagePopup, { AddImagePopupProps } from '../addImagePopup/AddImagePopup';
import ImageTile from '../../components/imageTile/ImageTile';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import PackageService from '../../services/package/package.service';
import MediaService from '../../services/media/media.service';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import ConfirmDeletePopup, { ConfirmDeletePopupProps } from '../confirmDeletePopup/ConfirmDeletePopup';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';

export interface DestinationPackagePopupProps extends PopupProps {
	package: Api.UpsellPackage.Details;
	onSave?: (updatedPackage: Api.UpsellPackage.Details) => void;
}

const DestinationPackagePopup: React.FC<DestinationPackagePopupProps> = (props) => {
	const packageService = serviceFactory.get<PackageService>('PackageService');
	const mediaService = serviceFactory.get<MediaService>('MediaService');
	const [canUsePoints, setCanUsePoints] = useState<0 | 1>(props.package.canUsePoints ? 1 : 0);
	const [mediaDataArray, setMediaDataArray] = useState<Api.Media.Res.Get[]>(props.package.media || []);
	const [packageObj, setPackageObj] = useState<Api.UpsellPackage.Req.Update>({
		id: props.package.id,
		mediaIds: props.package.media.map((media) => {
			return { id: media.id, isPrimary: media.isPrimary };
		})
	});
	const [titleDescription, setTitleDescription] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('title', props.package.title || '', []),
			new RsFormControl('description', props.package.description || '', [])
		])
	);

	useEffect(() => {
		let newFeatureObj = { ...packageObj };
		newFeatureObj.mediaIds = mediaDataArray.map((item) => {
			return {
				id: item.id,
				isPrimary: isPrimaryMediaItem(item.id) ? 1 : 0
			};
		});
		setPackageObj(newFeatureObj);
	}, [mediaDataArray]);

	function isPrimaryMediaItem(mediaId: number): boolean {
		if (!packageObj || !packageObj.mediaIds) return false;
		let isPrimary = false;
		packageObj.mediaIds.forEach((mediaItem) => {
			if (mediaItem.id === mediaId && mediaItem.isPrimary) {
				isPrimary = true;
				return false;
			}
		});
		return isPrimary;
	}

	function renderImageTiles() {
		let newMediaIds: Api.MediaDetails[] = [];
		let newMediaArray = mediaDataArray.map((item) => {
			newMediaIds.push({ id: item.id, isPrimary: 0 });
			return (
				<ImageTile
					key={item.id}
					mediaId={item.id}
					imgUrl={item.urls.small}
					title={item.title}
					description={item.description}
					isPrimary={isPrimaryMediaItem(item.id)}
					onClick={() =>
						popupController.open<AddImagePopupProps>(AddImagePopup, {
							title: item.title,
							description: item.description,
							mediaId: item.id,
							url: item.urls.small,
							onSave: (image, titleDescription) => {
								let value = { ...image, ...titleDescription };
								addMediaToFeature(value, true);
							}
						})
					}
					onDelete={() => {
						popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
							onDelete: () => {
								if (!packageObj || !packageObj.mediaIds) return;
								if (deleteMedia(item.id)) {
									setMediaDataArray(mediaDataArray.filter((value) => value.id !== item.id));
									let newFeatureObj = packageObj.mediaIds.filter((value) => value.id !== item.id);
									updateFeatureObj('mediaIds', newFeatureObj);
								}
							}
						});
					}}
					onChangePrimary={(value) => {
						let newPrimaryMedia = newMediaIds.map((media) => {
							return {
								id: media.id,
								isPrimary: media.id === value ? 1 : 0
							};
						});
						updateFeatureObj('mediaIds', newPrimaryMedia);
					}}
				/>
			);
		});
		return newMediaArray;
	}

	function addMediaToFeature(value: Api.Media.Res.Get, isEdit: boolean = false) {
		if (!isEdit) return setMediaDataArray([...mediaDataArray, value]);
		let newMediaDataArray = [...mediaDataArray];
		newMediaDataArray = newMediaDataArray.filter((item) => item.id !== value.id);
		setMediaDataArray([...newMediaDataArray, value]);
	}

	function updateFeatureObj(key: 'description' | 'mediaIds' | 'title', value: any) {
		setPackageObj((prev) => {
			let newFeatureObj: any = { ...prev };
			newFeatureObj[key] = value;
			return newFeatureObj;
		});
	}

	async function deleteMedia(id: number) {
		try {
			let response = await mediaService.deleteMedia({ id });
			if (response) return true;
		} catch (e) {
			console.log(e);
		}
		return false;
	}

	function updateTitleDescription(control: RsFormControl) {
		titleDescription.update(control);
		setTitleDescription(titleDescription.clone());
	}

	async function onSave() {
		let titleDescriptionObj: { title: string; description: string } = titleDescription.toModel();
		let newPackageObj: Api.UpsellPackage.Req.Update = { ...packageObj, ...titleDescriptionObj, canUsePoints };
		try {
			let response = await packageService.updatePackage(newPackageObj);
			if (props.onSave) props.onSave(response);
			if (!!response) popupController.close(DestinationPackagePopup);
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsDestinationPackagePopup'} borderRadius={'4px'} width={'550px'} padding={'25px'}>
				<Box className={'popupHeader'}>
					<Icon
						iconImg={'icon-close'}
						cursorPointer
						onClick={() => popupController.close(DestinationPackagePopup)}
					/>
					<Label className={'header'} variant={'h4'} ml={'10px'}>
						Package
					</Label>
				</Box>
				<Box className={'popupBody'} mt={'15px'}>
					<Box mt={'10px'} mb={'10px'} display="flex" justifyContent="space-between" alignItems="center">
						<LabelInput
							title={'Display Title'}
							inputType={'text'}
							control={titleDescription.get('title')}
							updateControl={updateTitleDescription}
						/>
						<LabelCheckbox
							value={'canUsePoints'}
							onDeselect={() => {
								setCanUsePoints(0);
							}}
							onSelect={() => {
								setCanUsePoints(1);
							}}
							text="Can use with Points?"
							isChecked={!!canUsePoints}
						/>
					</Box>
					<LabelInput
						title={'Description'}
						inputType={'textarea'}
						initialValue={props.package.description || ''}
						control={titleDescription.get('description')}
						updateControl={updateTitleDescription}
					/>
					<hr />
					<Box display={'flex'} alignItems={'center'}>
						<Label variant={'h2'}>Images</Label>
						<LabelButton
							className={'addImageButton'}
							look={'containedPrimary'}
							variant={'button'}
							label={'Add Image'}
							onClick={() =>
								popupController.open<AddImagePopupProps>(AddImagePopup, {
									onSave: (image, titleDescription) => {
										let value = { ...image, ...titleDescription };
										addMediaToFeature(value);
									}
								})
							}
						/>
					</Box>
					<Box maxHeight={'300px'} overflow={'auto'}>
						{renderImageTiles()}
					</Box>
					<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
						<Box display={'flex'} flexDirection={'column'}>
							<Label variant={'h2'}>External Title</Label>
							<Label variant={'body1'}>{props.package.externalTitle}</Label>
						</Box>
						<Label variant={'h4'}>Package Code: {props.package.code}</Label>
					</Box>
				</Box>
				<LabelButton
					className={'saveButton'}
					look={'containedPrimary'}
					variant={'button'}
					label={'Save'}
					onClick={() => onSave()}
				/>
			</Paper>
		</Popup>
	);
};

export default DestinationPackagePopup;
