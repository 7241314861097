import React, { useEffect, useState } from 'react';
import './BrandReportListPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import LabelInputFc from '../../components/LabelInputFc/LabelInputFc';
import serviceFactory from '../../services/serviceFactory';
import SpireTable from '../../components/spireTable/SpireTable';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import { useRecoilValue } from 'recoil';
import BrandService from '../../services/brand/brand.service';
import globalState from '../../state/globalState';
import LoadingPage from '../loadingPage/LoadingPage';
import Box from '../../components/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import LabelButton from '../../components/labelButton/LabelButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import RewardService from '../../services/reward/reward.service';
import debounce from 'lodash.debounce';

const BrandReportListPage: React.FC = () => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const rewardService = serviceFactory.get<RewardService>('RewardService');

	const brandService = serviceFactory.get<BrandService>('BrandService');
	const [brandList, setBrandList] = useState<Api.Brand.Res.Report[]>([]);
	const [sortField, setSortField] = useState<string>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [brandTotal, setBrandTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [form, setForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('minimumPointCost', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Minimum Point Cost is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
					return control.value > 0;
				})
			]),
			new RsFormControl('redemptionRatio', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Please fill redemption value')
			]),
			new RsFormControl('rewardPointsPerDollar', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Please fill reward points per dollar value')
				// new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
				// 	return control.value > 0;
				// })
			]),
			new RsFormControl('redeemPointsPerDollar', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Please fill redeem points per dollar value')
				// new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
				// 	return control.value > 0;
				// })
			])
		])
	);

	const getPlatformVariable = async () => {
		try {
			const res = await rewardService.getPointsCost();
			let updatedForm = form.clone();
			updatedForm.get('minimumPointCost').value = res?.minimumPointCost;
			updatedForm.get('redemptionRatio').value = res?.redemptionRatio;
			updatedForm.get('rewardPointsPerDollar').value = res?.rewardPointsPerDollar;
			updatedForm.get('redeemPointsPerDollar').value = res?.redeemPointsPerDollar;
			setForm(updatedForm);
		} catch (error) {}
	};

	useEffect(() => {
		getPlatformVariable();
	}, []);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await brandService.getReportsByPage(pageQuery);
			setBrandList(res.data);
			setBrandTotal(res.data.length);
			setLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			// popupController.close(BrandsReportPopup);
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	async function exportCSV() {
		let hiddenElement: HTMLAnchorElement = document.createElement('a');
		hiddenElement.href = `/api/v1/brand/reports/export?token=${user?.token || ''}`;
		hiddenElement.target = '_blank';
		hiddenElement.download = 'Exported CSV';
		hiddenElement.click();
	}

	let formDebounce = debounce(async (control: RsFormControl) => {
		if (control.key === 'minimumpointCost' && typeof control.value === 'string') {
			control.value = control.value.replace(/[^0-9]/g, '');
		}
		if (control.key === 'redemptionpointCost' && typeof control.value === 'string') {
			control.value = control.value.replace(/[^0-9]/g, '');
		}
		// if (control.key === 'bookPointsPerDollar' && typeof control.value === 'string') {
		// 	control.value = control.value.replace(/[^0-9]/g, '');
		// }
		// if (control.key === 'redeemPointsPerDollar' && typeof control.value === 'string') {
		// 	control.value = control.value.replace(/[^0-9]/g, '');
		// }
		setForm(form.clone().update(control));
	});

	async function checkIsFormValid(): Promise<boolean> {
		let formIsValid = await form.isValid();
		setForm(form.clone());
		return formIsValid;
	}

	const submitData = async () => {
		setLoading(true);
		if (!(await checkIsFormValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			setLoading(false);
			return;
		}
		try {
			let newReward: any = form.toModel();
			await rewardService.createUpdatePointsCost(newReward);
			setLoading(false);
		} catch (error) {
			rsToastify.error('Error', 'Something went wrong');
			setLoading(false);
		}
	};

	return (
		<Page className={'rsDestinationListPage'}>
			<SubHeader
				header={'Brand Report'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Brand List', link: '/dashboard/brand-list' },
					{ label: 'Brand Report', link: '/dashboard/brand-report' }
				]}
			/>
			<div className={'labelInputs'}>
				<Box className="topRowInputsContainer">
					{/* <Box className={'topRowInputs'}>
						<LabelInputFc
							className={'rewardName'}
							title={'Points Redemption Value'}
							inputType={'text'}
							control={form.get('redemptionRatio')}
							updateControl={(updateControl) => formDebounce(updateControl)}
						/>
						<LabelInputFc
							className={'rewardName'}
							title={'Minimum Allowed Point Cost'}
							inputType={'text'}
							control={form.get('minimumPointCost')}
							updateControl={(updateControl) => formDebounce(updateControl)}
						/>
					</Box> */}
					<Box className={'topRowInputs'}>
						<LabelInputFc
							className={'rewardName'}
							title={'Global Redeem Points Per Dollar'}
							inputType={'number'}
							control={form.get('rewardPointsPerDollar')}
							updateControl={(updateControl) => formDebounce(updateControl)}
						/>
						<LabelInputFc
							className={'rewardName'}
							title={'Global Reward Points Per Dollar'}
							inputType={'number'}
							control={form.get('redeemPointsPerDollar')}
							updateControl={(updateControl) => formDebounce(updateControl)}
						/>
					</Box>
				</Box>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					className={'saveButton'}
					label={'Save'}
					onClick={submitData}
				/>
			</div>
			<hr />
			<Box className={'titleContainer'}>
				<Label variant={'h2'}>All Brands Report Summary</Label>
				<Box className={'exportButtonContainer'}>
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Export to CSV'}
						onClick={exportCSV}
					/>
				</Box>
			</Box>
			{loading && <LoadingPage />}
			<SpireTable
				table={{
					placeholder: 'Search by name',
					filterQuery: [{ column: 'name', value: '', conjunction: 'OR' }]
				}}
				columns={[
					{
						id: 'brand.name',
						label: 'Brand Name',
						align: 'left',
						className: 'brandCell brandNameCell',
						sort: sortField === 'brand.name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'name'
					},
					{
						id: 'spireYTDRevenue',
						label: 'Spire Revenue YTD',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'spireYTDRevenue' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'pointsPerDollar',
						label: 'Points Per Dollar',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'pointsPerDollar' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'costPerPoint',
						label: 'Point Cost',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'costPerPoint' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'costToMerchant',
						label: 'Cost to Brand Per Dollar',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'costToMerchant' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'spireRevenuePerDollar',
						label: 'Spire Revenue Per Dollar',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'spireRevenuePerDollar' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'spireRevenuePerPoint',
						label: 'Spire Revenue Per Point',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'spireRevenuePerPoint' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'brand.loyaltyStatus',
						label: 'Loyalty Status',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'brand.loyaltyStatus' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'loyaltyStatus'
					}
				]}
				data={brandList}
				total={brandTotal}
				sortField={sortField}
				onGetData={getData}
				rowOnClick={() => {}}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default BrandReportListPage;
