import * as React from 'react';
import './SessionManagementPopup.scss';
import { useEffect } from 'react';
import { Box, Popup, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import router from '../../utils/router';
import LabelButton from '../../components/labelButton/LabelButton';

export interface SessionManagementPopupProps extends PopupProps {
	successCallback?: () => void;
	handleInteraction?: any;
}

const SessionManagementPopup: React.FC<SessionManagementPopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		window.history.pushState(
			null,
			'SessionManagementPopup Close',
			window.location.pathname + window.location.search
		);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(SessionManagementPopup);
		});
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(SessionManagementPopup);
			});
		};
	}, []);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsSessionManagementPopup'} position={'relative'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						localStorage.clear();
						window.location.assign('/');
						popupController.closeById(fullProps.popupId);
					}}
					size={16}
					className={'closeBtn'}
					cursorPointer
				/>
				<Box className={'popupTitle'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						Notification
					</Label>
				</Box>
				<Box className={'popupBody'}>
					<div className={'girlContanar'}>
						<div className={'girlContanarMain'}>
							<p className={'girlContanarDis'}>
								Your session will expire after 1 min.
								<br />
								click on continue to keep your session active
							</p>

							<Box className="buttoncontainer" display="flex">
								<LabelButton
									look={'containedPrimary'}
									className={'closeButton'}
									onClick={() => {
										localStorage.clear();
										window.location.assign('/');
										popupController.close(SessionManagementPopup);
									}}
									label={'close'}
									variant={'button'}
								/>

								<LabelButton
									look={'containedPrimary'}
									className={'ContinueButton'}
									onClick={() => {
										props.handleInteraction();
									}}
									label={'Continue'}
									variant={'button'}
								/>
							</Box>
						</div>
					</div>
				</Box>
			</Paper>
		</Popup>
	);
};

export default SessionManagementPopup;
