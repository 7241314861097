import * as React from 'react';
import './ItineraryInfoCard.scss';
import LabelLink from '../labelLink/LabelLink';
import Label from '@bit/redsky.framework.rs.label';
import { Box } from '@bit/redsky.framework.rs.996';
import LabelButton from '../labelButton/LabelButton';
import Paper from '../paper/Paper';

interface NavButtons {
	link: string;
	label: string;
}

interface ItineraryInfoCardProps {
	backButton: NavButtons;
	logoImgUrl: string;
	name: string;
	description: string;
	callToActionLeaveReview?: () => void;
	canLeaveReview?: boolean;
}

const ItineraryInfoCard: React.FC<ItineraryInfoCardProps> = (props) => {
	return (
		<Paper className={'rsItineraryInfoCard'} boxShadow>
			<Box margin={'40px auto 50px'} maxWidth={'477px'}>
				<Label margin={'0 auto 16px'} variant={'h1'}>
					{props.name}
				</Label>
				<Label variant={'h3'} lineClamp={3}>
					{props.description}
				</Label>
			</Box>
			<Box display={'flex'} justifyContent={'space-evenly'}>
				{props.canLeaveReview && (
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Leave a review'}
						onClick={props.callToActionLeaveReview}
					/>
				)}
			</Box>
		</Paper>
	);
};

export default ItineraryInfoCard;
