import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { WebUtils } from '../../utils/utils';

export default class CampaignService extends Service {
	async getCampaignsByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Campaign.Detail[]>> {
		let response = await http.get<RedSky.RsPagedResponseData<Api.Campaign.Detail[]>>(
			'/campaign/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async createCampaign(
		name: string,
		maxReward: number,
		type: string,
		startOn: Date | string,
		endOn: Date | string,
		actions: Api.CampaignAction.CreateMany[],
		completionPoints: number,
		pointValueMultiplier: number,
		bulkPoint?: number,
		pointStatus?: number,
		activeOnDays?: any,
		description?: string
	): Promise<Api.Campaign.Res.Create> {
		const response = await http.post<RsResponseData<Api.Campaign.Res.Create>>('campaign', {
			name,
			maxReward,
			type,
			startOn,
			endOn,
			actions,
			completionPoints,
			pointValueMultiplier,
			bulkPoint,
			pointStatus,
			activeOnDays,
			description
		});
		return response.data.data;
	}

	async updateCampaign(
		id: number,
		name: string,
		maxReward: number,
		type: string,
		startOn: Date | string,
		endOn: Date | string,
		actions: Api.CampaignAction.CreateMany[],
		completionPoints: number,
		pointValueMultiplier: number,
		bulkPoint?: number,
		pointStatus?: number,
		activeOnDays?: any,
		description?: string
	): Promise<Api.Campaign.Res.Update> {
		const response = await http.put<RsResponseData<Api.Campaign.Res.Update>>('campaign', {
			id,
			name,
			description,
			maxReward,
			type,
			startOn,
			endOn,
			actions,
			completionPoints,
			pointValueMultiplier,
			bulkPoint,
			pointStatus,
			activeOnDays
		});
		return response.data.data;
	}

	async getCampaignById(id: number): Promise<Api.Campaign.Res.Get> {
		const response = await http.get<RsResponseData<Api.Campaign.Res.Get>>('campaign', { id });
		return response.data.data;
	}

	async deleteCampaign(id: number) {
		const response = await http.delete('campaign', { id });
		return response;
	}

	async getAllActions(): Promise<Model.Action[]> {
		const response = await http.get<RsResponseData<Model.Action[]>>('action/paged');
		return response.data.data;
	}

	async getActionById(id: number): Promise<Api.Action.Res.Get> {
		const response = await http.get<RsResponseData<Api.Action.Res.Get>>('action', { id });
		return response.data.data;
	}

	async updateAction(actionObj: Api.Action.Req.Update): Promise<Api.Action.Res.Get> {
		const response = await http.put<RsResponseData<Api.Action.Res.Get>>('action', actionObj);
		return response.data.data;
	}

	async createAction(
		name: string,
		description: string,
		type: string,
		pointValue: number
	): Promise<Api.Action.Res.Get> {
		const response = await http.post<RsResponseData<Api.Action.Res.Get>>('action', {
			name,
			description,
			type,
			pointValue
		});
		return response.data.data;
	}

	async deleteAction(id: number) {
		const response = await http.delete('action', { id });
		return !!response;
	}

	async getCampaignAuditReport(pageQuery: RedSky.PageQuery): Promise<any> {
		const res = await http.get<RedSky.RsPagedResponseData<any>>(`/campaign/audit/report`, pageQuery);
		return res.data;
	}

	async getSpireMerchantReport(filterData?: Api.Transaction.Req.Filter): Promise<any> {
		const res = await http.get<RedSky.RsPagedResponseData<any>>(`/campaign/spireMerchant/report`, filterData);
		return res.data;
	}
	async getRentylMerchantReport(filterData?: Api.Transaction.Req.Filter): Promise<any> {
		const res = await http.get<RedSky.RsPagedResponseData<any>>(`/campaign/merchant/report`, filterData);
		return res.data;
	}
}
