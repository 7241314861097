import * as React from 'react';
import './IconFeatureTile.scss';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import Paper from '../paper/Paper';
import { useMultiColoredIcon } from '../../customHooks/useMultiColoredIcon';

interface IconFeatureTileProps {
	title: string;
	icon: string;
	onClick: () => void;
	onDelete: () => void;
}

const IconFeatureTile: React.FC<IconFeatureTileProps> = (props) => {
	return (
		<Paper
			className={'rsIconFeatureTile'}
			height={'90px'}
			width={'300px'}
			borderRadius={'4px'}
			padding={'0 25px'}
			onClick={props.onClick}
		>
			{useMultiColoredIcon(props.icon, 35)}
			<Label variant={'h2'}>{props.title}</Label>
			<Icon
				iconImg={'icon-trash'}
				cursorPointer
				onClick={(event) => {
					if (props.onDelete) props.onDelete();
					event?.stopPropagation();
				}}
			/>
		</Paper>
	);
};

export default IconFeatureTile;
