import * as React from 'react';
import './EditAddressPopup.scss';
import { Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Box from '../../components/box/Box';
import Icon from '@bit/redsky.framework.rs.icon';
import LabelInput from '../../components/labelInput/LabelInput';
import { useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import CountryService from '../../services/country/country.service';
import { OptionType } from '@bit/redsky.framework.rs.select';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import Paper from '../../components/paper/Paper';
import LabelButton from '../../components/labelButton/LabelButton';
import countryJsonList from '../../../countryList.json';

interface IBaseCountry {
	name: string;
	isoCode: string;
	stateList?: any[];
	countryCode?: string;
	latitude?: string;
	longitude?: string;
}

export interface EditAddressPopupProps extends PopupProps {
	locationDetailsForm: RsFormGroup;
	updateLocationDetailsForm: (formGroup: RsFormGroup) => void;
	resetFormControl: () => void;
}

const EditAddressPopup: React.FC<EditAddressPopupProps> = (props) => {
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const [stateList, setStateList] = useState<OptionType[]>([]);
	const [countryList, setCountryList] = useState<OptionType[]>(formatStateOrCountryListForSelect(countryJsonList));
	const [locationForm, setLocationForm] = useState<RsFormGroup>(props.locationDetailsForm);

	useEffect(() => {
		async function getStates() {
			let stateList: IBaseCountry[] =
				countryJsonList.find((o) => o.isoCode == locationForm.get('countryCode').value)?.stateList || [];

			setStateList(formatStateOrCountryListForSelect(stateList));
		}
		getStates().catch(console.error);
	}, [countryList, locationForm.get('country').value]);

	function updateLocationPopupDetailsForm(control: RsFormControl) {
		if (!control) return;
		if (control.key == 'countryCode') {
			let obj: any = locationForm.clone();
			let country = obj.get('country');
			country.value = countryList.find((o) => o.value == control.value)?.label;
			obj.update(country);
			obj.update(control);
			setLocationForm(obj);
		} else if (control.key == 'stateCode') {
			let obj: any = locationForm.clone();
			let state = obj.get('state');
			state.value = stateList.find((o) => o.value == control.value)?.label;
			obj.update(state);
			obj.update(control);
			setLocationForm(obj);
		} else {
			setLocationForm(locationForm.clone().update(control));
		}
	}

	function formatStateOrCountryListForSelect(statesOrCountries: IBaseCountry[]) {
		return statesOrCountries.map((item: IBaseCountry) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	return (
		<Popup {...props}>
			<Paper className={'rsEditAddressPopup'}>
				<Icon
					className={'closeIcon'}
					iconImg={'icon-close'}
					size={24}
					cursorPointer
					onClick={() => popupController.close(EditAddressPopup)}
				/>
				<Box className={'contentContainer'}>
					<LabelInput
						title={'Address 1'}
						labelVariant={'h2'}
						inputType={'text'}
						control={locationForm.get('address1')}
						updateControl={updateLocationPopupDetailsForm}
					/>
					<LabelInput
						title={'Address 2'}
						labelVariant={'h2'}
						inputType={'text'}
						control={locationForm.get('address2')}
						updateControl={updateLocationPopupDetailsForm}
					/>
					<LabelInput
						title={'City'}
						labelVariant={'h2'}
						inputType={'text'}
						control={locationForm.get('city')}
						updateControl={updateLocationPopupDetailsForm}
					/>
					<LabelSelect
						title={'State'}
						variant={'h2'}
						options={stateList}
						control={locationForm.get('stateCode')}
						updateControl={updateLocationPopupDetailsForm}
					/>
					<LabelInput
						title={'Zip'}
						labelVariant={'h2'}
						inputType={'text'}
						control={locationForm.get('zip')}
						updateControl={updateLocationPopupDetailsForm}
					/>
					<LabelSelect
						title={'Country'}
						variant={'h2'}
						options={countryList}
						control={locationForm.get('countryCode')}
						updateControl={updateLocationPopupDetailsForm}
					/>
					<Box className={'buttonsContainer'}>
						<LabelButton
							label={'Cancel'}
							variant={'button'}
							look={'containedPrimary'}
							onClick={() => {
								props.resetFormControl();
								popupController.close(EditAddressPopup);
							}}
						/>
						<LabelButton
							label={'Apply'}
							variant={'button'}
							look={'containedPrimary'}
							onClick={() => {
								props.updateLocationDetailsForm(locationForm);
								popupController.close(EditAddressPopup);
							}}
						/>
					</Box>
				</Box>
				)
			</Paper>
		</Popup>
	);
};

export default EditAddressPopup;
