import { Service } from '../Service';
import http from '../../utils/http';

export default class TransactionService extends Service {
	async getAll(filterData?: Api.Transaction.Req.Filter): Promise<Api.Transaction.Res.Transaction[]> {
		const response = await http.get<Api.Transaction.Res.Transaction[]>('transaction/all', filterData);
		return response.data;
	}

	async getMerchantAll(
		filterData?: Api.Transaction.Req.Filter
	): Promise<Api.Transaction.Res.TransactionRetailerReport[]> {
		const response = await http.get<Api.Transaction.Res.TransactionRetailerReport[]>(
			'transaction/merchant/all',
			filterData
		);
		return response.data;
	}
}
