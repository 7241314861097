import React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label/dist/Label';

function NotFoundPage(): JSX.Element {
	return (
		<Page className="rsNotFoundPage">
			<br />
			<Label>Invalid URL. Please contact the support team</Label>
		</Page>
	);
}

export default NotFoundPage;
