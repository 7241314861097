import * as React from 'react';
import { useState } from 'react';
import './DestinationExperiencePopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import LabelInput from '../../components/labelInput/LabelInput';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import Box from '../../components/box/Box';
import serviceFactory from '../../services/serviceFactory';
import ExperienceService from '../../services/experience/experience.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import LabelButton from '../../components/labelButton/LabelButton';
import LabelRadioButton from '../../components/labelRadioButton/LabelRadioButton';
import MediaService from '../../services/media/media.service';
import Paper from '../../components/paper/Paper';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import ImageManager from '../../components/imageManager/ImageManager';
import { useMultiColoredIcon } from '../../customHooks/useMultiColoredIcon';
import iconJson from '../../icons/cmsIcons/selection.json';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';

export interface DestinationExperiencePopupProps extends PopupProps {
	onSave: () => void;
	id?: number;
	experiences: Api.Experience.Res.Get[];
	destinationId: number;
	experienceId?: number;
	title: string;
	icon: string;
	isHighlighted: 0 | 1;
	media: Api.Media[];
	description: string;
}

const DestinationExperiencePopup: React.FC<DestinationExperiencePopupProps> = (props) => {
	const experienceService = serviceFactory.get<ExperienceService>('ExperienceService');
	const mediaService = serviceFactory.get<MediaService>('MediaService');
	const [mediaDetails, setMediaDetails] = useState<Omit<Api.Media, 'urls' | 'companyId' | 'uploaderId' | 'type'>[]>(
		[]
	);
	const [imagesToDelete, setImagesToDelete] = useState<number[]>([]);
	const [experienceForm, setExperienceForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('description', props.description || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Must have a description'),
				new RsValidator(RsValidatorEnum.MAX, 'limit 500 characters', 500)
			]),
			new RsFormControl('experienceId', props.experienceId || 0, [
				new RsValidator(RsValidatorEnum.REQ, 'Select an experience')
			]),
			new RsFormControl('isHighlighted', props.isHighlighted, [])
		])
	);

	async function save() {
		try {
			let experience = props.experiences.find(
				(experience) => experience.id === (experienceForm.get('experienceId').value as number)
			);

			if (!experience) {
				rsToastify.error('Must select an experience');
				return;
			}

			if (!mediaDetails.filter((media) => !imagesToDelete.includes(media.id)).find((media) => media.isPrimary)) {
				rsToastify.error('Must have a primary image');
				return;
			}

			if (await checkFormIsValid()) {
				popupController.open(SpinningLoaderPopup, {});
				const formData: {
					experienceId: number;
					description: string;
					isHighlighted: 0 | 1;
				} = experienceForm.toModel<{ experienceId: number; description: string; isHighlighted: 0 | 1 }>();
				if (ObjectUtils.isArrayWithData(imagesToDelete)) {
					imagesToDelete.forEach((imageId) => {
						mediaService.deleteMedia({ id: imageId });
					});
					setImagesToDelete([]);
				}
				if (props.id) {
					await experienceService.updateDestinationExperience({
						...formData,
						destinationExperienceId: props.id,
						destinationId: props.destinationId,
						media: mediaDetails
					});
				} else {
					await experienceService.createDestinationExperience({
						...formData,
						destinationId: props.destinationId,
						media: mediaDetails
					});
					rsToastify.success('Experience successfully created', 'Success!');
				}
				popupController.close(SpinningLoaderPopup);
				popupController.closeAll();
				props.onSave();
			} else {
				popupController.close(SpinningLoaderPopup);
				rsToastify.error('Missing experience information.', 'Missing Information!');
			}
		} catch (e) {
			popupController.close(SpinningLoaderPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unexpected server error!'), 'Error!');
		}
	}

	async function checkFormIsValid(): Promise<boolean> {
		let isValid = await experienceForm.isValid();
		setExperienceForm(experienceForm.clone());
		return ObjectUtils.isArrayWithData(mediaDetails) && isValid;
	}

	async function handleMediaData(
		mediaData: { id: number; isPrimary: 1 | 0; image: string; title: string; description: string }[],
		imageIdsToDelete: number[]
	) {
		let newMediaData: {
			id: number;
			isPrimary: 1 | 0;
			image: string;
			title: string;
			description: string;
		}[] = ObjectUtils.clone(mediaData);
		setMediaDetails(
			newMediaData.map((img) => {
				return { id: img.id, isPrimary: img.isPrimary, title: img.title, description: img.description };
			})
		);
		setImagesToDelete(imageIdsToDelete);
	}

	function renderMultiColoredIcon(iconName: string, size: number) {
		const newSelectedIcon = iconJson.icons.find((icon) => icon.icon.tags[0] === iconName);
		if (newSelectedIcon && newSelectedIcon.icon.isMulticolor) {
			return (
				<span className={`cms-${iconName}`} style={{ fontSize: `${size}px` }}>
					{newSelectedIcon.icon.paths.map((path: any, index: number) => {
						return <span key={path} className={`path${index + 1}`} />;
					})}
				</span>
			);
		} else {
			return <Icon iconImg={`cms-${iconName}`} size={size} />;
		}
	}

	function displayExperiences() {
		return (
			<Box className={'experienceIconsContainer'}>
				{props.experiences
					.filter((experience) => experience.id !== (experienceForm.get('experienceId').value as number))
					.map((experience) => {
						return (
							<Box key={experience.id} className={'iconSelection'}>
								{renderMultiColoredIcon(experience.icon, 35)}
								<Label variant={'caption'}>{experience.title}</Label>
								<LabelRadioButton
									radioName={'experience'}
									value={experience.id}
									checked={false}
									text={''}
									onSelect={(value) => {
										let tempControl = experienceForm.get('experienceId');
										tempControl.value = value;
										setExperienceForm(experienceForm.clone().update(tempControl));
									}}
								/>
							</Box>
						);
					})}
			</Box>
		);
	}

	function renderCurrentIcon() {
		let experience = props.experiences.find(
			(experience) => experience.id === (experienceForm.get('experienceId').value as number)
		);
		if (!experience) return;
		return (
			<Box className={'currentSelection'}>
				<Label className={'h2'}>Current Selection</Label>
				<Box paddingTop={'10px'}>
					{renderMultiColoredIcon(experience.icon, 35)}
					<Label variant={'caption'}>{experience.title}</Label>
				</Box>
			</Box>
		);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsDestinationExperiencePopup'} borderRadius={'4px'} width={'1000px'} padding={'25px'}>
				<Icon
					iconImg={'icon-close'}
					className={'closeIcon'}
					onClick={() => popupController.close(DestinationExperiencePopup)}
					size={21}
					color={'#003A76'}
					cursorPointer
				/>
				<Box className={'title'}>
					<Label variant={'h1'}>
						<span className="requiredAsterisk">*</span>
						{props.experienceId ? 'Edit Experience' : 'Select An Experience'}
					</Label>
					<LabelCheckbox
						value={experienceForm.get('isHighlighted').value as number}
						text={'Highlighted'}
						isChecked={!!props.isHighlighted}
						onSelect={() => {
							let tempControl = experienceForm.get('isHighlighted');
							tempControl.value = 1;
							setExperienceForm(experienceForm.clone().update(tempControl));
						}}
						onDeselect={() => {
							let tempControl = experienceForm.get('isHighlighted');
							tempControl.value = 0;
							setExperienceForm(experienceForm.clone().update(tempControl));
						}}
					/>
				</Box>
				<Box className={'iconContainer'}>
					{displayExperiences()}
					<Box className={'secondSection'}>
						{renderCurrentIcon()}
						<LabelInput
							title={'Description'}
							labelVariant={'h2'}
							inputType={'textarea'}
							initialValue={props.description || ''}
							control={experienceForm.get('description')}
							updateControl={(control) => setExperienceForm(experienceForm.clone().update(control))}
							isRequired
						/>
					</Box>
					<ImageManager
						data={props.media}
						handleMediaData={handleMediaData}
						id={'destinationExperienceImages'}
					/>
				</Box>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Save'}
					onClick={() => {
						save();
					}}
				/>
			</Paper>
		</Popup>
	);
};

export default DestinationExperiencePopup;
