import { Service } from './Service';
import UserService from './user/user.service';
import PermissionService from './permission/permission.service';
import UserPointService from './userPoint/userPoint.service';
import UserAddressService from './userAddress/userAddress.service';
import TierService from './tier/tier.service';
import UploadService from './upload/upload.service';
import DestinationService from './destination/destination.service';
import ExperienceService from './experience/experience.service';
import FeatureService from './feature/feature.service';
import PackageService from './package/package.service';
import MediaService from './media/media.service';
import AccommodationService from './accommodation/accommodation.service';
import RewardService from './reward/reward.service';
import CampaignService from './campaign/campaign.service';
import CompanyService from './company/company.service';
import ReviewService from './review/review.service';
import RegionService from './region/region.service';
import ActionService from './action/action.service';
import ReservationService from './reservation/reservation.service';
import OrdersService from './orders/orders.service';
import TransactionService from './transactions/transactions.service';
import PaymentService from './payment/payment.service';
import CountryService from './country/country.service';
import BrandService from './brand/brand.service';
import UserBusinessService from './userBusiness/userBusiness.service';

type ServiceKey =
	| 'UserService'
	| 'UserAddressService'
	| 'UserBusinessService'
	| 'UserPointService'
	| 'PermissionService'
	| 'TierService'
	| 'UploadService'
	| 'DestinationService'
	| 'ExperienceService'
	| 'FeatureService'
	| 'PackageService'
	| 'MediaService'
	| 'AccommodationService'
	| 'RewardService'
	| 'ReviewService'
	| 'CampaignService'
	| 'CompanyService'
	| 'RegionService'
	| 'OrdersService'
	| 'TransactionService'
	| 'ActionService'
	| 'ReservationService'
	| 'PaymentService'
	| 'CountryService'
	| 'BrandService';

class ServiceFactory {
	private services: { [key: string]: Service } = {};

	create() {
		// Add new models here to the factory
		this.services['UserService'] = new UserService();
		this.services['UserAddressService'] = new UserAddressService();
		this.services['UserBusinessService'] = new UserBusinessService();
		this.services['UserPointService'] = new UserPointService();
		this.services['PermissionService'] = new PermissionService();
		this.services['TierService'] = new TierService();
		this.services['UploadService'] = new UploadService();
		this.services['DestinationService'] = new DestinationService();
		this.services['ExperienceService'] = new ExperienceService();
		this.services['FeatureService'] = new FeatureService();
		this.services['RegionService'] = new RegionService();
		this.services['PackageService'] = new PackageService();
		this.services['MediaService'] = new MediaService();
		this.services['AccommodationService'] = new AccommodationService();
		this.services['RewardService'] = new RewardService();
		this.services['CampaignService'] = new CampaignService();
		this.services['CompanyService'] = new CompanyService();
		this.services['ReviewService'] = new ReviewService();
		this.services['OrdersService'] = new OrdersService();
		this.services['TransactionService'] = new TransactionService();
		this.services['ActionService'] = new ActionService();
		this.services['ReservationService'] = new ReservationService();
		this.services['PaymentService'] = new PaymentService();
		this.services['CountryService'] = new CountryService();
		this.services['BrandService'] = new BrandService();

		for (let key in this.services) {
			this.services[key].start();
		}
	}

	get<T extends Service>(name: ServiceKey): T {
		return this.services[name] as T;
	}
}

let serviceFactory = new ServiceFactory();
export default serviceFactory;

// TEMP
window.sf = serviceFactory;
