import * as React from 'react';
import './AccommodationLayoutPopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import { ChangeEvent, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import UploadService from '../../services/upload/upload.service';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import TitleDescriptionPopup, { TitleDescriptionPopupProps } from '../titleDescriptionPopup/TitleDescriptionPopup';
import RoomTile from '../../components/roomTile/RoomTile';
import AccommodationService from '../../services/accommodation/accommodation.service';
import { ObjectUtils } from '@bit/redsky.framework.rs.utils';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import ConfirmDeletePopup, { ConfirmDeletePopupProps } from '../confirmDeletePopup/ConfirmDeletePopup';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';

export interface AccommodationLayoutPopupProps extends PopupProps {
	accommodationId: number;
	layoutId?: number;
	onSave: (data: any) => void;
	title?: string;
	mediaId?: number;
	imgUrl?: string;
	edit?: number;
	rooms?: Model.AccommodationLayoutRoom[];
	onClose?: (rooms: any) => void;
}

const AccommodationLayoutPopup: React.FC<AccommodationLayoutPopupProps> = (props) => {
	const uploadService = serviceFactory.get<UploadService>('UploadService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const [image, setImage] = useState<string | undefined>(props.imgUrl || '');
	const [rooms, setRooms] = useState<Model.AccommodationLayoutRoom[]>(props.rooms || []);
	const [title, setTitle] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('title', props.title || '', [])])
	);

	const [layoutObj, setLayoutObj] = useState<Api.AccommodationLayout.Req.Create>({
		accommodationId: props.accommodationId,
		title: title.get('title').value.toString() || '',
		mediaId: props.mediaId || 0
	});

	async function uploadImage(file: ChangeEvent<HTMLInputElement>) {
		popupController.open(SpinningLoaderPopup, {});
		let image = file.currentTarget.files;
		console.log(image);
		if (!image) return;
		try {
			let response: any = await uploadService.uploadImage(image[0]);
			let newObj: any = { ...layoutObj };
			newObj.mediaId = response.data.id;
			setImage(response.data.urls.small ? response.data.urls.small : response.data.urls.large);
			setLayoutObj(newObj);
			popupController.close(SpinningLoaderPopup);
		} catch (e) {
			console.log(e);
		}
	}

	function updateTitle(control: RsFormControl) {
		title.update(control);
		setTitle(title.clone());
	}

	async function onSave() {
		let data = { ...layoutObj };
		data.title = title.get('title').value.toString();
		if (data.mediaId === 0) return rsToastify.info('Please upload image or floor plan photo.', 'Missing Image');
		else if (data.title === '') return rsToastify.info('Please provide a name for the layout.', 'Name Needed');

		if (props.edit === 2 || props.edit === 1) {
			if (props.edit === 1) {
				try {
					// let response = await accommodationService.updateAccommodationLayout(updateData);
					// if (response) popupController.close(AccommodationLayoutPopup);
					const dataEdit = {
						updateData: data,
						img: image,
						rooms: rooms
					};
					props.onSave(dataEdit);
					popupController.close(AccommodationLayoutPopup);
				} catch (e) {
					console.log(e);
				}
			} else {
				if (!props.layoutId) return;
				let layoutIdObj = { id: props.layoutId };
				let updateData: Api.AccommodationLayout.Req.Update = { ...data, ...layoutIdObj };
				try {
					// let response = await accommodationService.updateAccommodationLayout(updateData);
					// if (response) popupController.close(AccommodationLayoutPopup);
					const dataEdit = {
						updateData: updateData,
						img: image,
						rooms: rooms
					};
					props.onSave(dataEdit);
					popupController.close(AccommodationLayoutPopup);
				} catch (e) {
					console.log(e);
				}
			}
		} else {
			try {
				// let response = await accommodationService.createAccommodationLayout(data);
				// if (response) popupController.close(AccommodationLayoutPopup);
				const dataEdit = {
					updateData: data,
					img: image,
					rooms: rooms
				};
				props.onSave(dataEdit);
				popupController.close(AccommodationLayoutPopup);
			} catch (e) {
				console.log(e);
			}
		}
	}

	function updateRooms(updatedRoom: Api.AccommodationLayoutRoom.Res.Update) {
		if (!ObjectUtils.isArrayWithData(rooms)) return;
		let updatedRooms = [...rooms];
		updatedRooms = updatedRooms.map((item) => {
			if (updatedRoom.id === item.id) {
				return { ...item, ...updatedRoom };
			} else return item;
		});
		setRooms(updatedRooms);
	}

	function removeRoom(id: number) {
		let newRooms = [...rooms];
		newRooms = newRooms.filter((item) => item.id !== id);
		setRooms(newRooms);
	}

	async function createAccommodationLayoutRoom(layoutId: number, title: string, description: string) {
		let data: Api.AccommodationLayoutRoom.Req.Create = {
			accommodationLayoutId: layoutId,
			title: title,
			description: description
		};
		try {
			let response = await accommodationService.createAccommodationLayoutRoom(data);
			if (response.data.data) {
				let newRooms = [...rooms, response.data.data];
				setRooms(newRooms);
			}
		} catch (e) {
			console.log(e);
		}
	}

	async function updateAccommodationLayoutRoom(id: number, title: string, description: string) {
		let data: Api.AccommodationLayoutRoom.Req.Update = {
			id: id,
			title: title,
			description: description
		};
		try {
			let response = await accommodationService.updateAccommodationLayoutRoom(data);
			if (response.data.data) rsToastify.success('Room Updated!', 'Success');
			updateRooms(response.data.data);
		} catch (e) {
			console.log(e.message);
		}
	}

	async function deleteAccommodationLayoutRoom(id: number) {
		try {
			let response = await accommodationService.deleteAccommodationLayoutRoom(id);
			if (response.data) rsToastify.success('Delete Successful', 'Success');
			removeRoom(response.data.data);
		} catch (e) {
			console.log(e.message);
		}
	}

	function renderRooms() {
		return rooms.map((item) => {
			return (
				<RoomTile
					title={item.title}
					description={item.description}
					onClick={() => {
						popupController.open<TitleDescriptionPopupProps>(TitleDescriptionPopup, {
							title: item.title,
							description: item.description,
							onSave: (title, description) => {
								updateAccommodationLayoutRoom(item.id, title, description);
							}
						});
					}}
					onDelete={() => {
						popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
							onDelete: () => {
								deleteAccommodationLayoutRoom(item.id).catch(console.error);
							}
						});
					}}
				/>
			);
		});
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsAccommodationLayoutPopup'} borderRadius={'4px'} width={'550px'} padding={'25px'}>
				<Icon
					iconImg={'icon-close'}
					cursorPointer
					onClick={() => {
						if (props.onClose) props.onClose(rooms);
						popupController.close(AccommodationLayoutPopup);
					}}
				/>
				<Label className={'header'} variant={'h4'}>
					Layout
				</Label>
				<Box display={'flex'} alignItems={'center'}>
					<div className={'addImageBox'} style={{ backgroundImage: `url(${image})` }}>
						<div className={'uploadWrapper'}>
							<input
								id={'ImageUpload'}
								type={'file'}
								className={'uploadInput'}
								accept="image/*"
								onChange={uploadImage}
							/>
							<label htmlFor={'ImageUpload'} className={'edit'}>
								<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
							</label>
						</div>
					</div>
					<LabelInput
						title={'Level'}
						control={title.get('title')}
						updateControl={updateTitle}
						inputType={'text'}
						placeholder={'Floor One, Level One, etc...'}
					/>
				</Box>
				<LabelButton
					className={'saveBtn'}
					look={'containedPrimary'}
					variant={'button'}
					label={'Save'}
					onClick={() => {
						onSave();
					}}
				/>
				{props.edit == 2 && (
					<>
						<hr />
						<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
							<Label variant={'h2'}>Rooms</Label>
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Add Room'}
								onClick={() => {
									popupController.open<TitleDescriptionPopupProps>(TitleDescriptionPopup, {
										onSave: (title, description) => {
											if (!props.layoutId) return;
											if (createAccommodationLayoutRoom(props.layoutId, title, description)) {
												rsToastify.success('Room added', 'Success');
											}
										}
									});
								}}
							/>
						</Box>
						<Box className={'roomsContainer'}>{renderRooms()}</Box>
					</>
				)}
			</Paper>
		</Popup>
	);
};

export default AccommodationLayoutPopup;
