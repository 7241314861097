import * as React from 'react';
import { useEffect, useState } from 'react';
import './AddRemovePointsPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import Box from '../../components/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelButton from '../../components/labelButton/LabelButton';
import router from '../../utils/router';
import serviceFactory from '../../services/serviceFactory';
import UserPointService from '../../services/userPoint/userPoint.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { WebUtils } from '../../utils/utils';
import UserService from '../../services/user/user.service';
import LoadingPage from '../loadingPage/LoadingPage';

interface AddRemovePointsPageProps {}

const AddRemovePointsPage: React.FC<AddRemovePointsPageProps> = () => {
	const params: { userId: number; award: 0 | 1 } = router.getPageUrlParams([
		{ key: 'award', default: 0, type: 'integer', alias: 'award' },
		{ key: 'ui', default: 0, type: 'integer', alias: 'userId' }
	]);
	const userPointService = serviceFactory.get<UserPointService>('UserPointService');
	const userService = serviceFactory.get<UserService>('UserService');

	const reasonsToRemove: { value: Model.PointReason; label: string }[] = [
		{ value: 'TECHNICAL_ERROR', label: 'Technical Error' },
		{ value: 'HOTEL_STAY', label: 'Hotel Stay' },
		{ value: 'RETAIL_TRANSACTION', label: 'Retail Transaction' },
		{ value: 'RESTAURANT_TRANSACTION', label: 'Restaurant Transaction' }
	];

	const reasonsToAdd: { value: Model.PointReason; label: string }[] = [
		...reasonsToRemove,
		{ value: 'GOODWILL', label: 'Goodwill' },
		{ value: 'VOUCHER_CLAIM', label: 'Voucher Claim' },
		{ value: 'CAMPAIGN_ACTION', label: 'Campaign Action' },
		{ value: 'CAMPAIGN_COMPLETION', label: 'Campaign Completion' },
		{ value: 'TRANSACTION_REFUND', label: 'Transaction Refund' }
	];

	const [user, setUser] = useState<Api.User.Res.Get>();
	const [form, setForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('reason', '', [new RsValidator(RsValidatorEnum.REQ, 'Select a reason.')]),
			new RsFormControl('notes', '', [new RsValidator(RsValidatorEnum.MAX, 'Must be under 500 characters', 500)]),
			new RsFormControl('pointAmount', 0, [
				new RsValidator(RsValidatorEnum.REQ, 'Point amount needed'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Must be a number greater than 0', (control) => {
					return control.value > 0;
				})
			])
		])
	);

	useEffect(() => {
		async function getUser() {
			try {
				if (!params.userId) {
					rsToastify.error('User not found', 'Error!');
					router.navigate('/dashboard').catch(console.error);
				}
				const newUser = await userService.getUserById(params.userId);
				setUser(newUser);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected error occurred.'), 'Server Error!');
				router.navigate('/dashboard').catch(console.error);
			}
		}
		getUser().catch(console.error);
	}, []);

	async function checkIsFormValid(): Promise<boolean> {
		let formIsValid = await form.isValid();
		setForm(form.clone());
		return formIsValid;
	}

	async function submit(pointValue: number) {
		if (!(await checkIsFormValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}
		let newPointObj: any = {
			userId: params.userId,
			award: params.award,
			notes: form.get('notes').value,
			pointType: 'ADMIN',
			pointAmount: pointValue,
			reason: form.get('reason').value
		};
		try {
			let res = await userPointService.create(newPointObj);
			if (!!res.data.data)
				rsToastify.success(`Points have been ${params.award ? 'ADDED' : 'REMOVED'}`, 'Points Updated!');
			setForm(form.resetToInitialValue());
			let inputField: HTMLCollection = document.getElementsByClassName(' css-1uccc91-singleValue');
			inputField[0].innerHTML = '';
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to modify points'), 'Error!');
		}
	}

	async function assignPointValue() {
		if (!user) {
			rsToastify.error('Unable to find a user to modify', 'Error!');
			return;
		}
		let pointValue: number = +form.get('pointAmount').value;
		const pointDifference = user.availablePoints - pointValue;
		if (!params.award && pointDifference < 0 && -pointValue < 0) {
			rsToastify.error('Attempting to remove more points than are available from user.', 'Invalid Point Amount!');
			return;
		} else {
			submit(pointValue).catch(console.error);
		}
	}

	function updateForm(control: RsFormControl) {
		setForm(form.clone().update(control));
	}

	return !user ? (
		<LoadingPage />
	) : (
		<Page className={'rsAddRemovePointsPage'}>
			<SubHeader
				header={params.award ? 'Add Points' : 'Remove Points'}
				crumbs={[
					{ label: 'Dashboard', link: `/dashboard` },
					{ label: 'User List', link: `/dashboard/user-list` },
					{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${params.userId}` },
					{
						label: 'Manage Points',
						link: `/dashboard/user-list/manage-user/manage-points?ui=${params.userId}`
					},
					{
						label: 'Update Points',
						link: `/dashboard/user-list/manage-user/update-points?ui=${params.userId}`
					}
				]}
			/>
			<Box margin={'25px 0'} bgcolor={'inherit'}>
				<LabelInput
					title={'Point Amount'}
					control={form.get('pointAmount')}
					updateControl={updateForm}
					inputType={'number'}
					placeholder={'Enter point amount'}
				/>
				<LabelSelect
					title={'Reason'}
					options={params.award ? reasonsToAdd : reasonsToRemove}
					control={form.get('reason')}
					updateControl={updateForm}
				/>
				<LabelInput
					title={'Additional Notes'}
					control={form.get('notes')}
					updateControl={updateForm}
					inputType={'textarea'}
					textareaCols={40}
					textareaRows={10}
					placeholder={'Add a detailed note...'}
				/>
			</Box>
			<LabelButton
				look={'containedPrimary'}
				variant={'button'}
				label={params.award ? 'Add Points' : 'Remove Points'}
				onClick={assignPointValue}
			/>
		</Page>
	);
};

export default AddRemovePointsPage;
