import React, { useEffect, useState } from 'react';
import './CreateTierPage.scss';
import { Box, Page } from '@bit/redsky.framework.rs.996';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import serviceFactory from '../../services/serviceFactory';
import SubHeader from '../../components/subHeader/SubHeader';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import { axiosErrorHandler } from '../../utils/errorHandler';
import { HttpStatusCode } from '../../utils/http';
import TierService from '../../services/tier/tier.service';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import debounce from 'lodash.debounce';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import Select, { OptionType } from '@bit/redsky.framework.rs.select';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';

const CreateTierPage: React.FC = () => {
	const tierService = serviceFactory.get<TierService>('TierService');
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	let newTierObj: Api.Tier.Req.Create = {
		name: '',
		description: '',
		accrualRate: 0,
		threshold: 0,
		isAnnualRate: 1,
		featureIds: []
	};
	const [featureOptions, setFeatureOptions] = useState<OptionType[]>([]);
	const [form, setForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('annualRate', '', [new RsValidator(RsValidatorEnum.REQ, 'Select an annual rate.')]),
			new RsFormControl('featuresIds', '', [new RsValidator(RsValidatorEnum.REQ, 'Select a feature.')])
		])
	);

	let clearAllFields = debounce(async () => {
		// TODO: Make this clear the form or navigate to some other page depending on customer needs.
		window.location.reload();
	}, 2000);

	useEffect(() => {
		async function getFeatures() {
			try {
				let res = await tierService.getTierFeatures();
				setFeatureOptions(
					res.map((option) => {
						return { value: option.id, label: option.name };
					})
				);
			} catch (e) {
				axiosErrorHandler(e, {
					[HttpStatusCode.NOT_FOUND]: () => {
						rsToastify.error(WebUtils.getRsErrorMessage(e, 'No features available.'), 'Unavailable!');
					}
				});
			}
		}
		getFeatures().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function isFormValid() {
		if (newTierObj.name === '') {
			rsToastify.error('New tier must have a name.', 'Add Name!');
			return false;
		} else if (newTierObj.threshold === 0) {
			rsToastify.error('New tier must have threshold.', 'Add Threshold!');
			return false;
		} else if (newTierObj.accrualRate === 0) {
			rsToastify.error('New tier must have accrual rate.', 'Add Accrual Rate!');
			return false;
		} else if (newTierObj.featureIds.length === 0) {
			rsToastify.error('New tier must have at least one feature.', 'Add Feature!');
			return false;
		} else if (newTierObj.description === '') {
			rsToastify.error('New tier must have additional notes.', 'Add Notes!');
			return false;
		} else {
			return true;
		}
	}

	function cleanNumber(number: number) {
		let cleaned: number | string = Math.ceil(number);
		return parseInt(('' + cleaned).replace(/\D/g, ''));
	}

	async function submit() {
		if (!isFormValid()) return;
		setIsDisabled(true);
		newTierObj.featureIds = form.get('featuresIds').value as number[];
		newTierObj.isAnnualRate = form.get('annualRate').value = 1;
		try {
			await tierService.create(newTierObj);
			setIsDisabled(false);
			clearAllFields();
			rsToastify.success('New tier created successfully.', 'Success!');
		} catch (e) {
			setIsDisabled(false);
			axiosErrorHandler(e, {
				[HttpStatusCode.BAD_REQUEST]: () => {
					rsToastify.error(WebUtils.getRsErrorMessage(e, 'Could not create new tier'), 'Unable To Create!');
				}
			});
		}
	}

	function updateForm(control: RsFormControl) {
		setForm(form.clone().update(control));
	}

	return (
		<Page className={'rsCreateTier'}>
			<SubHeader
				header={'Create New Tier'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Manage Tier', link: '/dashboard/reward-list/create-tier' }
				]}
			/>
			<Box className={'inputContainer'} bgcolor={'inherit'}>
				<LabelInput title={'Tier Name'} onChange={(value) => (newTierObj.name = value)} inputType={'text'} />
				<Box className={'tierThresholdBox'} bgcolor={'inherit'}>
					<LabelInput
						title={'Tier Threshold'}
						onChange={(value) => (newTierObj.threshold = cleanNumber(value))}
						inputType={'number'}
					/>
					<Label className={'thresholdPointsLabel'}>Points</Label>
				</Box>
				<LabelInput
					title={'Point Accrual Rate'}
					onChange={(value) => (newTierObj.accrualRate = cleanNumber(value))}
					inputType={'number'}
				/>
				<div className={'featuresBox'}>
					<Label className={'featuresLabel'}>Features</Label>
					<Select updateControl={updateForm} options={featureOptions} control={form.get('featuresIds')} />
				</div>
				<div className={'additionalNotesBox'}>
					<LabelInput
						title={'Additional Notes'}
						onChange={(value) => (newTierObj.description = value)}
						inputType={'textarea'}
					/>
				</div>
				<div className={'isAnnualRateBox'}>
					<Select
						updateControl={updateForm}
						control={form.get('annualRate')}
						options={[{ value: 0, label: 'Any' }]}
					/>
				</div>
			</Box>
			<LabelButton
				look={'containedPrimary'}
				variant={'button'}
				label={'Create Tier'}
				onClick={submit}
				disabled={isDisabled}
			/>
		</Page>
	);
};

export default CreateTierPage;

export function createTierRewardsPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_REWARDS_WRITE, PermissionList.COMPANY_READ]);
}
