import http from '../../utils/http';
import SparkMD5 from 'spark-md5';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { Service } from '../Service';
import CompanyService from '../company/company.service';
import serviceFactory from '../serviceFactory';
import globalState, { getRecoilExternalValue, setRecoilExternalValue } from '../../state/globalState';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import { pageList } from '../../pagesTree';

export default class UserService extends Service {
	companyService!: CompanyService;

	start() {
		this.companyService = serviceFactory.get<CompanyService>('CompanyService');
	}

	async loginUserByPassword(username: string, password: string) {
		password = SparkMD5.hash(password);
		let axiosResponse = await http.post<RsResponseData<Api.User.Res.AdminLogin>>('user/login', {
			username,
			password
		});
		await this.onAfterLogin(axiosResponse.data.data);
	}

	async loginUserByToken(token: string) {
		let axiosResponse = await http.get<RsResponseData<Api.User.Res.AdminLogin>>('user/with/token?token=' + token);
		await this.onAfterLogin(axiosResponse.data.data);
	}

	async requestPasswordByEmail(primaryEmail: string) {
		return await http.post<RsResponseData<Api.User.Res.ForgotPassword>>('user/password/forgot', {
			primaryEmail
		});
	}

	async resetPasswordByGuid(passwordResetGuid: string, newPassword: string) {
		newPassword = SparkMD5.hash(newPassword);
		return await http.put<RsResponseData<Api.User.Res.ResetPassword>>('user/password/reset', {
			passwordResetGuid,
			newPassword
		});
	}

	async guidValidation(guid: string) {
		return await http.put<RsResponseData<Api.User.Res.ValidateGuid>>('user/password/guid/valid', {
			guid
		});
	}

	async getUserByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.User.Res.GetByPage[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.User.Res.GetByPage[]>>(
			'user/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	getUserRoles(): Api.User.Res.Role[] {
		return getRecoilExternalValue<Api.User.Res.Role[]>(globalState.userRoles);
	}

	getUserRoleNameById(id: number): string {
		let roles: Api.User.Res.Role[] = this.getUserRoles();
		if (roles === undefined) return '';
		let index = roles.findIndex((el) => el.id === id);
		if (index !== -1) return roles[index].name;
		return '';
	}

	async getUserById(id: number): Promise<Api.User.Res.Get> {
		const res = await http.get<RsResponseData<Api.User.Res.Get>>(`user?id=${id}`);
		return res.data.data;
	}

	async updateUser(data: Api.User.Req.Update): Promise<Api.User.Res.Get> {
		let businesses: Api.User.Update.Businesses | undefined = data.businesses;
		if (businesses) {
			let key: keyof Api.User.Req.Update.Businesses;
			for (key in businesses) {
				if (!ObjectUtils.isArrayWithData(businesses[key])) delete businesses[key];
			}
		}
		const res = await http.put<RsResponseData<Api.User.Res.Get>>(`user`, data);
		return res.data.data;
	}

	private async onAfterLogin(user: Api.User.Res.AdminLogin) {
		let axiosConfig = http.currentConfig();
		axiosConfig.headers = { ...axiosConfig.headers, 'company-id': user.companyId.toString(), token: user.token };
		http.changeConfig(axiosConfig);

		setRecoilExternalValue<Api.User.Res.AdminLogin | undefined>(globalState.user, user);

		this.companyService.refreshUserCompany(user).catch(console.error);
		this.companyService.refreshUserRoles().catch(console.error);
		this.getUserAccessPage();
	}

	private async getUserAccessPage(): Promise<any> {
		try {
			const response = await http.get<any>('/userAdminPagesAccess');
			console.log('response :', response.data.data);
			let accessPages = response.data.data.accessPages;

			let object: Api.AccessPages = {
				view: [],
				edit: []
			};
			object.view = accessPages.view.map((o: number) => this.getUrlAndNameFromId(o, pageList));
			object.edit = accessPages.edit.map((o: number) => this.getUrlAndNameFromId(o, pageList));
			setRecoilExternalValue<Api.AccessPages>(globalState.userAccessPages, object);
		} catch (error) {
			console.log('\nuser has no access now for any pages\n');
		}
	}

	private getUrlAndNameFromId(id: number, list: any) {
		const urlAndName: any = list.reduce((acc: any, abc: any) => {
			if (abc.id === id) {
				return abc.url;
			} else if (abc.childArray) {
				const childUrlAndName = this.getUrlAndNameFromId(id, abc.childArray);
				if (childUrlAndName) {
					return childUrlAndName;
				}
			}
			return acc;
		}, undefined);

		return urlAndName;
	}

	async createUser(userObj: Api.User.Req.Create): Promise<RsResponseData<Api.User.Req.Create>> {
		let businesses: Api.User.Update.Businesses | undefined = userObj.businesses;
		if (businesses) {
			let key: keyof Api.User.Req.Update.Businesses;
			for (key in businesses) {
				if (!ObjectUtils.isArrayWithData(businesses[key])) delete businesses[key];
			}
			if (ObjectUtils.isEmptyObject(businesses)) {
				delete userObj.businesses;
			} else {
				userObj.businesses = businesses;
			}
		}
		const response = await http.post<RsResponseData<Api.User.Req.Create>>('user', userObj);
		return response.data;
	}

	async deleteUser(userId: number) {
		const response = await http.delete<RsResponseData<Api.User.Req.Delete>>('user', { id: userId });
		return response.data;
	}

	async reactivateUser(userId: number) {
		const response = await http.put<RsResponseData<Api.User.Req.Delete>>('user/reactivate', { id: userId });
		return response.data;
	}

	async getAdminUserList(): Promise<Api.User.Res.AdminUserList[]> {
		const response = await http.get<RsResponseData<Api.User.Res.AdminUserList[]>>('user/list');
		return response.data.data;
	}

	async userAdminPageAccess(data: Api.UserAdminPagesAccess.Req.AddOrUpdate): Promise<any> {
		const response = await http.post<RsResponseData<any>>('/userAdminPagesAccess', data);
		return response.data.data;
	}

	async bulkPagesAccess(data: any): Promise<any> {
		const response = await http.post<RsResponseData<any>>('/userAdminPagesAccess/bulkPagesAccess', data);
		return response.data.data;
	}

	async getUserAdminPageAccess(data: any): Promise<any> {
		const response = await http.get<RsResponseData<any>>('/userAdminPagesAccess/getUserPagesAccess', { id: data });
		return response?.data?.data;
	}

	async getbulkPagesAccess(data: any): Promise<any> {
		const response = await http.get<RsResponseData<any>>('/userAdminPagesAccess/getbulkPagesAccess', { id: data });
		return response.data.data;
	}
}
