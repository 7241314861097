import * as React from 'react';
import './ExperienceTile.scss';
import Box from '../box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import Paper from '../paper/Paper';

interface ExperienceTileProps {
	id: number;
	experienceId: number;
	title: string;
	icon: string;
	description: string;
	imgPath: string;
	onClick: () => void;
	onDelete: () => void;
}

const ExperienceTile: React.FC<ExperienceTileProps> = (props) => {
	return (
		<Paper className={'rsExperienceTile'} borderRadius={'4px'} padding={'10px 25px'} onClick={props.onClick}>
			<img src={props.imgPath + '?tr=w-100,tr=h-auto'} alt={'Primary Image'} />
			<Box>
				<Label variant={'h2'}>Title: {props.title}</Label>
				<Label variant={'body2'}>
					<b>Description:</b> {props.description}
				</Label>
			</Box>
			<Icon
				iconImg={'icon-trash'}
				cursorPointer
				onClick={(event) => {
					props.onDelete();
					event?.stopPropagation();
				}}
			/>
		</Paper>
	);
};

export default ExperienceTile;
