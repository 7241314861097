import * as React from 'react';
import './BackButton.scss';
import Button from '@bit/redsky.framework.rs.button';
import Label from '@bit/redsky.framework.rs.label';
import router from '../../utils/router';
import Icon from '@bit/redsky.framework.rs.icon';

interface BackButtonProps {
	name: string;
	fallbackPath?: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
	return (
		<Button
			look={'none'}
			className={'rsBackButton'}
			onClick={() => {
				router.navigate('/dashboard').catch(console.error);
			}}
		>
			<Icon iconImg={'icon-chevron-left'} size={12} />
			<Label variant={'h4'}>{props.name}</Label>
		</Button>
	);
};

export default BackButton;
