import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import * as React from 'react';
import './ManageAccommodationDetailsPopup.scss';
import { Box, Popup, popupController } from '@bit/redsky.framework.rs.996';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import LabelButton from '../../components/labelButton/LabelButton';
import LabelInput from '../../components/labelInput/LabelInput';
import { useEffect, useState } from 'react';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import serviceFactory from '../../services/serviceFactory';
import CountryService from '../../services/country/country.service';
import { OptionType } from '@bit/redsky.framework.rs.select';

export interface ManageAccommodationDetailsPopupProps extends PopupProps {
	accommodationDetails?: any;
	setAccommodationDetails?: any;
	isCreate?: boolean;
}

// const  bedTypeOptions = [{
//     value: 'Queen',
//     label: 'Queen'
// }]

// const  unitTypeOptions = [{
//     value: 'SquareFeet',
//     label: 'SquareFeet'
// }]

const ManageAccommodationDetailsPopup: React.FC<ManageAccommodationDetailsPopupProps> = (props) => {
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const [accommoDetails, setAccommoDetails] = useState<any>({
		name: '',
		totalSleep: null,
		totalBeds: null,
		totalSize: null,
		priceCents: null
	});
	const [countryOptions, setCountryOptions] = useState<OptionType[]>([]);
	const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
	const [isDataFilled, setIsDataFilled] = useState(true);

	function formatStateOrCountryListForSelect(statesOrCountries: { name: string; isoCode: string }[]) {
		return statesOrCountries.map((item: { name: string; isoCode: string }) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	useEffect(() => {
		const accoDetails = {
			name: props.accommodationDetails?.name,
			totalSleep: props.accommodationDetails?.maxOccupantCount,
			totalBeds: props.accommodationDetails?.bedDetails[0]?.qty.toString(),
			totalSize: props.accommodationDetails?.size?.max,
			priceCents: props.accommodationDetails?.priceCents
		};
		setAccommoDetails(accoDetails);
	}, []);

	useEffect(() => {
		if (accommoDetails.name && accommoDetails.totalSleep && accommoDetails.totalBeds && accommoDetails.totalSize) {
			if (props.isCreate && !accommoDetails.priceCents) setIsDataFilled(true);
			else setIsDataFilled(false);
		} else setIsDataFilled(true);
	}, [accommoDetails]);

	function handleChange(key: any, value: any) {
		setAccommoDetails({ ...accommoDetails, [key]: value });
	}

	function handleSave() {
		if (accommoDetails.name && accommoDetails.totalSleep && accommoDetails.totalBeds && accommoDetails.totalSize) {
			rsToastify.success('Destination detail added!', 'Success!');
			const sizeData = { max: accommoDetails.totalSize, min: accommoDetails.totalSize, units: 'SquareFeet' };
			const accoDetails = {
				name: accommoDetails.name,
				bedDetails: [{ type: 'Queen', isPrimary: 1, qty: accommoDetails.totalBeds, description: 'Queen' }],
				size: sizeData,
				maxOccupantCount: accommoDetails.totalSleep,
				priceCents: accommoDetails?.priceCents
			};
			props.setAccommodationDetails(accoDetails);

			popupController.close(ManageAccommodationDetailsPopup);
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsDestinationDetailPopup'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.close(ManageAccommodationDetailsPopup);
					}}
					cursorPointer
				/>
				<Box className={'title'}>
					<Label variant={'h1'}>
						{!props.isCreate ? 'Edit Accommodation Details' : 'Add Accommodation Details'}
					</Label>
				</Box>
				<div>
					<div className="input-text">Accommodation Name</div>
					<input
						type="text"
						value={accommoDetails?.name}
						className="input-box"
						onChange={({ target: { value } }) => handleChange('name', value)}
					/>
					{!accommoDetails?.name ? <div className="err-msg">Accommodation Name is required</div> : ''}
				</div>
				<div>
					<div className="input-text">Sleeps</div>
					<input
						type="number"
						value={accommoDetails?.totalSleep}
						className="input-box"
						onChange={({ target: { value } }) => handleChange('totalSleep', value)}
					/>
					{!accommoDetails?.totalSleep ? <div className="err-msg">Sleeps are required</div> : ''}
				</div>
				<div>
					<div className="input-text">price (in cents)</div>
					<input
						type="number"
						value={accommoDetails?.priceCents}
						className="input-box"
						onChange={({ target: { value } }) => handleChange('priceCents', value)}
					/>
					{!accommoDetails?.priceCents && props.isCreate ? (
						<div className="err-msg">price is required</div>
					) : (
						''
					)}
				</div>
				<div>
					<div className="input-text">Beds</div>
					<input
						type="number"
						value={accommoDetails?.totalBeds}
						className="input-box"
						onChange={({ target: { value } }) => handleChange('totalBeds', value)}
					/>
					{!accommoDetails?.totalBeds ? <div className="err-msg">Beds are required</div> : ''}
				</div>
				<div>
					<div className="input-text">Size</div>
					<input
						type="number"
						value={accommoDetails?.totalSize}
						className="input-box"
						onChange={({ target: { value } }) => handleChange('totalSize', value)}
					/>
					{!accommoDetails?.totalSize ? <div className="err-msg">Size is required</div> : ''}
				</div>
				<Box className="detail-btn-box">
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Save'}
						onClick={() => {
							handleSave();
						}}
						disabled={isDataFilled}
					/>
					<LabelButton
						className="cancel-btn"
						look={'containedSecondary'}
						variant={'button'}
						label={'Cancel'}
						onClick={async () => {
							popupController.close(ManageAccommodationDetailsPopup);
						}}
					/>
				</Box>
			</Paper>
		</Popup>
	);
};

export default ManageAccommodationDetailsPopup;
