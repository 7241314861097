import * as React from 'react';
import './ChooseIconPopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Paper from '../../components/paper/Paper';
import iconJson from '../../icons/cmsIcons/selection.json';
import Icon from '@bit/redsky.framework.rs.icon';
import Button from '@bit/redsky.framework.rs.button/dist/Button';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { ObjectUtils } from '../../utils/utils';

export interface ChooseIconPopupProps extends PopupProps {
	onSelect: (value: string) => void;
}

const ChooseIconPopup: React.FC<ChooseIconPopupProps> = (props) => {
	function renderIcons() {
		return iconJson.icons.map((item: any) => {
			let iconName = item.icon.tags[0];
			return (
				<Button
					key={iconName}
					look={'none'}
					onClick={() => {
						props.onSelect(iconName);
						popupController.close(ChooseIconPopup);
					}}
				>
					{ObjectUtils.isArrayWithData(item.icon.paths) && item.icon.isMulticolor && (
						<span className={`cms-${iconName}`} style={{ fontSize: '32px' }}>
							{item.icon.paths.map((path: any, index: number) => {
								return <span key={path} className={`path${index + 1}`} />;
							})}
						</span>
					)}
					{!item.icon.isMulticolor && <Icon iconImg={`cms-${iconName}`} size={18} />}
				</Button>
			);
		});
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper
				className={'rsChooseIconPopup'}
				borderRadius={'4px'}
				width={'550px'}
				height={'500px'}
				padding={'50px 25px'}
			>
				<Label variant={'h4'}>Choose an icon</Label>
				<Icon
					iconImg={'icon-close'}
					size={17}
					cursorPointer
					onClick={() => {
						popupController.close(ChooseIconPopup);
					}}
				/>
				{renderIcons()}
			</Paper>
		</Popup>
	);
};

export default ChooseIconPopup;
