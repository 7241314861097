import React, { useEffect, useState } from 'react';
import './PointsAdmin.scss';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import debounce from 'lodash.debounce';
import SubHeader from '../../components/subHeader/SubHeader';
import router from '../../utils/router';
import serviceFactory from '../../services/serviceFactory';
import DestinationService from '../../services/destination/destination.service';
import SpireTable from '../../components/spireTable/SpireTable';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import LabelInputFc from '../../components/LabelInputFc/LabelInputFc';
import { WebUtils } from '../../utils/utils';
import BrandService from '../../services/brand/brand.service';
import LoadingPage from '../loadingPage/LoadingPage';
import Box from '../../components/box/Box';
import { RsFormGroup, RsFormControl, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelButton from '../../components/labelButton/LabelButton';
import RewardService from '../../services/reward/reward.service';
import { useRecoilValue } from 'recoil';
import globalState, { getRecoilExternalValue } from '../../state/globalState';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import AuditLogPopup, { AuditLogPopupProps } from '../../popups/auditLogPopup/AuditReportPopup';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import Select, { OptionType } from '@bit/redsky.framework.rs.select';
import { log } from 'console';

const PointsAdminListPage: React.FC = () => {
	const brandService = serviceFactory.get<BrandService>('BrandService');
	const rewardService = serviceFactory.get<RewardService>('RewardService');
	const permissionService = serviceFactory.get<PermissionService>('PermissionService');
	let user = getRecoilExternalValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const [brandList, setBrandList] = useState<Api.Brand.Res.Report[]>([]);
	const [sortField, setSortField] = useState<string>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [brandTotal, setBrandTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [pointExpirationOptions, setpointExpirationOptions] = useState<OptionType[]>([{ value: 0, label: 'Any' }]);

	const [form, setForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('minimumPointCost', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Minimum Point Cost is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
					return control.value > 0;
				})
			]),
			new RsFormControl('redemptionRatio', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Please fill redemption value')
			]),
			new RsFormControl('redeemPointsPerDollar', 0, [
				new RsValidator(RsValidatorEnum.REQ, 'Please fill redeem per dollar value'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
					return control.value > 0;
				})
			]),
			new RsFormControl('rewardPointsPerDollar', 0, [
				new RsValidator(RsValidatorEnum.REQ, 'Please fill reward points per dollar value'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
					return control.value > 0;
				})
			]),
			new RsFormControl('pointExpirationTime', '', [])
		])
	);

	const getPlatformVariable = async () => {
		try {
			const res = await rewardService.getPointsCost();
			let updatedForm = form.clone();
			updatedForm.get('minimumPointCost').value = res?.minimumPointCost;
			updatedForm.get('redemptionRatio').value = res?.redemptionRatio;
			updatedForm.get('rewardPointsPerDollar').value = res?.rewardPointsPerDollar;
			updatedForm.get('redeemPointsPerDollar').value = res?.redeemPointsPerDollar;

			let pointExpirationTime = updatedForm.getCloneDeep('pointExpirationTime');
			pointExpirationTime.value = JSON.stringify(res?.pointExpirationTime) || '';
			setForm((form) => form.clone().update(pointExpirationTime));

			let expiry: any = res?.pointExpiration!;
			setpointExpirationOptions(
				expiry.map((expiry: any) => {
					return {
						value: JSON.stringify(expiry),
						label: expiry.expiration !== null ? expiry.expiration + ' ' + expiry.period : expiry.period
					};
				})
			);

			setForm(updatedForm);
		} catch (error) {
			console.log('check platform error', error);
		}
	};

	useEffect(() => {
		// console.log(user, 'checking user');
		getPlatformVariable();
	}, []);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await brandService.getReportsByPage(pageQuery);
			setBrandList([...res.data]);
			setBrandTotal(res.data.length);
			setLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			// popupController.close(BrandsReportPopup);
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}
	function handleTableClick(data: any) {
		router.navigate('/dashboard/destination-list/manage-destinations?di=' + data.id).catch();
	}

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	let formDebounce = debounce(async (control: RsFormControl) => {
		if (control.key === 'minimumPointCost') {
			if (isNaN(Number(control.value))) {
				control.value = control.value.toString().replace(/[^.0-9]+/g, '');
			}
			control.value = ConTwoDecDigit(control.value);
		}
		if (control.key === 'redeemPointsPerDollar' && typeof control.value === 'string') {
			if (isNaN(Number(control.value))) {
				control.value = control.value.toString().replace(/[^.0-9]+/g, '');
			}
			control.value = ConTwoDecDigit(control.value);
		}
		if (control.key === 'rewardPointsPerDollar' && typeof control.value === 'string') {
			if (isNaN(Number(control.value))) {
				control.value = control.value.toString().replace(/[^.0-9]+/g, '');
			}
			control.value = ConTwoDecDigit(control.value);
		}
		// if (control.key === 'pointExpirationTime') {
		// 	if (isNaN(Number(control.value))) {
		// 		control.value = control.value.toString().replace(/[^.0-9]+/g, '');
		// 	}
		// 	control.value = ConTwoDecDigit(control.value);
		// }
		// if (control.key === 'bookPointsPerDollar' && typeof control.value === 'string') {
		// 	control.value = control.value.replace(/[^0-9]/g, '');
		// }
		// if (control.key === 'redeemPointsPerDollar' && typeof control.value === 'string') {
		// 	control.value = control.value.replace(/[^0-9]/g, '');
		// }
		setForm(form.clone().update(control));
	});

	async function checkIsFormValid(): Promise<boolean> {
		let formIsValid = await form.isValid();
		setForm(form.clone());
		return formIsValid;
	}

	const submitData = async () => {
		setLoading(true);
		if (!(await checkIsFormValid())) {
			setLoading(false);
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}
		try {
			let newReward: any = form.toModel();

			await rewardService.createUpdatePointsCost(newReward);
			// pointExpirationTime: JSON.stringify({ expiration:1, period:"Year"})
			const pageQuery: RedSky.PageQuery = {
				pagination: {
					page: 1,
					perPage: 10
				},
				sort: {
					field: sortField,
					order: sortOrder
				},
				filter: {
					matchType: 'like',
					searchTerm: []
				}
			};
			await getData(pageQuery);
			rsToastify.success('Points value updated successfully', 'Success');
			setLoading(false);
		} catch (error) {
			rsToastify.error('Error', 'Something went wrong');
			setLoading(false);
		}
	};

	function renderSaveButton() {
		if (permissionService.hasPermission(PermissionList.ADMINISTRATION_WRITE) && user?.userRoleId === 1) {
			return (
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					className={'saveButton'}
					label={'Save'}
					onClick={submitData}
				/>
			);
		}
		return null;
	}

	function auditlogButton() {
		if (permissionService.hasPermission(PermissionList.ADMINISTRATION_WRITE) && user?.userRoleId === 1) {
			return (
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					className={'saveButton'}
					label={'Audit log'}
					onClick={() => popupController.open<AuditLogPopupProps>(AuditLogPopup, {})}
				/>
			);
		}
		return null;
	}

	return (
		<Page className={'rsDestinationListPage'}>
			<SubHeader
				header={'Manage Point Cost'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Destination List', link: '/dashboard/destination-list' }
				]}
			/>
			{loading && <LoadingPage />}
			<div className={'labelInputscontainer'}>
				<div className={'labelInputs'}>
					<Box className="topRowInputsContainer">
						{/* <Box className={'topRowInputs'}>
							<LabelInputFc
								className={'rewardName'}
								title={'Points Redemption Value'}
								inputType={'text'}
								control={form.get('redemptionRatio')}
								updateControl={(updateControl) => formDebounce(updateControl)}
							/>
							<LabelInputFc
								className={'rewardName'}
								title={'Minimum Allowed Point Cost'}
								inputType={'text'}
								control={form.get('minimumPointCost')}
								updateControl={(updateControl) => formDebounce(updateControl)}
							/>
						</Box> */}
						<Box className={'topRowInputs'}>
							<LabelInputFc
								className={'rewardName'}
								title={'Redemption Value of a Point'}
								inputType={'number'}
								control={form.get('redeemPointsPerDollar')}
								updateControl={(updateControl) => formDebounce(updateControl)}
							/>
							<LabelInputFc
								className={'rewardName'}
								title={'Global Point Cost'}
								inputType={'number'}
								control={form.get('minimumPointCost')}
								updateControl={(updateControl) => formDebounce(updateControl)}
							/>
						</Box>
						<Box className={'topRowInputs'}>
							<LabelSelect
								variant={'h2'}
								title={'Point Expiration'}
								options={pointExpirationOptions}
								control={form.get('pointExpirationTime')}
								updateControl={(updateControl) => formDebounce(updateControl)}
							/>
							<LabelInputFc
								className={'rewardName'}
								title={'Global Reward Points Per Dollar'}
								inputType={'number'}
								control={form.get('rewardPointsPerDollar')}
								updateControl={(updateControl) => formDebounce(updateControl)}
							/>
						</Box>
					</Box>
					{renderSaveButton()}
				</div>
				{auditlogButton()}
			</div>
			<hr />
			<SpireTable
				table={{
					placeholder: 'Search by name',
					filterQuery: [{ column: 'name', value: '', conjunction: 'OR' }]
				}}
				columns={[
					{
						id: 'brand.name',
						label: 'Brand Name',
						align: 'left',
						className: 'brandCell brandNameCell',
						sort: sortField === 'brand.name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'name'
					},
					{
						id: 'pointsPerDollar',
						label: 'Points Per Dollar',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'pointsPerDollar' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'costPerPoint',
						label: 'Point Cost',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'costPerPoint' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: (data) => (Math.round(data.costPerPoint * 100) / 100).toFixed(4)
					},
					{
						id: 'costToMerchant',
						label: 'Cost to Brand Per Dollar',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'costToMerchant' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: (data) => (Math.round(data.costToMerchant * 100) / 100).toFixed(4)
					},
					{
						id: 'spireRevenuePerDollar',
						label: 'Spire Revenue Per Dollar',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'spireRevenuePerDollar' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: (data) => (Math.round(data.spireRevenuePerDollar * 100) / 100).toFixed(4)
					},
					{
						id: 'spireRevenuePerPoint',
						label: 'Spire Revenue Per Point',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'spireRevenuePerPoint' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'brand.loyaltyStatus',
						label: 'Loyalty Status',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'brand.loyaltyStatus' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'loyaltyStatus'
					}
				]}
				data={brandList}
				total={brandTotal}
				sortField={sortField}
				onGetData={getData}
				rowOnClick={() => {}}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default PointsAdminListPage;

export function pointsAdminPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([
			PermissionList.LOYALTY_REWARDS_WRITE,
			PermissionList.COMPANY_READ,
			PermissionList.ADMINISTRATION_READ
		]);
}
