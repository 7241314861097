import * as React from 'react';
import { useEffect, useState } from 'react';
import './CreateUserPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import Box from '../../components/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import { ObjectUtils, US_States, WebUtils } from '../../utils/utils';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import router from '../../utils/router';
import UserService from '../../services/user/user.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { OptionType } from '@bit/redsky.framework.rs.select';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import UserReportingPermissions from '../../components/userReportingPermissions/UserReportingPermissions';
import CountryService from '../../services/country/country.service';

interface CreateUserPageProps {}

const CreateUserPage: React.FC<CreateUserPageProps> = () => {
	const userService = serviceFactory.get<UserService>('UserService');
	const recoilUserRoles = useRecoilValue<Model.UserRole[]>(globalState.userRoles);
	const [userBusinessIds, setUserBusinessIds] = useState<{
		companyIds?: number[];
		brandIds?: number[];
		brandLocationIds?: number[];
	}>({});
	const [userForm, setUserForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('firstName', '', [new RsValidator(RsValidatorEnum.REQ, 'Enter a first name')]),
			new RsFormControl('lastName', '', [new RsValidator(RsValidatorEnum.REQ, 'Enter a last name')]),
			new RsFormControl('phone', '', [
				new RsValidator(RsValidatorEnum.REG, 'Enter a phone number', /^\d{4,15}$/)
			]),
			new RsFormControl('primaryEmail', '', [new RsValidator(RsValidatorEnum.EMAIL, 'Enter a valid email')]),
			new RsFormControl('userRoleId', '', [new RsValidator(RsValidatorEnum.REQ, 'Select a member type')])
		])
	);
	const [addressForm, setAddressForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('address', '', [new RsValidator(RsValidatorEnum.REQ, 'Enter an address')]),
			new RsFormControl('address2', '', []),
			new RsFormControl('city', '', [new RsValidator(RsValidatorEnum.REQ, 'Enter a city')]),
			new RsFormControl('state', '', [new RsValidator(RsValidatorEnum.REQ, 'Select a state')]),
			new RsFormControl('zip', '', [new RsValidator(RsValidatorEnum.REG, 'Enter a valid zip code', /^\d{5,9}$/)]),
			new RsFormControl('country', 'US', [new RsValidator(RsValidatorEnum.REQ, 'Select your Country')])
		])
	);
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const [roleOptions, setRoleOptions] = useState<OptionType[]>([]);
	const [countryOptions, setCountryOptions] = useState<OptionType[]>([]);
	const [stateOptions, setStateOptions] = useState<OptionType[]>([]);

	useEffect(() => {
		let userRoles = userService.getUserRoles();
		console.log(userRoles, 'check roles');

		setRoleOptions(
			userRoles.map((role) => {
				return { value: role.id, label: role.name };
			})
		);
	}, [recoilUserRoles]);
	useEffect(() => {
		async function getCountries() {
			try {
				let countries = await countryService.getAllCountries();
				setCountryOptions(formatStateOrCountryListForSelect(countries.countries));
			} catch (e) {
				console.error('getCountries', e);
				throw rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get a list of countries.'),
					'Server Error'
				);
			}
		}
		getCountries().catch(console.error);
	}, []);

	useEffect(() => {
		async function getStates() {
			try {
				let response = await countryService.getStates(`${addressForm.get('country').value}` || 'US');
				if (response.states) {
					let newStates = formatStateOrCountryListForSelect(response.states);
					setStateOptions(newStates);
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);
			}
		}
		getStates().catch(console.error);
	}, [countryOptions, addressForm.get('country').value]);

	function formatStateOrCountryListForSelect(statesOrCountries: { name: string; isoCode: string }[]) {
		return statesOrCountries.map((item: { name: string; isoCode: string }) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	function updateUserForm(control: RsFormControl) {
		setUserForm(userForm.clone().update(control));
	}

	function updateAddressForm(control: RsFormControl) {
		setAddressForm(addressForm.clone().update(control));
	}

	async function validatePhoneNumber() {
		await userForm.get('phone').validate();
	}

	async function validateMemberType() {
		await userForm.get('userRoleId').validate();
	}

	async function saveUser() {
		let validUser = await userForm.isValid();
		let validAddress = await addressForm.isValid();
		if (!validUser || !validAddress) {
			setUserForm(userForm.clone());
			rsToastify.error('Make sure everything is filled out correctly', 'Missing or Incorrect Information');
			return;
		}
		if (userForm.get('firstName').value.toString().includes('"')) {
			let firstNameValue = userForm.getCloneDeep('firstName');
			firstNameValue.value = firstNameValue.value.toString().replace(/"/g, '');
			setUserForm(userForm.clone().update(firstNameValue));
		}
		if (userForm.get('lastName').value.toString().includes('"')) {
			let lastNameValue = userForm.getCloneDeep('lastName');
			lastNameValue.value = lastNameValue.value.toString().replace(/"/g, '');
			setUserForm(userForm.clone().update(lastNameValue));
		}
		const userObj: {
			firstName: string;
			lastName: string;
			primaryEmail: string;
			phone: string;
			userRoleId: number;
		} = userForm.toModel();
		let address: Api.UserAddress.Req.Create = {
			type: 'BOTH',
			address1: addressForm.get('address').value.toString(),
			address2: addressForm.get('address2').value.toString(),
			city: addressForm.get('city').value.toString(),
			state: addressForm.get('state').value.toString(),
			zip: addressForm.get('zip').value.toString(),
			country: addressForm.get('country').value.toString(),
			isDefault: 1
		};
		try {
			const userToCreate: Api.User.Req.Create = {
				...userObj,
				address,
				password: ''
			};
			if (!ObjectUtils.isEmptyObject(userBusinessIds)) {
				userToCreate.businesses = userBusinessIds;
			}
			await userService.createUser(userToCreate);
			rsToastify.success('You created a user!', 'Success!');
			await router.navigate('/dashboard/user-list');
		} catch (e) {
			console.log(e);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function handleIds(userBusinessLevel: Model.UserBusinessLevel, ids: number[]) {
		if (userBusinessLevel === 'SUPER') {
			setUserBusinessIds({
				companyIds: [0]
			});
		} else if (userBusinessLevel === 'COMPANY') {
			setUserBusinessIds({
				companyIds: ids,
				brandIds: [],
				brandLocationIds: []
			});
		} else if (userBusinessLevel === 'BRAND') {
			setUserBusinessIds({
				companyIds: [],
				brandIds: ids,
				brandLocationIds: []
			});
		} else if (userBusinessLevel === 'LOCATION') {
			setUserBusinessIds({
				companyIds: [],
				brandIds: [],
				brandLocationIds: ids
			});
		}
	}

	function renderUser() {
		return (
			<Box className={'renderUser'}>
				<LabelInput
					title={'First Name'}
					onChange={() => {}}
					inputType={'text'}
					control={userForm.get('firstName')}
					updateControl={updateUserForm}
				/>
				<LabelInput
					title={'Last Name'}
					onChange={() => {}}
					inputType={'text'}
					control={userForm.get('lastName')}
					updateControl={updateUserForm}
				/>
				<LabelSelect
					variant={'caption'}
					title={'Member Type'}
					control={userForm.get('userRoleId')}
					updateControl={(control) => {
						setUserForm(userForm.clone().update(control));
						validateMemberType();
					}}
					options={roleOptions}
				/>

				<LabelInput
					className={'phoneInput'}
					title={'Phone'}
					onChange={(value) => {
						let phoneValue = userForm.get('phone');
						phoneValue.value = value;
						setUserForm(userForm.clone().update(phoneValue));
						validatePhoneNumber();
					}}
					labelVariant={'caption'}
					inputType={'tel'}
					debounceTime={0}
					control={userForm.get('phone')}
					updateControl={updateUserForm}
					isPhoneInput
				/>
				<LabelInput
					title={'Email'}
					onChange={() => {}}
					inputType={'text'}
					className={'email'}
					control={userForm.get('primaryEmail')}
					updateControl={updateUserForm}
				/>
			</Box>
		);
	}

	function renderAddress() {
		return (
			<Box className={'address'}>
				<Box className={'addressLines'}>
					<LabelInput
						title={'Address 1'}
						onChange={() => {}}
						inputType={'text'}
						className={'addressLine'}
						control={addressForm.get('address')}
						updateControl={updateAddressForm}
					/>
					<LabelInput
						title={'Address 2'}
						control={addressForm.get('address2')}
						updateControl={updateAddressForm}
						inputType={'text'}
						className={'addressLine'}
					/>
				</Box>
				<Box className={'cityStateZip'}>
					<LabelInput
						title={'City'}
						onChange={() => {}}
						inputType={'text'}
						control={addressForm.get('city')}
						updateControl={updateAddressForm}
					/>
					<LabelSelect
						variant={'caption'}
						title={'Country'}
						control={addressForm.get('country')}
						options={countryOptions}
						updateControl={(control) => {
							setAddressForm(addressForm.clone().update(control));
						}}
					/>
					<LabelSelect
						variant={'caption'}
						options={stateOptions}
						updateControl={(control) => {
							setAddressForm(addressForm.clone().update(control));
						}}
						control={addressForm.get('state')}
						title={'State'}
					/>
					<LabelInput
						title={'Zip'}
						onChange={() => {}}
						minLength={5}
						maxLength={9}
						inputType={'text'}
						control={addressForm.get('zip')}
						updateControl={updateAddressForm}
					/>
				</Box>
			</Box>
		);
	}

	function renderBusinessAccess() {
		return (
			<Box className={'businessAccessContainer'}>
				<UserReportingPermissions handleIds={handleIds} />
			</Box>
		);
	}

	return (
		<Page className={'rsCreateUserPage'}>
			<SubHeader
				header={'Create User'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'User List', link: '/dashboard/user-list' },
					{ label: 'Create User', link: '/dashboard/user-list/create-user' }
				]}
			/>
			{renderUser()}
			<hr className={'cardDivider'} />
			{renderBusinessAccess()}
			<hr className={'cardDivider'} />
			{renderAddress()}
			<Box className={'buttonGroup'} display={'flex'}>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Create New User'}
					onClick={() => saveUser()}
				/>
				<LabelButton
					look={'containedSecondary'}
					variant={'button'}
					label={'Cancel'}
					onClick={async () => {
						await router.navigate('/dashboard/user-list');
					}}
				/>
			</Box>
		</Page>
	);
};

export default CreateUserPage;

export function createUserPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_WRITE]);
}
