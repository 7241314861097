import React, { useEffect, useState } from 'react';
import './CreateEditRewardCategoryPage.scss';
import { Box, Page } from '@bit/redsky.framework.rs.996';
import disableRoute from '../../customHooks/useMiddleware';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import LabelButton from '../../components/labelButton/LabelButton';
import RewardService from '../../services/reward/reward.service';
import router from '../../utils/router';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import LoadingPage from '../loadingPage/LoadingPage';
import LabelInput from '../../components/labelInput/LabelInput';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import ImageManager from '../../components/imageManager/ImageManager';

const CreateEditRewardCategoryPage: React.FC = () => {
	const params = router.getPageUrlParams<{ categoryId: number }>([
		{ key: 'ci', default: '', type: 'integer', alias: 'categoryId' }
	]);
	const rewardService = serviceFactory.get<RewardService>('RewardService');
	const [category, setCategory] = useState<Api.Reward.Category.Res.Get>();
	const [isFeatured, setIsFeatured] = useState<1 | 0>(0);
	const [mediaDetails, setMediaDetails] = useState<Api.MediaDetails[]>([]);
	const [deleteImages, setDeleteImages] = useState<number[]>([]);
	const [waitToLoad, setWaitToLoad] = useState<boolean>(true);
	const [categoryForm, setCategoryForm] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('name', '', [new RsValidator(RsValidatorEnum.REQ, 'Enter a Name')])])
	);

	useEffect(() => {
		disableRoute.useMiddleware();
	}, []);

	useEffect(() => {
		async function getCategory() {
			try {
				let res = await rewardService.getCategoryById(params.categoryId);
				setCategory(res);
				let name = categoryForm.get('name');
				name.value = res.name;
				setCategoryForm(categoryForm.clone().update(name));
				setWaitToLoad(false);
				setIsFeatured(res.isFeatured);
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
					'Server Error'
				);
			}
		}
		if (params.categoryId) {
			getCategory().catch(console.error);
		} else {
			setWaitToLoad(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function deleteCategory() {
		setIsFeatured(0);
		try {
			await createEditStoreCategory(0);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function checkIsFormValid(): Promise<boolean> {
		if (!ObjectUtils.isArrayWithData(mediaDetails)) {
			rsToastify.error('New Category requires at least one image.', 'Add Image!');
		}
		let formIsValid = await categoryForm.isValid();
		setCategoryForm(categoryForm.clone());
		return formIsValid;
	}

	async function createEditStoreCategory(isActive: 1 | 0) {
		if (!mediaDetails.find((img) => img.isPrimary)) {
			rsToastify.error('Must select a primary Image', 'Select Primary!');
			return;
		}
		if (!(await checkIsFormValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}
		if (deleteImages.length >= 1) {
			deleteImages.forEach((imageId) => {
				rewardService.deleteCategoryImage({ id: imageId });
			});
		}
		let data: Api.Reward.Category.Req.Create = {
			name: categoryForm.get('name').value.toString(),
			mediaDetails: mediaDetails.map((img) => {
				return { id: img.id, isPrimary: img.isPrimary };
			}),
			isFeatured,
			isActive
		};

		let updateData: Api.Reward.Category.Req.Update = {
			id: params.categoryId,
			...data
		};

		if (params.categoryId) {
			updateCategory(updateData).catch(console.error);
		} else {
			saveCategory(data).catch(console.error);
		}
	}

	async function saveCategory(data: Api.Reward.Category.Req.Create) {
		try {
			const newCategory: Api.Reward.Category.Res.Get | null = await rewardService.createCategory(data);
			rsToastify.success(`${newCategory.name} category has been created.`, 'Success');
			router.navigate('/dashboard/reward-list/category-list').catch(console.error);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function updateCategory(data: Api.Reward.Category.Req.Update) {
		try {
			await rewardService.updateCategory(data);
			rsToastify.success('Category was updated.', 'Success');
			router.navigate('/dashboard/reward-list/category-list').catch(console.error);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function handleMediaData(mediaData: { id: number; isPrimary: 1 | 0; image: string }[], deleteImg: number[]) {
		setMediaDetails(
			mediaData.map((img) => {
				return { id: img.id, isPrimary: img.isPrimary };
			})
		);
		setDeleteImages(deleteImg);
	}

	function renderButtons() {
		if (params.categoryId) {
			return (
				<Box display={'flex'} justifyContent={'space-between'} maxWidth={'500px'}>
					<LabelButton
						look="containedPrimary"
						variant="button"
						label="Update Category"
						onClick={() => createEditStoreCategory(category?.isActive || 1)}
					/>
					<LabelButton
						look={category?.isActive ? 'containedSecondary' : 'containedPrimary'}
						variant="button"
						label={category?.isActive ? 'Archive Category' : 'Reactivate Category'}
						onClick={() => (category?.isActive ? deleteCategory() : createEditStoreCategory(1))}
					/>
					<LabelButton
						look="containedSecondary"
						variant="button"
						label="Cancel"
						onClick={() => router.navigate('/dashboard/reward-list/category-list').catch(console.error)}
					/>
				</Box>
			);
		} else {
			return (
				<Box display={'flex'} justifyContent={'space-between'} width={'380px'}>
					<LabelButton
						look="containedPrimary"
						variant="button"
						label="Add Category"
						disabled={!mediaDetails.find((image) => image.isPrimary)}
						onClick={() => createEditStoreCategory(category?.isActive || 1)}
					/>
					<LabelButton
						look="containedSecondary"
						variant="button"
						label="Cancel"
						onClick={() => router.navigate('/dashboard/reward-list/category-list').catch(console.error)}
					/>
				</Box>
			);
		}
	}

	return waitToLoad ? (
		<LoadingPage />
	) : (
		<Page className={'rsCreateEditRewardCategoryPage'}>
			<SubHeader
				header={!params.categoryId ? 'Create Reward Category' : 'Manage Reward Category'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Category List', link: '/dashboard/reward-list/category-list' },
					{ label: 'Manage Category', link: '/dashboard/reward-list/manage-category' }
				]}
			/>
			<Box className="layoutContainer">
				<Box className="newCategorySection">
					<LabelInput
						inputType="text"
						title="Category Name"
						control={categoryForm.get('name')}
						updateControl={(control) => setCategoryForm(categoryForm.clone().update(control))}
					/>
					<LabelCheckbox
						value={'isFeatured'}
						text={'Featured'}
						isChecked={!!category?.isFeatured}
						onSelect={() => setIsFeatured(1)}
						onDeselect={() => setIsFeatured(0)}
					/>
					<ImageManager
						data={category ? category.media : []}
						id={'categoryUpload'}
						handleMediaData={handleMediaData}
					/>
					<div className={'buttonContainer'}>{renderButtons()}</div>
				</Box>
			</Box>
		</Page>
	);
};

export default CreateEditRewardCategoryPage;

export function createEditRewardCategoryPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_REWARDS_READ]);
}
