import React, { useEffect, useRef, useState } from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import styles from './ManageCampaignsPage.scss';
import disableRoute from '../../customHooks/useMiddleware';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import serviceFactory from '../../services/serviceFactory';
import SubHeader from '../../components/subHeader/SubHeader';
import Box from '../../components/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import CampaignDetailsCard from './campaignDetailsCard/CampaignDetailsCard';
import CampaignService from '../../services/campaign/campaign.service';
import router from '../../utils/router';
import { addDeleteFilterQueryForTables, DateUtils, WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpireTable, { FieldName } from '../../components/spireTable/SpireTable';

const ManageCampaignsPage: React.FC = () => {
	const campaignService = serviceFactory.get<CampaignService>('CampaignService');
	const matchType = 'like';
	const conjunction = 'OR';

	const [scrollLength] = useState<number>(parseInt(styles.cardWidth) + parseInt(styles.cardGapPixels));
	const parentRef = useRef<HTMLElement>(null);
	const [imageIndex, setImageIndex] = useState<number>(0);
	const [endingCampaigns, setEndingCampaigns] = useState<Api.Campaign.Detail[]>([]);
	const [campaigns, setCampaigns] = useState<Api.Campaign.Detail[]>([]);
	const [campaignTotal, setCampaignTotal] = useState<number>(0);
	const [sortField, setSortField] = useState<FieldName>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [filter, setFilter] = useState<RedSky.FilterQueryValue[]>([
		{ column: 'name', value: '', conjunction, matchType }
	]);
	const [perPage, setPerPage] = useState<number>(10);
	const [pageNumber, setPageNumber] = useState<number>(1);

	useEffect(() => {
		disableRoute.useMiddleware();
	}, []);

	useEffect(() => {
		getCampaigns().catch(console.error);
	}, [pageNumber, perPage, sortField, sortOrder, matchType, filter]);

	function addFilterDates(column: string, date: Date | null) {
		if (date !== null) {
			setFilter(
				addDeleteFilterQueryForTables([{ column: column, value: DateUtils.clientToServerDate(date) }], filter)
			);
		} else {
			setFilter(addDeleteFilterQueryForTables([{ column: column, value: '' }], filter));
		}
	}

	async function getCampaigns() {
		try {
			let pageQuery: RedSky.PageQuery = {
				pagination: { page: pageNumber, perPage },
				sort: {
					field: sortField,
					order: sortOrder
				},
				filter: {
					matchType,
					searchTerm: filter.map((search) => {
						if (search.column === 'createDate') {
							return { column: 'createdOn', value: search.value };
						} else return search;
					})
				}
			};
			const response = await campaignService.getCampaignsByPage(pageQuery);

			let campaignResults: Api.Campaign.Detail[] = response.data;
			setCampaigns(campaignResults);
			setEndingCampaigns(
				campaignResults.filter((campaign) => {
					return (
						DateUtils.clientToServerDate(new Date(campaign.endOn)) <=
						DateUtils.clientToServerDate(DateUtils.addDays(new Date(), 30))
					);
				})
			);
			setCampaignTotal(response.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function onNextCarousel() {
		if (imageIndex === endingCampaigns.length - 1) return;
		setImageIndex(imageIndex + 1);
		let val = scrollLength * (imageIndex + 1);
		parentRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
	}

	function onPrevCarousel() {
		if (imageIndex === 0) return;
		setImageIndex(imageIndex - 1);
		let val = scrollLength * (imageIndex - 1);
		if (val < 0) val = parentRef.current!.scrollLeft;
		parentRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
	}

	function renderEndingCampaigns() {
		if (!endingCampaigns) return;
		return endingCampaigns.map((item: Api.Campaign.Res.Get, index) => {
			return <CampaignDetailsCard key={index} campaignDetails={item} />;
		});
	}

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const response = await campaignService.getCampaignsByPage(pageQuery);
			let campaignResults: Api.Campaign.Detail[] = response.data;
			setCampaigns(campaignResults);
			setEndingCampaigns(
				campaignResults.filter((campaign) => {
					return (
						DateUtils.clientToServerDate(new Date(campaign.endOn)) <=
						DateUtils.clientToServerDate(DateUtils.addDays(new Date(), 30))
					);
				})
			);
			setCampaignTotal(response.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function renderActions(data: any) {
		return data.actions.map((action: Api.Campaign.Action) => action.name).join(', ');
	}

	function handleTableClick(data: any) {
		router.navigate(`/dashboard/reward-list/campaigns/create-campaign?ci=${data.id}`).catch(console.error);
	}

	return (
		<Page className="rsManageCampaignsPage">
			<SubHeader
				header="Manage Loyalty Campaigns"
				buttonOptions={[
					{ name: 'Create Campaign', path: '/dashboard/reward-list/campaigns/create-campaign' },
					{ name: 'Campaign Actions', path: '/dashboard/reward-list/campaigns/actions' }
				]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Campaigns', link: '/dashboard/reward-list/campaigns' }
				]}
			/>
			<Box className="endingSoonSection">
				<Label variant="h1">Campaigns Ending Soon</Label>
				<div className="carouselHolder">
					<div className="carouselControl" onClick={onPrevCarousel}>
						&lt;
					</div>
					<div ref={parentRef} className="campaignCarousel">
						{renderEndingCampaigns()}
						<div className="controlBuffer">&nbsp;</div>
					</div>
					<div className="carouselControl" onClick={onNextCarousel}>
						&gt;
					</div>
				</div>
			</Box>
			<Label variant="h1" className={'allCampaignsHeader'}>
				All Campaigns
			</Label>
			<SpireTable
				table={{
					placeholder: 'Campaign Name',
					filterQuery: [{ column: 'name', value: '' }]
				}}
				columns={[
					{
						id: 'name',
						label: 'Campaign Name',
						align: 'left',
						className: 'campaignCell nameHeader',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'userActions',
						label: 'User Actions',
						align: 'left',
						className: 'campaignCell actionsHeader hideSortArrow',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: renderActions
					},
					{
						id: 'createdOn',
						label: 'Creation Date',
						align: 'left',
						className: 'campaignCell',
						sort: sortField === 'createdOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},
					{
						id: 'startOn',
						label: 'Start Date',
						align: 'left',
						className: 'campaignCell',
						sort: sortField === 'startOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'startOn',
						cellType: 'DATE'
					},
					{
						id: 'endOn',
						label: 'End Date',
						align: 'left',
						className: 'campaignCell',
						sort: sortField === 'endOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'endOn',
						cellType: 'DATE'
					},
					{
						id: 'maxReward',
						label: 'Max Action Points',
						align: 'left',
						className: 'campaignCell',
						sort: sortField === 'maxReward' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'completionPoints',
						label: 'Campaign Completion Points',
						align: 'left',
						className: 'campaignCell',
						sort: sortField === 'completionPoints' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					}
					//TODO: WILL BE ADDING BACK IN AS SOON AS WE HAVE A REFERENCE NUMBER FROM THEM
					// {
					// 	id: 'referenceNumber',
					// 	label: 'Activity Reference Number',
					// 	align: 'left',
					// 	className: 'campaignCellHeader referenceNumberHeader',
					// 	sort: sortField === 'referenceNumber' ? sortOrder : undefined
					// }
				]}
				data={campaigns}
				total={campaignTotal}
				sortField={'name'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default ManageCampaignsPage;

export function manageCampaignsPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_CAMPAIGNS_READ]);
}
