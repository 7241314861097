import * as React from 'react';
import './PackageFeatureTile.scss';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Paper from '../paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';

interface PackageTileProps {
	code?: string;
	title: string;
	description: string;
	imgPath: string;
	onClick?: (event: any) => void;
	onDelete?: () => void;
	isPackage: boolean;
}

const PackageFeatureTile: React.FC<PackageTileProps> = (props) => {
	return (
		<Paper className={'rsPackageFeatureTile'} borderRadius={'4px'} padding={'10px 25px'} onClick={props.onClick}>
			<img src={props.imgPath + '?tr=w-100,tr=h-auto'} alt={'Primary Image'} />
			<Box maxWidth={'85%'} maxHeight={'70px'}>
				{props.isPackage && <Label variant={'h4'}>Package Code: {props.code}</Label>}
				<Label variant={'h2'}>Title: {props.title}</Label>
				<Label className="description" variant={'body2'}>
					<b>Description:</b> {props.description}
				</Label>
			</Box>
			{!props.isPackage && (
				<Icon
					iconImg={'icon-trash'}
					cursorPointer
					onClick={(event) => {
						if (props.onDelete) props.onDelete();
						event?.stopPropagation();
					}}
				/>
			)}
		</Paper>
	);
};

export default PackageFeatureTile;
