import * as React from 'react';
import './SubHeader.scss';
import BackButton from '../backButton/BackButton';
import Label from '@bit/redsky.framework.rs.label';
import BreadCrumb from '../breadCrumb/BreadCrumb';
import Box from '../box/Box';
import LinkButton from '../linkButton/LinkButton';

interface ButtonObj {
	name: string;
	path: string;
	iconName?: string;
}

interface SubHeaderProps {
	header: string;
	buttonOptions?: ButtonObj[];
	fallbackPath?: string;
	crumbs?: { label: string; link: string }[];
}

const SubHeader: React.FC<SubHeaderProps> = (props) => {
	function renderButtons() {
		if (!props.buttonOptions) return;
		return props.buttonOptions.map((item, index) => {
			return <LinkButton key={index} path={item.path} label={item.name} iconName={item.iconName} />;
		});
	}

	return (
		<Box className={'rsSubHeader'} display={'flex'} justifyContent={'space-between'}>
			<Box>
				<BackButton name={'Back to dashboard'} fallbackPath="/dashboard" />
				<Label variant={'h1'}>{props.header}</Label>
				{props.crumbs && <BreadCrumb crumbs={props.crumbs} />}
			</Box>
			{!!props.buttonOptions && (
				<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
					{renderButtons()}
				</Box>
			)}
		</Box>
	);
};

export default SubHeader;
