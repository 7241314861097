import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { WebUtils } from '../../utils/utils';

export default class DestinationService extends Service {
	async getDestinationByPage(
		pageQuery: RedSky.PageQuery
	): Promise<RedSky.RsPagedResponseData<Api.Destination.Res.Details[]>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.Destination.Res.Details[]>>(
			'destination/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return res.data;
	}

	async getDetails(destinationId: Api.Destination.Req.Details): Promise<Api.Destination.Res.Details> {
		const response = await http.get<RsResponseData<Api.Destination.Res.Details>>('destination/details/admin', {
			destinationId
		});
		return response.data.data;
	}

	async getDetailsRateList(destinationId: Api.Destination.Req.Details): Promise<any> {
		const response = await http.get<RsResponseData<any>>('destination/details/getratelist', {
			destinationId
		});
		return response.data.data;
	}

	async getDestinationPropertyTypes(destinationId: number): Promise<Api.Destination.Res.PropertyType[]> {
		const response = await http.get<RsResponseData<Api.Destination.Res.PropertyType[]>>(
			`/destination/propertyType?destinationId=${destinationId}`
		);
		return response.data.data;
	}

	async update(data: Api.Destination.Req.Update): Promise<Api.Destination.Res.Update> {
		let res = await http.put<RsResponseData<Api.Destination.Res.Update>>('destination', data);
		return res.data.data;
	}

	async create(data: Api.Destination.Req.Create): Promise<Api.Destination.Res.Create> {
		let res = await http.post<RsResponseData<Api.Destination.Res.Create>>('destination/create', data);
		return res.data.data;
	}

	async getAllPropertyTypes(): Promise<Api.Destination.Res.PropertyType[]> {
		let res = await http.get<RsResponseData<Api.Destination.Res.PropertyType[]>>('destination/allPropertyTypes');
		return res.data.data;
	}
}
