import * as React from 'react';
import './LabelData.scss';
import Label from '@bit/redsky.framework.rs.label';

interface LabelDataProps {
	title: string;
	subTitle: string | number | Date;
	largeData?: boolean;
}

const LabelData: React.FC<LabelDataProps> = (props) => {
	function addCommas(intNum: any) {
		if (isNaN(intNum)) return intNum;
		return (intNum + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,');
	}

	return (
		<div className={'rsLabelData'}>
			<Label variant={'h2'}>{props.title}</Label>
			<Label variant={props.largeData ? 'h1' : 'body1'}>{addCommas(props.subTitle)}</Label>
		</div>
	);
};

export default LabelData;
