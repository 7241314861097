import { Service } from '../Service';
import http from '../../utils/http';
import { WebUtils } from '../../utils/utils';

export default class ActionService extends Service {
	async getActionsByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Action.Res.Get[]>> {
		let response = await http.get<RedSky.RsPagedResponseData<Api.Action.Res.Get[]>>(
			'/action/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}
}
