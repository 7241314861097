import * as React from 'react';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import { useState } from 'react';
import './ManagePendingPointReportPage.scss';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import SpireTable from '../../components/spireTable/SpireTable';
import Box from '../../components/box/Box';
import LabelButton from '../../components/labelButton/LabelButton';
import router from '../../utils/router';
import BrandService from '../../services/brand/brand.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import UserPointService from '../../services/userPoint/userPoint.service';

interface ManagePendingPointReportPageProps {}

const ManagePendingPointReportPage: React.FC<ManagePendingPointReportPageProps> = () => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const UserPointService = serviceFactory.get<UserPointService>('UserPointService');
	const [userPointList, setUserPointList] = useState<Api.User.Res.UserPointReport[]>([]);
	const [sortField, setSortField] = useState<string>('id');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [userPointTotal, setUserPointTotal] = useState<number>(0);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await UserPointService.getUserPointReport(pageQuery);
			setUserPointList(res.data);
			setUserPointTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	return (
		<Page className={'rsManagePendingPointReportPage'}>
			<Box className={'subNavContainer'}>
				<SubHeader
					header={'Pending Point Report'}
					crumbs={[
						{ label: 'Dashboard', link: '/dashboard' },
						{ label: 'Pending Point Report', link: `/dashboard/manage-pending-point-report` }
					]}
				/>
				{ObjectUtils.isArrayWithData(user?.businessAccesses.companyIds) && (
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Pending Point Report'}
						onClick={() => {
							router.navigate('/dashboard/manage-pending-point-report').catch(console.error);
							// popupController.open<BrandsReportPopupProps>(BrandsReportPopup);
						}}
					/>
				)}
			</Box>
			<SpireTable
				table={{
					placeholder: 'Search by Id or Reservation Id',
					filterQuery: [
						{ column: 'id', value: '' },
						{ column: 'reservationId', value: '', conjunction: 'OR' }
					]
				}}
				columns={[
					{
						id: 'id',
						label: 'ID',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'id' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'reservationId',
						label: 'Reservation Id',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'reservationId' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'externalConfirmationId',
						label: 'External Confirmation Id',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'guestFirstName',
						label: 'Guest First Name',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'guestLastName',
						label: 'guestLastName',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'pointAmount',
						label: 'Point Amount',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'destination',
						label: 'Destination',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'cardNumber',
						label: 'Card Number',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'cardName',
						label: 'Card Name',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'arrivalDate',
						label: 'Arrival Date',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'departureDate',
						label: 'Departure Date',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					},
					{
						id: 'createdOn',
						label: 'Created On',
						align: 'left',
						className: 'brandCell',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'TEXT'
					}
				]}
				data={userPointList}
				total={userPointTotal}
				sortField={'id'}
				onGetData={getData}
				rowOnClick={() => null}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default ManagePendingPointReportPage;

export function manageBrandsPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_READ]);
}
