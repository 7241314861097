import React, { PropsWithChildren } from 'react';
import './DashboardNavCard.scss';
import { Box, Link } from '@bit/redsky.framework.rs.996';
import Button from '@bit/redsky.framework.rs.button';
import LabelLink from '../labelLink/LabelLink';

type SubNavLinks = { title: string; path: string };

export interface dashboardNavCardProps {
	className?: string;
	subNavLinks?: SubNavLinks[];
	mainTitle: string;
	mainPath: string;
}

const DashboardNavCard: React.FC<dashboardNavCardProps> = (props) => {
	function setLinks() {
		//TODO need to loop through and create sub nav links
		if (!props.subNavLinks) return;
		return props.subNavLinks.map((item, index) => {
			return (
				<LabelLink key={index} path={item.path} externalLink={false} label={item.title} variant={'button'} />
			);
		});
	}

	return (
		<Box className="rsDashboardNavCard">
			<Link path={props.mainPath}>
				<Button className="dashboardNavHeader" look={'containedPrimary'}>
					{props.mainTitle}
				</Button>
			</Link>
			<Box width={'100%'} padding={10}>
				{setLinks()}
			</Box>
		</Box>
	);
};
export default DashboardNavCard;
