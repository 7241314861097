import React, { FormEvent, useState } from 'react';
import Box from '../box/Box';
import './RequestResetPassword.scss';
import UserService from '../../services/user/user.service';
import Input from '@bit/redsky.framework.rs.input';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import Button from '@bit/redsky.framework.rs.button';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';

const RequestResetPassword: React.FC = () => {
	let userService = serviceFactory.get<UserService>('UserService');
	const form = new RsFormGroup([
		new RsFormControl('email', '', [
			new RsValidator(RsValidatorEnum.REQ, 'Email is required'),
			new RsValidator(RsValidatorEnum.EMAIL, 'Email format is invalid')
		])
	]);
	const [isResetPasswordVisible, setIsResetPasswordVisible] = useState<boolean>(false);
	const [checkEmailMessage, setCheckEmailMessage] = useState<string>('');

	async function resetPassword(e: FormEvent) {
		e.preventDefault();
		if (await form.isValid()) {
			userService.requestPasswordByEmail(`${form.get('email').value}`);
			setCheckEmailMessage(
				'If user exists email has been sent. Please Check your email and follow the directions.'
			);
		} else throw rsToastify.error('Invailid email address, Please check your email is correct.', 'Invalid Email!');
	}
	return (
		<>
			<Box
				className="rsRequestResetPassword"
				onClick={() => {
					setIsResetPasswordVisible(true);
				}}
			>
				Reset password?
			</Box>
			<Box className={`resetPasswordSlide${isResetPasswordVisible ? ' visible' : ''}`}>
				<Box className="closeResetPasswordBox">
					<Icon
						className={'closeResetPassword'}
						iconImg={'icon-chevron-right'}
						cursorPointer
						onClick={() => {
							setIsResetPasswordVisible(false);
						}}
					></Icon>
				</Box>
				<form className="resetPasswordForm" action={'#'} onSubmit={resetPassword}>
					<Box className="resetPasswordBox" display={'flex'} flexDirection={'column'}>
						<Box className="titleContainer" display={'flex'} flexDirection={'column'} alignItems={'center'}>
							<Label className="resetPasswordHeader" variant={'h1'}>
								Reset Password
							</Label>
						</Box>
						<Input
							className="signInInput"
							placeholder={'Email Address *'}
							type={'text'}
							look={'outlined'}
							control={form.get('email')}
							updateControl={(control) => form.update(control)}
							unStyled
						/>
						<Button className="resetPasswordButton" look={'containedSecondary'} type="submit">
							Send Reset Password Email
						</Button>
						<Label className="checkEmailMessage">{checkEmailMessage}</Label>
					</Box>
				</form>
			</Box>
		</>
	);
};
export default RequestResetPassword;
