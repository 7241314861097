import * as React from 'react';
import { TableCell } from '@bit/redsky.framework.rs.datatable';
import Label from '@bit/redsky.framework.rs.label';

interface SpireTableCellProps {
	align: 'left' | 'center' | 'inherit' | 'justify' | 'right';
	className: string;
	data: any;
}

const SpireTableCell: React.FC<SpireTableCellProps> = (props) => {
	return (
		<TableCell align={props.align} className={`rsSpireTableCell ${props.className}`}>
			<Label variant={'body1'}>{props.data}</Label>
		</TableCell>
	);
};

export default SpireTableCell;
