import React, { useEffect, useState } from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './RewardListPage.scss';
import { StringUtils, WebUtils } from '../../utils/utils';
import SubHeader from '../../components/subHeader/SubHeader';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import serviceFactory from '../../services/serviceFactory';
import RewardService from '../../services/reward/reward.service';
import router from '../../utils/router';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpireTable from '../../components/spireTable/SpireTable';

const RewardListPage: React.FC = () => {
	const rewardService = serviceFactory.get<RewardService>('RewardService');
	const [rewards, setRewards] = useState<Api.Reward.Res.Get[]>([]);
	const [allCategories, setAllCategories] = useState<Api.Reward.Category.Res.Get[]>([]);
	const [allVendors, setAllVendors] = useState<Api.Vendor.Res.Get[]>([]);
	const [sortField, setSortField] = useState<string>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [rewardTotal, setRewardTotal] = useState<number>(0);

	useEffect(() => {
		(async () => {
			try {
				let categoryRes = await rewardService.getAllCategories();
				setAllCategories(categoryRes.data);
				let vendorRes = await rewardService.getAllVendors();
				setAllVendors(vendorRes.data);
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage('', 'An unexpected server error has occurred'),
					'Server Error'
				);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			let res = await rewardService.getAllRewards(pageQuery);
			setRewards(res.data);
			setRewardTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function renderCategories(data: any): string {
		let rewardCategories: number[] = data.categoryIds;
		let categoryString: string = '';
		for (let categoryId of rewardCategories) {
			let category = allCategories.find((item) => item.id === categoryId);
			if (category) {
				categoryString += category.name + ', ';
			}
		}
		return categoryString.replace(/,\s*$/, '');
	}

	function renderVendor(data: any): string {
		let vendor;
		if (data.brandId) {
			vendor = allVendors.find((item) => item.brandId === data.brandId);
		} else if (data.destinationId) {
			vendor = allVendors.find((item) => item.destinationId === data.destinationId);
		}
		if (!vendor) return '';
		return vendor.name;
	}

	function renderPointCost(data: any) {
		return StringUtils.addCommasToNumber(data.pointCost);
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/reward-list/manage-reward?ri=' + data.id).catch(console.error);
	}

	return (
		<Page className="rsRewardListPage">
			<SubHeader
				header="Manage Redeemable Rewards"
				buttonOptions={[
					{
						name: 'Categories',
						path: '/dashboard/reward-list/category-list'
					},
					{
						name: 'Create new reward',
						path: '/dashboard/reward-list/create-reward',
						iconName: 'icon-white-plus'
					}
				]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' }
				]}
			/>
			<SpireTable
				table={{
					placeholder: 'Reward Name',
					filterQuery: [{ column: 'name', value: '' }]
				}}
				columns={[
					{
						id: 'mainImage',
						label: 'Main Image',
						align: 'left',
						className: 'userCell thumbnailHeader hideSortArrow',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'IMAGE'
					},
					{
						id: 'name',
						label: 'Reward Name',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'pointCost',
						label: 'Reward Point Cost',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'pointCost' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'HANDLER',
						handler: renderPointCost
					},
					{
						id: 'categories',
						label: 'Categories',
						align: 'left',
						className: 'userCell categoryCell categoriesHeader hideSortArrow',
						sort: undefined,
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: renderCategories
					},
					{
						id: 'vendor.name',
						label: 'Vendor',
						align: 'left',
						className: 'userCell vendorCell',
						sort: sortField === 'brandId' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: renderVendor
					},
					{
						id: 'createdOn',
						label: 'Date Created',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'createdOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},
					{
						id: 'isActive',
						label: 'Active',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'isActive' ? sortOrder : 'DESC',
						filterType: 'CHECKBOX',
						cellType: 'BOOLEAN',
						textOptions: {
							false: 'Inactive',
							true: 'Active'
						}
					},
					{
						id: 'upc',
						label: 'Reward Number',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'upc' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					}
				]}
				data={rewards}
				total={rewardTotal}
				sortField={'name'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default RewardListPage;

export function rewardListPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_REWARDS_READ]);
}
