import * as React from 'react';
import './CompanyListPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import { useState } from 'react';
import CompanyService from '../../services/company/company.service';
import { WebUtils } from '../../utils/utils';
import router from '../../utils/router';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpireTable from '../../components/spireTable/SpireTable';

interface CompanyListPageProps {}

const CompanyListPage: React.FC<CompanyListPageProps> = () => {
	let companyService = serviceFactory.get<CompanyService>('CompanyService');
	const [companyList, setCompanyList] = useState<Api.Company.Res.Get[]>([]);
	const [sortField, setSortField] = useState<string>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [companyTotal, setCompanyTotal] = useState<number>(0);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			let res = await companyService.getCompanyByPage(pageQuery);
			setCompanyList(res.data);
			setCompanyTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error retrieving companies.'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/company-list/manage-company?ci=' + data.id).catch(console.error);
	}

	return (
		<Page className={'rsCompanyListPage'}>
			<SubHeader
				header={'Company'}
				buttonOptions={[{ name: 'Create Company', path: '/dashboard/company-list/create-company' }]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Company List', link: '/dashboard/company-list' }
				]}
			/>
			<SpireTable
				table={{
					placeholder: 'Search by name',
					filterQuery: [{ column: 'name', value: '' }]
				}}
				columns={[
					{
						id: 'name',
						label: 'Name',
						align: 'left',
						className: 'userCellHeader',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'createdOn',
						label: 'Created On',
						align: 'left',
						className: 'userCellHeader',
						sort: sortField === 'createdOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},
					{
						id: 'city',
						label: 'City',
						align: 'left',
						className: 'userCellHeader',
						sort: sortField === 'city' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'state',
						label: 'State',
						align: 'left',
						className: 'userCellHeader',
						sort: sortField === 'state' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					}
				]}
				data={companyList}
				total={companyTotal}
				sortField={'name'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export function companyListPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.COMPANY_READ]);
}

export default CompanyListPage;
