import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ManageUserRoles.scss';
import SubHeader from '../../components/subHeader/SubHeader';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import { Box, Page } from '@bit/redsky.framework.rs.996';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { OptionType } from '@bit/redsky.framework.rs.select';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import TransactionService from '../../services/transactions/transactions.service';
import Accordion from '@bit/redsky.framework.rs.accordion';
import Label from '@bit/redsky.framework.rs.label';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import UserService from '../../services/user/user.service';
import SpireTable from '../../components/spireTable/SpireTable';
import { pageList as list } from '../../pagesTree';
import LoadingPage from '../loadingPage/LoadingPage';

interface ManageUserRolesProps {}

interface AdminPages {
	name: string;
	url: string;
	id: number;
	childArray?: AdminPages[];
}

const ManageUserRoles: React.FC<ManageUserRolesProps> = () => {
	const userRoles = useRecoilValue<Model.UserRole[]>(globalState.userRoles);
	const recoilUserRoles = useRecoilValue<Model.UserRole[]>(globalState.userRoles);
	let userService = serviceFactory.get<UserService>('UserService');

	const [userForm, setUserForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('userRoleId', 1, [new RsValidator(RsValidatorEnum.REQ, 'Select a member type')])
		])
	);

	const [roleOptions, setRoleOptions] = useState<OptionType[]>([]);
	const [isbulkEdit, setIsbulkEdit] = useState<boolean>(false);
	const [bulkEdit, setbulkEdit] = useState<boolean>(false);
	const [rowData, setRowData] = useState<any[]>([]);
	const [userTotal, setUserTotal] = useState<number>(0);
	const [viewList, setViewList] = useState<number[]>([]);
	const [editList, setEditList] = useState<number[]>([]);
	const [selectedUser, setSelectedUser] = useState<number[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [sortField, setSortField] = useState<string>('id');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');

	function hasWhiteSpace(str: any) {
		return /\s/.test(str);
	}

	const getUserData = async (pageQuery: RedSky.PageQuery, type?: string) => {
		if (
			userForm.get('userRoleId').value == '' ||
			userForm.get('userRoleId').value == 0 ||
			!userForm.get('userRoleId').value
		) {
			return;
		}
		try {
			if (hasWhiteSpace(pageQuery?.filter?.searchTerm[0]?.value)) {
				let result: any = pageQuery?.filter?.searchTerm[0]?.value.toString().split(' ');
				const name: any = [
					{
						column: 'firstName',
						value: result[0],
						conjunction: 'AND'
					},
					{
						column: 'lastName',
						value: result[1],
						conjunction: 'AND'
					}
				];

				if (pageQuery.filter) {
					pageQuery.filter.searchTerm = name;
				}
			}
			const userRole: any = [
				{
					column: 'userRoleId',
					value: userForm.get('userRoleId').value,
					conjunction: 'AND'
				}
			];
			if (pageQuery.filter) {
				pageQuery.filter.matchType = 'like';
				pageQuery.filter.searchTerm = pageQuery.filter.searchTerm.concat(userRole);
			}

			let res = await userService.getUserByPage(pageQuery);
			setRowData(res.data);
			setUserTotal(res.total);
			setLoading(false);
		} catch (e) {
			rsToastify.error('Something went wrong', 'Error');
			setLoading(false);
		}
	};

	useEffect(() => {
		if (
			!!userForm.get('userRoleId').value &&
			userForm.get('userRoleId').value !== '' &&
			userForm.get('userRoleId').value != 0
		) {
			let pageQuery: RedSky.PageQuery = {
				pagination: {
					page: 1,
					perPage: 10
				},
				sort: {
					field: '',
					order: 'ASC'
				},
				filter: {
					matchType: 'like',
					searchTerm: []
				}
			};
			getUserData(pageQuery, 'userRole');
		}
	}, [userForm.get('userRoleId').value]);

	useEffect(() => {
		setRoleOptions(
			userRoles.map((role) => {
				return { value: role.id, label: role.name };
			})
		);
	}, [recoilUserRoles]);

	const getChildIdList = (id: number, array?: AdminPages[]) => {
		let idArr: number[] = [id];

		function getArr(arr: AdminPages[]) {
			arr.map((item: AdminPages) => {
				idArr.push(item.id);
				if (!!item.childArray && item.childArray.length > 0) {
					getArr(item.childArray);
				}
			});
		}
		if (!!array) getArr(array);

		return idArr;
	};

	const updateSelectedUser = async (userObj: any) => {
		// if (userObj.select == true) {
		// 	setSelectedUser([...selectedUser, userObj.id]);
		// } else {
		// 	let arr = selectedUser.filter((item: number) => item !== userObj.id);
		// 	setSelectedUser([...arr]);
		// }
		// setIsbulkEdit(true);
		setbulkEdit(false);

		if (userObj.select === true) {
			setSelectedUser([userObj.id]);
			const response = await userService.getUserAdminPageAccess(userObj.id);
			if (response && response.length !== 0) {
				setViewList(response[0].accessPages.view);
				setEditList(response[0].accessPages.edit);
			} else {
				setViewList([]);
				setEditList([]);
			}
		} else {
			// If a user is being deselected, you can simply clear the selectedUser array
			setSelectedUser([]);
			setViewList([]);
			setEditList([]);
		}
	};

	const saveUserPagesAccess = async () => {
		// if (viewList.length > 0) {
		const body = {
			userIds: selectedUser,
			isbulkEdit: bulkEdit,
			roleId: bulkEdit ? userForm.get('userRoleId').value : null,
			accessPages: JSON.stringify({
				view: viewList.length > 0 ? viewList : [],
				edit: editList.length > 0 ? editList : []
			})
		};
		setLoading(true);
		try {
			const response = await userService.userAdminPageAccess(body);
			rsToastify.success('User admin pages access update successfully.');
			// setSelectedUser([]);
			// setViewList([]);
			// setEditList([]);
		} catch (error: any) {
			rsToastify.error('Something went wrong', 'Error');
			setSelectedUser([]);
			setViewList([]);
			setEditList([]);
		}
		setLoading(false);
		// }
	};

	const saveBulkEdits = async (body?: any) => {
		setbulkEdit(true);
		setSelectedUser([]);
		const response = await userService.getbulkPagesAccess(userForm.get('userRoleId').value);
		setViewList(response.accessPages.view);
		setEditList(response.accessPages.edit);
	};

	const listMap = (array: AdminPages[], isChild: number = 0) => {
		return array.map((item) => {
			return (
				<Box className={'pageList'}>
					{!!item.childArray && item.childArray.length > 0 ? (
						<>
							<Accordion
								className={isChild == 0 ? 'mb10' : ''}
								disableRipple={true}
								titleReact={
									<Box className="accordionHeaderBox">
										<LabelCheckbox
											value={item.id}
											onSelect={(value) => {
												let value2 = Number(value);
												let arr = getChildIdList(value2, item.childArray);

												setViewList([...viewList, ...arr]);
											}}
											onDeselect={(value) => {
												let value2 = Number(value);
												let arr = getChildIdList(value2, item.childArray);
												let array = viewList.filter((item: number) => !arr.includes(item));
												setViewList(array);
											}}
											isChecked={viewList.includes(item.id)}
										/>
										<LabelCheckbox
											value={item.id}
											onSelect={(value) => {
												let value2 = Number(value);
												let arr = getChildIdList(value2, item.childArray);
												setViewList([...viewList, ...arr]);
												setEditList([...editList, ...arr]);
											}}
											onDeselect={(value) => {
												let value2 = Number(value);
												let arr = getChildIdList(value2, item.childArray);
												let array = editList.filter((item: number) => !arr.includes(item));
												setEditList(array);
											}}
											isChecked={editList.includes(item.id)}
										/>
										<p className={'listItem'} style={{ paddingLeft: isChild * 25 }}>
											{item.name}
										</p>
									</Box>
								}
							>
								{listMap(item.childArray, isChild + 1)}
							</Accordion>
						</>
					) : (
						<Box className={isChild == 0 ? 'mb10 pageList' : 'pageList'}>
							<LabelCheckbox
								value={item.id}
								onSelect={(value) => {
									let value2 = Number(value);
									setViewList([...viewList, value2]);
								}}
								onDeselect={(value) => {
									let value2 = Number(value);
									let array = viewList.filter((item: number) => item !== value2);
									setViewList(array);
								}}
								isChecked={viewList.includes(item.id)}
							/>
							<LabelCheckbox
								value={item.id}
								onSelect={(value) => {
									let value2 = Number(value);
									setViewList([...viewList, value2]);
									setEditList([...editList, value2]);
								}}
								onDeselect={(value) => {
									let value2 = Number(value);
									let array = editList.filter((item: number) => item !== value2);
									setEditList(array);
								}}
								isChecked={editList.includes(item.id)}
							/>

							<p className={'listItem'} style={{ paddingLeft: isChild * 25 }}>
								{item.name}
							</p>
						</Box>
					)}
				</Box>
			);
		});
	};

	return (
		<Page className="manageUserRoles">
			<SubHeader
				header={'Manage User Roles'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Manage User Roles', link: '/dashboard/manage-user-roles' }
				]}
			/>
			{loading ? (
				<LoadingPage />
			) : (
				<div style={{ display: 'flex', height: '100%' }}>
					<Box className="manageUserRolesSelectBox">
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<LabelSelect
								className="selectBox"
								variant={'caption'}
								title={'Member Type'}
								control={userForm.get('userRoleId')}
								updateControl={async (control) => {
									setIsbulkEdit(false);
									setSelectedUser([]);
									setViewList([]);
									setEditList([]);
									setUserForm(userForm.clone().update(control));
									await userForm.get('userRoleId').validate();
								}}
								options={roleOptions}
							/>
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Bulk edits'}
								onClick={saveBulkEdits}
								disabled={isbulkEdit}
							/>
						</Box>
						<SpireTable
							table={{
								placeholder: 'Search by name, email',
								filterQuery: [
									{ column: 'firstName', value: '', conjunction: 'OR' },
									{ column: 'lastName', value: '', conjunction: 'OR' },
									{ column: 'primaryEmail', value: '', conjunction: 'OR' }
								]
							}}
							selectedList={selectedUser}
							columns={[
								{
									id: 'checkbox',
									label: 'Select',
									align: 'left',
									className: 'userCell',
									sort: undefined,
									filterType: 'NORMAL',
									cellType: 'CHECKBOX',
									onClick: updateSelectedUser
								},
								{
									id: 'firstName',
									label: 'First Name',
									align: 'left',
									className: 'userCell',
									sort: sortField === 'firstName' ? sortOrder : 'DESC',
									filterType: 'NORMAL',
									cellType: 'TEXT'
								},
								{
									id: 'lastName',
									label: 'Last Name',
									align: 'left',
									className: 'userCell',
									sort: sortField === 'lastName' ? sortOrder : 'DESC',
									filterType: 'NORMAL',
									cellType: 'TEXT'
								},
								{
									id: 'primaryEmail',
									label: 'Email',
									align: 'left',
									className: 'userCell',
									sort: sortField === 'primaryEmail' ? sortOrder : 'DESC',
									filterType: 'NORMAL',
									cellType: 'TEXT'
								}
							]}
							total={userTotal}
							data={rowData}
							sortField={'id'}
							onGetData={getUserData}
							rowOnClick={() => {}}
							changeSort={(field: string, order: RedSky.StandardOrderTypes) => {
								setSortField(field);
								setSortOrder(order);
							}}
							// filterName={undefined}
						/>
					</Box>

					<Box className="rightSideList">
						<Box>
							<Label variant="h3" marginBottom={20}>
								Admin Page List
							</Label>
							<Box className={'pageList'}>
								<label className="PageHadingView hading">View</label>
								<label className="PageHadingEdit hading">Edit</label>
								<label className="PageHadingPage hading">Pages</label>
							</Box>
							{listMap(list)}
						</Box>
						<Box style={{ display: 'flex', justifyContent: 'flex-end' }} marginTop={25}>
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Save'}
								onClick={saveUserPagesAccess}
								// disabled={!viewList || viewList.length == 0}
							/>
						</Box>
					</Box>
				</div>
			)}
		</Page>
	);
};

export default ManageUserRoles;
