import React, { useState } from 'react';
import Box from '../box/Box';
import './AppBar.scss';
import UserBadge from '../userBadge/UserBadge';
import { Link } from '@bit/redsky.framework.rs.996';
import Icon from '@bit/redsky.framework.rs.icon';
import NavPopout from '../../popups/navPopout/NavPopout';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import useWindowScrollChange from '../../customHooks/useWindowScrollChange';
import classNames from 'classnames';

const AppBar: React.FC = () => {
	const [showSlideOutMenu, setShowSlideOutMenu] = useState<boolean>(false);
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const company = useRecoilValue<Api.Company.Res.Get | undefined>(globalState.company);
	let scrollDirection = useWindowScrollChange();
	let isDestinationPage: boolean =
		window.location.href.includes('/dashboard/destination-list/manage-destinations') ||
		window.location.href.includes('/dashboard/destination-list/create-resort')
			? true
			: false;

	return (
		<Box
			className={classNames('rsAppBar', {
				hide: isDestinationPage && scrollDirection === 'DOWN'
			})}
		>
			<Link path={'/dashboard'}>
				{!!company && (
					<Box display={'flex'} alignItems={'center'}>
						{!!company.wideLogoUrl && (
							<img src={company.wideLogoUrl} alt={'company logo'} width={'111px'} />
						)}
						<Label variant={'h6'} ml={24}>
							{company.name}
						</Label>
					</Box>
				)}
			</Link>

			<Box display={'flex'} alignItems={'center'}>
				<UserBadge userName={`${user?.firstName} ${user?.lastName}`} />
				<Icon
					iconImg={'icon-hamburger-menu'}
					color={'#003A76'}
					size={21}
					cursorPointer
					onClick={() => setShowSlideOutMenu(true)}
				/>
			</Box>
			<NavPopout isOpened={showSlideOutMenu} onClose={() => setShowSlideOutMenu(false)} />
		</Box>
	);
};

export default AppBar;
