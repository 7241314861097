import * as React from 'react';
import './TitleDescriptionPopup.scss';
import Paper from '../../components/paper/Paper';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Icon from '@bit/redsky.framework.rs.icon';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';

export interface TitleDescriptionPopupProps extends PopupProps {
	title?: string;
	description?: string;
	onSave: (title: string, description: string) => void;
}

const TitleDescriptionPopup: React.FC<TitleDescriptionPopupProps> = (props) => {
	let title = props.title || '';
	let description = props.description || '';

	return (
		<Popup {...props}>
			<Paper className={'rsTitleDescriptionPopup'} borderRadius={'4px'} width={'550px'} padding={'25px'}>
				<Icon
					iconImg={'icon-close'}
					cursorPointer
					onClick={() => popupController.close(TitleDescriptionPopup)}
				/>
				<LabelInput
					title={'Title'}
					onChange={(value) => (title = value)}
					inputType={'text'}
					initialValue={title}
				/>
				<LabelInput
					title={'Description'}
					onChange={(value) => (description = value)}
					inputType={'textarea'}
					initialValue={description}
				/>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Save'}
					onClick={() => {
						if (title === '') return rsToastify.info('Must have a title to save.', 'Add Title');
						else props.onSave(title, description);
						popupController.close(TitleDescriptionPopup);
					}}
				/>
			</Paper>
		</Popup>
	);
};

export default TitleDescriptionPopup;
