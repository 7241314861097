import * as React from 'react';
import './ReservationDetailsPage.scss';
import { Box, Page, popupController } from '@bit/redsky.framework.rs.996';
import router from '../../utils/router';
import { useEffect, useState } from 'react';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import serviceFactory from '../../services/serviceFactory';
import LoadingPage from '../loadingPage/LoadingPage';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import { WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import ReservationService from '../../services/reservation/reservation.service';
import ItineraryInfoCard from '../../components/itineraryInfoCard/ItineraryInfoCard';
import ReservationDetailsAccordion from '../../components/reservationDetailsAccordion/ReservationDetailsAccordion';
import ConfirmRemovePopup, { ConfirmRemovePopupProps } from '../../popups/ConfirmRemovePopup/ConfirmRemovePopup';
import EditReservationDetailsPopup, {
	EditReservationDetailsPopupProps
} from '../../popups/editReservationDetailsPopup/EditReservationDetailsPopup';
import ReservationDetailsCostSummaryCard from '../../components/reservationDetailsCostSummaryCard/ReservationDetailsCostSummaryCard';
import SubHeader from '../../components/subHeader/SubHeader';

const ReservationDetailsPage: React.FC = () => {
	const reservationsService = serviceFactory.get<ReservationService>('ReservationService');
	const params = router.getPageUrlParams<{ reservationId: number }>([
		{ key: 'ri', default: 0, type: 'integer', alias: 'reservationId' }
	]);
	const [reservation, setReservation] = useState<Api.Reservation.Res.Get>();

	useEffect(() => {
		async function getReservationData(id: number) {
			try {
				let res = await reservationsService.get(id);
				setReservation(res);
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get reservation information.'),
					'Server Error'
				);
			}
		}
		getReservationData(params.reservationId).catch(console.error);
	}, []);

	function getPoliciesValue(option: 'CheckIn' | 'CheckOut' | 'Cancellation') {
		if (!reservation) return '';
		let time = reservation.destination.policies.find((item) => {
			return item.type === option;
		});
		if (time !== undefined) return time.value;
		else return '';
	}

	async function updateReservation(data: Api.Reservation.Req.Update) {
		if (!reservation) return;
		try {
			popupController.open(SpinningLoaderPopup);
			let res = await reservationsService.update(data);
			setReservation(res);
			popupController.close(SpinningLoaderPopup);
			popupController.closeAll();
		} catch (e: any) {
			if (e.response.data.msg.ErrorCode === 'ModificationNotAllowed') {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Cannot update a past reservation.'), 'Server Error');
			} else {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failure to update.'), 'Server Error');
				console.error(e.message, e.msg);
			}
			popupController.closeAll();
		}
	}

	return !reservation ? (
		<LoadingPage />
	) : (
		<Page className={'rsReservationDetailsPage'}>
			<SubHeader
				header={'Reservation Details'}
				crumbs={[
					{ label: 'Dashboard', link: `/dashboard` },
					{ label: 'User List', link: `/dashboard/user-list` },
					{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${reservation.userId}` },
					{
						label: 'Booking History',
						link: `/dashboard/user-list/manage-user/booking-history?ui=${params.reservationId}`
					}
				]}
			/>
			<div className={'rs-page-content-wrapper'}>
				<ItineraryInfoCard
					backButton={{
						link: '/reservations/itinerary/details?ii=' + reservation.itineraryId,
						label: '< Back to itinerary Details'
					}}
					logoImgUrl={reservation.destination.logoUrl}
					name={reservation.accommodation.name}
					description={reservation.accommodation.longDescription}
				/>
				<div className={'contentWrapper'}>
					<div className={'reservationsWrapper'}>
						<ReservationDetailsAccordion
							reservationId={reservation.id}
							accommodationName={reservation.accommodation.name}
							arrivalDate={reservation.arrivalDate}
							departureDate={reservation.departureDate}
							externalConfirmationId={reservation.externalConfirmationId}
							maxOccupantCount={reservation.accommodation.maxOccupantCount}
							maxSleeps={reservation.accommodation.maxSleeps}
							adultCount={reservation.adultCount}
							childCount={reservation.childCount}
							amenitiesCount={reservation.destination.experiences.length}
							adaCompliant={reservation.accommodation.adaCompliant}
							extraBeds={reservation.accommodation.extraBeds}
							floorCount={reservation.accommodation.floorCount}
							featureIcons={reservation.accommodation.featureIcons}
							contactInfo={`${reservation.guest.firstName} ${reservation.guest.lastName}`}
							email={reservation.guest.email}
							phone={reservation.guest.phone}
							additionalDetails={reservation.additionalDetails}
							isCancelable={!!reservation.cancellationPermitted}
							upsellPackages={reservation.upsellPackages}
							onEditService={() => {
								if (!reservation) return;
								router.navigate(`/reservations/edit-services?ri=${reservation.id}`);
							}}
							onSave={(data) => {
								let guestNameSplit = data.contactInfo.split(' ').filter((item) => item.length > 0);
								let guest = {
									firstName: guestNameSplit[0],
									lastName: guestNameSplit[1],
									email: data.email,
									phone: data.phone
								};
								updateReservation({
									id: reservation.id,
									guest,
									additionalDetails: data.additionalDetails
								}).catch(console.error);
							}}
							onRemove={() => {
								popupController.open<ConfirmRemovePopupProps>(ConfirmRemovePopup, {
									onRemove: async () => {
										popupController.open(SpinningLoaderPopup);
										try {
											let res = await reservationsService.cancel(reservation.id);
											if (res) {
												popupController.closeAll();
												rsToastify.success(
													`Successfully cancelled ${reservation?.externalConfirmationId}`,
													'Success!'
												);
												router.navigate('/reservations').catch(console.error);
											}
										} catch (e: any) {
											rsToastify.error(
												WebUtils.getRsErrorMessage(e, 'An unexpected error has occurred'),
												'Error!'
											);
											console.error(e.message);
											popupController.close(SpinningLoaderPopup);
										}
									}
								});
							}}
							onEditDetails={() => {
								if (!reservation) return;
								popupController.open<EditReservationDetailsPopupProps>(EditReservationDetailsPopup, {
									accommodationId: reservation.accommodation.id,
									destinationId: reservation.destination.id,
									adultCount: reservation.adultCount,
									childCount: reservation.childCount,
									arrivalDate: reservation.arrivalDate,
									departureDate: reservation.departureDate,
									onApplyChanges: (data) => {
										let newData: Api.Reservation.Req.Update = {
											...data,
											id: reservation.id,
											rateCode: reservation.rateCode,
											paymentMethodId: reservation.paymentMethod?.id,
											guest: reservation.guest,
											accommodationId: reservation.accommodation.id,
											numberOfAccommodations: 1
										};
										updateReservation(newData).catch(console.error);
									}
								});
							}}
							onChangeRoom={() => {
								if (!reservation) return;
								router
									.navigate(
										`/reservations/edit-room?ri=${reservation.id}&di=${reservation.destination.id}`
									)
									.catch(console.error);
							}}
							isEdit
							isPastReservation={new Date(reservation.arrivalDate).getTime() < Date.now()}
							isOpen
						/>
					</div>
					<div>
						<Label variant={'h1'} mb={40}>
							Reservation Cost Summary
						</Label>
						<Box position={'sticky'} top={20}>
							<ReservationDetailsCostSummaryCard
								accommodationName={reservation.accommodation.name}
								checkInTime={getPoliciesValue('CheckIn')}
								checkOutTime={getPoliciesValue('CheckOut')}
								arrivalDate={reservation.arrivalDate}
								departureDate={reservation.departureDate}
								adultCount={reservation.adultCount}
								childCount={reservation.childCount}
								taxAndFeeTotalsInCents={[
									...reservation.priceDetail.feeTotalsInCents,
									...reservation.priceDetail.taxTotalsInCents
								]}
								upsellPackages={reservation.upsellPackages}
								costPerNight={reservation.priceDetail.accommodationDailyCostsInCents}
								accommodationTotalCents={reservation.priceDetail.accommodationTotalInCents}
								grandTotalCents={reservation.priceDetail.grandTotalCents}
								subtotalPoints={reservation.priceDetail.subtotalPoints}
								paidWithPoints={!reservation.paymentMethod}
							/>
						</Box>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default ReservationDetailsPage;
