import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { Service } from '../Service';

export default class ExperienceService extends Service {
	async getAllExperiences(): Promise<Api.Experience.Res.Get[]> {
		const res = await http.get<RsResponseData<Api.Experience.Res.Get[]>>('experience');
		return res.data.data;
	}

	async updateExperience(data: Api.Experience.Req.Update): Promise<Api.Experience.Res.Update> {
		const res = await http.put<RsResponseData<Api.Experience.Res.Update>>('experience', data);
		return res.data.data;
	}

	async deleteExperience(data: Api.Experience.Req.Delete): Promise<Api.Experience.Res.Delete> {
		const res = await http.delete<RsResponseData<Api.Experience.Res.Delete>>('experience', data);
		return res.data.data;
	}

	async createExperience(data: Api.Experience.Req.Create): Promise<Api.Experience.Res.Create> {
		const res = await http.post<RsResponseData<Api.Experience.Res.Create>>('experience', data);
		return res.data.data;
	}

	async createDestinationExperience(
		data: Api.Experience.Req.CreateDestinationExperience
	): Promise<Model.DestinationExperience> {
		const res = await http.post<RsResponseData<Model.DestinationExperience>>('experience/destination', data);
		return res.data.data;
	}

	async updateDestinationExperience(
		data: Api.Experience.Req.UpdateDestinationExperience
	): Promise<Model.DestinationExperience> {
		const res = await http.put<RsResponseData<Model.DestinationExperience>>('experience/destination', data);
		return res.data.data;
	}

	async deleteDestinationExperience(data: Api.Experience.Req.Delete) {
		const res = http.delete<RsResponseData<any>>('experience/destination', data);
		return res;
	}
}
