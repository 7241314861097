import React, { useEffect, useRef, useState } from 'react';
import router from '../../utils/router';
import './AddNewCardPage.scss';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import LabelInput from '../../components/labelInput/LabelInput';
import Label from '@bit/redsky.framework.rs.label';
import serviceFactory from '../../services/serviceFactory';
import PaymentService from '../../services/payment/payment.service';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import LabelButton from '../../components/labelButton/LabelButton';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SubHeader from '../../components/subHeader/SubHeader';

const AddNewCardPage: React.FC = () => {
	const numberRef = useRef<HTMLElement>(null);
	const cvvRef = useRef<HTMLElement>(null);
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const params = router.getPageUrlParams<{ userId: number }>([
		{ key: 'ui', default: 0, type: 'integer', alias: 'userId' }
	]);
	const [isPrimary, setIsPrimary] = useState<0 | 1>(0);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	const [isValidCard, setIsValidCard] = useState<boolean>(false);
	const [isValidCvv, setIsValidCvv] = useState<boolean>(false);
	const [isValidForm, setIsValidForm] = useState<boolean>(false);
	const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
	const [creditCardObj, setCreditCardObj] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('full_name', '', [new RsValidator(RsValidatorEnum.REQ, 'Full name is required')]),
			new RsFormControl('expDate', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Expiration required'),
				new RsValidator(RsValidatorEnum.MIN, 'Expiration too short', 7),
				new RsValidator(RsValidatorEnum.MAX, 'Expiration too long', 7),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Expiration Date', (control) => {
					let month = parseInt(control.value.toString().slice(0, 2));
					let year = parseInt(control.value.toString().slice(3, 7));
					let currentYear = new Date().getFullYear();
					let currentMonth = new Date().getMonth() + 1;
					if (month > 12) return false;
					if (year === currentYear) return month >= currentMonth;
					else return year > currentYear;
				})
			])
		])
	);

	useEffect(() => {
		if (!params.userId) router.navigate('/dashboard/user-list').catch(console.error);
		async function init() {
			const gatewayDetails: Api.Payment.Res.PublicData = await paymentService.getGateway();
			window.Spreedly.init(gatewayDetails.publicData.token, {
				numberEl: 'spreedly-number',
				cvvEl: 'spreedly-cvv'
			});
		}
		init().catch(console.error);
	}, []);

	useEffect(() => {
		async function checkCompletedForm() {
			setIsFormComplete(isValidForm && isValidCvv && isValidCard);
		}
		checkCompletedForm().catch(console.error);
	}, [isValidForm, isValidCard, isValidCvv]);

	useEffect(() => {
		let readyId = paymentService.subscribeToSpreedlyReady(() => {
			window.Spreedly.setStyle(
				'number',
				'width:200px;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms; '
			);
			window.Spreedly.setStyle(
				'cvv',
				'width:200px;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; text-align: center; transition: border-color 300ms; '
			);
			window.Spreedly.setFieldType('number', 'text');
			window.Spreedly.setNumberFormat('prettyFormat');
		});

		let fieldEventId = paymentService.subscribeToSpreedlyFieldEvent(
			(
				name: 'number' | 'cvv',
				type: 'focus' | 'blur' | 'mouseover' | 'mouseout' | 'input' | 'enter' | 'escape' | 'tab' | 'shiftTab',
				activeEl: 'number' | 'cvv',
				inputProperties: {
					cardType?: string;
					validNumber?: boolean;
					validCvv?: boolean;
					numberLength?: number;
					cvvLength?: number;
				}
			) => {
				if (name === 'number') {
					if (type === 'focus') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}

					if (type === 'input' && !inputProperties.validNumber) {
						setIsValidCard(false);
					} else if (type === 'input' && inputProperties.validNumber) {
						setIsValidCard(true);
					}
				}
				if (name === 'cvv') {
					if (type === 'focus') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'input' && !inputProperties.validCvv) {
						setIsValidCvv(false);
					} else if (type === 'input' && inputProperties.validCvv) {
						setIsValidCvv(true);
					}
				}
			}
		);

		// Error response codes
		// https://docs.spreedly.com/reference/api/v1/#response-codes
		let errorId = paymentService.subscribeToSpreedlyError((errorMsg) => {
			let errorMessages = errorMsg.map((item) => {
				return item.message;
			});
			popupController.closeAll();
			return rsToastify.error(errorMessages.join(' '), 'Error!');
		});

		let paymentMethodId = paymentService.subscribeToSpreedlyPaymentMethod(
			async (token: string, pmData: Api.Payment.PmData) => {
				let data: Api.Payment.Req.Create = {
					userId: params.userId,
					cardToken: token,
					pmData: pmData,
					isPrimary: isPrimary,
					offsiteLoyaltyEnrollment: isAuthorized ? 1 : 0
				};

				try {
					const result = await paymentService.addPaymentMethod(data);
					if (result) rsToastify.success('Card successfully added.', 'Card Added!');
					router.navigate(`/dashboard/user-list/manage-user?ui=${params.userId}`).catch(console.error);
					popupController.close(SpinningLoaderPopup);
				} catch (e) {
					console.error(e);
					popupController.close(SpinningLoaderPopup);
				}
			}
		);

		return () => {
			paymentService.unsubscribeToSpreedlyPaymentMethod(paymentMethodId);
			paymentService.unsubscribeToSpreedlyReady(readyId);
			paymentService.unsubscribeToSpreedlyFieldEvent(fieldEventId);
			paymentService.unsubscribeToSpreedlyError(errorId);
		};
	}, [isAuthorized]);

	function isFormFilledOut(): boolean {
		return (
			!!creditCardObj.get('full_name').value.toString().length &&
			!!creditCardObj.get('expDate').value.toString().length
		);
	}

	async function updateForm(control: RsFormControl) {
		if (
			control.key === 'expDate' &&
			!control.value.toString().includes('/') &&
			control.value.toString().length === 4
		) {
			control.value = control.value.toString().slice(0, 2) + '/' + control.value.toString().slice(2, 4);
		}
		creditCardObj.update(control);
		let isFormValid = await creditCardObj.isValid();
		setIsValidForm(isFormFilledOut() && isFormValid);
		setCreditCardObj(creditCardObj.clone());
	}

	async function save() {
		let newCreditCardObj: any = creditCardObj.toModel();
		newCreditCardObj.month = parseInt(newCreditCardObj.expDate.split('/')[0]);
		newCreditCardObj.year = parseInt(newCreditCardObj.expDate.split('/')[1]);
		delete newCreditCardObj.expDate;
		popupController.open(SpinningLoaderPopup);
		window.Spreedly.tokenizeCreditCard(newCreditCardObj);
	}

	return (
		<Page className={'rsAddNewCardPage'}>
			<SubHeader
				header={'Add New Payment'}
				crumbs={[
					{ label: 'Dashboard', link: `/dashboard` },
					{ label: 'User List', link: `/dashboard/user-list` },
					{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${params.userId}` },
					{ label: 'New Payment', link: `/dashboard/user-list/manage-user/new-card?ui=${params.userId}` }
				]}
			/>
			<form id={'payment-form'} action={'/card-payment'}>
				<LabelInput
					title={'Name on Card'}
					inputType={'text'}
					control={creditCardObj.get('full_name')}
					updateControl={updateForm}
				/>
				<div ref={numberRef} id={'spreedly-number'}>
					<Label variant={'caption'} mb={10}>
						Card Number
					</Label>
				</div>
				<div ref={cvvRef} id={'spreedly-cvv'}>
					<Label variant={'caption'} mb={10}>
						CVV
					</Label>
				</div>
				<LabelInput
					className={'creditCardExpInput'}
					maxLength={7}
					title={'Expiration Date'}
					inputType={'text'}
					control={creditCardObj.get('expDate')}
					updateControl={updateForm}
					placeholder={'MM/YYYY'}
				/>
			</form>
			<LabelCheckbox
				value={1}
				text={`* By checking this box, you authorize your credit card network to monitor and share transaction data with Fidel (our service provider) to earn points for your offline purchases. You also
						acknowledge and agree that Fidel may share certain details of your qualifying transactions with Spire Loyalty in accordance with the Terms and Conditions, Privacy Policy
						and Fidel Privacy Policy. You may opt-out of this optional service at any time by removing this card from your Spire Loyalty account.`}
				isChecked={isAuthorized}
				onSelect={() => {
					setIsAuthorized(true);
				}}
				onDeselect={() => {
					setIsAuthorized(false);
				}}
			/>
			<LabelCheckbox
				value={'isPrimary'}
				text={'Set as primary'}
				isChecked={!!isPrimary}
				onSelect={() => {
					setIsPrimary(1);
				}}
				onDeselect={() => {
					setIsPrimary(0);
				}}
			/>
			<LabelButton
				look={isFormComplete ? 'containedPrimary' : 'containedSecondary'}
				variant={'button'}
				label={'Add New Payment'}
				disabled={!isFormComplete}
				onClick={() => {
					save();
				}}
			/>
		</Page>
	);
};

export default AddNewCardPage;
