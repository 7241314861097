import React, { useState } from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './RegionListPage.scss';
import SubHeader from '../../components/subHeader/SubHeader';
import SpireTable, { FieldName } from '../../components/spireTable/SpireTable';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import LabelInput from '../../components/labelInput/LabelInput';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import RegionService from '../../services/region/region.service';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';

function RegionListPage(): JSX.Element {
	const regionService = serviceFactory.get<RegionService>('RegionService');
	const [regions, setRegions] = useState<Api.Region.Res.Get[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [sortField, setSortField] = useState<FieldName>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [regionControl, setRegionControl] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('region', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Enter a region name'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
					return control.value != '';
				})
			])
		])
	);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			let res = await regionService.getByPage(pageQuery);
			setRegions(res.data);
			setTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error retrieving companies.'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	async function deactivateOrActivateRegion(region: Api.Region.Res.Get) {
		try {
			const regionObj: Api.Region.Req.Update = {
				id: region.id,
				name: region.name,
				isActive: !region.isActive ? 1 : 0
			};
			await regionService.updateRegion(regionObj);
			reloadPage();
			if (region.isActive) {
				rsToastify.success('Region was deactivated successfully.', 'Success!');
			} else {
				rsToastify.success('Region was activated successfully.', 'Success!');
			}
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function editRegionName(region: any) {
		if (!region.value) {
			rsToastify.error('Please fix invalid inputs', 'Invalid Inputs');
			return;
		}

		try {
			const regionObj: Api.Region.Req.Update = {
				id: region.data.id,
				name: region.value as string,
				isActive: region.data.isActive
			};
			await regionService.updateRegion(regionObj);
			reloadPage();
			rsToastify.success('Region was successfully updated.', 'Success!');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function reloadPage() {
		const pageQuery: RedSky.PageQuery = {
			pagination: {
				page: 1,
				perPage: 10
			},
			sort: {
				field: sortField,
				order: sortOrder
			},
			filter: {
				matchType: 'like',
				searchTerm: []
			}
		};
		getData(pageQuery).catch(console.error);
	}

	function renderActivationButtons(region: Api.Region.Res.Get) {
		if (region.isActive !== 0 && region.isActive) {
			return (
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'deactivate'}
					onClick={() => deactivateOrActivateRegion(region)}
				/>
			);
		}
		return (
			<LabelButton
				look={'containedSecondary'}
				variant={'button'}
				label={'activate'}
				onClick={() => deactivateOrActivateRegion(region)}
			/>
		);
	}

	return (
		<Page className="rsRegionListPage">
			<SubHeader
				header={'Region'}
				buttonOptions={[{ name: '', path: '/dashboard/region-list' }]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Region List', link: '/dashboard/region-list' }
				]}
			/>
			<div className={'addRegion'}>
				<LabelInput
					title={'Add Region'}
					inputType={'text'}
					control={regionControl.get('region')}
					updateControl={(control) => setRegionControl(regionControl.clone().update(control))}
				/>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Add Region'}
					onClick={async () => {
						if (regionControl.get('region').value === '') {
							rsToastify.error('Enter a region name.', 'Error!');
							return;
						}

						try {
							const newRegion = await regionService.addRegion({
								name: regionControl.get('region').value.toString()
							});

							rsToastify.success('Region is successfully added.', 'Success!');
							setRegions([...regions, newRegion]);
							regionControl.resetToInitialValue();
							setTotal(total + 1);
						} catch (e) {
							rsToastify.error(
								WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
								'Server Error'
							);
						}
					}}
				/>
			</div>
			<SpireTable
				table={{
					placeholder: 'Search by name',
					filterQuery: [{ column: 'name', value: '' }]
				}}
				columns={[
					{
						id: 'name',
						label: 'Name',
						align: 'left',
						className: 'regionCell',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: '',
						label: 'Edit Region',
						align: 'left',
						className: 'regionCell editRegionHeader',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'INPUT',
						onClick: editRegionName
					},
					{
						id: 'isActive',
						label: 'Activate or Deactivate',
						align: 'left',
						className: 'regionCell',
						sort: sortField === 'isActive' ? sortOrder : 'DESC',
						filterType: 'NONE',
						cellType: 'HANDLER',
						handler: renderActivationButtons
					}
				]}
				data={regions}
				total={total}
				sortField={'name'}
				changeSort={changeSort}
				onGetData={getData}
				rowOnClick={(data: any) => {}}
			/>
		</Page>
	);
}
export default RegionListPage;

export function regionListPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.ADMINISTRATION_READ, PermissionList.ADMINISTRATION_WRITE]);
}
