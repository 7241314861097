import React, { useCallback, useEffect, useRef, useState } from 'react';
import CampaignService from '../../services/campaign/campaign.service';
import SubHeader from '../../components/subHeader/SubHeader';
import serviceFactory from '../../services/serviceFactory';
import { WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import Accordion from '@bit/redsky.framework.rs.accordion';
import './PointCampaignCostReportForDestinations.scss';
import LoadingPage from '../loadingPage/LoadingPage';

const PointCampaignCostReportForDestinations: React.FC = () => {
	const campaignService = serviceFactory.get<CampaignService>('CampaignService');
	const gridRef = useRef<any>();
	const [compainData, setCompainData] = useState<any>([]);
	const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
	const [loading, setLoading] = useState<boolean>(true);

	async function getData(filter?: Api.Transaction.Req.Filter) {
		try {
			let res = await campaignService.getRentylMerchantReport(filter);
			setCompainData(res.data);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	useEffect(() => {
		getData();
	}, []);

	const expandedRowRender = (record: any) => {
		return (
			<>
				{expandedRows[record.key] && (
					<table style={{ marginLeft: '26.7%' }}>
						<tbody>
							{record.campaignDetails.map((detail: any, index: number) => (
								<tr key={index}>
									<td style={{ minWidth: '220px', width: '220x' }}>{detail.name}</td>
									{detail.months.map((month: any, idx: number) => (
										<td style={{ minWidth: '78px', width: '78px' }} key={idx}>
											{parseFloat(month || 0).toFixed(2)}
										</td>
									))}
									<td style={{ minWidth: '78px', width: '78px', fontWeight: 700 }}>
										{parseFloat(detail.total || 0).toFixed(2)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</>
		);
	};

	const data = compainData.map((item: any, index: number) => {
		let totals: any = Array.from({ length: 12 }).fill(0);

		item.campaigns.forEach((campaign: any) => {
			campaign.months.forEach((month: any, idx: number) => {
				totals[idx] += month;
			});
		});

		const total = totals.reduce((acc: any, curr: any) => acc + curr, 0);
		return {
			key: index.toString(),
			destination: item.name,
			campaign: 'All',
			campaignDetails: item.campaigns,
			jan: parseFloat(totals[0].toFixed(2)),
			feb: parseFloat(totals[1].toFixed(2)),
			mar: parseFloat(totals[2].toFixed(2)),
			apr: parseFloat(totals[3].toFixed(2)),
			may: parseFloat(totals[4].toFixed(2)),
			jun: parseFloat(totals[5].toFixed(2)),
			jul: parseFloat(totals[6].toFixed(2)),
			aug: parseFloat(totals[7].toFixed(2)),
			sep: parseFloat(totals[8].toFixed(2)),
			oct: parseFloat(totals[9].toFixed(2)),
			nov: parseFloat(totals[10].toFixed(2)),
			dec: parseFloat(totals[11].toFixed(2)),
			total: parseFloat(total.toFixed(2))
		};
	});

	const columns = [
		{ title: 'Destination', dataIndex: 'destination', key: 'destination', width: '465px', minWidth: '465px' },
		{ title: 'Campaign', dataIndex: 'campaign', key: 'campaign', width: '220px', minWidth: '220px' },
		{ title: 'Jan', dataIndex: 'jan', key: 'jan', width: '80px', minWidth: '80px' },
		{ title: 'Feb', dataIndex: 'feb', key: 'feb', width: '80px', minWidth: '80px' },
		{ title: 'Mar', dataIndex: 'mar', key: 'mar', width: '80px', minWidth: '80px' },
		{ title: 'Apr', dataIndex: 'apr', key: 'apr', width: '80px', minWidth: '80px' },
		{ title: 'May', dataIndex: 'may', key: 'may', width: '80px', minWidth: '80px' },
		{ title: 'Jun', dataIndex: 'jun', key: 'jun', width: '80px', minWidth: '80px' },
		{ title: 'Jul', dataIndex: 'jul', key: 'jul', width: '80px', minWidth: '80px' },
		{ title: 'Aug', dataIndex: 'aug', key: 'aug', width: '80px', minWidth: '80px' },
		{ title: 'Sep', dataIndex: 'sep', key: 'sep', width: '80px', minWidth: '80px' },
		{ title: 'Oct', dataIndex: 'oct', key: 'oct', width: '80px', minWidth: '80px' },
		{ title: 'Nov', dataIndex: 'nov', key: 'nov', width: '80px', minWidth: '80px' },
		{ title: 'Dec', dataIndex: 'dec', key: 'dec', width: '80px', minWidth: '80px' },
		{ title: 'Total', dataIndex: 'total', key: 'total', width: '80px', minWidth: '80px' }
	];

	return loading ? (
		<LoadingPage />
	) : (
		<div className="destinations-campaign-report">
			<SubHeader
				header={'Destination Campaign Report'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{
						label: 'Destination Campaign Report',
						link: '/dashboard/point-campaign-cost-report-for-destinations'
					}
				]}
			/>
			<div
				className="ag-theme-alpine"
				style={{
					height: '500px',
					width: 'fit-content',
					marginLeft: 'auto',
					overflow: 'scroll',
					marginRight: 'auto'
				}}
			>
				{data && data.length ? (
					<table className="report-table">
						<thead>
							<tr>
								{columns.map((column) => (
									<th
										style={{
											width: column?.width,
											minWidth: column.minWidth,
											maxWidth: column.minWidth
										}}
										key={column.key}
									>
										{column.title}
									</th>
								))}
							</tr>
						</thead>
						<tbody style={{ height: '7vh' }}>
							{data?.map((item: any) => (
								<React.Fragment key={item.key}>
									<tr
										style={{ fontWeight: 700 }}
										onClick={() => {
											setExpandedRows({ ...expandedRows, [item.key]: !expandedRows[item.key] });
										}}
									>
										<td>{item.destination}</td>
										<td>{item.campaign}</td>
										<td>{item.jan}</td>
										<td>{item.feb}</td>
										<td>{item.mar}</td>
										<td>{item.apr}</td>
										<td>{item.may}</td>
										<td>{item.jun}</td>
										<td>{item.jul}</td>
										<td>{item.aug}</td>
										<td>{item.sep}</td>
										<td>{item.oct}</td>
										<td>{item.nov}</td>
										<td>{item.dec}</td>
										<td>{item.total}</td>
									</tr>
									<tr className="expanded-row">
										<td colSpan={47}>
											<Accordion hideChevron disableRipple isOpen={expandedRows[item.key]}>
												{expandedRowRender(item)}
											</Accordion>
										</td>
									</tr>
								</React.Fragment>
							))}
						</tbody>
					</table>
				) : (
					<div>Record not Found</div>
				)}
			</div>
		</div>
	);
};

export default PointCampaignCostReportForDestinations;
