interface AdminPages {
	name: string;
	url: string;
	id: number;
	childArray?: AdminPages[];
}

// last id is 45 now, Please Update this line every time when something add , this is for reference.

export const pageList: AdminPages[] = [
	{
		name: 'Manage Users',
		url: '/dashboard/user-list',
		id: 1,
		childArray: [
			{ name: 'Create New User', url: '/dashboard/user-list/create-user', id: 2 },
			{
				name: 'Manage User',
				url: '/dashboard/user-list/manage-user',
				id: 25,
				childArray: [
					{
						name: 'Booking History',
						url: '/dashboard/user-list/manage-user/booking-history',
						id: 26,
						childArray: [
							{
								name: 'Reservation Detail',
								url: '/dashboard/user-list/manage-user/booking-history/reservation',
								id: 27
							}
						]
					},
					{
						name: 'Order History',
						url: '/dashboard/user-list/manage-user/order-history',
						id: 28
					},
					{
						name: 'Manage Points',
						url: '/dashboard/user-list/manage-user/manage-points',
						id: 29,
						childArray: [
							{
								name: 'Update Point',
								url: '/dashboard/user-list/manage-user/manage-points/update-points',
								id: 30
							}
						]
					},
					{
						name: 'Create Address',
						url: '/dashboard/user-list/manage-user/create-address',
						id: 31
					},
					{
						name: 'New Card',
						url: '/dashboard/user-list/manage-user/new-card',
						id: 32
					}
				]
			},
			{
				name: 'manage user role',
				url: '/dashboard/manage-user-roles',
				id: 3
			}
		]
	},
	{
		name: 'Manage Loyalty Rewards',
		url: '/dashboard/reward-list',
		id: 4,
		childArray: [
			{
				name: 'Create Reward',
				url: '/dashboard/reward-list/create-reward',
				id: 5
			},
			{
				name: 'Manage Reward',
				url: '/dashboard/reward-list/manage-reward',
				id: 33
			},
			{
				name: 'Manage Categories',
				url: '/dashboard/reward-list/category-list',
				id: 6,
				childArray: [
					{
						name: 'Create Categories',
						url: '/dashboard/reward-list/category-list/create-category',
						id: 7
					},
					{
						name: 'Manage Categories',
						url: '/dashboard/reward-list/category-list/manage-category',
						id: 34
					}
				]
			},
			{
				name: 'Campaigns',
				url: '/dashboard/reward-list/campaigns',
				id: 8,
				childArray: [
					{
						name: 'Create Campaigns',
						url: '/dashboard/reward-list/campaigns/create-campaign',
						id: 9
					},

					{
						name: 'Actions',
						url: '/dashboard/reward-list/campaigns/actions',
						id: 10,
						childArray: [
							{
								name: 'Create Actions',
								url: '/dashboard/reward-list/campaigns/actions/create-action',
								id: 11
							}
						]
					},
					{
						name: 'Create Tier',
						url: '/dashboard/reward-list/create-tier',
						id: 35
					},
					{
						name: 'Points Admin',
						url: '/dashboard/reward-list/manage-reward-point',
						id: 12
					}
				]
			}
		]
	},
	{
		name: 'Vendor Management',
		url: '/dashboard/destination-list',
		id: 43,
		childArray: [
			{
				name: 'Destinations',
				url: '/dashboard/destination-list',
				id: 13,
				childArray: [
					{
						name: 'Create Resort',
						url: '/dashboard/destination-list/create-resort',
						id: 37
					},
					{
						name: 'Manage Destinations',
						url: '/dashboard/destination-list/manage-destinations',
						id: 38,
						childArray: [
							{
								name: 'Manage Accommodation',
								url: '/dashboard/destination-list/manage-destinations/manage-accommodation',
								id: 39
							}
						]
					}
				]
			},
			{
				name: 'Manage Pages',
				url: '/dashboard/manage-pages',
				id: 14
			},
			{
				name: 'Brands',
				url: '/dashboard/brand-list',
				id: 15,
				childArray: [
					{
						name: 'Brand Detail',
						url: '/dashboard/brand-list/brand-details',
						id: 41,
						childArray: [
							{
								name: 'Location Detail',
								url: '/dashboard/brand-list/brand-details/location-details',
								id: 42
							}
						]
					},
					{
						name: 'Brands Report',
						url: '/dashboard/brand-report',
						id: 40
					}
				]
			}
		]
	},

	{
		name: 'Manage Companies',
		url: '/dashboard/company-list',
		id: 16,
		childArray: [
			{
				name: 'Create New Company',
				url: '/dashboard/company-list/create-company',
				id: 17
			},
			{
				name: 'Update Company',
				url: '/dashboard/company-list/manage-company',
				id: 36
			}
		]
	},
	{
		name: 'Manage User Reviews',
		url: '/dashboard/review-list',
		id: 18
	},
	{
		name: 'Manage Regions',
		url: '/dashboard/region-list',
		id: 19
	},
	{
		name: 'Manage Icons',
		url: '/dashboard/experience-icons',
		id: 44,
		childArray: [
			{
				name: 'Experience Icons',
				url: '/dashboard/experience-icons',
				id: 20
			},
			{
				name: 'In Unit Amenity Icons',
				url: '/dashboard/unit-amenity-icons',
				id: 21
			}
		]
	},
	{
		name: 'Manage Reports',
		url: '/dashboard/transaction-report',
		id: 45,
		childArray: [
			{
				name: 'Transaction Reports',
				url: '/dashboard/transaction-report',
				id: 22
			},
			{
				name: 'Pending Point Report',
				url: '/dashboard/manage-pending-point-report',
				id: 23
			},
			{
				name: 'Campaign Audit Report',
				url: '/dashboard/campaign-audit-report',
				id: 24
			},
			{
				name: 'Retail Revenue Report',
				url: '/dashboard/retail-revenue-report',
				id: 25
			},
			{
				name: 'Point Campaign Cost Report',
				url: '/dashboard/point-campaign-cost-report',
				id: 26
			},
			{
				name: 'Destination Campaign Report',
				url: '/dashboard/point-campaign-cost-report-for-destinations',
				id: 46
			}
		]
	}
];
