import * as React from 'react';
import './NavPopout.scss';
import Icon from '@bit/redsky.framework.rs.icon';
import Box from '../../components/box/Box';
import Button from '@bit/redsky.framework.rs.button';
import LabelLink from '../../components/labelLink/LabelLink';
import router from '../../utils/router';
import useOnClickOutsideRef from '../../customHooks/useOnClickOutsideRef';

interface NavPopoutProps {
	onClose: () => void;
	isOpened: boolean;
}

const NavPopout: React.FC<NavPopoutProps> = (props) => {
	const modalRef = useOnClickOutsideRef(() => {
		if (props.isOpened) props.onClose();
	});

	return (
		<div ref={modalRef} className={props.isOpened ? `rsNavPopout opened` : 'rsNavPopout'}>
			<Icon iconImg={'icon-close'} onClick={props.onClose} size={21} color={'#003A76'} cursorPointer />
			<Button
				className={'signOutBtn'}
				look={'containedPrimary'}
				onClick={() => {
					localStorage.clear();
					window.location.assign('/');
				}}
			>
				Sign Out
			</Button>
			<Box mt={100} ml={40}>
				<Box mb={30}>
					<LabelLink
						path={'/dashboard/rewards'}
						label={'Rewards'}
						variant={'h2'}
						onClick={() => {
							props.onClose();
							router.navigate('/dashboard/reward-list').catch(console.error);
						}}
					/>
					<LabelLink
						path={'/dashboard/reward-list/create-reward'}
						label={'Create Reward'}
						variant={'h4'}
						onClick={() => {
							props.onClose();
							router.navigate('/dashboard/reward-list/create-reward').catch(console.error);
						}}
					/>
					<LabelLink
						path={'/dashboard/reward-list/category-list'}
						label={'Manage Categories'}
						variant={'h4'}
						onClick={() => {
							props.onClose();
							router.navigate('/dashboard/reward-list/category-list').catch(console.error);
						}}
					/>
					<LabelLink
						path={'/dashboard/reward-list/category-list/create-category'}
						label={'Create Categories'}
						variant={'h4'}
						onClick={() => {
							props.onClose();
							router
								.navigate('/dashboard/reward-list/category-list/create-category')
								.catch(console.error);
						}}
					/>
				</Box>
				<Box mb={30}>
					<LabelLink
						path={'/dashboard/user-list'}
						label={'Users'}
						variant={'h2'}
						onClick={() => {
							props.onClose();
							router.navigate('/dashboard/user-list').catch(console.error);
						}}
					/>
					<LabelLink
						path={'/dashboard/user-list/create-user'}
						label={'Create New User'}
						variant={'h4'}
						onClick={() => {
							props.onClose();
							router.navigate('/dashboard/user-list/create-user').catch(console.error);
						}}
					/>
				</Box>
				<Box mb={30}>
					<LabelLink
						path={'/dashboard/destination-list'}
						label={'Content Management'}
						variant={'h2'}
						onClick={() => {
							props.onClose();
							router.navigate('/dashboard/destination-list').catch(console.error);
						}}
					/>
					<LabelLink
						path={'/dashboard/destination-list'}
						label={'Destinations'}
						variant={'h4'}
						onClick={() => {
							props.onClose();
							router.navigate('/dashboard/destination-list').catch(console.error);
						}}
					/>
				</Box>
			</Box>
		</div>
	);
};

export default NavPopout;
