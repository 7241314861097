import { Service } from '../Service';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import http from '../../utils/http';
import { WebUtils } from '../../utils/utils';

export default class RegionService extends Service {
	async addRegion(data: Api.Region.Req.Create): Promise<Api.Region.Res.Get> {
		const response = await http.post<RsResponseData<Api.Region.Res.Get>>('/region', data);
		return response.data.data;
	}

	async getByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Region.Res.Get[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Region.Res.Get[]>>(
			'/region/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async get(): Promise<Api.Region.Res.Get[]> {
		const response = await http.get<RsResponseData<Api.Region.Res.Get[]>>('/region');
		return response.data.data;
	}

	async updateRegion(region: Api.Region.Req.Update): Promise<Api.Region.Res.Get> {
		const response = await http.put<RsResponseData<Model.Region>>('/region/', region);
		return response.data.data;
	}
}
