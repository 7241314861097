import * as React from 'react';
import './ManageInUnitAmenityIcons.scss';
import Box from '../../components/box/Box';
import { useEffect, useState } from 'react';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import Label from '@bit/redsky.framework.rs.label';
import LabelButton from '../../components/labelButton/LabelButton';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import AccommodationService from '../../services/accommodation/accommodation.service';
import serviceFactory from '../../services/serviceFactory';
import { popupController } from '@bit/redsky.framework.rs.996';
import IconFeatureTile from '../../components/iconFeatureTile/IconFeatureTile';
import ConfirmDeletePopup, { ConfirmDeletePopupProps } from '../../popups/confirmDeletePopup/ConfirmDeletePopup';
import IconFeaturePopup, { IconFeaturePopupProps } from '../../popups/iconFeaturePopup/IconFeaturePopup';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';

interface ManageInUnitAmenityIconsProps {}

const ManageInUnitAmenityIcons: React.FC<ManageInUnitAmenityIconsProps> = () => {
	const accommodationService: AccommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const [unitAmenityIcons, setUnitAmenityIcons] = useState<Api.Amenity.Res.Get[]>([]);
	const [reloadPage, setReloadPage] = useState<number>(1);

	useEffect(() => {
		async function getAllAmenities() {
			try {
				const amenities = await accommodationService.getAllAmenities();
				setUnitAmenityIcons(amenities);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'No results found.'), 'Error!');
			}
		}
		getAllAmenities().catch(console.error);
	}, [reloadPage]);

	async function saveIconInfo(icon: string, title: string, id?: number) {
		if (id) {
			await accommodationService.updateAmenity({
				id: id,
				icon,
				title
			});
			setReloadPage(reloadPage + 1);
			popupController.closeAll();
		} else {
			await accommodationService.createAmenity({ title, icon });
			setReloadPage(reloadPage + 1);
			popupController.closeAll();
		}
	}

	async function deleteAmenityIcon(id: number) {
		await accommodationService.deleteAmenity({ id });
		setReloadPage(reloadPage + 1);
	}

	function renderUnitAmenityIcons() {
		if (!ObjectUtils.isArrayWithData(unitAmenityIcons)) return;
		return unitAmenityIcons.map((item) => {
			return (
				<IconFeatureTile
					key={item.id}
					title={item.title}
					icon={item.icon}
					onClick={() => {
						popupController.open<IconFeaturePopupProps>(IconFeaturePopup, {
							id: item.id,
							icon: item.icon,
							title: item.title,
							onSave: saveIconInfo
						});
					}}
					onDelete={() => {
						popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
							onDelete: () => {
								deleteAmenityIcon(item.id).catch(console.error);
							}
						});
					}}
				/>
			);
		});
	}

	return (
		<Box className={'rsManageInUnitAmenityIcons'}>
			<SubHeader
				header={'Manage In Unit Amenity Icons'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Unit Amenity Icons', link: '/dashboard/unit-amenity-icons' }
				]}
			/>
			<Box display={'flex'} justifyContent={'space-around'}>
				<Label variant={'h1'}>In Unit Amenity Icons</Label>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Add Amenity'}
					onClick={() =>
						popupController.open<IconFeaturePopupProps>(IconFeaturePopup, {
							icon: '',
							title: '',
							onSave: saveIconInfo
						})
					}
				/>
			</Box>
			<Box className={'iconContainer'}>{renderUnitAmenityIcons()}</Box>
		</Box>
	);
};

export default ManageInUnitAmenityIcons;

export function manageUnitAmenityIconsGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_READ]);
}
