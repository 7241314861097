import * as React from 'react';
import { Box, Link } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import './BreadCrumb.scss';

export interface Crumb {
	label: string;
	link: string;
}

interface BreadcrumbProps {
	crumbs: Crumb[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
	return (
		<Box className={'rsBreadCrumb'} display={'flex'}>
			{props.crumbs.map((item, index) => {
				return (
					<React.Fragment key={item.link}>
						{index < props.crumbs.length - 1 ? (
							<Link path={item.link}>
								<Label className={'linkName'} variant={'h4'}>
									{item.label}
								</Label>
							</Link>
						) : (
							<Label className={'linkName'} variant={'h4'}>
								{item.label}
							</Label>
						)}
						{index < props.crumbs.length - 1 && (
							<Label className={'slash'} variant={'h4'} ml={4} mr={4}>
								/
							</Label>
						)}
					</React.Fragment>
				);
			})}
		</Box>
	);
};

export default Breadcrumb;
