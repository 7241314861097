import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { WebUtils } from '../../utils/utils';

export default class ReviewService extends Service {
	async getById(id: number): Promise<Api.Review.Res.Get> {
		const response = await http.get<RsResponseData<Api.Review.Res.Get>>(`review?id=${id}`);
		return response.data.data;
	}

	async getReviewByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Review.Res.Get[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Review.Res.Get[]>>(
			'review/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async unPublish(reviewId: number): Promise<RedSky.RsResponseData<Api.Review.Res.Update>> {
		const updatedReview = await http.put<RsResponseData<Api.Review.Res.Update>>(`review/un-publish`, { reviewId });
		return updatedReview.data;
	}

	async publish(reviewId: number): Promise<RedSky.RsResponseData<Api.Review.Res.Update>> {
		const updatedReview = await http.put<RsResponseData<Api.Review.Res.Update>>(`review/publish`, { reviewId });
		return updatedReview.data;
	}

	async verify(reviewId: number): Promise<RedSky.RsResponseData<Api.Review.Req.Verify>> {
		const updatedReview = await http.put<RsResponseData<Api.Review.Req.Verify>>(`review/verify`, { reviewId });
		return updatedReview.data;
	}
}
