import * as React from 'react';
import './ReviewPopout.scss';
import Box from '../../components/box/Box';
import Button from '@bit/redsky.framework.rs.button/dist/Button';
import { DateUtils, StringUtils } from '../../utils/utils';
import Icon from '@bit/redsky.framework.rs.icon';
import useOnClickOutsideRef from '../../customHooks/useOnClickOutsideRef';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import ReviewRowContainer from './reviewRowContainer/ReviewRowContainer';

interface ReviewPopoutProps {
	review: Api.Review.Res.Get | undefined;
	approveReview: (id: number) => void;
	rejectReview: (id: number) => void;
	onClose: () => void;
	isOpened: boolean;
}

const ReviewPopout: React.FC<ReviewPopoutProps> = (props) => {
	const modalRef = useOnClickOutsideRef(() => {
		if (props.isOpened) props.onClose();
	});

	function renderReviewerInfo() {
		if (!props.review) return;
		return (
			<Box className={'tableContainer'}>
				<Box className={'tableBody'}>
					<ReviewRowContainer
						title={'Name'}
						value={`${props.review.guest.firstName} ${props.review.guest.lastName}`}
					/>
					<ReviewRowContainer title={'Member Number'} value={props.review.guest.accountNumber} />
					<ReviewRowContainer
						title={'Phone'}
						value={
							props.review.guest.phone
								? StringUtils.formatCountryCodePhoneNumber(props.review.guest.phone)
								: ''
						}
					/>
					<ReviewRowContainer title={'Email'} value={props.review.guest.primaryEmail} />
					<ReviewRowContainer
						title={'Created On'}
						value={DateUtils.clientToServerDate(new Date(props.review.createdOn))}
					/>
					<ReviewRowContainer
						title={'Modify On'}
						value={
							props.review.modifiedOn
								? DateUtils.clientToServerDate(new Date(props.review.modifiedOn))
								: 'None'
						}
					/>
					<ReviewRowContainer
						title={'Verified On'}
						value={
							props.review.verifiedOn
								? DateUtils.clientToServerDate(new Date(props.review.verifiedOn))
								: 'Unverified'
						}
					/>
					<ReviewRowContainer title={'Destination'} value={props.review.destination.name} />
					<ReviewRowContainer title={'Accommodation'} value={props.review.accommodation.name} />
					<Box className={'rowContainer'}>
						<Box className={'reviewCellData'}>
							<Label variant={'body1'}>Packages</Label>
						</Box>
						<Box className={'reviewCellData'}>
							{props.review.packages ? (
								props.review.packages.map((packages) => (
									<Label variant={'body1'}>{packages.name}</Label>
								))
							) : (
								<Label variant={'body1'}>None</Label>
							)}
						</Box>
					</Box>
					<ReviewRowContainer
						title={'Date Range'}
						value={`${
							props.review.stayStartDate
								? DateUtils.clientToServerDate(new Date(props.review.stayStartDate))
								: 'None'
						} - ${
							props.review.stayEndDate
								? DateUtils.clientToServerDate(new Date(props.review.stayEndDate))
								: 'None'
						}`}
					/>
				</Box>
			</Box>
		);
	}

	return (
		<div ref={modalRef} className={props.isOpened ? `rsReviewPopout opened` : 'rsReviewPopout'}>
			<Box className={'informationContainer'}>
				<Icon
					iconImg={'icon-close'}
					className={'closeIcon'}
					onClick={props.onClose}
					size={21}
					color={'#003A76'}
					cursorPointer
				/>
				<Label variant={'h3'} className={'tableTitle'}>
					Member
				</Label>
				{renderReviewerInfo()}
				<Box className={'buttonContainer'}>
					<Button
						look={'containedPrimary'}
						className={'approveButton'}
						onClick={() => {
							if (!props.review) return;
							props.onClose();
							props.approveReview(props.review.id);
						}}
					>
						Approve
					</Button>
					<Button
						look={'containedSecondary'}
						onClick={() => {
							if (!props.review) return;
							props.onClose();
							props.rejectReview(props.review.id);
						}}
					>
						Reject
					</Button>
				</Box>
			</Box>
			<Box className={'reviewContainer'}>
				<Box className={'reviewTitle'}>
					<Label variant={'h3'} className={'tableTitle'}>
						Review
					</Label>
					<Label variant={'h3'} className={'tableTitle'}>
						{props.review?.rating}
					</Label>
				</Box>
				<Label showMoreButton variant={'body1'} className={'reviewText'} lineClamp={4}>
					{props.review?.message}
				</Label>
			</Box>
		</div>
	);
};

export default ReviewPopout;
