import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { WebUtils } from '../../utils/utils';

export default class ReservationService extends Service {
	async getReservations(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Reservation.Res.Get[]>> {
		let response = await http.get<RedSky.RsPagedResponseData<Api.Reservation.Res.Get[]>>(
			'/reservation/paged',
			pageQuery
		);
		return response.data;
	}

	async get(id: number): Promise<Api.Reservation.Res.Get> {
		let response = await http.get<RsResponseData<Api.Reservation.Res.Get>>('reservation/admin', { id });
		return response.data.data;
	}

	async update(data: Api.Reservation.Req.Update): Promise<Api.Reservation.Res.Get> {
		let response = await http.put<RsResponseData<Api.Reservation.Res.Get>>('reservation', data);
		return response.data.data;
	}

	async cancel(id: number): Promise<Api.Reservation.Res.Cancel> {
		let response = await http.post<RsResponseData<Api.Reservation.Res.Cancel>>('reservation/cancel', { id });
		return response.data.data;
	}

	async verifyAvailability(data: Api.Reservation.Req.Verification): Promise<Api.Reservation.Res.Verification> {
		let response = await http.get<RsResponseData<Api.Reservation.Res.Verification>>(
			'reservation/verification',
			WebUtils.convertDataForUrlParams(data)
		);
		return response.data.data;
	}
}
