import { Service } from '../Service';
import globalState, { getRecoilExternalValue } from '../../state/globalState';

const FORBIDDEN_PAGE_PATH = '/forbidden';

export enum PermissionList {
	ADMINISTRATION_READ = 'ADMINISTRATION_READ',
	ADMINISTRATION_WRITE = 'ADMINISTRATION_WRITE',
	ANALYTICS_READ = 'ANALYTICS_READ',
	ANALYTICS_WRITE = 'ANALYTICS_WRITE',
	COMPANY_READ = 'COMPANY_READ',
	COMPANY_WRITE = 'COMPANY_WRITE',
	LOYALTY_CAMPAIGNS_READ = 'LOYALTY_CAMPAIGNS_READ',
	LOYALTY_CAMPAIGNS_WRITE = 'LOYALTY_CAMPAIGNS_WRITE',
	LOYALTY_REWARDS_READ = 'LOYALTY_REWARDS_READ',
	LOYALTY_REWARDS_WRITE = 'LOYALTY_REWARDS_WRITE',
	ORDERS_READ = 'ORDERS_READ',
	ORDERS_WRITE = 'ORDERS_WRITE',
	POINTS_READ = 'POINTS_READ',
	POINTS_WRITE = 'POINTS_WRITE',
	REAL_ESTATE_READ = 'REAL_ESTATE_READ',
	REAL_ESTATE_WRITE = 'REAL_ESTATE_WRITE',
	USER_READ = 'USER_READ',
	USER_WRITE = 'USER_WRITE',
	USER_POINTS_READ = 'USER_POINTS_READ',
	USER_POINTS_WRITE = 'USER_POINTS_WRITE'
}

export default class PermissionService extends Service {
	hasPermission(permission: PermissionList): boolean {
		let user = getRecoilExternalValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
		if (!user) return false;

		let splitPermission = permission.split('_');
		let readOrWrite = splitPermission.pop();
		let permissionOnly = splitPermission.join('_');

		for (let userPermission of user.permission) {
			if (
				userPermission.key === permissionOnly &&
				((readOrWrite === 'READ' && userPermission.read === 1) ||
					(readOrWrite === 'WRITE' && userPermission.write === 1))
			) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Check permissions will determine if the user has the correct permission for all the given permissions.
	 * If they do not it reutnrs a string for the forbidden path
	 * @param permissionList Array of permissions that all must be meet to return true
	 * @returns True if all permissions pass or a path for the forbidden page
	 */
	checkPermissions(permissionList: PermissionList[]): true | string {
		let user = getRecoilExternalValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
		if (!user) return FORBIDDEN_PAGE_PATH;

		for (let requestPermission of permissionList) {
			let splitPermission = requestPermission.split('_');
			let readOrWrite = splitPermission.pop();
			let permissionOnly = splitPermission.join('_');

			let hasPermission = false;
			for (let userPermission of user.permission) {
				if (
					userPermission.key === permissionOnly &&
					((readOrWrite === 'READ' && userPermission.read === 1) ||
						(readOrWrite === 'WRITE' && userPermission.write === 1))
				) {
					hasPermission = true;
					break;
				}
			}

			if (!hasPermission) return FORBIDDEN_PAGE_PATH;
		}

		return true;
	}
}
