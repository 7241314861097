import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import * as React from 'react';
import './ManageDestinationsDetailsPopup.scss';
import { Box, Popup, popupController } from '@bit/redsky.framework.rs.996';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import LabelButton from '../../components/labelButton/LabelButton';
import LabelInput from '../../components/labelInput/LabelInput';
import { useEffect, useState } from 'react';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import serviceFactory from '../../services/serviceFactory';
import CountryService from '../../services/country/country.service';
import { OptionType } from '@bit/redsky.framework.rs.select';

export interface ManageDestinationsDetailsPopupProps extends PopupProps {
	destinationDetails?: any;
	setDestinationDetails?: any;
	isCreate?: boolean;
}

const ManageDestinationsDetailsPopup: React.FC<ManageDestinationsDetailsPopupProps> = (props) => {
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const [destDetails, setDestDetails] = useState(props.destinationDetails);
	const [countryOptions, setCountryOptions] = useState<OptionType[]>([]);
	const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
	const [isDataFilled, setIsDataFilled] = useState(true);

	function formatStateOrCountryListForSelect(statesOrCountries: { name: string; isoCode: string }[]) {
		return statesOrCountries.map((item: { name: string; isoCode: string }) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	useEffect(() => {
		setDestDetails(props.destinationDetails);

		async function getCountries() {
			try {
				let countries = await countryService.getAllCountries();
				setCountryOptions(formatStateOrCountryListForSelect(countries.countries));
			} catch (e) {
				throw rsToastify.error('Unable to get a list of countries.');
			}
		}
		getCountries().catch(console.error);
	}, []);

	useEffect(() => {
		async function getStates() {
			try {
				let response = await countryService.getStates(destDetails.country || 'US');
				if (response.states) {
					let newStates = formatStateOrCountryListForSelect(response.states);
					setStateOptions(newStates);
				}
			} catch (e) {
				rsToastify.error('Unable to get states for the selected country.');
			}
		}
		setDestDetails({ ...destDetails });
		getStates().catch(console.error);
	}, [countryOptions, destDetails.country]);

	useEffect(() => {
		if (
			destDetails.name &&
			destDetails.address1 &&
			destDetails.city &&
			destDetails.country &&
			destDetails.state &&
			destDetails.zip &&
			destDetails.phone &&
			destDetails.phone.length > 9 &&
			destDetails.zip.length > 4
		) {
			setIsDataFilled(false);
		} else setIsDataFilled(true);
	}, [destDetails]);

	function handleChange(key: any, value: any) {
		if (value !== ' ') {
			if (key === 'phone') {
				if (value.toString().length < 13) {
					setDestDetails({ ...destDetails, [key]: value });
				}
			} else if (key === 'zip') {
				if (value.toString().length < 7) {
					setDestDetails({ ...destDetails, [key]: value });
				}
			} else {
				setDestDetails({ ...destDetails, [key]: value });
			}
		}
	}

	function handleSave() {
		if (
			destDetails.name &&
			destDetails.address1 &&
			destDetails.phone &&
			destDetails.city &&
			destDetails.country &&
			destDetails.state &&
			destDetails.zip
		) {
			rsToastify.success('Destination detail added!', 'Success!');
			props.setDestinationDetails(destDetails);
			popupController.close(ManageDestinationsDetailsPopup);
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsDestinationDetailPopup'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.close(ManageDestinationsDetailsPopup);
					}}
					cursorPointer
				/>
				<Box className={'title'}>
					<Label variant={'h1'}>{!props.isCreate ? 'Edit Resort Details' : 'Add Resort Details'}</Label>
				</Box>
				<div>
					<div className="input-text">Resort Name</div>
					<input
						type="text"
						value={destDetails?.name}
						pattern="^[^ ].+[^ ]$"
						className="input-box"
						onChange={({ target: { value } }) => handleChange('name', value)}
					/>
					{!destDetails?.name ? <div className="err-msg">Destination Name is required</div> : ''}
				</div>
				<div>
					<div className="input-text">Address Line 1</div>
					<input
						type="text"
						value={destDetails?.address1}
						className="input-box"
						onChange={({ target: { value } }) => handleChange('address1', value)}
					/>
					{!destDetails?.address1 ? <div className="err-msg">Address Line is required</div> : ''}
				</div>
				<div>
					<div className="input-text">Address Line 2</div>
					<input
						type="text"
						value={destDetails?.address2}
						className="input-box"
						onChange={({ target: { value } }) => handleChange('address2', value)}
					/>
				</div>
				<div>
					<div className="input-text">Phone</div>
					<input
						type="number"
						value={destDetails?.phone}
						className="input-box"
						// step="0.01"
						minLength={10}
						maxLength={12}
						onChange={({ target: { value } }) => handleChange('phone', value)}
					/>
				</div>
				{!destDetails?.phone ? (
					<div className="err-msg">Phone number is required</div>
				) : destDetails?.phone.length <= 9 ? (
					<div className="err-msg">Enter valid phone number</div>
				) : (
					''
				)}
				<Box className={'d-flex'}>
					<div className="w-100 mr-10">
						<div className="input-text">City</div>
						<input
							type="text"
							value={destDetails?.city}
							className="input-box"
							onChange={({ target: { value } }) => handleChange('city', value)}
						/>
						{!destDetails?.city ? <div className="err-msg">City is required</div> : ''}
					</div>
					<div className="w-100 sec-input">
						<div className="input-text">Zip</div>
						<input
							type="text"
							minLength={5}
							maxLength={6}
							// inputProps= {{maxLength:6}}
							value={destDetails?.zip}
							className="input-box"
							onChange={({ target: { value } }) => handleChange('zip', value)}
						/>
						{!destDetails?.zip ? (
							<div className="err-msg">Zip is required</div>
						) : destDetails?.zip.length <= 4 ? (
							<div className="err-msg">Enter valid Zip code</div>
						) : (
							''
						)}
					</div>
				</Box>
				<Box className={'d-flex'}>
					<div className="w-100 mr-10">
						<div className="input-text">State</div>
						<select
							className="input-box"
							onChange={({ target: { value } }) => handleChange('state', value)}
						>
							<option value="">Select a State</option>
							{stateOptions.map((item: any, index) => (
								<option key={index} selected={destDetails?.state === item.value} value={item.value}>
									{item.label}
								</option>
							))}
						</select>
						{!destDetails?.state ? <div className="err-msg">State is required</div> : ''}
					</div>
					<div className="w-100 sec-input">
						<div className="input-text">Country</div>
						<select
							className="input-box"
							onChange={({ target: { value } }) => handleChange('country', value)}
						>
							<option value="">Select a Country</option>
							{countryOptions.map((item: any, index) => (
								<option key={index} selected={destDetails?.country === item.value} value={item.value}>
									{item.label}
								</option>
							))}
						</select>
						{!destDetails?.country ? <div className="err-msg">Country is required</div> : ''}
					</div>
				</Box>
				<Box className="detail-btn-box">
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Save'}
						onClick={() => {
							handleSave();
						}}
						disabled={isDataFilled}
					/>
					<LabelButton
						className="cancel-btn"
						look={'containedSecondary'}
						variant={'button'}
						label={'Cancel'}
						onClick={async () => {
							popupController.close(ManageDestinationsDetailsPopup);
						}}
					/>
				</Box>
			</Paper>
		</Popup>
	);
};

export default ManageDestinationsDetailsPopup;
