import * as React from 'react';
import { useEffect, useState } from 'react';
import './SpireTable.scss';
import { Box } from '@bit/redsky.framework.rs.996';
import {
	FilterDropdownCell,
	FilterInputCell,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow
} from '@bit/redsky.framework.rs.datatable';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { addDeleteFilterQueryForTables, DateUtils } from '../../utils/utils';
import Label from '@bit/redsky.framework.rs.label';
import LabelCheckbox from '../labelCheckbox/LabelCheckbox';
import SpireTableRow, { ColumnDetails } from './spireTableRow/SpireTableRow';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';

export type FieldName = string;

export interface tableDetails {
	placeholder: string;
	filterQuery: {
		column: string;
		value: string | string[] | number | number[];
		conjunction?: 'AND' | 'OR';
	}[];
}

export interface SpireTableProps {
	table: tableDetails;
	columns: ColumnDetails[];
	data: any[];
	total: number;
	sortField: FieldName;
	onGetData: (pageQuery: RedSky.PageQuery) => void;
	rowOnClick: (data: any) => void;
	changeSort: (field: string, order: RedSky.StandardOrderTypes) => void;
	filterName?: string;
	selectedList?: any[];
}

const SpireTable: React.FC<SpireTableProps> = (props) => {
	const [errorMsg] = useState<string>('');
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState<number>(10);
	const [sortField, setSortField] = useState<FieldName>(props.sortField);
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('ASC');
	const [matchType] = useState<RedSky.MatchTypes>('like');
	const [showColumnFilters, setShowColumnFilters] = useState<boolean>(false);
	const [filter, setFilter] = useState<RedSky.FilterQueryValue[]>([]);
	const [filterDates, setFilterDates] = useState<{
		createdOn: Date | null;
		startOn: Date | null;
		endOn: Date | null;
	}>({ createdOn: null, startOn: null, endOn: null });
	const [focusedInputs, setFocusedInputs] = useState<'createdOn' | 'startOn' | 'endOn' | null>();
	const [filterFormGroup, setFilterFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('filter', '', [])])
	);

	useEffect(() => {
		props.changeSort(sortField, sortOrder);
	}, [sortOrder, sortField]);

	useEffect(() => {
		let pageQuery: RedSky.PageQuery = {
			pagination: {
				page: pageNumber,
				perPage
			},
			sort: {
				field: sortField,
				order: sortOrder
			},
			filter: {
				matchType,
				searchTerm: filter
			}
		};
		props.onGetData(pageQuery);
	}, [pageNumber, perPage, sortField, sortOrder]);

	// different use effect for filter
	useEffect(() => {
		let pageQuery: RedSky.PageQuery = {
			pagination: {
				page: 1,
				perPage
			},
			sort: {
				field: sortField,
				order: sortOrder
			},
			filter: {
				matchType,
				searchTerm: filter
			}
		};
		setPageNumber(1);
		props.onGetData(pageQuery);
	}, [filter, filterDates]);

	function addFilterDates(column: string, date: Date | null) {
		if (date !== null) {
			setFilter(
				addDeleteFilterQueryForTables([{ column: column, value: DateUtils.clientToServerDate(date) }], filter)
			);
		} else {
			let i = 0;
			while (i !== -1) {
				i = filter.findIndex((el) => el.column === column);
				if (i !== -1) filter.splice(i, 1);
			}
			if (filterDates) {
				setFilter(addDeleteFilterQueryForTables([], filter));
				return;
			}
			setFilter(
				addDeleteFilterQueryForTables(
					[
						{
							matchType: 'exact',
							column: column,
							value: filterDates || ''
						}
					],
					filter
				)
			);
		}
	}

	function renderColumnFilters() {
		return (
			<TableRow>
				{props.columns.map((cell) => {
					switch (cell.filterType) {
						case 'SINGLE_DATE':
							return (
								<TableCell key={cell.id} align={'left'} className="actionCellData">
									<SingleDatePicker
										id={cell.id}
										date={
											filterDates[cell.filterName || 'createdOn']
												? moment(filterDates[cell.filterName || 'createdOn'])
												: null
										}
										onDateChange={(date) => {
											let convertedDate: Date | null = date ? date.toDate() : null;
											setFilterDates({ ...filterDates, [cell.id]: convertedDate });
											addFilterDates(cell.id, convertedDate);
										}}
										focused={focusedInputs === cell.id}
										onFocusChange={({ focused }) => {
											if (focused) {
												setFocusedInputs(cell.filterName);
											} else {
												setFocusedInputs(null);
											}
										}}
										numberOfMonths={1}
										isOutsideRange={() => false}
										showClearDate={true}
									/>
								</TableCell>
							);
						case 'NORMAL':
							return (
								<FilterInputCell
									key={cell.id}
									column={cell.id}
									enteredValueCallback={(value) => {
										setFilter(
											addDeleteFilterQueryForTables(
												[
													{
														column: cell.id,
														value: value
													}
												],
												filter
											)
										);
									}}
								/>
							);
						case 'DROPDOWN':
							return (
								<FilterDropdownCell
									key={cell.id}
									className="rsFilterDropdownCell"
									options={cell.dropDownOptions || []}
									placeholder={'Filter...'}
									control={filterFormGroup.get('filter')}
									updateControl={(control) => {
										setFilterFormGroup(filterFormGroup.clone().update(control));
										setFilter(
											addDeleteFilterQueryForTables(
												[{ column: cell.id, value: control.value }],
												filter
											)
										);
									}}
									isClearable
								/>
							);
						case 'CHECKBOX':
							return (
								<TableCell key={cell.id} align={'left'}>
									<LabelCheckbox
										key={cell.id}
										value={cell.id}
										text={props.filterName || 'Show' + cell.label}
										onSelect={(value) => {
											setFilter(
												addDeleteFilterQueryForTables(
													[
														{
															column: cell.id,
															value: 1 || 0
														}
													],
													filter
												)
											);
										}}
										onDeselect={() => {
											setFilter(
												addDeleteFilterQueryForTables(
													[
														{
															column: cell.id,
															value: ''
														}
													],
													filter
												)
											);
										}}
									/>
								</TableCell>
							);
						case 'MULTI_QUERY':
							return (
								<FilterInputCell
									key={cell.id}
									column={cell.id}
									enteredValueCallback={(value) => {
										const queryArray: any = [];
										if (cell.multiFilterQuery) {
											cell.multiFilterQuery.forEach((newFilter) => {
												let obj = {
													column: newFilter.column,
													value: value,
													conjunction: newFilter.conjunction
												};
												queryArray.push(obj);
											});
										} else {
											queryArray.push([{ column: cell.id, value: value }]);
										}
										setFilter(addDeleteFilterQueryForTables(queryArray, filter));
									}}
								/>
							);
						case 'NONE':
							return <TableCell align={'left'}> </TableCell>;
					}
					return null;
				})}
			</TableRow>
		);
	}

	return (
		<Box className={'rsSpireTable'}>
			<Table
				placeholder={props.table.placeholder}
				toggleColumnFilters={() => setShowColumnFilters(!showColumnFilters)}
				debounceTimeout={500}
				searchInputCallback={(value: string) => {
					setPageNumber(1);
					const filterQueryArray: any = [];
					props.table.filterQuery.forEach((newFilter) => {
						let obj = { column: newFilter.column, value: value, conjunction: newFilter.conjunction };
						filterQueryArray.push(obj);
					});
					setFilter(addDeleteFilterQueryForTables(filterQueryArray, filter));
				}}
			>
				<TableHead
					columns={props.columns}
					selectedHeaderSort={(columnId) => {
						let column = props.columns.find((column) => column.id === columnId);
						if (column ? column.sort === undefined : undefined) {
							setSortField(props.sortField);
							setSortOrder('DESC');
						} else if (columnId === sortField) {
							if (sortOrder === 'ASC') {
								setSortOrder('DESC');
							} else {
								setSortOrder('ASC');
							}
						} else {
							setSortField(columnId as FieldName);
							if (sortOrder === 'ASC') {
								setSortOrder('DESC');
							} else {
								setSortOrder('ASC');
							}
						}
					}}
				/>
				{!!errorMsg.length && (
					<Label className="errorText" variant={'body1'}>
						{errorMsg}
					</Label>
				)}
				<TableBody isFilterShown={showColumnFilters}>
					{showColumnFilters && renderColumnFilters()}
					{props.data.length === 0 ? (
						<Box className="noDataCard" display="flex" justifyContent="center" alignItems="center">
							<h1>No Records Found.</h1>
						</Box>
					) : (
						props.data.map((data: any) => (
							<SpireTableRow
								key={data.id}
								data={data}
								rowOnClick={props.rowOnClick}
								columns={props.columns}
								selectedList={!!props.selectedList ? props.selectedList : []}
							/>
						))
					)}
				</TableBody>

				<TableFooter className="Footer">
					<TablePagination
						selectedRowsPerPage={perPage}
						currentPageNumber={pageNumber}
						rowsPerPageOptions={[5, 10, 25, 50, 100]}
						index={[(pageNumber - 1) * perPage + 1, Math.min(pageNumber * perPage, props.total)]}
						setSelectedPage={(page) => setPageNumber(page)}
						setPerPage={(newPerPage) => setPerPage(newPerPage)}
						total={props.total}
					/>
				</TableFooter>
			</Table>
		</Box>
	);
};

export default SpireTable;
