import * as React from 'react';
import './LayoutTile.scss';
import Paper from '../paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import { ObjectUtils } from '@bit/redsky.framework.rs.utils';

interface LayoutTileProps {
	title: string;
	imgUrl: string | undefined;
	rooms: Model.AccommodationLayoutRoom[];
	onClick: () => void;
	onDelete: () => void;
}

const LayoutTile: React.FC<LayoutTileProps> = (props) => {
	function renderRooms() {
		if (!ObjectUtils.isArrayWithData(props.rooms)) return;

		return props.rooms.map((item, index) => {
			return <li key={item.id}>{item.title}</li>;
		});
	}

	return (
		<Paper className={'rsLayoutTile'} borderRadius={'4px'} padding={'10px 25px'} onClick={props.onClick}>
			<img src={props.imgUrl + '?tr=w-100,tr=h-auto'} alt={'Primary Image'} />
			<Label variant={'h2'}>{props.title}</Label>
			{ObjectUtils.isArrayWithData(props.rooms) && (
				<Box>
					<ul>
						<Label variant={'h4'}>Rooms</Label>
						{renderRooms()}
					</ul>
				</Box>
			)}
			<Box marginLeft={'auto'} display={'flex'} alignItems={'center'}>
				<Icon
					iconImg={'icon-trash'}
					cursorPointer
					onClick={(event) => {
						props.onDelete();
						event?.stopPropagation();
					}}
				/>
			</Box>
		</Paper>
	);
};

export default LayoutTile;
