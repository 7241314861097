import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class PackageService extends Service {
	async updatePackage(data: Api.UpsellPackage.Req.Update): Promise<Api.UpsellPackage.Res.Update> {
		const result = await http.put<RsResponseData<Api.UpsellPackage.Res.Update>>('package', data);
		return result.data.data;
	}

	async updatePackageIndex(data: any): Promise<Api.UpsellPackage.Res.Update> {
		const result = await http.put<RsResponseData<Api.UpsellPackage.Res.Update>>('package/packageIndex', data);
		return result.data.data;
	}
}
