import React, { useEffect, useState } from 'react';
import './CreateEditRewardPage.scss';
import disableRoute from '../../customHooks/useMiddleware';
import { Page } from '@bit/redsky.framework.rs.996';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import RewardService from '../../services/reward/reward.service';
import SubHeader from '../../components/subHeader/SubHeader';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import debounce from 'lodash.debounce';
import { WebUtils } from '../../utils/utils';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelInputFc from '../../components/LabelInputFc/LabelInputFc';
import LabelButton from '../../components/labelButton/LabelButton';
import Box from '../../components/box/Box';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import router from '../../utils/router';
import LoadingPage from '../loadingPage/LoadingPage';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpireTable, { FieldName } from '../../components/spireTable/SpireTable';
import { OptionType } from '@bit/redsky.framework.rs.select';
import ImageManager from '../../components/imageManager/ImageManager';

const CreateEditRewardPage: React.FC = () => {
	const rewardService = serviceFactory.get<RewardService>('RewardService');
	const params = router.getPageUrlParams<{ rewardId: number }>([
		{ key: 'ri', default: 0, type: 'integer', alias: 'rewardId' }
	]);
	const [rewardIsActive, setRewardIsActive] = useState<boolean>(false);
	const [categoryList, setCategoryList] = useState<Api.Reward.Category.Res.Get[]>([]);
	const [vendorList, setVendorList] = useState<OptionType[]>([]);
	const [mediaDetails, setMediaDetails] = useState<Api.MediaDetails[]>([]);
	const [deleteImages, setDeleteImages] = useState<number[]>([]);
	const [currentRewardToEdit, setCurrentRewardToEdit] = useState<Api.Reward.Res.Get>();
	const [waitToLoad, setWaitToLoad] = useState<boolean>(true);
	const [csvCodes, setCsvCodes] = useState<string[]>([]);
	const [csvFileName, setCsvFileName] = useState<string>('');
	const [voucherList, setVoucherList] = useState<Api.Reward.Voucher.Res.Get[]>([]);
	const filter: RedSky.FilterQueryValue[] = [{ column: 'rewardId', value: params.rewardId.toString() }];
	const [sortField, setSortField] = useState<FieldName>('code');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [voucherTotal, setVoucherTotal] = useState<number>(0);
	const [form, setForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('name', '', [new RsValidator(RsValidatorEnum.REQ, 'Reward Name is required')]),
			new RsFormControl('pointCost', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Point Cost is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
					return control.value > 0;
				})
			]),
			new RsFormControl('redemptionInstructions', '', [
				new RsValidator(RsValidatorEnum.MAX, 'Please keep it less than 500 characters', 500)
			]),
			new RsFormControl('description', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Description is required'),
				new RsValidator(RsValidatorEnum.MAX, 'Please keep it less than 500 characters', 500)
			]),
			new RsFormControl('upc', '', [
				new RsValidator(RsValidatorEnum.REQ, 'UPC is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Entry', (control) => {
					return control.value > 0;
				})
			]),
			new RsFormControl('vendor', '', [new RsValidator(RsValidatorEnum.REQ, 'Select a vendor.')]),
			new RsFormControl('categoryIds', [], [new RsValidator(RsValidatorEnum.REQ, 'Select a category.')])
		])
	);

	let formDebounce = debounce(async (control: RsFormControl) => {
		if (control.key === 'pointCost' && typeof control.value === 'string') {
			control.value = control.value.replace(/[^0-9]/g, '');
		}
		if (control.key === 'upc' && typeof control.value === 'string') {
			control.value = control.value.replace(/[^0-9]/g, '');
		}
		setForm(form.clone().update(control));
	});

	useEffect(() => {
		disableRoute.useMiddleware();
		async function getRewardCategoriesVendors() {
			try {
				let categoryRes = await rewardService.getAllActiveCategories();
				setCategoryList(categoryRes.data);

				let vendorRes = await rewardService.getVendorsInSelectFormat();
				setVendorList(vendorRes);
				if (params.rewardId) {
					let productRes: Api.Reward.Res.Get = await rewardService.getRewardById(params.rewardId);
					setCurrentRewardToEdit(productRes);
					setRewardIsActive(productRes.isActive === 1);
				} else {
					setWaitToLoad(false);
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
					'Server Error'
				);
			}
			setWaitToLoad(false);
		}
		getRewardCategoriesVendors().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!currentRewardToEdit) return;

		let currentVendor = '';
		if (currentRewardToEdit.brandId !== null) currentVendor = 'a' + currentRewardToEdit.brandId;
		else if (currentRewardToEdit.destinationId !== null) currentVendor = 'd' + currentRewardToEdit.destinationId;

		let updatedForm = form.clone();
		updatedForm.get('name').value = currentRewardToEdit.name?.toString() || '';
		updatedForm.get('pointCost').value = currentRewardToEdit.pointCost?.toString() || '';
		updatedForm.get('description').value = currentRewardToEdit.description?.toString() || '';
		updatedForm.get('upc').value = currentRewardToEdit.upc?.toString() || '';
		updatedForm.get('redemptionInstructions').value = currentRewardToEdit.redemptionInstructions?.toString() || '';
		updatedForm.get('vendor').value = currentVendor || '';
		updatedForm.get('categoryIds').value = currentRewardToEdit.categoryIds || [];

		setForm(updatedForm);

		setWaitToLoad(false);
	}, [currentRewardToEdit]);

	async function createVoucher() {
		try {
			await rewardService.createVoucher(params.rewardId, csvCodes);
			rsToastify.success(`Reward vouchers were created successfully.`, 'Success!');
			const pageQuery: RedSky.PageQuery = {
				pagination: {
					page: 1,
					perPage: 10
				},
				sort: {
					field: sortField,
					order: sortOrder
				},
				filter: {
					matchType: 'like',
					searchTerm: filter
				}
			};
			getData(pageQuery).catch(console.error);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function toggleRewardActiveStatus() {
		try {
			await rewardService.toggleRewardActiveStatus(params.rewardId);
			setRewardIsActive(!rewardIsActive);
			rsToastify.success('Successfully changed the active status of the reward', 'Success!');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function handleMediaData(mediaData: { id: number; isPrimary: 1 | 0; image: string }[], deleteImg: number[]) {
		setMediaDetails(
			mediaData.map((img) => {
				return { id: img.id, isPrimary: img.isPrimary };
			})
		);
		setDeleteImages(deleteImg);
	}

	async function checkIsFormValid(): Promise<boolean> {
		let formIsValid = await form.isValid();
		setForm(form.clone());
		return formIsValid && form.get('pointCost').value !== 0;
	}

	async function createEditStoreReward() {
		if (!mediaDetails.find((img) => img.isPrimary)) {
			rsToastify.error('Must select a primary Image', 'Select Primary!');
			return;
		}
		if (!(await checkIsFormValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}
		let newReward: any = form.toModel();
		delete newReward['vendor'];
		newReward.pointCost = parseInt(newReward.pointCost);
		newReward.monetaryValueInCents = 0;
		newReward.mediaDetails = mediaDetails;
		if (form.get('vendor').value) {
			let vendor = form.get('vendor').value as string;
			const brandOrDestination = vendor.slice(0, 1);
			const vendorId: number = parseInt(vendor.slice(1));
			if (brandOrDestination === 'd') {
				newReward.destinationId = vendorId;
			} else if (brandOrDestination === 'a') {
				newReward.brandId = vendorId;
			}
		}
		if (params.rewardId) {
			updateReward(newReward).catch(console.error);
		} else {
			createReward(newReward).catch(console.error);
		}
	}

	async function updateReward(newReward: Api.Reward.Res.Get) {
		newReward.id = params.rewardId;
		try {
			if (deleteImages.length >= 1) {
				deleteImages.forEach((imageId) => {
					rewardService.deleteCategoryImage({ id: imageId });
				});
			}
			await rewardService.updateReward(newReward);
			rsToastify.success(`Reward was updated successfully.`, 'Success!');
			router.navigate(`/dashboard/reward-list/manage-reward?ri=${params.rewardId}`).catch(console.error);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function createReward(newReward: Api.Reward.Req.Create) {
		try {
			if (deleteImages.length >= 1) {
				deleteImages.forEach((imageId) => {
					rewardService.deleteCategoryImage({ id: imageId });
				});
			}
			let res = await rewardService.createReward(newReward);
			router.updateUrlParams({
				ri: res.id
			});
			rsToastify.success('Reward was created successfully.', 'Success!');
			router.navigate(`/dashboard/reward-list/manage-reward?ri=${res.id}`).catch(console.error);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function readCsvFile(file: File) {
		if (file === null) return;
		setCsvFileName(file.name);
		let formData = new FormData();
		formData.append('file', file);
		const fileContentStream = file.stream();
		let streamList = await streamToText(fileContentStream);
		let csvArray = streamList.replace(/,\s+/g, ',').split(/[\n,\s+]/);
		setCsvCodes(csvArray.filter((code) => code !== ''));
	}

	async function streamToText(blob: any) {
		const readableStream = await blob.getReader();
		const chunk = await readableStream.read();
		return new TextDecoder('utf-8').decode(chunk.value);
	}

	async function deleteVoucher(rewardId: number, code: string) {
		try {
			setWaitToLoad(true);
			await rewardService.deleteVoucher(rewardId, `${code}`);
			const pageQuery: RedSky.PageQuery = {
				pagination: {
					page: 1,
					perPage: 10
				},
				sort: {
					field: sortField,
					order: sortOrder
				},
				filter: {
					matchType: 'like',
					searchTerm: filter
				}
			};
			getData(pageQuery).catch(console.error);
			rsToastify.success('Voucher deactivated successfully.', 'Success!');
			setWaitToLoad(false);
		} catch (e) {
			setWaitToLoad(false);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function activateVoucher(rewardId: number, code: string) {
		try {
			setWaitToLoad(true);
			await rewardService.activateVoucher(rewardId, `${code}`);
			const pageQuery: RedSky.PageQuery = {
				pagination: {
					page: 1,
					perPage: 10
				},
				sort: {
					field: sortField,
					order: sortOrder
				},
				filter: {
					matchType: 'like',
					searchTerm: filter
				}
			};
			getData(pageQuery).catch(console.error);
			rsToastify.success('Voucher activated successfully.', 'Success!');
			setWaitToLoad(false);
		} catch (e) {
			setWaitToLoad(false);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			let searchTerms = pageQuery.filter?.searchTerm;
			if (pageQuery.filter && searchTerms) {
				let hasRewardId = false;
				for (let term of searchTerms) {
					if (term.column === 'rewardId') {
						hasRewardId = true;
					}
				}
				if (!hasRewardId) {
					searchTerms.push({ column: 'rewardId', value: params.rewardId });
					pageQuery.filter.searchTerm = searchTerms;
				}
			}
			let voucherRes = await rewardService.getAllVouchers(pageQuery);
			setVoucherList(voucherRes.data);
			setVoucherTotal(voucherRes.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function renderRedeemed(data: any) {
		if (data.customerUserId) {
			return 'Yes';
		} else {
			return 'No';
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function renderUploadCsv() {
		if (!csvCodes) return;
		return csvCodes.map((csv, index) => {
			return (
				<div key={index} className={'displayCsvCodes'}>
					{csv}
				</div>
			);
		});
	}

	function renderActivateOrDeactivateVoucher(voucher: Api.Reward.Voucher.Res.Get) {
		if (voucher.isActive) {
			return (
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'deactivate'}
					onClick={() => deleteVoucher(voucher.rewardId, voucher.code.toString())}
				/>
			);
		} else {
			return (
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Activate'}
					onClick={() => activateVoucher(voucher.rewardId, voucher.code.toString())}
				/>
			);
		}
	}

	return waitToLoad ? (
		<LoadingPage />
	) : (
		<Page className={'rsCreateEditRewardPage'}>
			<SubHeader
				header={!params.rewardId ? 'Create Redeemable Reward' : 'Manage Redeemable Reward'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Manage Reward', link: '/dashboard/reward-list/create-reward' }
				]}
			/>
			<ImageManager
				data={currentRewardToEdit ? currentRewardToEdit.media : []}
				id={'rewardUpload'}
				handleMediaData={handleMediaData}
			/>
			<div className={'labelInputs'}>
				<Box className={'topRowInputs'} display={'flex'}>
					<LabelInputFc
						className={'rewardName'}
						title={'Reward Name'}
						inputType={'text'}
						control={form.get('name')}
						updateControl={(updateControl) => formDebounce(updateControl)}
					/>
					<LabelInputFc
						className={'pointsCost'}
						title={'Points Cost'}
						inputType={'text'}
						control={form.get('pointCost')}
						updateControl={(updateControl) => formDebounce(updateControl)}
					/>
					<LabelInputFc
						className={'upc'}
						title={'UPC'}
						inputType={'text'}
						control={form.get('upc')}
						updateControl={(updateControl) => formDebounce(updateControl)}
					/>
				</Box>
				<div className={'labelSelects'}>
					<LabelSelect
						title={'Vendor'}
						variant={'h2'}
						options={vendorList.map((vendor) => {
							return { value: vendor.value, label: vendor.label };
						})}
						updateControl={(updateControl) => formDebounce(updateControl)}
						control={form.get('vendor')}
					/>
					<LabelSelect
						title={'Category'}
						variant={'h2'}
						isMulti={true}
						updateControl={(updateControl) => formDebounce(updateControl)}
						control={form.get('categoryIds')}
						options={categoryList.map((category) => {
							return { value: category.id, label: category.name };
						})}
					/>
				</div>
				<div className={'redemptionInstructions'}>
					<LabelInputFc
						title={'Redemption Instructions'}
						control={form.get('redemptionInstructions')}
						updateControl={(updateControl) => formDebounce(updateControl)}
						inputType={'textarea'}
					/>
				</div>
				<div className={'descriptionBox'}>
					<LabelInputFc
						className={'rewardDescription'}
						title={'Description'}
						inputType={'textarea'}
						control={form.get('description')}
						updateControl={(updateControl) => formDebounce(updateControl)}
					/>
				</div>
				<Box className={'buttonBox'} display={'flex'}>
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={params.rewardId ? 'Update Reward' : 'Save Reward'}
						onClick={createEditStoreReward}
					/>
					{params.rewardId ? (
						<LabelButton
							look={'containedSecondary'}
							variant={'button'}
							label={rewardIsActive ? 'Archive Reward' : 'Unarchive Reward'}
							onClick={toggleRewardActiveStatus}
						/>
					) : (
						<div />
					)}
					<LabelButton
						look={'containedSecondary'}
						variant={'button'}
						label={'Cancel'}
						onClick={() => router.navigate('/dashboard/reward-list').catch(console.error)}
					/>
				</Box>
			</div>
			<Box className={'voucherUploadWrapper'} display={params.rewardId ? 'block' : 'none'}>
				<Label className={'voucherLabel'} variant={'h2'}>
					Upload Voucher Codes
				</Label>
				<div className={'voucherInputWrapper'}>
					<label htmlFor={'csvUpload'} className={'uploadWrapper'}>
						<label className={'uploadBtn'} htmlFor={'csvUpload'}>
							Upload CSV File
						</label>
						<input
							id={'csvUpload'}
							name={'file'}
							type={'file'}
							className={'uploadInput'}
							onChange={async (event) => {
								if (event.target.files !== null)
									readCsvFile(event.target.files[0]).catch(console.error);
							}}
						/>
					</label>
					<Label className={'fileName'} variant={'subtitle1'}>
						{csvFileName}
					</Label>
					<div className={'csvWrapper'}>{renderUploadCsv()}</div>
				</div>
				<Box display={csvCodes ? 'block' : 'none'} marginTop={'20px'}>
					<LabelButton
						disabled={!csvFileName}
						look={!csvFileName ? 'containedSecondary' : 'containedPrimary'}
						variant={'button'}
						label={'Create Reward Vouchers'}
						onClick={() => {
							createVoucher().catch(console.error);
						}}
					/>
				</Box>
			</Box>
			<Box className={'voucherTable'} display={params.rewardId ? 'block' : 'none'} marginBottom={'100px'}>
				<SpireTable
					table={{
						placeholder: 'Search by voucher code',
						filterQuery: [{ column: 'code', value: '' }]
					}}
					columns={[
						{
							id: 'code',
							label: 'Voucher Code',
							align: 'left',
							className: 'voucherCell',
							sort: sortField === 'code' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'isActive',
							label: 'Active',
							align: 'left',
							className: 'voucherCell',
							sort: sortField === 'isActive' ? sortOrder : 'DESC',
							filterType: 'CHECKBOX',
							cellType: 'BOOLEAN',
							textOptions: {
								true: 'Active',
								false: 'Inactive'
							}
						},
						{
							id: 'createdOn',
							label: 'Created On',
							align: 'left',
							className: 'voucherCell',
							sort: sortField === 'createdOn' ? sortOrder : 'DESC',
							filterType: 'SINGLE_DATE',
							filterName: 'createdOn',
							cellType: 'DATE'
						},
						{
							id: 'customerUserId',
							label: 'Claimed',
							align: 'left',
							className: 'voucherCell',
							sort: sortField === 'customerUserId' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'HANDLER',
							handler: renderRedeemed
						},
						{
							id: 'isActive',
							label: 'Activate or Deactivate',
							align: 'left',
							className: 'voucherCell',
							sort: sortField === 'isActive' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'HANDLER',
							handler: renderActivateOrDeactivateVoucher
						}
					]}
					data={voucherList}
					total={voucherTotal}
					sortField={'code'}
					onGetData={getData}
					rowOnClick={(data: any) => {
						return null;
					}}
					changeSort={changeSort}
				/>
			</Box>
		</Page>
	);
};

export default CreateEditRewardPage;

export function createEditRewardPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_REWARDS_WRITE]);
}
