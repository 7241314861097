import { RouteDetails } from '@bit/redsky.framework.rs.996';
import NotFoundPage from './pages/notFoundPage/notFoundPage';
import LoginPage from './pages/loginPage/LoginPage';
import DashboardPage from './pages/dashboardPage/DashboardPage';
import LoadingPage from './pages/loadingPage/LoadingPage';
import ResetPasswordPage from './pages/resetPasswordPage/ResetPasswordPage';
import ForbiddenPage from './pages/forbiddenPage/ForbiddenPage';
import UserListPage, { userListPageGuard } from './pages/userListPage/UserListPage';
import ManageUserPage, { manageUserPageGuard } from './pages/manageUserPage/ManageUserPage';
import AddNewAddressPage from './pages/addNewAddressPage/AddNewAddressPage';
import AddRemovePointsPage from './pages/addRemovePointsPage/AddRemovePointsPage';
import ManageUserPointsPage, { manageUserPointsPageGuard } from './pages/manageUserPointsPage/ManageUserPointsPage';
import CreateUserPage, { createUserPageGuard } from './pages/createUserPage/CreateUserPage';
import LoginVerifyExpiredPage from './pages/loginVerifyExpiredPage/LoginVerifyExpiredPage';
import CreateTierPage, { createTierRewardsPageGuard } from './pages/createTier/CreateTierPage';
import ManageDestinationsPage from './pages/manageDestinationsPage/ManageDestinationsPage';
import ManageAccommodationPage from './pages/manageAccommodationPage/ManageAccommodationPage';
import RewardListPage, { rewardListPageGuard } from './pages/rewardListPage/RewardListPage';
import CreateEditRewardPage, { createEditRewardPageGuard } from './pages/createEditRewardPage/CreateEditRewardPage';
import DestinationListPage from './pages/destinationListPage/DestinationListPage';
import RewardCategoryListPage, {
	rewardCategoryListPageGuard
} from './pages/rewardCategoryListPage/RewardCategoryListPage';
import CreateEditRewardCategoryPage, {
	createEditRewardCategoryPageGuard
} from './pages/createEditRewardCategoryPage/CreateEditRewardCategoryPage';
import ManageCampaignsPage, { manageCampaignsPageGuard } from './pages/manageCampaignsPage/ManageCampaignsPage';
import ManageCampaignActionsPage, {
	manageCampaignActionsPageGuard
} from './pages/manageCampaignActionsPage/ManageCampaignActionsPage';
import AddEditCampaignPage, { manageEditCampaignsPageGuard } from './pages/addEditCampaignPage/AddEditCampaignPage';
import AddEditCampaignActionPage, {
	manageCampaignActionPageGuard
} from './pages/addEditCampaignActionPage/AddEditCampaignActionPage';
import CompanyListPage, { companyListPageGuard } from './pages/companyListPage/CompanyListPage';
import CreateEditCompanyPage, { createEditCompanyPageGuard } from './pages/createEditCompanyPage/CreateEditCompanyPage';
import ReviewListPage, { reviewListPageGuard } from './pages/reviewListPage/ReviewListPage';
import ManageCompanyPagesPage from './pages/manageCompanyPagesPage/ManageCompanyPagesPage';
import RegionListPage, { regionListPageGuard } from './pages/regionListPage/RegionListPage';
import BookingHistoryPage from './pages/bookingHistoryPage/BookingHistoryPage';
import ReservationDetailsPage from './pages/reservationDetailsPage/ReservationDetailsPage';
import OrderHistoryPage from './pages/orderHistoryPage/OrderHistoryPage';
import AddNewCardPage from './pages/addNewCardPage/AddNewCardPage';
import ManageExperienceIcons, { manageExperienceIconsGuard } from './pages/manageExperienceIcons/ManageExperienceIcons';
import ManageInUnitAmenityIcons, {
	manageUnitAmenityIconsGuard
} from './pages/manageInUnitAmenityIcons/ManageInUnitAmenityIcons';
import BrandListPage, { manageBrandsPageGuard } from './pages/brandListPage/BrandListPage';
import BrandDetailsPage from './pages/brandDetailsPage/BrandDetailsPage';
import LocationDetailsPage from './pages/locationDetailsPage/LocationDetailsPage';
import TransactionReportListPage from './pages/transactionReports/TransactionReport';
import BrandReportListPage from './pages/brandReportPage/BrandReportListPage';
import PointsAdminListPage from './pages/pointsAdmin/PointsAdmin';
import ManagePendingPointReportPage from './pages/managePendingPointReportPage/ManagePendingPointReportPage';
import ManageCampaignAuditReport from './pages/manageCampaignAuditReport/ManageCampaignAuditReport';
import ManageUserRoles from './pages/manageUserRoles/ManageUserRoles';
import ManageRetailRevenueReport from './pages/manageRetailRevenueReport/ManageRetailRevenueReport';
import PointCampaignCostReport from './pages/pointCampaignCostReport/PointCampaignCostReport';
import PointCampaignCostReportForDestinations from './pages/pointCampaignCostReportForDestinations/PointCampaignCostReportForDestinations';

const routes: RouteDetails[] = [
	{
		path: '/',
		page: LoginPage
	},
	{
		path: '/dashboard',
		page: DashboardPage
	},
	{
		path: '/password-reset',
		page: ResetPasswordPage
	},
	{
		path: '/search',
		page: LoadingPage
	},
	{
		path: '/dashboard/user-list',
		page: UserListPage,
		routeGuard: userListPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user',
		page: ManageUserPage,
		routeGuard: manageUserPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user/booking-history',
		page: BookingHistoryPage,
		routeGuard: manageUserPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user/booking-history/reservation',
		page: ReservationDetailsPage,
		routeGuard: manageUserPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user/order-history',
		page: OrderHistoryPage,
		routeGuard: manageUserPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user/manage-points',
		page: ManageUserPointsPage,
		routeGuard: manageUserPointsPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user/manage-points/update-points',
		page: AddRemovePointsPage,
		routeGuard: manageUserPointsPageGuard
	},
	{
		path: '/dashboard/user-list/create-user',
		page: CreateUserPage,
		routeGuard: createUserPageGuard
	},
	{
		path: '/dashboard/manage-user-roles',
		page: ManageUserRoles,
		routeGuard: createUserPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user/create-address',
		page: AddNewAddressPage,
		routeGuard: userListPageGuard
	},
	{
		path: '/dashboard/user-list/manage-user/new-card',
		page: AddNewCardPage,
		routeGuard: userListPageGuard
	},
	{
		path: '/dashboard/region-list',
		page: RegionListPage,
		routeGuard: regionListPageGuard
	},
	{
		path: '/dashboard/reward-list',
		page: RewardListPage,
		routeGuard: rewardListPageGuard
	},
	{
		path: '/dashboard/reward-list/create-reward',
		page: CreateEditRewardPage,
		routeGuard: createEditRewardPageGuard
	},
	{
		path: '/dashboard/reward-list/manage-reward',
		page: CreateEditRewardPage,
		routeGuard: createEditRewardPageGuard
	},
	{
		path: '/dashboard/reward-list/category-list',
		page: RewardCategoryListPage,
		routeGuard: rewardCategoryListPageGuard
	},
	{
		path: '/dashboard/reward-list/category-list/create-category',
		page: CreateEditRewardCategoryPage,
		routeGuard: createEditRewardCategoryPageGuard
	},
	{
		path: '/dashboard/reward-list/category-list/manage-category',
		page: CreateEditRewardCategoryPage,
		routeGuard: createEditRewardCategoryPageGuard
	},
	{
		path: '/dashboard/reward-list/campaigns',
		page: ManageCampaignsPage,
		routeGuard: manageCampaignsPageGuard
	},
	{
		path: '/dashboard/reward-list/campaigns/create-campaign',
		page: AddEditCampaignPage,
		routeGuard: manageEditCampaignsPageGuard
	},
	{
		path: '/dashboard/reward-list/campaigns/actions',
		page: ManageCampaignActionsPage,
		routeGuard: manageCampaignActionsPageGuard
	},
	{
		path: '/dashboard/reward-list/campaigns/actions/create-action',
		page: AddEditCampaignActionPage,
		routeGuard: manageCampaignActionPageGuard
	},
	{
		path: '/dashboard/reward-list/create-tier',
		page: CreateTierPage,
		routeGuard: createTierRewardsPageGuard
	},
	{
		path: '/dashboard/reward-list/manage-reward-point',
		page: PointsAdminListPage
		// routeGuard: createTierRewardsPageGuard
	},
	{
		path: '/dashboard/manage-pending-point-report',
		page: ManagePendingPointReportPage
		// routeGuard: createTierRewardsPageGuard
	},
	{
		path: '/dashboard/campaign-audit-report',
		page: ManageCampaignAuditReport
		// routeGuard: createTierRewardsPageGuard
	},
	{
		path: '/dashboard/retail-revenue-report',
		page: ManageRetailRevenueReport
		// routeGuard: createTierRewardsPageGuard
	},
	{
		path: '/dashboard/company-list',
		page: CompanyListPage,
		routeGuard: companyListPageGuard
	},
	{
		path: '/dashboard/company-list/create-company',
		page: CreateEditCompanyPage,
		routeGuard: createEditCompanyPageGuard
	},
	{
		path: '/dashboard/company-list/manage-company',
		page: CreateEditCompanyPage,
		routeGuard: createEditCompanyPageGuard
	},
	{
		path: '/dashboard/review-list',
		page: ReviewListPage,
		routeGuard: reviewListPageGuard
	},
	{
		path: '/dashboard/experience-icons',
		page: ManageExperienceIcons,
		routeGuard: manageExperienceIconsGuard
	},
	{
		path: '/dashboard/unit-amenity-icons',
		page: ManageInUnitAmenityIcons,
		routeGuard: manageUnitAmenityIconsGuard
	},
	{
		path: '/dashboard/destination-list',
		page: DestinationListPage
	},
	{
		path: '/dashboard/destination-list/create-resort',
		page: ManageDestinationsPage
	},
	{
		path: '/dashboard/transaction-report',
		page: TransactionReportListPage
	},
	{
		path: '/dashboard/point-campaign-cost-report',
		page: PointCampaignCostReport
	},
	{
		path: '/dashboard/point-campaign-cost-report-for-destinations',
		page: PointCampaignCostReportForDestinations
	},
	{
		path: '/dashboard/destination-list/manage-destinations',
		page: ManageDestinationsPage
	},
	{
		path: '/dashboard/destination-list/manage-destinations/manage-accommodation',
		page: ManageAccommodationPage
	},
	{
		path: '/dashboard/brand-list',
		page: BrandListPage,
		routeGuard: manageBrandsPageGuard
	},
	{
		path: '/dashboard/brand-report',
		page: BrandReportListPage,
		routeGuard: manageBrandsPageGuard
	},
	{
		path: '/dashboard/brand-list/brand-details',
		page: BrandDetailsPage
	},
	{
		path: '/dashboard/brand-list/brand-details/location-details',
		page: LocationDetailsPage
	},
	{
		path: '/dashboard/manage-pages',
		page: ManageCompanyPagesPage
	},
	{
		path: '/forbidden',
		page: ForbiddenPage
	},
	{
		path: '/login/verify/expired',
		page: LoginVerifyExpiredPage
	},
	{
		path: '*',
		page: NotFoundPage
	}
];

export default routes;
(window as any).routes = routes;
