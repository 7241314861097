import * as React from 'react';
import './BrandDetailsPage.scss';
import { Box, Page, popupController } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import { ChangeEvent, useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelInput from '../../components/labelInput/LabelInput';
import Label from '@bit/redsky.framework.rs.label';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import UploadService from '../../services/upload/upload.service';
import router from '../../utils/router';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelButton from '../../components/labelButton/LabelButton';
import SpireTable from '../../components/spireTable/SpireTable';
import BrandLocationReportPopup, {
	BrandLocationReportPopupProps
} from '../../popups/brandLocationReportPopup/BrandLocationReportPopup';
import BrandService from '../../services/brand/brand.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';

interface BrandDetailsPageProps {}

const BrandDetailsPage: React.FC<BrandDetailsPageProps> = () => {
	const params = router.getPageUrlParams<{ brandId: number }>([
		{ key: 'bi', default: 0, type: 'integer', alias: 'brandId' }
	]);
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const uploadService = serviceFactory.get<UploadService>('UploadService');
	const [viewOnly, setViewOnly] = useState<boolean>(false); // TODO: update once permissions are set up
	const [brandDetails, setBrandDetails] = useState<Api.Brand.Res.Details>();
	const brandService = serviceFactory.get<BrandService>('BrandService');
	const [brandLocationsList, setBrandLocationsList] = useState<Api.Brand.Res.BrandLocation[]>([]);
	const [sortField, setSortField] = useState<string>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [brandLocationsTotal, setBrandLocationsTotal] = useState<number>(0);
	const [brandDetailsForm, setBrandDetailsForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('name', '', [new RsValidator(RsValidatorEnum.REQ, 'Brand name required')]),
			new RsFormControl('description', '', [
				new RsValidator(
					RsValidatorEnum.CUSTOM,
					'Please try and keep description to under 500 characters.',
					(control) => {
						return control.value.toString().length < 500;
					}
				)
			]),
			new RsFormControl('loyaltyStatus', '', []),
			new RsFormControl('isActive', 1, []),
			new RsFormControl('squareLogoUrl', '', []),
			new RsFormControl('wideLogoUrl', '', [])
		])
	);

	const [pointsForm, setPointsForm] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('pointsPerDollar', '', []), new RsFormControl('costPerPoint', '', [])])
	);
	const [logo, setLogo] = useState<string>('');
	const [heroLogo, setHeroLogo] = useState<string>('');

	useEffect(() => {
		async function getBrandDetails() {
			try {
				if (!params.brandId) return;
				const res = await brandService.getBrandDetails(params.brandId);
				setBrandDetails(res);
				brandDetailsForm.get('name').value = res.name || '';
				brandDetailsForm.get('description').value = res.description || '';
				brandDetailsForm.get('loyaltyStatus').value = res.loyaltyStatus || 'PENDING';
				brandDetailsForm.get('isActive').value = res.isActive ? 1 : 0;
				brandDetailsForm.get('squareLogoUrl').value = res.squareLogoUrl || '';
				brandDetailsForm.get('wideLogoUrl').value = res.wideLogoUrl || '';
				brandDetailsForm.updateInitialValues();
				setBrandDetailsForm(brandDetailsForm.clone());

				pointsForm.get('pointsPerDollar').value = res.pointsPerDollar || '';
				pointsForm.get('costPerPoint').value = res.costPerPoint || '';
				pointsForm.updateInitialValues();
				setPointsForm(pointsForm.clone());

				setHeroLogo(res.wideLogoUrl);
				setLogo(res.squareLogoUrl);
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
					'Server Error'
				);
			}
		}
		if (
			ObjectUtils.isArrayWithData(user?.businessAccesses.brandIds) ||
			ObjectUtils.isArrayWithData(user?.businessAccesses.companyIds)
		) {
			getBrandDetails().catch(console.error);
		} else {
			setViewOnly(true);
			getBrandDetails().catch(console.error);
		}
	}, [params.brandId]);

	async function uploadImage(file: ChangeEvent<HTMLInputElement>) {
		let image = file.currentTarget.files;
		if (!image) return;
		try {
			let response: any = await uploadService.uploadImage(image[0]);
			return response.data;
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function updateControl(control: RsFormControl) {
		setBrandDetailsForm(brandDetailsForm.clone().update(control));
	}

	function updatePointControl(control: RsFormControl) {
		setPointsForm(pointsForm.clone().update(control));
	}

	function getPointRate() {
		let costPerPoint = pointsForm.get('costPerPoint').value;
		let pointsPerDollar = pointsForm.get('pointsPerDollar').value;

		if (costPerPoint === '' && pointsPerDollar === '') return undefined;

		if ((costPerPoint === '' && pointsPerDollar !== '') || (costPerPoint !== '' && pointsPerDollar === '')) {
			rsToastify.error(
				'Points Per Dollar and Cost Per Points must both have information.',
				'Missing Information!'
			);
		}
		return { costPerPoint: Number(costPerPoint), pointsPerDollar: Number(pointsPerDollar) };
	}

	async function saveBrandDetails() {
		// if (!brandDetails || params.brandId) return;
		if (!(await brandDetailsForm.isValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}

		let brandDetailsChanges: Api.Brand.Req.Update = brandDetailsForm.toModel();
		let pointsChanges: Api.PointRate | undefined = getPointRate();

		if (pointsChanges?.costPerPoint === 0 || pointsChanges?.pointsPerDollar === 0) return;
		if (pointsChanges !== undefined) brandDetailsChanges.pointRate = pointsChanges;

		try {
			const res = await brandService.updateBrand({ ...brandDetailsChanges, id: params.brandId });
			setBrandDetails(res);
			brandDetailsForm.updateInitialValues();
			pointsForm.updateInitialValues();
			setBrandDetailsForm(brandDetailsForm.clone());
			setPointsForm(pointsForm.clone());
			rsToastify.success('Brand successfully updated', 'Success!');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unknown server error has occurred.'), 'Error!');
		}
	}

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await brandService.getLocationsByPage({ id: params.brandId, pageQuery });
			setBrandLocationsList(res.data);
			setBrandLocationsTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/brand-list/brand-details/location-details?li=' + data.id).catch(console.error);
	}

	function renderForm() {
		if (!brandDetails) return;
		return (
			<Box display={'flex'} flexDirection={'column'}>
				<div className={'brandDetailsForm'}>
					<LabelInput
						className={'fullWidthInput'}
						title={'Name'}
						labelVariant={'h2'}
						control={brandDetailsForm.get('name')}
						updateControl={updateControl}
						inputType={'text'}
						disabled={viewOnly}
					/>
					<LabelInput
						className={'fullWidthInput'}
						title={'Description'}
						inputType={'textarea'}
						labelVariant={'h2'}
						control={brandDetailsForm.get('description')}
						updateControl={updateControl}
						disabled={viewOnly}
					/>

					<div className={'formImageSection'}>
						<div className={'imgInput'}>
							<Label variant={'h2'}>Logo</Label>
							<Paper
								className={'brandDetailsLogoPaper'}
								padding={'10px'}
								width={'160px'}
								height={'160px'}
								borderRadius={'4px'}
								boxShadow
							>
								<div className={'logo'} style={{ backgroundImage: `url(${logo || ''})` }} />
								{!viewOnly && (
									<div className={'uploadWrapper'}>
										<input
											id={'LogoUpload'}
											type={'file'}
											className={'uploadInput'}
											accept="image/*"
											onChange={async (event) => {
												let imageUrl: any = await uploadImage(event);
												if (!imageUrl) return;
												setLogo(imageUrl.urls.large);
												const control = brandDetailsForm.get('squareLogoUrl');
												control.value = imageUrl.urls.large;
												updateControl(control);
											}}
										/>
										<label htmlFor={'LogoUpload'} className={'edit'}>
											<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
										</label>
									</div>
								)}
							</Paper>
						</div>
						<div className={'imgInput'}>
							<Label variant={'h2'}>Hero Image (Recommended Size: 1440px x 420px)</Label>
							<Paper
								className={'heroImagePaper'}
								padding={'10px'}
								width={'680px'}
								height={'160px'}
								borderRadius={'4px'}
								boxShadow
							>
								<div className={'heroImage'} style={{ backgroundImage: `url(${heroLogo || ''})` }} />

								{!viewOnly && (
									<div className={'uploadWrapper'}>
										<input
											id={'HeroImgUpload'}
											type={'file'}
											className={'uploadInput'}
											accept="image/*"
											onChange={async (event) => {
												let imageUrl: any = await uploadImage(event);
												if (!imageUrl) return;
												setHeroLogo(imageUrl.urls.large);
												const control = brandDetailsForm.get('wideLogoUrl');
												control.value = imageUrl.urls.large;
												updateControl(control);
											}}
										/>

										<label htmlFor={'HeroImgUpload'} className={'edit'}>
											<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
										</label>
									</div>
								)}
							</Paper>
						</div>
					</div>

					<Box display={'flex'} justifyContent={'space-between'}>
						<LabelSelect
							title={'Loyalty Status'}
							control={brandDetailsForm.get('loyaltyStatus')}
							updateControl={updateControl}
							options={[
								{ label: 'Active', value: 'ACTIVE' },
								{ label: 'Pending', value: 'PENDING' },
								{ label: 'Frozen', value: 'FROZEN' }
							]}
							isDisabled={viewOnly}
							variant={'h2'}
						/>
						<LabelSelect
							title={'Status'}
							control={brandDetailsForm.get('isActive')}
							updateControl={updateControl}
							options={[
								{ label: 'Active', value: 1 },
								{ label: 'Inactive', value: 0 }
							]}
							isDisabled={viewOnly}
							variant={'h2'}
						/>
						<LabelInput
							title={'Points Per Dollar'}
							inputType={'number'}
							labelVariant={'h2'}
							control={pointsForm.get('pointsPerDollar')}
							updateControl={updatePointControl}
							disabled={viewOnly}
						/>
						<LabelInput
							title={'Cost Per Point'}
							inputType={'number'}
							labelVariant={'h2'}
							control={pointsForm.get('costPerPoint')}
							updateControl={updatePointControl}
							disabled={viewOnly}
						/>
					</Box>
					{!viewOnly && (
						<Box display={'flex'} justifyContent={'flex-end'} marginTop={24}>
							<LabelButton
								className={'saveButton'}
								look={
									brandDetailsForm.isModified() || pointsForm.isModified()
										? 'containedPrimary'
										: 'containedSecondary'
								}
								disabled={!(brandDetailsForm.isModified() || pointsForm.isModified())}
								variant={'button'}
								label={'Save'}
								onClick={saveBrandDetails}
							/>
						</Box>
					)}
				</div>
			</Box>
		);
	}

	return (
		<Page className={'rsBrandDetailsPage'}>
			<Box className={'subNavContainer'}>
				<SubHeader
					header={brandDetails?.name || ''}
					fallbackPath={'/dashboard/brand-list'}
					crumbs={[
						{ label: 'Dashboard', link: '/dashboard' },
						{ label: 'Brand List', link: `/dashboard/brand-list` },
						{ label: 'Brand Details', link: `/dashboard/brand-list/brand-details?bi=${params.brandId}` }
					]}
				/>
				{!viewOnly && (
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Location Report'}
						onClick={() =>
							popupController.open<BrandLocationReportPopupProps>(BrandLocationReportPopup, {
								brandId: params.brandId
							})
						}
					/>
				)}
			</Box>
			{renderForm()}
			<div className={'locationsTable'}>
				<Label variant={'h2'}>Brand Locations</Label>
				<SpireTable
					table={{
						placeholder: 'Search by Name or Id',
						filterQuery: [
							{ column: 'name', value: '', conjunction: 'OR' },
							{ column: 'externalId', value: '', conjunction: 'OR' }
						]
					}}
					columns={[
						{
							id: 'externalId',
							label: 'Location ID',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'externalId' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'name',
							label: 'Location Name',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'name' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'isActive',
							label: 'Status',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'isActive' ? sortOrder : 'DESC',
							filterType: 'CHECKBOX',
							cellType: 'BOOLEAN',
							textOptions: {
								false: 'Inactive',
								true: 'Active'
							}
						},
						{
							id: 'address1',
							label: 'Address',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'address1' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'city',
							label: 'City',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'city' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'state',
							label: 'State',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'state' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'zip',
							label: 'Zip',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'zip' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'country',
							label: 'Country',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'country' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						}
					]}
					data={brandLocationsList}
					total={brandLocationsTotal}
					sortField={sortField}
					onGetData={getData}
					rowOnClick={handleTableClick}
					changeSort={changeSort}
				/>
			</div>
		</Page>
	);
};
export default BrandDetailsPage;
