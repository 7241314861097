import * as React from 'react';
import './UserTransactionList.scss';
import Box from '../box/Box';
import SpireTable from '../spireTable/SpireTable';
import { StringUtils, WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import UserPointService from '../../services/userPoint/userPoint.service';

interface UserTransactionListProps {
	userId: number;
	pendingOnly?: boolean;
	completedOnly?: boolean;
}

const UserTransactionList: React.FC<UserTransactionListProps> = (props) => {
	const pointService = serviceFactory.get<UserPointService>('UserPointService');
	const [sortField, setSortField] = useState<string>('userPoint.createdOn');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [pointTransactions, setPointTransactions] = useState<Api.UserPoint.Res.Get[]>([]);
	const [pointTransactionsTotal, setPointTransactionsTotal] = useState<number>(0);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			if (props.pendingOnly) {
				pageQuery.filter = {
					matchType: 'like',
					searchTerm: [
						{ column: 'userPoint.status', value: 'PENDING', conjunction: 'AND', matchType: 'exact' }
					]
				};
			}
			if (props.completedOnly) {
				pageQuery.filter = {
					matchType: 'like',
					searchTerm: [
						{ column: 'userPoint.status', value: 'RECEIVED', conjunction: 'OR', matchType: 'exact' },
						{ column: 'userPoint.status', value: 'REVOKED', conjunction: 'OR', matchType: 'exact' },
						{ column: 'userPoint.status', value: 'EXPIRED', conjunction: 'OR', matchType: 'exact' },
						{ column: 'userPoint.status', value: 'REDEEMED', conjunction: 'OR', matchType: 'exact' },
						{ column: 'userPoint.status', value: 'CANCELED', conjunction: 'OR', matchType: 'exact' },
						{ column: 'userPoint.status', value: 'REFUNDED', conjunction: 'OR', matchType: 'exact' }
					]
				};
			}
			const res = await pointService.getUserTransactionsByPage({ userId: props.userId, pageQuery });
			setPointTransactions(res.data);
			setPointTransactionsTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handlePointAmount(transaction: any): string {
		let amount: number = transaction.pointAmount;
		switch (transaction.status) {
			case 'EXPIRED':
			case 'REVOKED':
			case 'REDEEMED':
				amount *= -1;
				break;
			default:
				break;
		}
		return StringUtils.addCommasToNumber(amount);
	}

	return (
		<Box className={'rsUserTransactionList'}>
			<SpireTable
				table={{
					placeholder: '',
					filterQuery: [{ column: 'transaction.createdOn', value: '' }]
				}}
				columns={[
					{
						id: 'title',
						label: 'Transaction',
						align: 'left',
						className: 'userTransactionCell',
						sort: sortField === 'title' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'createdOn',
						label: 'Created On',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'createdOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},
					{
						id: 'pointType',
						label: 'Type',
						align: 'left',
						className: 'userTransactionCell',
						sort: sortField === 'pointType' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'campaign',
						label: 'Campaign',
						align: 'left',
						className: 'userTransactionCell',
						sort: sortField === 'campaign' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'pointAmount',
						label: 'Point Amount',
						align: 'left',
						className: 'userTransactionCell',
						sort: sortField === 'pointAmount' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'HANDLER',
						handler: handlePointAmount
					},
					{
						id: 'campaignId',
						label: 'CampaignId',
						align: 'left',
						className: 'userTransactionCell',
						sort: sortField === 'campaignId' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'status',
						label: 'Status',
						align: 'left',
						className: 'userTransactionCell',
						sort: sortField === 'status' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					}
				]}
				data={pointTransactions}
				total={pointTransactionsTotal}
				sortField={sortField}
				onGetData={getData}
				rowOnClick={() => {}}
				changeSort={changeSort}
			/>
		</Box>
	);
};

export default UserTransactionList;
