import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { WebUtils } from '../../utils/utils';
import RsPagedResponseData = RedSky.RsPagedResponseData;

export default class BrandService extends Service {
	async getBrandLocations(id: number): Promise<Api.Brand.Res.BrandLocation[]> {
		const response = await http.get<RsResponseData<Api.Brand.Res.BrandLocation[]>>('brand/location', { id });
		return response.data.data;
	}

	async getAllBrands(): Promise<Api.Brand.Res.Get[]> {
		const response = await http.get<RsResponseData<Api.Brand.Res.Get[]>>('brand');
		return response.data.data;
	}

	async getLocationDetails(id: number) {
		const response = await http.get<RsResponseData<Api.Brand.Res.Location.Details>>('brand/location/details', {
			id
		});
		return response.data.data;
	}

	async getByPage(pageQuery: RedSky.PageQuery): Promise<RsPagedResponseData<Api.Brand.Res.Details[]>> {
		const response = await http.get<RsPagedResponseData<Api.Brand.Res.Details[]>>('brand/paged', pageQuery);
		return response.data;
	}

	async getReportsByPage(pageQuery: RedSky.PageQuery): Promise<RsResponseData<Api.Brand.Res.Report[]>> {
		const response = await http.get<RsResponseData<Api.Brand.Res.Report[]>>(
			'brand/reports',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async getLocationsOverviewByPage(
		id: number,
		pageQuery: RedSky.PageQuery
	): Promise<RsPagedResponseData<Api.Brand.Res.Report[]>> {
		const response = await http.get<RsPagedResponseData<Api.Brand.Res.Report[]>>('brand/location/reports', {
			id,
			pageQuery
		});
		return response.data;
	}

	async exportLocationsOverviewReport(id: number): Promise<string> {
		const response = await http.get<string>('brand/location/reports/export', { id });
		return response.data;
	}

	async getBrandDetails(id: number): Promise<Api.Brand.Res.Details> {
		const response = await http.get<RsResponseData<Api.Brand.Res.Details>>('brand/details', { id });
		return response.data.data;
	}

	async getLocationsByPage(data: Api.Brand.Req.Report): Promise<any> {
		const response = await http.get('brand/location/paged', data);
		return response.data;
	}

	async exportBrandsReport(): Promise<string> {
		const response = await http.get<string>('brand/reports/export');
		return response.data;
	}

	async exportBrandLocationReport(id: Api.Brand.Req.Location.Get): Promise<string> {
		const response = await http.get<string>('brand/location/transactions/export', id);
		return response.data;
	}

	async getBrandLocationTransactionsByPage(
		pageQuery: Api.Brand.Req.Location.Report
	): Promise<RedSky.RsPagedResponseData<Api.Brand.Res.Location.Transaction[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Brand.Res.Location.Transaction[]>>(
			'brand/location/transactions',
			pageQuery
		);
		return response.data;
	}

	async updateBrand(data: Api.Brand.Req.Update): Promise<Api.Brand.Res.Details> {
		const response = await http.patch<Api.Brand.Res.Details>('/brand', data);
		return response.data;
	}

	async updateLocation(data: Api.Brand.Req.Location.Update): Promise<Api.Brand.Res.Location.Details> {
		const response = await http.patch<Api.Brand.Res.Location.DetailsData>('/brand/location', data);
		return response.data.data;
	}
}
