import React from 'react';
import './PaymentCard.scss';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { Link } from '@bit/redsky.framework.rs.996';
import Paper from '../paper/Paper';
import Box from '../box/Box';
import LabelButton from '../labelButton/LabelButton';

export interface paymentCardProps {
	className?: string;
	name: string;
	cardType: string;
	lastFourCreditCard: string;
	expDate: string;
	selected?: boolean;
	onDelete: () => void;
	onSetPrimaryCard?: () => void;
}

const PaymentCard: React.FC<paymentCardProps> = (props) => {
	return (
		<Paper
			className="rsPaymentCard"
			boxShadow={props.selected}
			height={'fit-content'}
			padding={'10px'}
			backgroundColor={'#fcfbf8'}
		>
			<Box className="paymentPersonalInfoTop">
				<Box className="paymentPersonalInfo">
					<Label className="paymentName" variant={'h4'}>
						{props.name}
					</Label>
					<Label className={'paymentType'} variant={'h4'}>
						{props.cardType.toUpperCase()}
					</Label>
					<Label className="paymentCardNumber" variant={'subtitle2'}>
						**** **** **** {props.lastFourCreditCard}
					</Label>
					<Label className="paymentCardExpDate" variant={'subtitle2'}>
						{props.expDate}
					</Label>
				</Box>
				<Box className="paymentActions">
					<LabelButton
						className="editLink"
						look={'none'}
						variant={'h4'}
						label={'Delete'}
						onClick={props.onDelete}
					/>
				</Box>
			</Box>
			<Box className="paymentButtonBox">
				{!props.selected && (
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Set Primary Card'}
						onClick={props.onSetPrimaryCard}
					/>
				)}
			</Box>
		</Paper>
	);
};
export default PaymentCard;
