import * as React from 'react';
import './AccommodationTile.scss';
import Paper from '../paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import { Link } from '@bit/redsky.framework.rs.996';

interface AccommodationTileProps {
	accommodationId: number;
	title: string;
	description: string;
	path: string;
}

const AccommodationTile: React.FC<AccommodationTileProps> = (props) => {
	return (
		<Link path={props.path}>
			<Paper className={'rsAccommodationTile'} borderRadius={'4px'} padding={'10px 25px'}>
				<Label variant={'h2'}>{props.title}</Label>
				<Label variant={'body1'}>{props.description}</Label>
			</Paper>
		</Link>
	);
};

export default AccommodationTile;
