import * as React from 'react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import './CreateEditCompanyPage.scss';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import Switch from '@bit/redsky.framework.rs.switch';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import UploadService from '../../services/upload/upload.service';
import SpinningLoaderPopup, { SpinningLoaderPopupProps } from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import router from '../../utils/router';
import CompanyService from '../../services/company/company.service';
import { US_States, WebUtils } from '../../utils/utils';
import Button from '@bit/redsky.framework.rs.button';
import Input from '@bit/redsky.framework.rs.input';
import globalState from '../../state/globalState';
import { useRecoilState, useRecoilValue } from 'recoil';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';

interface CreateEditCompanyPageProps {}

enum ControlKeys {
	NAME = 'name',
	DESCRIPTION = 'description',
	SQUARE_LOGO_URL = 'squareLogoUrl',
	WIDE_LOGO_URL = 'wideLogoUrl',
	VANITY_URLS = 'vanityUrls',
	NEW_URL = 'newUrl',
	PRIVACY_POLICY_URL = 'privacyPolicyUrl',
	TERMS_CONDITIONS_URL = 'termsConditionsUrl',
	RETURN_POLICY_URL = 'returnPolicyUrl',
	ADDRESS = 'address',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
	COUNTRY = 'country',
	NEW_ADMIN_EMAIL = 'newAdminEmail',
	NEW_ADMIN_PASSWORD = 'newAdminPassword',
	LOYALTY_STATUS = 'loyaltyStatus',
	STATUS = 'isActive'
}

const CreateEditCompanyPage: React.FC<CreateEditCompanyPageProps> = () => {
	const uploadService = serviceFactory.get<UploadService>('UploadService');
	const companyService = serviceFactory.get<CompanyService>('CompanyService');
	const [usersCompany, setUsersCompany] = useRecoilState<Api.Company.Res.Get | undefined>(globalState.company);
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const [viewOnly, setViewOnly] = useState<boolean>(true);
	const params = router.getPageUrlParams<{ companyId: number }>([
		{ key: 'ci', default: -1, type: 'integer', alias: 'companyId' }
	]);
	const isNewCompany = params.companyId === -1;

	const [newUrlInputControl, setNewUrlInputControl] = useState<RsFormControl>(
		new RsFormControl(ControlKeys.NEW_URL, '')
	);

	const [form, setForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(ControlKeys.DESCRIPTION, ''),
			new RsFormControl(ControlKeys.NAME, '', [new RsValidator(RsValidatorEnum.REQ, 'Required')]),
			new RsFormControl(ControlKeys.SQUARE_LOGO_URL, ''),
			new RsFormControl(ControlKeys.WIDE_LOGO_URL, ''),
			new RsFormControl(ControlKeys.VANITY_URLS, []),
			new RsFormControl(ControlKeys.PRIVACY_POLICY_URL, ''),
			new RsFormControl(ControlKeys.TERMS_CONDITIONS_URL, ''),
			new RsFormControl(ControlKeys.RETURN_POLICY_URL, ''),
			new RsFormControl(ControlKeys.ADDRESS, ''),
			new RsFormControl(ControlKeys.CITY, ''),
			new RsFormControl(ControlKeys.STATE, ''),
			new RsFormControl(ControlKeys.ZIP, ''),
			new RsFormControl(ControlKeys.COUNTRY, ''),
			new RsFormControl(ControlKeys.LOYALTY_STATUS, ''),
			new RsFormControl(ControlKeys.STATUS, ''),
			new RsFormControl(
				ControlKeys.NEW_ADMIN_EMAIL,
				'',
				isNewCompany
					? [
							new RsValidator(RsValidatorEnum.REQ, 'Required'),
							new RsValidator(RsValidatorEnum.EMAIL, 'Must be a valid email')
					  ]
					: []
			),
			new RsFormControl(
				ControlKeys.NEW_ADMIN_PASSWORD,
				'',
				isNewCompany ? [new RsValidator(RsValidatorEnum.REQ, 'Required')] : []
			)
		])
	);

	const [pointsForm, setPointsForm] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('pointsPerDollar', '', []), new RsFormControl('costPerPoint', '', [])])
	);

	useEffect(() => {
		if (user?.businessAccesses) {
			if (user?.businessAccesses.userBusinessLevel) setViewOnly(false);
		}
		if (isNewCompany) return;
		async function getCompanyDetails() {
			try {
				let company = await companyService.getCompanyDetailsById(params.companyId);
				let updatedForm = form.clone();
				updatedForm.get(ControlKeys.NAME).value = company.name;
				updatedForm.get(ControlKeys.DESCRIPTION).value = company.description || '';
				updatedForm.get(ControlKeys.SQUARE_LOGO_URL).value = company.squareLogoUrl || '';
				updatedForm.get(ControlKeys.WIDE_LOGO_URL).value = company.wideLogoUrl || '';
				updatedForm.get(ControlKeys.VANITY_URLS).value = company.vanityUrls || [];
				updatedForm.get(ControlKeys.PRIVACY_POLICY_URL).value = company.privacyPolicyUrl || '';
				updatedForm.get(ControlKeys.TERMS_CONDITIONS_URL).value = company.termsConditionsUrl || '';
				updatedForm.get(ControlKeys.RETURN_POLICY_URL).value = company.returnPolicyUrl || '';
				updatedForm.get(ControlKeys.ADDRESS).value = company.address || '';
				updatedForm.get(ControlKeys.CITY).value = company.city || '';
				updatedForm.get(ControlKeys.STATE).value = company.state || '';
				updatedForm.get(ControlKeys.ZIP).value = company.zip || '';
				updatedForm.get(ControlKeys.COUNTRY).value = company.country || '';
				updatedForm.get(ControlKeys.LOYALTY_STATUS).value = company.loyaltyStatus || '';
				updatedForm.get(ControlKeys.STATUS).value = company.isActive || '';
				updatedForm.updateInitialValues();
				setForm(updatedForm);

				let updatedPointAndStatusForm = pointsForm.clone();
				updatedPointAndStatusForm.get('pointsPerDollar').value = company.pointsPerDollar || '';
				updatedPointAndStatusForm.get('costPerPoint').value = company.costPerPoint || '';
				updatedPointAndStatusForm.updateInitialValues();
				setPointsForm(updatedPointAndStatusForm);
				console.log('company', company);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed Getting Company'), 'Server Error');
			}
		}
		getCompanyDetails().catch(console.error);
	}, [params.companyId, isNewCompany]);

	async function uploadImage(file: ChangeEvent<HTMLInputElement>) {
		let images = file.currentTarget.files;
		if (!images || !images[0]) return;

		try {
			popupController.open<SpinningLoaderPopupProps>(SpinningLoaderPopup, {});
			let response: any = await uploadService.uploadImage(images[0]);
			popupController.close(SpinningLoaderPopup);
			return response.data;
		} catch (e) {
			popupController.close(SpinningLoaderPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Upload Error Try Again.'), 'Upload Error');
		}
	}

	async function saveCompany() {
		let update = form.toChangedModel<Api.Company.Req.Update>();
		update.id = params.companyId;
		let points = pointsForm.toChangedModel<Api.PointRate>();
		if (Object.keys(points).length > 0)
			update.pointRate = {
				pointsPerDollar: Number(pointsForm.get('pointsPerDollar').value),
				costPerPoint: Number(pointsForm.get('costPerPoint').value)
			};
		try {
			let updatedCompany = await companyService.update(update);
			if (usersCompany && usersCompany.id === update.id) setUsersCompany(updatedCompany);
			rsToastify.success('Successfully Updated Company.', 'Update Complete');
			router.navigate('/dashboard/company-list').catch(console.error);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Failed Updating');
		}
	}

	async function createCompany() {
		let newCompany = form.toChangedModel<Api.Company.Req.Create>();
		// let newCompany = form.toModel<Api.Company.Req.Create>();
		console.log('newCompany', newCompany);
		let pointsChanges: Api.PointRate | undefined = getPointRate();

		if (pointsChanges?.costPerPoint === 0 || pointsChanges?.pointsPerDollar === 0) return;
		if (pointsChanges !== undefined) newCompany.pointRate = pointsChanges;
		console.log('newCompany with points', newCompany);
		try {
			popupController.open<SpinningLoaderPopupProps>(SpinningLoaderPopup, {});
			await companyService.create(newCompany);
			popupController.close(SpinningLoaderPopup);
			rsToastify.success('Successfully Created Company', 'Update Complete');
			router.navigate('/dashboard/company-list').catch(console.error);
		} catch (e) {
			popupController.close(SpinningLoaderPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Failed Updating');
		}
	}

	function getPointRate() {
		let costPerPoint = pointsForm.get('costPerPoint').value;
		let pointsPerDollar = pointsForm.get('pointsPerDollar').value;

		if (costPerPoint === '' && pointsPerDollar === '') return undefined;

		if ((costPerPoint === '' && pointsPerDollar !== '') || (costPerPoint !== '' && pointsPerDollar === '')) {
			rsToastify.error(
				'Points Per Dollar and Cost Per Points must both have information.',
				'Missing Information!'
			);
		}
		return { costPerPoint: Number(costPerPoint), pointsPerDollar: Number(pointsPerDollar) };
	}

	async function createOrSave() {
		if (!(await form.isValid())) {
			rsToastify.error('Please fix invalid inputs', 'Invalid Inputs');
			setForm(form.clone());
			return;
		}
		if (!isNewCompany) await saveCompany();
		else await createCompany();
	}

	function renderLogos() {
		return (
			<Box display={'flex'} marginBottom={'25px'}>
				<div>
					<Label variant={'h2'}>Square Logo</Label>
					<Paper
						className={'logoPaper'}
						padding={'10px'}
						width={'300px'}
						height={'200px'}
						borderRadius={'4px'}
						boxShadow
					>
						<Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
							<div
								className={'squareLogoImage'}
								style={{
									backgroundImage: `url(${form.get(ControlKeys.SQUARE_LOGO_URL).value.toString()})`
								}}
							/>
						</Box>
						<div className={'uploadWrapper'}>
							<input
								id={'SquareLogoUpload'}
								type={'file'}
								className={'uploadInput'}
								accept="image/*"
								onChange={async (event) => {
									let imageUrl: any = await uploadImage(event);
									if (!imageUrl) return;
									let updatedForm = form.clone();
									updatedForm.get(ControlKeys.SQUARE_LOGO_URL).value = imageUrl.urls.large;
									setForm(updatedForm);
								}}
							/>
							<label htmlFor={'SquareLogoUpload'} className={'edit'}>
								<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
							</label>
						</div>
					</Paper>
				</div>
				<Box marginLeft={'auto'}>
					<Label variant={'h2'}>Wide Logo</Label>
					<Paper
						className={'logoPaper'}
						padding={'10px'}
						width={'800px'}
						height={'200px'}
						borderRadius={'4px'}
						boxShadow
					>
						<div
							className={'wideLogoImage'}
							style={{ backgroundImage: `url(${form.get(ControlKeys.WIDE_LOGO_URL).value.toString()})` }}
						/>

						<div className={'uploadWrapper'}>
							<input
								id={'WideLogoImgUpload'}
								type={'file'}
								className={'uploadInput'}
								accept="image/*"
								onChange={async (event) => {
									let imageUrl: any = await uploadImage(event);
									if (!imageUrl) return;
									let updatedForm = form.clone();
									updatedForm.get(ControlKeys.WIDE_LOGO_URL).value = imageUrl.urls.large;
									setForm(updatedForm);
								}}
							/>
							<label htmlFor={'WideLogoImgUpload'} className={'edit'}>
								<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
							</label>
						</div>
					</Paper>
				</Box>
			</Box>
		);
	}

	function addUrl(e: FormEvent) {
		e.preventDefault();

		let newUrl = newUrlInputControl.value.toString();
		if (!newUrl) {
			rsToastify.error('Must be a valid domain.', 'Invalid domain!');
			return;
		}
		if (newUrl.includes('http')) {
			rsToastify.error('Please remove http', 'Remove http!');
			return;
		}

		let updatedForm = form.clone();
		updatedForm.get(ControlKeys.VANITY_URLS).value = [
			...(updatedForm.get(ControlKeys.VANITY_URLS).value as string[]),
			newUrl
		];
		setForm(updatedForm);
		newUrlInputControl.resetToInitial();
		setNewUrlInputControl(newUrlInputControl);
	}

	function renderVanityUrls() {
		const vanityUrls = form.get(ControlKeys.VANITY_URLS).value as string[];
		return (
			<>
				<Label variant={'h2'} mb={16}>
					{'Incoming URLs'}
				</Label>
				{vanityUrls.map((url) => {
					return (
						<Box display={'flex'} key={url} marginBottom={8}>
							<Label variant={'subtitle1'}>{url}</Label>
							<Label
								variant={'h5'}
								className={'removeUrl'}
								onClick={() => {
									let updatedUrls = vanityUrls.filter((filterUrl) => {
										return url !== filterUrl;
									});
									let updatedForm = form.clone();
									updatedForm.get(ControlKeys.VANITY_URLS).value = updatedUrls;
									setForm(updatedForm);
								}}
							>
								X
							</Label>
						</Box>
					);
				})}
				{vanityUrls.length === 0 && <Label variant={'subtitle1'}>--- No URLs! ---</Label>}
				<form action={'#'} onSubmit={addUrl}>
					<Box className={'addUrlInputArea'}>
						<Input
							look={'none'}
							control={newUrlInputControl}
							updateControl={(control) => setNewUrlInputControl(control)}
							placeholder={'subdomain.domain.com'}
							type={'text'}
							unStyled
						/>
						<Button look={'containedSecondary'} onClick={addUrl}>
							Add
						</Button>
					</Box>
				</form>
			</>
		);
	}

	function renderPolicyLinks() {
		return (
			<>
				<Label variant={'h2'}>Policy Links</Label>
				<Box display={'flex'} className={'policyLinks'}>
					<Box>
						<Label variant={'h4'}>Privacy</Label>
						<Input
							look={'none'}
							unStyled
							control={form.get(ControlKeys.PRIVACY_POLICY_URL)}
							updateControl={(control) => setForm(form.clone().update(control))}
							type={'text'}
						/>
					</Box>
					<Box>
						<Label variant={'h4'}>Terms & Conditions</Label>
						<Input
							look={'none'}
							unStyled
							control={form.get(ControlKeys.TERMS_CONDITIONS_URL)}
							updateControl={(control) => setForm(form.clone().update(control))}
							type={'text'}
						/>
					</Box>
					<Box>
						<Label variant={'h4'}>Return</Label>
						<Input
							look={'none'}
							unStyled
							control={form.get(ControlKeys.RETURN_POLICY_URL)}
							updateControl={(control) => setForm(form.clone().update(control))}
							type={'text'}
						/>
					</Box>
				</Box>
			</>
		);
	}

	function formatStates() {
		return US_States.map((state) => {
			return { value: state.value, label: state.text };
		});
	}

	function renderAddressInfo() {
		return (
			<Box className={'addressInputs'}>
				<Label variant={'h2'}>Company Address</Label>
				<Box marginBottom={30}>
					<Label variant={'h4'}>Address</Label>
					<Input
						type={'text'}
						unStyled
						look={'none'}
						control={form.get('address')}
						updateControl={(control) => setForm(form.clone().update(control))}
					/>
				</Box>
				<Box className={'cityStateZip'}>
					<Box marginBottom={30}>
						<Label variant={'h4'}>City</Label>
						<Input
							noAutocomplete
							placeholder={'City'}
							type={'text'}
							look={'none'}
							unStyled
							control={form.get('city')}
							updateControl={(control) => setForm(form.clone().update(control))}
						/>
					</Box>
					<Box marginBottom={30}>
						<LabelSelect
							variant={'h4'}
							title={'State'}
							options={formatStates()}
							control={form.get(ControlKeys.STATE)}
							updateControl={(control) => setForm(form.clone().update(control))}
						/>
					</Box>
					<Box marginBottom={30}>
						<Label variant={'h4'}>Zip</Label>
						<Input
							type={'text'}
							look={'none'}
							unStyled
							control={form.get('zip')}
							updateControl={(control) => setForm(form.clone().update(control))}
						/>
					</Box>
				</Box>
			</Box>
		);
	}

	function renderNewAdmin() {
		return (
			<Box marginBottom={24}>
				<Label variant={'h2'} mb={10}>
					New Admin
				</Label>
				<Box className={'newAdminInputs'}>
					<Box mr={24}>
						<Input
							look={'none'}
							control={form.get(ControlKeys.NEW_ADMIN_EMAIL)}
							updateControl={(control) => setForm(form.clone().update(control))}
							placeholder={'admin email'}
							type={'text'}
							unStyled
						/>
					</Box>
					<Box>
						<Input
							look={'none'}
							control={form.get(ControlKeys.NEW_ADMIN_PASSWORD)}
							updateControl={(control) => setForm(form.clone().update(control))}
							placeholder={'admin password'}
							type={'text'}
							unStyled
						/>
					</Box>
				</Box>
			</Box>
		);
	}

	function updateControl(control: RsFormControl) {
		setPointsForm(pointsForm.clone().update(control));
	}

	const pointAwardToggleCallBack = async (data: any) => {
		popupController.open(SpinningLoaderPopup, {});
		try {
			let update = form.toChangedModel<Api.Company.Req.Update>();
			update.id = params.companyId;
			update.isPointAward = data;
			let points = pointsForm.toChangedModel<Api.PointRate>();
			if (Object.keys(points).length > 0)
				update.pointRate = {
					pointsPerDollar: Number(pointsForm.get('pointsPerDollar').value),
					costPerPoint: Number(pointsForm.get('costPerPoint').value)
				};

			let updatedCompany = await companyService.update(update);
			if (usersCompany && usersCompany.id === update.id) setUsersCompany(updatedCompany);
			rsToastify.success('Successfully Updated Company.', 'Update Complete');
			popupController.close(SpinningLoaderPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'), 'Server Error');
			popupController.closeAll();
		}
	};

	function renderInstallmentToggle() {
		return (
			<>
				<Label variant={'h2'} marginTop={'24px'}>
					Point Award
				</Label>
				<Switch
					className={`toggleButton`}
					label={'{"Off":"On" }'}
					checked={usersCompany?.isPointAward}
					onChange={pointAwardToggleCallBack}
				/>
			</>
		);
	}

	function renderStatusPoints() {
		if (params.companyId === 0) return <></>;
		return (
			<Box display={'flex'} justifyContent={'space-between'}>
				<LabelSelect
					title={'Loyalty Status'}
					control={form.get('loyaltyStatus')}
					updateControl={(control) => setForm(form.clone().update(control))}
					options={[
						{ label: 'Active', value: 'ACTIVE' },
						{ label: 'Pending', value: 'PENDING' },
						{ label: 'Frozen', value: 'FROZEN' }
					]}
					isDisabled={viewOnly}
					variant={'h4'}
				/>
				<div>{!isNewCompany && renderInstallmentToggle()}</div>
				<LabelSelect
					title={'Status'}
					control={form.get('isActive')}
					updateControl={(control) => setForm(form.clone().update(control))}
					options={[
						{ label: 'Active', value: 1 },
						{ label: 'Inactive', value: 0 }
					]}
					isDisabled={viewOnly}
					variant={'h4'}
				/>
				<LabelInput
					title={'Points Per Dollar'}
					inputType={'number'}
					labelVariant={'h4'}
					control={pointsForm.get('pointsPerDollar')}
					updateControl={updateControl}
					disabled={viewOnly}
				/>
				<LabelInput
					title={'Cost Per Point'}
					inputType={'number'}
					labelVariant={'h4'}
					control={pointsForm.get('costPerPoint')}
					updateControl={updateControl}
					disabled={viewOnly}
				/>
			</Box>
		);
	}

	return (
		<Page className={'rsCreateEditCompanyPage'}>
			<SubHeader
				header={isNewCompany ? 'Create New Company' : 'Manage Company'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Company List', link: '/dashboard/company-list' },
					{ label: 'Manage Company', link: '/dashboard/company-list/manage-company' }
				]}
			/>
			{renderLogos()}
			<LabelInput
				title={'Name'}
				control={form.get(ControlKeys.NAME)}
				updateControl={(control) => setForm(form.clone().update(control))}
				inputType={'text'}
			/>
			{isNewCompany && renderNewAdmin()}
			{renderVanityUrls()}
			<LabelInput
				title={'Description'}
				control={form.get(ControlKeys.DESCRIPTION)}
				updateControl={(control) => setForm(form.clone().update(control))}
				inputType={'textarea'}
			/>
			{renderPolicyLinks()}
			{renderAddressInfo()}
			{renderStatusPoints()}
			<LabelButton
				className={'saveButton'}
				look={form.isModified() || pointsForm.isModified() ? 'containedPrimary' : 'containedSecondary'}
				variant={'button'}
				label={isNewCompany ? 'Create' : 'Save'}
				disabled={!(form.isModified() || pointsForm.isModified())}
				onClick={createOrSave}
			/>
		</Page>
	);
};

export function createEditCompanyPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.COMPANY_WRITE]);
}

export default CreateEditCompanyPage;
