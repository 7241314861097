import { Box, Page } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import * as React from 'react';
import { useEffect, useState } from 'react';
import LabelData from '../../components/labelData/LabelData';
import SubHeader from '../../components/subHeader/SubHeader';
import serviceFactory from '../../services/serviceFactory';
import router from '../../utils/router';
import { WebUtils } from '../../utils/utils';
import './ManageUserPointsPage.scss';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';

import UserService from '../../services/user/user.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import UserTransactionList from '../../components/userTransactionList/UserTransactionList';

const ManageUserPointsPage: React.FC = () => {
	const userService = serviceFactory.get<UserService>('UserService');
	const hasPermissionToEditPoints: boolean = serviceFactory
		.get<PermissionService>('PermissionService')
		.hasPermission(PermissionList.POINTS_WRITE);
	const [selectedUser, setSelectedUser] = useState<Api.User.Res.Get>();
	const params = router.getPageUrlParams<{ userId: number }>([
		{ key: 'ui', default: 0, type: 'integer', alias: 'userId' }
	]);

	useEffect(() => {
		async function getUserDetails(id: number) {
			try {
				let userDetails = await userService.getUserById(id);
				setSelectedUser(userDetails);
			} catch (e) {
				console.error(e);
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error retrieving user.'), 'Server Error');
			}
		}
		getUserDetails(params.userId).catch(console.error);
	}, []);

	return (
		<Page className="rsManageUserPointsPage">
			{hasPermissionToEditPoints && (
				<SubHeader
					header="Manage Points"
					buttonOptions={[
						{
							name: 'Award Points',
							path: `/dashboard/user-list/manage-user/manage-points/update-points?ui=${params.userId}&award=1`
						},
						{
							name: 'Remove Points',
							path: `/dashboard/user-list/manage-user/manage-points/update-points?ui=${params.userId}&award=0`
						}
					]}
					crumbs={[
						{ label: 'Dashboard', link: `/dashboard` },
						{ label: 'User List', link: `/dashboard/user-list` },
						{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${params.userId}` },
						{
							label: 'Manage Points',
							link: `/dashboard/user-list/manage-user/manage-points?ui=${params.userId}`
						}
					]}
				/>
			)}
			<Box className={'grid'} display={'grid'}>
				<LabelData title={'Current Points'} subTitle={selectedUser?.availablePoints || 0} largeData />
				<LabelData title={'Pending Points'} subTitle={selectedUser?.pendingPoints || 0} largeData />
				<LabelData title={'Lifetime Points'} subTitle={selectedUser?.lifeTimePoints || 0} largeData />
			</Box>
			<Box className="transactionSection ">
				<Label variant="h1">Pending Transactions</Label>
				<UserTransactionList userId={params.userId} pendingOnly={true} />

				<Label variant="h1">Completed Transactions</Label>
				<UserTransactionList userId={params.userId} completedOnly={true} />
			</Box>
		</Page>
	);
};
export default ManageUserPointsPage;

export function manageUserPointsPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').hasPermission(PermissionList.USER_POINTS_READ);
}
