import React, { FC, ReactNode } from 'react';
import { Droppable } from 'react-beautiful-dnd';

const Drop = (props: any) => {
	return (
		<Droppable droppableId={props.id.toString()} type={props.type}>
			{(provided: any) => {
				return (
					<div ref={provided.innerRef} {...provided.droppableProps} {...props}>
						{props.children}
						{provided.placeholder}
					</div>
				);
			}}
		</Droppable>
	);
};

export default Drop;
