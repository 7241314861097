import * as React from 'react';
import './BrandLocationTransactionList.scss';
import Box from '../box/Box';
import LabelSelect from '../labelSelect/LabelSelect';
import DateRangeSelector from '../dateRangeSelector/DateRangeSelector';
import Label from '@bit/redsky.framework.rs.label';
import SpireTable from '../spireTable/SpireTable';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { DateUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelButton from '../labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import BrandService from '../../services/brand/brand.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';

interface BrandLocationTransactionListProps {
	locationId: number;
	costPerPoint: number;
	pointsAccrued: number;
	locationName: string;
	pointRedeemed: number;
}

const BrandLocationTransactionList: React.FC<BrandLocationTransactionListProps> = (props) => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const brandService = serviceFactory.get<BrandService>('BrandService');
	const [locationTransactionList, setLocationTransactionList] = useState<Api.Brand.Res.Location.Transaction[]>([]);
	const [locationTransactionTotal, setLocationTransactionTotal] = useState<number>(0);
	const [sortField, setSortField] = useState<string>('transaction.createdOn');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
	const [startDateControl, setStartDateControl] = useState<moment.Moment | null>(moment().startOf('month'));
	const [endDateControl, setEndDateControl] = useState<moment.Moment | null>(moment().endOf('month'));
	const [pointsHandlerControlr, setpointsHandlerControlr] = useState<number>(2);
	const [transactionTableForm, setTransactionTableForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('tableDisplay', 2, [new RsValidator(RsValidatorEnum.REQ, 'Field is Required.')])
		])
	);

	useEffect(() => {
		const pageQuery: RedSky.PageQuery = {
			pagination: {
				page: 1,
				perPage: 10
			},
			sort: {
				field: sortField,
				order: sortOrder
			},
			filter: {
				matchType: 'like',
				searchTerm: [
					{
						column: 'transaction.createdOn',
						value: DateUtils.formatFilterDateForServer(startDateControl, 'start'),
						conjunction: 'OR',
						matchType: 'greaterThanEqual'
					},
					{
						column: 'transaction.createdOn',
						value: DateUtils.formatFilterDateForServer(endDateControl, 'end'),
						matchType: 'lessThanEqual'
					}
				]
			}
		};
		getData(pageQuery).catch(console.error);
	}, [endDateControl, startDateControl]);

	async function getData(pageQuery: RedSky.PageQuery) {
		if (pageQuery.filter?.searchTerm.length) {
			try {
				const res = await brandService.getBrandLocationTransactionsByPage({
					id: props.locationId,
					pageQuery: pageQuery
				});
				let transactionsList = res.data;
				transactionsList = transactionsList.map((transaction) => {
					return { ...transaction, transactionDate: DateUtils.displayUserDate(transaction.transactionDate) };
				});
				console.log('TransationList...', [...transactionsList]);

				setLocationTransactionList([...transactionsList]);
				setLocationTransactionTotal(res.total);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function onDatesChange(startDate: moment.Moment | null, endDate: moment.Moment | null): void {
		setStartDateControl(startDate);
		setEndDateControl(endDate);
	}

	async function exportReport() {
		let hiddenElement: HTMLAnchorElement = document.createElement('a');
		const startDate = startDateControl?.format('YYYY-MM-DD');
		const endDate = endDateControl?.format('YYYY-MM-DD');
		const type = 'brandLocation';
		const locationName = props.locationName;

		hiddenElement.href = `/api/v1/brand/location/transactions/export?id=${props.locationId}&token=${
			user?.token || ''
		}&startDate=${startDate}&endDate=${endDate}&type=${type}&locationName=${locationName}`;

		hiddenElement.target = '_blank';
		hiddenElement.download = 'Exported CSV';
		hiddenElement.click();
	}

	const pointsHandler = (control: any) => {
		setpointsHandlerControlr(control._value);
	};

	return (
		<div className={'rsBrandLocationTransactionList'}>
			<Box className={'tableInformation'}>
				<Box className={'customFiltersContainer'}>
					<LabelSelect
						title={''}
						control={transactionTableForm.get('tableDisplay')}
						options={[
							{ label: 'Redemptions', value: 1 },
							{ label: 'Accruals', value: 2 }
						]}
						updateControl={(control) => {
							pointsHandler(control);
							setTransactionTableForm(transactionTableForm.cloneDeep().update(control));
						}}
					/>
					<DateRangeSelector
						onDatesChange={onDatesChange}
						startDate={startDateControl}
						endDate={endDateControl}
						focusedInput={focusedInput}
						onFocusChange={(input) => {
							setFocusedInput(input);
						}}
						monthsToShow={2}
						endDateLabel={'End Date'}
						startDateLabel={'Start Date'}
						useOutsideRange={true}
					/>
				</Box>
				<Box className={'pointRedemptionContainer'}>
					{props.pointRedeemed !== 0 && pointsHandlerControlr === 1 && (
						<Box className={'labelContainer'}>
							<Label variant={'h2'} marginRight={'5px'}>
								Points Redemptions:
							</Label>
							<Label variant={'h2'}>{props.pointRedeemed}</Label>
						</Box>
					)}
					{props.pointsAccrued !== 0 && pointsHandlerControlr === 2 && (
						<Box className={'labelContainer'}>
							<Label variant={'h2'} marginRight={'5px'}>
								Points Accrued:
							</Label>
							<Label variant={'h2'}>{props.pointsAccrued}</Label>
						</Box>
					)}
					{props.costPerPoint !== 0 && (
						<Box className={'labelContainer'}>
							<Label variant={'h2'} marginRight={'5px'}>
								Point Cost:
							</Label>
							<Label variant={'h2'}>${props.costPerPoint}</Label>
							<Label variant={'caption'} marginLeft={'5px'}>
								per Point
							</Label>
						</Box>
					)}
				</Box>
				<Box>
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Export CSV'}
						onClick={() => {
							if (!ObjectUtils.isArrayWithData(locationTransactionList)) {
								rsToastify.error(
									'No available transactions to export for these selected dates.',
									'No transactions available.'
								);
								return;
							}
							exportReport().catch(console.error);
						}}
					/>
				</Box>
			</Box>
			{transactionTableForm.get('tableDisplay').value === 2 ? (
				<Box>
					<Label className={'tableTitle'} variant={'h2'}>
						Accruals
					</Label>
					<SpireTable
						table={{
							placeholder: '',
							filterQuery: [{ column: 'transaction.createdOn', value: '' }]
						}}
						columns={[
							{
								id: 'transaction.createdOn',
								label: 'Transaction Date',
								align: 'left',
								className: 'userCell',
								sort: sortField === 'transaction.createdOn' ? sortOrder : 'DESC',
								filterType: 'SINGLE_DATE',
								filterName: 'createdOn',
								cellType: 'UN_NESTED_TEXT',
								nested: 'transactionDate'
							},
							{
								id: 'transaction.externalId',
								label: 'Transaction ID',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'transaction.externalId' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'transactionId'
							},
							{
								id: 'source',
								label: 'Source',
								align: 'left',
								className: 'brandLocationCell hideSortArrow',
								sort: undefined,
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'user.cardType',
								label: 'Card Type',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.cardType' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'cardType'
							},
							{
								id: 'user.last4',
								label: 'Last 4',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.last4' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'last4'
							},
							{
								id: 'pointsTransacted',
								label: 'Points Transacted',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'pointsTransacted' ? sortOrder : 'DESC',
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'transaction.amount',
								label: 'Total Transaction Amount',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'transaction.amount' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'totalTransactionAmount'
							},
							{
								id: 'deferredRevenue',
								label: 'Deferred Revenue',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'deferredRevenue' ? sortOrder : 'DESC',
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'eligibleSubtotal',
								label: 'Eligible Subtotal',
								align: 'left',
								className: 'brandLocationCell hideSortArrow',
								sort: undefined,
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'nonEligibleSubtotal',
								label: 'Non-Eligible',
								align: 'left',
								className: 'brandLocationCell hideSortArrow',
								sort: undefined,
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'user.customerName',
								label: 'Customer Name',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.customerName' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'customerName'
							},
							{
								id: 'user.primaryEmail',
								label: 'Customer Email',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.primaryEmail' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'primaryEmail'
							}
						]}
						data={locationTransactionList}
						total={locationTransactionTotal}
						sortField={sortField}
						onGetData={getData}
						rowOnClick={() => {}}
						changeSort={changeSort}
					/>
				</Box>
			) : (
				<Box>
					<Label className={'tableTitle'} variant={'h2'}>
						Redemptions
					</Label>
					<SpireTable
						table={{
							placeholder: '',
							filterQuery: [{ column: 'date', value: '' }]
						}}
						columns={[
							{
								id: 'transaction.CreatedOn',
								label: 'Transaction Date',
								align: 'left',
								className: 'userCell',
								sort: sortField === 'transaction.createdOn' ? sortOrder : 'DESC',
								filterType: 'SINGLE_DATE',
								filterName: 'createdOn',
								cellType: 'UN_NESTED_TEXT',
								nested: 'transactionDate'
							},
							{
								id: 'transaction.externalId',
								label: 'Transaction ID',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'transaction.externalId' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'transactionId'
							},
							{
								id: 'source',
								label: 'Source',
								align: 'left',
								className: 'brandLocationCell hideSortArrow',
								sort: undefined,
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'user.cardType',
								label: 'Card Type',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.cardType' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'cardType'
							},
							{
								id: 'user.last4',
								label: 'Last 4',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.last4' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'last4'
							},
							{
								id: 'pointsTransacted',
								label: 'Points Transacted',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'pointsTransacted' ? sortOrder : 'DESC',
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'transaction.amount',
								label: 'Total Transaction Amount',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'transaction.amount' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'totalTransactionAmount'
							},
							{
								id: 'costPerPoint',
								label: 'Original Cost of Point Redeemed',
								align: 'left',
								className: 'brandLocationCell hideSortArrow',
								sort: undefined,
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'redemptionValue',
								label: 'Redemption Value',
								align: 'left',
								className: 'brandLocationCell hideSortArrow',
								sort: undefined,
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'spireRevenue',
								label: 'Spire Revenue',
								align: 'left',
								className: 'brandLocationCell hideSortArrow',
								sort: undefined,
								filterType: 'NONE',
								cellType: 'TEXT'
							},
							{
								id: 'user.customerName',
								label: 'Customer Name',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.customerName' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'customerName'
							},
							{
								id: 'user.primaryEmail',
								label: 'Customer Email',
								align: 'left',
								className: 'brandLocationCell',
								sort: sortField === 'user.primaryEmail' ? sortOrder : 'DESC',
								filterType: 'NORMAL',
								cellType: 'UN_NESTED_TEXT',
								nested: 'primaryEmail'
							}
						]}
						data={locationTransactionList}
						total={locationTransactionTotal}
						sortField={sortField}
						onGetData={getData}
						rowOnClick={() => {}}
						changeSort={changeSort}
					/>
				</Box>
			)}
		</div>
	);
};

export default BrandLocationTransactionList;
