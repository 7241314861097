import * as React from 'react';
import './BrandLocationReportPopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Paper from '../../components/paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import Box from '../../components/box/Box';
import LabelButton from '../../components/labelButton/LabelButton';
import Icon from '@bit/redsky.framework.rs.icon';
import serviceFactory from '../../services/serviceFactory';
import { useState } from 'react';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import SpireTable from '../../components/spireTable/SpireTable';
import BrandService from '../../services/brand/brand.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import LoadingPage from '../../pages/loadingPage/LoadingPage';

export interface BrandLocationReportPopupProps extends PopupProps {
	brandId: number;
}

const BrandLocationReportPopup: React.FC<BrandLocationReportPopupProps> = (props) => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const brandService = serviceFactory.get<BrandService>('BrandService');
	const [brandLocationsList, setBrandLocationsList] = useState<Api.Brand.Res.Report[]>([]);
	const [sortField, setSortField] = useState<string>('brandLocation.id');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [brandLocationsTotal, setBrandLocationsTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await brandService.getLocationsOverviewByPage(props.brandId, pageQuery);
			setBrandLocationsList(res.data);
			setBrandLocationsTotal(res.total);
			setLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			popupController.close(BrandLocationReportPopup);
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	async function exportCSV() {
		let hiddenElement: HTMLAnchorElement = document.createElement('a');
		hiddenElement.href = `/api/v1/brand/location/reports/export?id=${props.brandId}&token=${user?.token || ''}`;
		hiddenElement.target = '_blank';
		hiddenElement.download = 'Exported CSV';
		hiddenElement.click();
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsBrandLocationReportPopup'}>
				<Box className={'titleContainer'}>
					<Label variant={'h2'}>All Locations Report Summary</Label>
					<Box className={'exportButtonContainer'}>
						<LabelButton
							look={'containedPrimary'}
							variant={'button'}
							label={'Export to CSV'}
							onClick={exportCSV}
						/>
						<Icon
							className={'closeIcon'}
							iconImg={'icon-close'}
							cursorPointer
							onClick={() => popupController.close(BrandLocationReportPopup)}
						/>
					</Box>
				</Box>
				{loading && <LoadingPage />}
				<SpireTable
					table={{
						placeholder: '',
						filterQuery: [{ column: '', value: '' }]
					}}
					columns={[
						{
							id: 'brandLocation.id',
							label: 'ID',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'brandLocation.id' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'UN_NESTED_TEXT',
							nested: 'id'
						},
						{
							id: 'brandLocation.name',
							label: 'Location Name',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'brandLocation.name' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'UN_NESTED_TEXT',
							nested: 'name'
						},
						{
							id: 'spireYTDRevenue',
							label: 'Spire Revenue YTD',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'spireYTDRevenue' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'TEXT'
						},
						{
							id: 'pointsPerDollar',
							label: 'Points Per Dollar',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'pointsPerDollar' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'TEXT'
						},
						{
							id: 'costPerPoint',
							label: 'Point Cost',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'costPerPoint' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'TEXT'
						},
						{
							id: 'costToMerchant',
							label: 'Cost to Merchant per Dollar',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'costToMerchant' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'TEXT'
						},
						{
							id: 'spireRevenuePerDollar',
							label: 'Spire Revenue per Dollar',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'spireRevenuePerDollar' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'TEXT'
						},
						{
							id: 'spireRevenuePerPoint',
							label: 'Spire Revenue per Point',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'spireRevenuePerPoint' ? sortOrder : 'DESC',
							filterType: 'NONE',
							cellType: 'TEXT'
						},
						{
							id: 'loyaltyStatus',
							label: 'Loyalty Status',
							align: 'left',
							className: 'brandLocationCell',
							sort: sortField === 'loyaltyStatus' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						}
					]}
					data={brandLocationsList}
					total={brandLocationsTotal}
					sortField={sortField}
					onGetData={getData}
					rowOnClick={() => {}}
					changeSort={changeSort}
				/>
			</Paper>
		</Popup>
	);
};

export default BrandLocationReportPopup;
