// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("MrsEavesXLSerifOT-Heavy.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("MrsEavesXLSerifOT-HeavyItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("MrsEavesXLSerifOT-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("MrsEavesXLSerifOT-Reg.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("MrsEavesXLSerifOT-RegItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("Mrs-Eaves-All-Petite-Caps-OT.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'MrsEavesXL';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: 'MrsEavesXL';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: 'MrsEavesXL';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'MrsEavesXL';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: 'MrsEavesXL';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'MrsEavesXL';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'Mrs-Eaves-All-Petite-Caps';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
	font-style: normal;
	font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/fonts/MrsEavesXL/font.scss"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,+DAA0D;CAC1D,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;CACzB,+DAAgE;CAChE,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;CACzB,+DAAyD;CACzD,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;CACzB,+DAAyD;CACzD,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;CACzB,+DAAwD;CACxD,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;CACzB,+DAA8D;CAC9D,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,wCAAwC;CACxC,+DAA+D;CAC/D,kBAAkB;CAClB,gBAAgB;AACjB","sourcesContent":["@font-face {\n\tfont-family: 'MrsEavesXL';\n\tsrc: url('MrsEavesXLSerifOT-Heavy.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 900;\n}\n\n@font-face {\n\tfont-family: 'MrsEavesXL';\n\tsrc: url('MrsEavesXLSerifOT-HeavyItalic.ttf') format('truetype');\n\tfont-style: italic;\n\tfont-weight: 900;\n}\n\n@font-face {\n\tfont-family: 'MrsEavesXL';\n\tsrc: url('MrsEavesXLSerifOT-Bold.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 700;\n}\n\n@font-face {\n\tfont-family: 'MrsEavesXL';\n\tsrc: url('MrsEavesXLSerifOT-Bold.ttf') format('truetype');\n\tfont-style: italic;\n\tfont-weight: 700;\n}\n\n@font-face {\n\tfont-family: 'MrsEavesXL';\n\tsrc: url('MrsEavesXLSerifOT-Reg.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'MrsEavesXL';\n\tsrc: url('MrsEavesXLSerifOT-RegItalic.ttf') format('truetype');\n\tfont-style: italic;\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'Mrs-Eaves-All-Petite-Caps';\n\tsrc: url('Mrs-Eaves-All-Petite-Caps-OT.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
