import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import './ManageAccommodationPage.scss';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import serviceFactory from '../../services/serviceFactory';
import AccommodationService from '../../services/accommodation/accommodation.service';
import router from '../../utils/router';
import LoadingPage from '../loadingPage/LoadingPage';
import Icon from '@bit/redsky.framework.rs.icon';
import Paper from '../../components/paper/Paper';
import UploadService from '../../services/upload/upload.service';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelButton from '../../components/labelButton/LabelButton';
import Label from '@bit/redsky.framework.rs.label';
import AccommodationLayoutPopup, {
	AccommodationLayoutPopupProps
} from '../../popups/accommodationLayoutPopup/AccommodationLayoutPopup';
import LayoutTile from '../../components/layoutTile/LayoutTile';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import ConfirmDeletePopup, { ConfirmDeletePopupProps } from '../../popups/confirmDeletePopup/ConfirmDeletePopup';
import MediaService from '../../services/media/media.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import DestinationService from '../../services/destination/destination.service';
import Select, { OptionType } from '@bit/redsky.framework.rs.select';
import ImageManager from '../../components/imageManager/ImageManager';
import AmenityTile from '../../components/amenityTile/AmenityTile';
import Accordion from '@bit/redsky.framework.rs.accordion';
import AmenityPopup, { AmenityPopupProps } from '../../popups/amenityPopup/AmenityPopup';
import Counter from '../../components/counter/Counter';
import ManageAccommodationDetailsPopup, {
	ManageAccommodationDetailsPopupProps
} from '../../popups/manageAccommodationDetailsPopup/ManageAccommodationDetailsPopup';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';

interface ManageAccommodationPageProps {}

const ManageAccommodationPage: React.FC<ManageAccommodationPageProps> = () => {
	const params = router.getPageUrlParams<{
		accommodationId: number;
		destinationId: number;
	}>([
		{ key: 'ai', default: 0, type: 'integer', alias: 'accommodationId' },
		{ key: 'di', default: 0, type: 'integer', alias: 'destinationId' }
	]);
	const accommodationService: AccommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const uploadService: UploadService = serviceFactory.get<UploadService>('UploadService');
	const mediaService: MediaService = serviceFactory.get<MediaService>('MediaService');
	const [accommodation, setAccommodation] = useState<Api.Accommodation.Res.Details>();
	const [addAccommodationLayout, setAddAccommodationLayout] = useState<any>([]);
	const [options, setOptions] = useState<OptionType[]>([]);
	const [reloadPage, setReloadPage] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [heroLogo, setHeroLogo] = useState<string>('');
	const [mediaDetails, setMediaDetails] = useState<Api.MediaDetails[]>([]);
	const [amenityIcons, setAmenityIcons] = useState<Model.Amenity[]>([]);
	const [deleteImages, setDeleteImages] = useState<number[]>([]);
	const [isValid, setIsValid] = useState<boolean>(false);
	const [accommodationDetails, setAccommodationDetails] = useState<any>({
		name: '',
		maxOccupantCount: null,
		bedDetails: [],
		size: {},
		priceCents: null
	});
	const [selectedAmenityIds, setSelectedAmenityIds] = useState<any>([]);
	const [layoutData, setLayoutData] = useState<any>([]);
	const [isCreate, setIsCreate] = useState<boolean>(false);
	const [accommodationForm, setAccommodationForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('description', '', [
				new RsValidator(
					RsValidatorEnum.CUSTOM,
					'Please try and keep description to under 500 characters.',
					(control) => {
						if (typeof control.value !== 'string') return true;
						return control.value.toString().length < 500;
					}
				)
			]),
			new RsFormControl('bedroomCount', 1, [
				new RsValidator(RsValidatorEnum.REQ, 'Bedroom Count required'),
				new RsValidator(RsValidatorEnum.NUM, 'Must be a number'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Must be greater than 0', (control) => {
					return control.value >= 1;
				})
			]),
			new RsFormControl('bathroomCount', 1, [
				new RsValidator(RsValidatorEnum.REQ, 'Bathroom Count required'),
				new RsValidator(RsValidatorEnum.NUM, 'Must be a number'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Must be greater than 0', (control) => {
					return control.value >= 1;
				})
			]),
			new RsFormControl('propertyType', 0, [new RsValidator(RsValidatorEnum.REQ, 'Property type required.')])
		])
	);

	useEffect(() => {
		async function getAllAmenities() {
			try {
				const newAmenities = await accommodationService.getAllAmenities();
				setAmenityIcons(newAmenities);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to find amenities.'), 'Error!');
			}
		}
		getAllAmenities().catch(console.error);
	}, []);

	useEffect(() => {
		async function getAccommodationInfo(destinationId: number, accommodationId: number) {
			// setLoading(true);
			try {
				let propertyTypes = await destinationService.getDestinationPropertyTypes(destinationId);
				setOptions(
					propertyTypes.map((propertyType) => {
						return { value: propertyType.id, label: propertyType.name };
					})
				);
				if (accommodationId) {
					let accommodation = await accommodationService.getDetails(accommodationId);
					// Converting qty type into string
					accommodation.bedDetails.map((item) => {
						item.qty = item.qty.toString();
						return item;
					});
					setAccommodation(accommodation);
					setHeroLogo(accommodation.heroUrl || '');

					let accommodationFormClone = accommodationForm.clone();
					if (accommodation.propertyTypeId) {
						let propertyTypeValue = accommodationFormClone.get('propertyType');
						propertyTypeValue.value = accommodation.propertyTypeId;
					}
					let descriptionValue = accommodationFormClone.get('description');
					descriptionValue.value = accommodation.longDescription || '';

					let bedroomCountValue = accommodationFormClone.get('bedroomCount');
					bedroomCountValue.value = accommodation.bedroomCount || 1;

					let bathroomCountValue = accommodationFormClone.get('bathroomCount');
					bathroomCountValue.value = accommodation.bathroomCount || 1;

					setAccommodationDetails({
						name: accommodation.name,
						maxOccupantCount: accommodation.maxOccupantCount,
						bedDetails: accommodation.bedDetails,
						size: accommodation.size,
						priceCents: accommodation?.priceCents
					});

					setAccommodationForm(accommodationFormClone);
					// setLoading(false);
				} else {
					setIsCreate(true);
					// setLoading(false);
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
					'Server Error'
				);
				// setLoading(false);
			}
		}
		getAccommodationInfo(params.destinationId, params.accommodationId).catch(console.error);
	}, [reloadPage]);

	useEffect(() => {
		async function checkForValidity() {
			let isValid = await accommodationForm.isValid();
			setIsValid(isValid);
		}
		checkForValidity().catch(console.error);
	}, [accommodationForm]);

	async function uploadImage(file: ChangeEvent<HTMLInputElement>) {
		let image = file.currentTarget.files;
		if (!image) return;
		try {
			let response: any = await uploadService.uploadImage(image[0]);
			return response.data;
		} catch (e) {
			console.log(e);
		}
	}

	function updateForm(control: RsFormControl) {
		setAccommodationForm(accommodationForm.clone().update(control));
	}

	function checkDestinationDetailValidationFn(destinationDetails: any) {
		if (
			destinationDetails.name &&
			destinationDetails.maxOccupantCount &&
			destinationDetails.bedDetails &&
			destinationDetails.size &&
			destinationDetails.priceCents
		) {
			return false;
		} else {
			rsToastify.error('Accommodation detail required');
			return true;
		}
	}

	async function save(newAmenities?: number[]) {
		if (!accommodation && !isCreate) return;
		popupController.closeAll();
		if (isCreate && checkDestinationDetailValidationFn(accommodationDetails)) return;
		if (!ObjectUtils.isArrayWithData(mediaDetails)) {
			rsToastify.error('Accommodation requires at least one image.', 'Add Image!');
			return;
		}
		if (!mediaDetails.find((img) => img.isPrimary)) {
			rsToastify.error('Must select a primary Image', 'Select Primary!');
			return;
		}
		if (!accommodationForm.get('description').value.toString()) {
			rsToastify.error('Description required');
			return;
		}
		setLoading(true);
		let data: any = {
			// id: accommodation.id,
			heroUrl: heroLogo,
			mediaIds: mediaDetails,
			longDescription: accommodationForm.get('description').value.toString(),
			bedroomCount: parseInt(accommodationForm.get('bedroomCount').value.toString()),
			bathroomCount: parseInt(accommodationForm.get('bathroomCount').value.toString()),
			amenityIds: selectedAmenityIds,
			name: accommodationDetails.name,
			maxOccupantCount: parseInt(accommodationDetails.maxOccupantCount),
			bedDetails: accommodationDetails.bedDetails,
			size: JSON.stringify(accommodationDetails.size),
			priceCents: parseInt(accommodationDetails?.priceCents),
			maxSleeps: parseInt(accommodationDetails.maxOccupantCount)
		};

		if (accommodation && !isCreate) data.id = accommodation.id;
		if (isCreate) data.destinationId = params.destinationId;
		if ((accommodationForm.get('propertyType').value as number) > 0) {
			data.propertyTypeId = accommodationForm.get('propertyType').value as number;
		}
		try {
			if (ObjectUtils.isArrayWithData(deleteImages)) {
				deleteImages.forEach((imageId) => {
					mediaService.deleteMedia({ id: imageId });
				});
				setDeleteImages([]);
			}
			let response: any = isCreate
				? await accommodationService.createAccommodation(data)
				: await accommodationService.updateAccommodation(data);
			// if (!isCreate) setAccommodation(response);
			if (layoutData && layoutData.length) {
				layoutData.map(async (items: any) => {
					if (items && items.updateData && items.updateData.title && items.updateData.mediaId) {
						if (items.updateData.id) {
							await accommodationService.updateAccommodationLayout(items.updateData);
							// setReloadPage(reloadPage + 1);
						}
					}
				});
			}

			if (addAccommodationLayout && addAccommodationLayout.length) {
				addAccommodationLayout.map(async (item: any) => {
					if (item && item.data && item.data.updateData.title && item.data.updateData.mediaId) {
						if (response?.data?.data?.id) {
							layoutData.accommodationId = response.data.data.id;
							item.data.updateData.accommodationId = layoutData.accommodationId
								? layoutData.accommodationId
								: response.data.data.id;
							await accommodationService.createAccommodationLayout(item.data.updateData);
							setAddAccommodationLayout([]);
						}
					}
				});
			}

			if (response.data.data) rsToastify.success('Accommodation was successfully updated.', 'Update Successful');
			setLoading(false);
			if (isCreate) await router.navigate('/dashboard/destination-list');
			else setReloadPage(reloadPage + 1);
		} catch (e) {
			setLoading(false);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	async function deleteAccommodationLayout(id: number) {
		try {
			let response = await accommodationService.deleteAccommodationLayout(id);
			if (response.data.data) {
				rsToastify.success('Accommodation layout successfully removed.', 'Delete Successful');
				setReloadPage(reloadPage + 1);
			}
		} catch (e: any) {
			console.log(e.message);
		}
	}

	async function handleMediaData(mediaData: { id: number; isPrimary: 1 | 0; image: string }[], deleteImg: number[]) {
		setMediaDetails(
			mediaData.map((img) => {
				return { id: img.id, isPrimary: img.isPrimary };
			})
		);
		setDeleteImages(deleteImg);
	}

	function renderAccommodationLayout() {
		if (!accommodation) return;
		return accommodation?.layout?.map((item) => {
			return (
				<LayoutTile
					key={item.id}
					title={item.title}
					imgUrl={item.media.urls.imageKit}
					rooms={item.rooms}
					onClick={() => {
						popupController.open<AccommodationLayoutPopupProps>(AccommodationLayoutPopup, {
							accommodationId: accommodation.id,
							title: item.title,
							imgUrl: item.media.urls.imageKit,
							mediaId: item.media.id,
							edit: 2,
							rooms: item.rooms,
							layoutId: item.id,
							onSave: (data) => {
								setLayoutData((prevState: any) => [...prevState, data]);

								const newadd = [...accommodation?.layout];

								const objIndex = newadd.findIndex((obj) => obj.id == item.id);
								newadd[objIndex].media.urls.imageKit = data.img;
								newadd[objIndex].rooms = data.rooms;
								newadd[objIndex].title = data.updateData.title;
								setAccommodation((pre: any) => {
									return {
										...pre,
										[pre.layout]: newadd
									};
								});
							},
							onClose: (rooms) => {
								const newadd = [...accommodation?.layout];
								const objIndex = newadd.findIndex((obj) => obj.id == item.id);
								newadd[objIndex].rooms = rooms;
								setAccommodation((pre: any) => {
									return {
										...pre,
										[pre.layout]: newadd
									};
								});
							}
						});
					}}
					onDelete={() => {
						popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
							onDelete: () => {
								deleteAccommodationLayout(item.id).catch(console.error);
							}
						});
					}}
				/>
			);
		});
	}

	function renderaddAccommodationLayout() {
		if (!addAccommodationLayout) return;
		return addAccommodationLayout?.map((item: any, index: number) => {
			return (
				<LayoutTile
					key={item.data.updateData.accommodationId}
					title={item.data.updateData.title}
					imgUrl={item.data.img}
					rooms={item.data.rooms}
					onClick={() => {
						popupController.open<AccommodationLayoutPopupProps>(AccommodationLayoutPopup, {
							accommodationId: item.data.updateData.accommodationId,
							title: item.data.updateData.title,
							imgUrl: item.data.img,
							mediaId: item.data.updateData.mediaId,
							rooms: item?.data?.rooms,
							layoutId: item?.data?.id,
							edit: 1,
							onSave: (data) => {
								const newadd = [...addAccommodationLayout];
								const objIndex = newadd.findIndex(
									(obj) => obj.data.updateData.mediaId == item.data.updateData.mediaId
								);

								newadd[objIndex].data.img = data.img;
								newadd[objIndex].data.updateData = data.updateData;

								setAddAccommodationLayout(newadd);
							},
							onClose: () => {
								// setReloadPage(reloadPage + 1);
							}
						});
					}}
					onDelete={() => {
						popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
							onDelete: () => {
								const filteredPeople = addAccommodationLayout.filter(
									(item: any, ind: number) => ind != index
								);
								setAddAccommodationLayout(filteredPeople);
							}
						});
					}}
				/>
			);
		});
	}
	function renderAmenities() {
		if (!accommodation) return;

		const amenityes = amenityIcons.filter(
			(amenity: any) =>
				!accommodation?.amenities.some((accommodationAmenity) => accommodationAmenity.id === amenity.id) &&
				selectedAmenityIds.includes(amenity.id)
		);

		// Concatenating accommodation?.amenities and amenityes
		const allAmenities = (accommodation?.amenities || []).concat(amenityes);

		return allAmenities.map((item) => {
			return (
				<AmenityTile
					key={item.id}
					amenity={item}
					onClick={() => {
						const amenityes = amenityIcons.filter(
							(amenity: any) =>
								!accommodation?.amenities.some(
									(accommodationAmenity) => accommodationAmenity.id === amenity.id
								) && selectedAmenityIds.includes(amenity.id)
						);

						// Concatenating accommodation?.amenities and amenityes
						const allAmenities = (accommodation?.amenities || []).concat(amenityes);

						popupController.open<AmenityPopupProps>(AmenityPopup, {
							amenityIds:
								allAmenities?.map((amenity) => {
									return amenity.id;
								}) || [],
							onSave: (newAmenities: any) => {
								setSelectedAmenityIds(newAmenities);
								// save(newAmenities).catch(console.error);
							}
						});
					}}
				/>
			);
		});
	}

	return !accommodation && !isCreate ? (
		<LoadingPage />
	) : (
		<Page className={'rsManageAccommodationPage'}>
			{loading ? (
				<LoadingPage />
			) : (
				<div className={'pageWrapper'}>
					<SubHeader
						header={accommodationDetails.name}
						crumbs={[
							{ label: 'Dashboard', link: '/dashboard' },
							{ label: 'Destination List', link: '/dashboard/destination-list' },
							{
								label: 'Manage Destination',
								link: `/dashboard/destination-list/manage-destinations?di=${params.destinationId}`
							},
							{
								label: 'Manage Accommodation',
								link: `/dashboard/destination-list/manage-destinations/manage-accommodation?ai=${params.accommodationId}&di=${params.destinationId}`
							}
						]}
					/>
					<Box margin={'50px 0'} className={'sectionOne'}>
						{/* <LabelInput
							title={'Name'}
							maxLength={500}
							control={accommodationForm.get('name')}
							updateControl={updateForm}
							inputType={'text'}
						/> */}
						<LabelButton
							look={'containedPrimary'}
							variant={'button'}
							label={`${isCreate ? 'Add' : 'Edit'} Accommodation Details`}
							className={'add-edit-accomodation'}
							onClick={() =>
								popupController.open<ManageAccommodationDetailsPopupProps>(
									ManageAccommodationDetailsPopup,
									{
										accommodationDetails: accommodationDetails,
										setAccommodationDetails: setAccommodationDetails,
										isCreate: isCreate
									}
								)
							}
						/>
						<Label variant={'h2'} marginBottom={5}>
							Hero Image (Recommended Size: 1440px x 630px)
						</Label>
						<Paper
							className={'heroImagePaper'}
							padding={'10px'}
							width={'100%'}
							height={'200px'}
							borderRadius={'4px'}
							boxShadow
						>
							<div className={'heroImage'} style={{ backgroundImage: `url(${heroLogo || ''})` }} />

							<div className={'uploadWrapper'}>
								<input
									id={'HeroImgUpload'}
									type={'file'}
									className={'uploadInput'}
									accept="image/*"
									onChange={async (event) => {
										let imageUrl: any = await uploadImage(event);
										if (!imageUrl) return;
										setHeroLogo(imageUrl.urls.large);
									}}
								/>
								<label htmlFor={'HeroImgUpload'} className={'edit'}>
									<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
								</label>
							</div>
						</Paper>
						<Box>
							<Label variant={'h2'}>Accommodation Images (Recommended Size: 1920px x 1080px)</Label>
							<ImageManager
								data={accommodation?.media || []}
								handleMediaData={handleMediaData}
								id={'accommodationUpload'}
							/>
						</Box>
						<LabelInput
							title={'Description'}
							maxLength={500}
							control={accommodationForm.get('description')}
							updateControl={updateForm}
							inputType={'textarea'}
						/>
						<Box className={'inputGrid'}>
							<Box>
								<Label variant={'h2'}>Property Type</Label>
								<Select
									control={accommodationForm.get('propertyType')}
									updateControl={(control) => {
										if (control.value !== 0) {
											setAccommodationForm(accommodationForm.clone().update(control));
										} else {
											setIsValid(false);
											rsToastify.error('Select a property type.', 'Property Type Required!');
										}
									}}
									options={options}
								/>
							</Box>
							<Counter
								title={'Bedrooms'}
								control={accommodationForm.get('bedroomCount')}
								updateControl={updateForm}
							/>
							<Counter
								title={'Bathrooms'}
								control={accommodationForm.get('bathroomCount')}
								updateControl={updateForm}
							/>
						</Box>
					</Box>
					<hr />
					{/* <Box className={'sectionThree'} marginBottom={'50px'}>
						<Box display={'flex'}>
							<Label variant={'h1'}>Layout</Label>
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Add Layout'}
								onClick={() => {
									popupController.open<AccommodationLayoutPopupProps>(AccommodationLayoutPopup, {
										accommodationId: accommodation?.id || 0,
										onSave: (data) => {
											// setLayoutData(data );
											setAddAccommodationLayout((oldArray: any) => [...oldArray, { data }]);
											// setReloadPage(reloadPage + 1);
										}
									});
								}}
							/>
						</Box>
						<Box className={'listContainer'}>{renderAccommodationLayout()}</Box>
						<Box className={'listContainer'}>{renderaddAccommodationLayout()}</Box>
					</Box> */}
					<Box className={'sectionFour'}>
						<Box display={'flex'}>
							<Label variant={'h1'}>Layout</Label>
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Add Layout'}
								onClick={() => {
									popupController.open<AccommodationLayoutPopupProps>(AccommodationLayoutPopup, {
										accommodationId: accommodation?.id || 0,
										onSave: (data) => {
											// setLayoutData(data );
											setAddAccommodationLayout((oldArray: any) => [...oldArray, { data }]);
											// setReloadPage(reloadPage + 1);
										}
									});
								}}
							/>
						</Box>
						<Accordion>
							<Box className={'listContainer'}>{renderAccommodationLayout()}</Box>
							<Box className={'listContainer'}>{renderaddAccommodationLayout()}</Box>
						</Accordion>
					</Box>
					<hr />
					<Box className={'sectionFour'}>
						<Box display={'flex'}>
							<Label variant={'h1'}>In Unit Amenities</Label>
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={accommodation?.amenities ? 'Edit Amenities' : 'Add Amenity'}
								onClick={() => {
									const amenityes = amenityIcons.filter(
										(amenity: any) =>
											!accommodation?.amenities.some(
												(accommodationAmenity) => accommodationAmenity.id === amenity.id
											) && selectedAmenityIds.includes(amenity.id)
									);

									// Concatenating accommodation?.amenities and amenityes
									const allAmenities = (accommodation?.amenities || []).concat(amenityes);
									popupController.open<AmenityPopupProps>(AmenityPopup, {
										amenityIds:
											allAmenities.map((amenity) => {
												return amenity.id;
											}) || [],
										onSave: (newAmenities: any) => {
											setSelectedAmenityIds(newAmenities);
											// save(newAmenities).catch(console.error);
										}
									});
								}}
							/>
						</Box>
						<Accordion>
							<Box className={'amenitiesContainer'}>{renderAmenities()}</Box>
						</Accordion>
					</Box>
					<hr />
					<Box display={'flex'}>
						<div className={'saveBtnSection'}>
							<LabelButton
								disabled={!isValid}
								className={'saveButton'}
								look={isValid ? 'containedPrimary' : 'containedSecondary'}
								variant={'button'}
								label={'Save'}
								onClick={() => {
									save();
								}}
							/>
						</div>
						{isCreate ? null : (
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={accommodation?.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}
								onClick={async () => {
									popupController.open(SpinningLoaderPopup, {});
									try {
										let response: any = await accommodationService.updateAccommodation({
											id: accommodation?.id || 0,
											status: accommodation?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
										});
										if (response && response.data && response.data.data) {
											setAccommodation(response.data.data);
										}
										popupController.close(SpinningLoaderPopup);
									} catch (e) {
										rsToastify.error(
											WebUtils.getRsErrorMessage(e, 'Unable to toggle active status'),
											'Server Error'
										);
										popupController.closeAll();
									}
								}}
							/>
						)}
					</Box>
				</div>
			)}
		</Page>
	);
};

export default ManageAccommodationPage;
