import React, { FormEvent, useEffect, useState } from 'react';
import { Link, Page } from '@bit/redsky.framework.rs.996';
import './ResetPasswordPage.scss';
import Box from '../../components/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Input from '@bit/redsky.framework.rs.input';
import Button from '@bit/redsky.framework.rs.button';
import { RsFormControl, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import router from '../../utils/router';
import UserService from '../../services/user/user.service';
import serviceFactory from '../../services/serviceFactory';
import { HttpStatusCode } from '../../utils/http';
import { axiosErrorHandler } from '../../utils/errorHandler';

const ResetPasswordPage: React.FC = () => {
	let userService = serviceFactory.get<UserService>('UserService');
	const [guidResponse, setGuidResponse] = useState<boolean>(false);
	const [guid, setGuid] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const params: any = router.getPageUrlParams([{ key: 'guid', default: '', type: 'string', alias: 'guid' }]);

	const [password, setPassword] = useState<string | number | string[] | number[]>('');
	const [verifyPassword, setVerifyPassword] = useState<string | number | string[] | number[]>('');
	const [passwordControl, setPasswordControl] = useState(
		new RsFormControl('password', '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')])
	);
	const [verifyPasswordControl, setVerifyPasswordControl] = useState(
		new RsFormControl('password', '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')])
	);

	async function resetPasswordRequest(e: FormEvent) {
		e.preventDefault();
		if (password === verifyPassword) {
			try {
				let res = await userService.resetPasswordByGuid(`${guid}`, `${password}`);
				await router.navigate('/');
				console.log(res);
			} catch (e) {
				axiosErrorHandler(e, {
					[HttpStatusCode.NOT_FOUND]: () => {
						setErrorMessage(
							'The 2 hour time has elapsed. The reset password link is no longer valid. Please try again.'
						);
					},
					[HttpStatusCode.BAD_REQUEST]: () => {
						setErrorMessage(
							'The 2 hour time has elapsed. The password link is no longer valid. Please try again.'
						);
					}
				});
			}
		} else {
			setErrorMessage('passwords do not match.');
		}
	}

	useEffect(() => {
		async function verifyGuid() {
			try {
				setErrorMessage('');
				let res = await userService.guidValidation(`${params.guid}`);
				setGuid(params.guid);
				setGuidResponse(!!res.data.data as boolean);
			} catch (e) {
				setErrorMessage(
					'The 2 hour time has elapsed. The reset password link is no longer valid. Please try again.'
				);
				axiosErrorHandler(e, {
					[HttpStatusCode.NOT_FOUND]: () => {
						setErrorMessage('The 2 hour time has elapsed. The link is no longer valid. Please try again.');
					},
					[HttpStatusCode.BAD_REQUEST]: () => {
						setErrorMessage('The 2 hour time has elapsed. The link is no longer valid. Please try again.');
					}
				});
			}
		}
		verifyGuid();
	}, []);

	return !guidResponse ? (
		<Page className="rsResetPasswordPage">
			<Box className="loggedOutTitleBar" p={'0 20px'} display={'flex'} alignItems={'center'}>
				<Label className="spireLabel" variant={'h6'}>
					SPIRE
				</Label>
			</Box>
			<Box className="resetPasswordWrapper">
				<Box
					className="resetPasswordContainer"
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
					p={'22px 68px'}
				>
					<Box className="titleContainer" display={'flex'} flexDirection={'column'} alignItems={'center'}>
						<Label className="resetPasswordHeader" variant={'h4'}>
							Reset Password Admin Center
						</Label>
					</Box>
					<Label className="resetPasswordError">{errorMessage}</Label>
					<Link className="loginLink" path={'/'}>
						Login Page
					</Link>
				</Box>
			</Box>
		</Page>
	) : (
		<Page className="rsResetPasswordPage">
			<Box className="loggedOutTitleBar" p={'0 20px'} display={'flex'} alignItems={'center'}>
				<Label className="spireLabel" variant={'h6'}>
					SPIRE
				</Label>
			</Box>
			<Box className="resetPasswordWrapper">
				<Box
					className="resetPasswordContainer"
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
					p={'22px 68px'}
				>
					<Box className="titleContainer" display={'flex'} flexDirection={'column'} alignItems={'center'}>
						<Label className="resetPasswordHeader" variant={'h4'}>
							Reset Password Admin Center
						</Label>
					</Box>
					<form className="resetPasswordForm" action={'#'} onSubmit={resetPasswordRequest}>
						<Input
							className="passwordInput"
							placeholder="password *"
							type={'password'}
							look={'outlined'}
							control={passwordControl}
							updateControl={(updateControl) => setPassword(updateControl.value)}
						/>
						<Input
							className="verifyPasswordInput"
							placeholder="verify password *"
							type={'password'}
							look={'outlined'}
							control={verifyPasswordControl}
							updateControl={(updateControl) => setVerifyPassword(updateControl.value)}
						/>
						<Button className="resetPasswordButton" look={'containedPrimary'} type="submit">
							SIGN IN
						</Button>
						{!!errorMessage.length && (
							<Label className="errorText" variant={'body1'}>
								{errorMessage}
							</Label>
						)}
					</form>
				</Box>
			</Box>
		</Page>
	);
};

export default ResetPasswordPage;
