import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class UserAddressService extends Service {
	async create(data: Api.UserAddress.Req.Create): Promise<Api.UserAddress.Res.Create> {
		let response = await http.post<RsResponseData<Api.UserAddress.Res.Create>>('userAddress/', data);
		return response.data.data;
	}

	async delete(id: number): Promise<number> {
		let result = await http.delete<RsResponseData<number>>('userAddress', { id });
		return result.data.data;
	}

	async update(data: Api.UserAddress.Req.Update): Promise<Api.UserAddress.Res.Update> {
		let result = await http.put<RsResponseData<Api.UserAddress.Res.Update>>('userAddress', data);
		return result.data.data;
	}
}
