import * as React from 'react';
import './AddImagePopup.scss';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import LabelButton from '../../components/labelButton/LabelButton';
import { ChangeEvent, useEffect, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import UploadService from '../../services/upload/upload.service';
import MediaService from '../../services/media/media.service';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import SpinningLoaderPopup, { SpinningLoaderPopupProps } from '../spinningLoaderPopup/SpinningLoaderPopup';

export type ImageUploadObject = { mediaId: number; url: string | undefined; title: string; description: string };
export interface TitleDescription {
	title: string;
	description: string;
}

export interface AddImagePopupProps extends PopupProps {
	title?: string;
	description?: string;
	url?: string;
	mediaId?: number;
	onSave: (newMedia: Api.Media.Res.Get, titleDescription: TitleDescription) => void;
}

const AddImagePopup: React.FC<AddImagePopupProps> = (props) => {
	const uploadService = serviceFactory.get<UploadService>('UploadService');
	const mediaService = serviceFactory.get<MediaService>('MediaService');
	const [image, setImage] = useState<string | undefined>(props.url || '');
	const [previewImage, setPreviewImage] = useState<string>();
	const [imageObject, setImageObject] = useState<Api.Media.Res.Get>();
	const [titleDescription, setTitleDescription] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('title', props.title || '', []),
			new RsFormControl('description', props.description || '', [])
		])
	);

	useEffect(() => {
		async function getImageData() {
			if (props.mediaId === -1) return;
			if (props.mediaId) {
				try {
					let response = await mediaService.get(props.mediaId);
					setImageObject(response.data.data);
				} catch (e) {
					rsToastify.error(
						WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
						'Server Error'
					);
				}
			}
		}
		getImageData().catch(console.error);
	}, []);

	async function uploadImage(file: ChangeEvent<HTMLInputElement>) {
		popupController.open<SpinningLoaderPopupProps>(SpinningLoaderPopup, { preventCloseByBackgroundClick: true });
		let image = file.currentTarget.files;
		if (!image) return;
		try {
			let response = await uploadService.uploadImage(image[0]);
			setImage(response.data.urls.small);
			setPreviewImage(response.data.urls.imageKit);
			setImageObject(response.data);
			popupController.close(SpinningLoaderPopup);
		} catch (e) {
			console.log(e);
			popupController.close(SpinningLoaderPopup);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to upload image'), 'File Error');
		}
	}

	function updateTitleDescription(control: RsFormControl) {
		titleDescription.update(control);
		setTitleDescription(titleDescription.clone());
	}

	async function saveImage() {
		if (!imageObject) return;
		if (imageObject.id === 0) return;
		let newTitleDescription: TitleDescription = titleDescription.toModel();

		try {
			let newImageObject = { ...imageObject, ...newTitleDescription };
			let response = await mediaService.update(newImageObject);
			if (response.data.data) rsToastify.success('Image Added!', 'Success');
			props.onSave(imageObject, titleDescription.toModel());
			popupController.close(AddImagePopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsAddImagePopup'} borderRadius={'4px'} width={'550px'} padding={'25px'}>
				<Icon iconImg={'icon-close'} cursorPointer onClick={() => popupController.close(AddImagePopup)} />
				<Label className={'header'} variant={'h4'}>
					Add Image
				</Label>
				<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
					<div className={'addImageBox'} style={{ backgroundImage: `url(${previewImage})` }}>
						<div className={'uploadWrapper'}>
							<input
								id={'ImageUpload'}
								type={'file'}
								className={'uploadInput'}
								accept="image/*"
								onChange={uploadImage}
							/>
							<label htmlFor={'ImageUpload'} className={'edit'}>
								<Icon className={'edit'} iconImg={'icon-edit'} size={18} cursorPointer />
							</label>
						</div>
					</div>
					<div>
						<LabelInput
							title={'Title'}
							inputType={'text'}
							control={titleDescription.get('title')}
							updateControl={updateTitleDescription}
						/>
						<LabelInput
							title={'Description'}
							control={titleDescription.get('description')}
							updateControl={updateTitleDescription}
							inputType={'textarea'}
						/>
					</div>
				</Box>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Save'}
					onClick={() => {
						saveImage();
					}}
				/>
			</Paper>
		</Popup>
	);
};

export default AddImagePopup;
