import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class UserBusinessService extends Service {
	async getAvailable(): Promise<RsResponseData<Api.UserBusiness.Res.Available>> {
		const response = await http.get<RsResponseData<Api.UserBusiness.Res.Available>>('userBusiness/available');
		return response.data;
	}
}
