import React, { FC, ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';

const Drag = (props: any) => {
	return (
		<Draggable isDragDisabled={props.id === 'otherPackages'} draggableId={props.id.toString()} index={props.index}>
			{(provided: any, snapshot: any) => {
				return (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...props}
						style={{ ...provided.draggableProps.style }}
					>
						<div className="drag-handle" {...provided.dragHandleProps}>
							{props.children}
						</div>
					</div>
				);
			}}
		</Draggable>
	);
};

export default Drag;
