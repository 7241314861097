import * as React from 'react';
import SpireTableCell from '../spireTableCell/SpireTableCell';
import { DateUtils } from '../../../utils/utils';
import { TableCell, TableRow } from '@bit/redsky.framework.rs.datatable';
import Button from '@bit/redsky.framework.rs.button';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { OptionType } from '@bit/redsky.framework.rs.select';
import Input from '@bit/redsky.framework.rs.input';
import { useState } from 'react';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import { Box } from '@bit/redsky.framework.rs.996';
import LabelCheckbox from '../../labelCheckbox/LabelCheckbox';
import moment from 'moment';

export interface ColumnDetails {
	id: string;
	label: string;
	align: 'left' | 'center' | 'inherit' | 'justify' | 'right';
	className: string;
	sort: string | undefined;
	filterType: 'NORMAL' | 'SINGLE_DATE' | 'DROPDOWN' | 'CHECKBOX' | 'MULTI_QUERY' | 'NONE';
	cellType:
		| 'TEXT'
		| 'DATE'
		| 'NESTED_TEXT'
		| 'HANDLER'
		| 'UN_NESTED_TEXT'
		| 'BUTTON'
		| 'IMAGE'
		| 'BOOLEAN'
		| 'INPUT'
		| 'CHECKBOX';
	look?:
		| 'containedPrimary'
		| 'containedSecondary'
		| 'containedTertiary'
		| 'textPrimary'
		| 'textSecondary'
		| 'textTertiary'
		| 'outlinedPrimary'
		| 'outlinedSecondary'
		| 'outlinedTertiary'
		| 'none';
	filterName?: 'createdOn' | 'startOn' | 'endOn';
	onClick?: (data: any) => void;
	handler?: (data: any) => void;
	nested?: string;
	textOptions?: {
		true: string;
		false: string;
	};
	multiFilterQuery?: {
		column: string;
		value: string | string[] | number | number[];
		conjunction?: 'AND' | 'OR';
	}[];
	dropDownOptions?: OptionType[];
}

interface SpireTableRowProps {
	data: any;
	rowOnClick: (data: any) => void;
	columns: ColumnDetails[];
	selectedList?: any[];
}

const SpireTableRow: React.FC<SpireTableRowProps> = (props) => {
	const [formControl, setFormControl] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('formKey', props.data.name || '', [])])
	);

	return (
		<TableRow
			className={'rsSpireTableRow'}
			onClick={() => {
				props.rowOnClick(props.data);
			}}
		>
			{props.columns.map((cell) => {
				switch (cell.cellType) {
					case 'TEXT':
						return (
							<SpireTableCell
								key={cell.id}
								align={cell.align}
								className={cell.className}
								data={props.data[cell.id]}
							/>
						);
					case 'NESTED_TEXT':
						let words: string[] = [];
						words = cell.nested ? cell.nested.split('.') : [''];
						return (
							<SpireTableCell
								key={cell.id}
								align={cell.align}
								className={cell.className}
								data={props.data[words[0]][words[1]]}
							/>
						);
					case 'UN_NESTED_TEXT':
						return (
							<SpireTableCell
								key={cell.id}
								align={cell.align}
								className={cell.className}
								data={cell.nested ? props.data[cell.nested] : ''}
							/>
						);
					case 'DATE':
						return (
							<SpireTableCell
								key={cell.id}
								align={cell.align}
								className={cell.className}
								data={moment(props.data[cell.filterName || 'createdOn']).format('L')}
							/>
						);
					case 'HANDLER':
						return (
							<SpireTableCell
								key={cell.id}
								align={cell.align}
								className={cell.className}
								data={cell.handler ? cell.handler(props.data) : ''}
							/>
						);
					case 'BUTTON':
						return (
							<TableCell key={cell.id} align={cell.align} className={cell.className}>
								<Button
									look={cell.look || 'containedPrimary'}
									onClick={(event) => {
										if (cell.onClick) {
											cell.onClick(props.data.id);
											event.stopPropagation();
										}
									}}
								>
									{cell.label}
								</Button>
							</TableCell>
						);
					case 'IMAGE':
						let media = '';
						if (props.data.media.length >= 2) {
							let img = props.data.media.find((image: any) => image.isPrimary);
							if (!img) {
								media = props.data.media[0].urls.imageKit?.toString() || props.data.media[0].urls.thumb;
							} else {
								media = img.urls.imageKit?.toString() || img.urls.thumb;
							}
						} else if (props.data.media.length === 1) {
							media = props.data.media[0].urls.imageKit?.toString() || props.data.media[0].urls.thumb;
						} else {
							media = '';
						}
						return (
							<TableCell key={cell.id} align={cell.align} className={cell.className}>
								{media !== '' ? (
									<img className={'imageThumb'} src={media + '?tr=w-50,tr=h-auto'} alt={'Primary'} />
								) : (
									<Label variant={'body1'}>No Image</Label>
								)}
							</TableCell>
						);
					case 'BOOLEAN':
						let text = '';
						if (!!!props.data[cell.id]) {
							text = cell.textOptions ? cell.textOptions.false : '';
						} else if (!!props.data[cell.id]) {
							text = cell.textOptions ? cell.textOptions.true : '';
						} else {
							text = props.data[cell.id];
						}
						return (
							<SpireTableCell key={cell.id} align={cell.align} className={cell.className} data={text} />
						);
					case 'CHECKBOX':
						return (
							<TableCell key={cell.id} align={cell.align} className={cell.className}>
								<Box display={'flex'}>
									<LabelCheckbox
										value={props.data.id}
										isChecked={props.selectedList?.includes(props.data.id)}
										onDeselect={() => {
											const data = {
												select: false,
												id: props.data.id
											};
											if (!cell.onClick) return;
											cell.onClick(data);
										}}
										onSelect={() => {
											const data = {
												select: true,
												id: props.data.id
											};
											if (!cell.onClick) return;
											cell.onClick(data);
										}}
									/>
								</Box>
							</TableCell>
						);
					case 'INPUT':
						return (
							<TableCell key={cell.id} align={cell.align} className={cell.className}>
								<Box display={'flex'}>
									<Input
										placeholder={props.data.name}
										type={'text'}
										look={'none'}
										color={'#858585'}
										control={formControl.get('formKey')}
										updateControl={(control) => {
											setFormControl(formControl.clone().update(control));
										}}
										maxLength={10}
										unStyled
									/>
									<Button
										look={'containedPrimary'}
										onClick={() => {
											const data = {
												data: props.data,
												value: formControl.get('formKey').value
											};
											if (!cell.onClick) return;
											cell.onClick(data);
										}}
									>
										Apply
									</Button>
								</Box>
							</TableCell>
						);
				}
				return null;
			})}
		</TableRow>
	);
};

export default SpireTableRow;
