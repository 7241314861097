import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
import './UserListPage.scss';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import router from '../../utils/router';
import { StringUtils, WebUtils } from '../../utils/utils';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpireTable from '../../components/spireTable/SpireTable';
import { OptionType } from '@bit/redsky.framework.rs.select';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import LabelButton from '../../components/labelButton/LabelButton';
import UploadService from '../../services/upload/upload.service';
import SpinningLoaderPopup, { SpinningLoaderPopupProps } from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';

const UserListPage: React.FC = () => {
	let userService = serviceFactory.get<UserService>('UserService');
	const uploadService = serviceFactory.get<UploadService>('UploadService');
	let inputFile = React.useRef<HTMLInputElement>(null);
	const recoilUserRoles = useRecoilValue<Model.UserRole[]>(globalState.userRoles);
	const [userList, setUserList] = useState<Api.User.Res.GetByPage[]>([]);
	const [sortField, setSortField] = useState<string>('accountNumber');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [userTotal, setUserTotal] = useState<number>(0);
	const [roleOptions, setRoleOptions] = useState<OptionType[]>([]);

	useEffect(() => {
		setRoleOptions(
			recoilUserRoles.map((role) => {
				return { value: role.id, label: role.name };
			})
		);
	}, [recoilUserRoles]);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			if (pageQuery.filter) {
				pageQuery.filter.matchType = 'like';
				pageQuery.filter.searchTerm = pageQuery.filter?.searchTerm[pageQuery.filter?.searchTerm.length - 1]
					? [pageQuery.filter?.searchTerm[pageQuery.filter?.searchTerm.length - 1]]
					: pageQuery.filter?.searchTerm;
			}
			let res = await userService.getUserByPage(pageQuery);
			setUserList(res.data);
			setUserTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function roleToHumanReadable(data: any): string {
		let roleName = userService.getUserRoleNameById(data.userRoleId);
		return StringUtils.snakeCaseToHuman(roleName);
	}

	function renderPhoneNumber(data: any) {
		return StringUtils.formatCountryCodePhoneNumber(data.phone) || data.phone;
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/user-list/manage-user?ui=' + data.id).catch(console.error);
	}
	async function uploadImage(event: ChangeEvent<HTMLInputElement>) {
		event.preventDefault();
		popupController.open<SpinningLoaderPopupProps>(SpinningLoaderPopup, { preventCloseByBackgroundClick: true });
		let csv = event.currentTarget.files;
		if (!csv) return;
		try {
			await uploadService.uploadUserCsv(csv[0]);
			rsToastify.success('User imported successfully');
			popupController.close(SpinningLoaderPopup);
		} catch (e: any) {
			console.log(e);
			rsToastify.error(WebUtils.getRsErrorMessage(e, e?.msg), 'File Error');
			popupController.close(SpinningLoaderPopup);
		}
		event.target.value = '';
	}

	const openBrowse = () => inputFile?.current?.click();
	return (
		<Page className={'rsUserListPage'}>
			<SubHeader
				header={'Manage Spire Loyalty Members'}
				buttonOptions={[{ name: 'Create User', path: '/dashboard/user-list/create-user' }]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'User List', link: '/dashboard/user-list' }
				]}
			/>
			<LabelButton
				className={'uploadBtn'}
				look={'containedPrimary'}
				variant={'button'}
				label={'Import User'}
				onClick={openBrowse}
			/>
			<input
				id={'ImageUpload'}
				type={'file'}
				ref={inputFile}
				className={'uploadInput'}
				accept=".csv"
				onChange={uploadImage}
			/>
			<SpireTable
				table={{
					placeholder: 'Search by name, email',
					filterQuery: [
						{ column: 'firstName', value: '' },
						{ column: 'lastName', value: '', conjunction: 'OR' },
						{ column: 'primaryEmail', value: '', conjunction: 'OR' }
					]
				}}
				columns={[
					{
						id: 'firstName',
						label: 'First Name',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'firstName' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'lastName',
						label: 'Last Name',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'lastName' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'userRoleId',
						label: 'User Type',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'userRoleId' ? sortOrder : 'DESC',
						dropDownOptions: roleOptions,
						filterType: 'DROPDOWN',
						cellType: 'HANDLER',
						handler: roleToHumanReadable
					},
					{
						id: 'lifeTimePoints',
						label: 'Points Over Life',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'lifeTimePoints' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'availablePoints',
						label: 'Current Points',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'availablePoints' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'accountNumber',
						label: 'Member Number',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'accountNumber' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'createdOn',
						label: 'Member Since',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'createdOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},
					{
						id: 'permissionLogin',
						label: 'Status',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'permissionLogin' ? sortOrder : 'DESC',
						filterType: 'CHECKBOX',
						cellType: 'BOOLEAN',
						textOptions: {
							false: 'Deactivated',
							true: 'Active'
						}
					},
					{
						id: 'phone',
						label: 'Phone',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'phone' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'HANDLER',
						handler: renderPhoneNumber
					},
					{
						id: 'primaryEmail',
						label: 'Email',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'primaryEmail' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'defaultAddress.city',
						label: 'City',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'city' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'city'
					},
					{
						id: 'defaultAddress.state',
						label: 'State',
						align: 'left',
						className: 'userCell',
						sort: sortField === 'state' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'state'
					}
				]}
				total={userTotal}
				data={userList}
				sortField={'accountNumber'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
				filterName={'Only Show Active'}
			/>
		</Page>
	);
};

export default UserListPage;

export function userListPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_READ]);
}
