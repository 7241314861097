import React, { useEffect, useState } from 'react';
import './UserReportingPermissions.scss';
import LabelSelect from '../labelSelect/LabelSelect';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import { OptionType } from '@bit/redsky.framework.rs.select';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import serviceFactory from '../../services/serviceFactory';
import UserBusinessService from '../../services/userBusiness/userBusiness.service';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Box from '../box/Box';
import CheckboxTree from '../checkboxTree/CheckboxTree';
import { ObjectUtils } from '../../utils/utils';

interface UserReportingPermissionsProps {
	className?: string;
	userBusinessLevel?: Model.UserBusinessLevel;
	companyIds?: number[];
	brandIds?: number[];
	locationIds?: number[];
	handleIds: (userBusinessLevel: Model.UserBusinessLevel, ids: number[]) => void;
}

const UserReportingPermissions: React.FC<UserReportingPermissionsProps> = (props) => {
	const userBusinessService = serviceFactory.get<UserBusinessService>('UserBusinessService');
	const loggedInUser = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const loggedInUserLevel: Model.UserBusinessLevel = loggedInUser?.businessAccesses.userBusinessLevel || 'NONE';
	const [reportingPermissionsOptions, setReportingPermissionsOptions] = useState<OptionType[]>([]);
	const [treeData, setTreeData] = useState<Api.UserBusiness.Res.Available>();
	const [reportingForm, setReportingForm] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('userBusinessLevel', 'NONE', [])])
	);

	useEffect(() => {
		async function getAvailableTreeData() {
			const res = await userBusinessService.getAvailable();
			setTreeData(res.data);
		}
		getAvailableTreeData().catch(console.error);
	}, []);

	useEffect(() => {
		switch (loggedInUserLevel) {
			case 'SUPER':
				setReportingPermissionsOptions([
					{ value: 'SUPER', label: 'Super' },
					{ value: 'COMPANY', label: 'Company Access' },
					{ value: 'BRAND', label: 'Brand Access' },
					{ value: 'LOCATION', label: 'Location Access' },
					{ value: 'NONE', label: 'None' }
				]);
				break;
			case 'COMPANY':
				setReportingPermissionsOptions([
					{ value: 'COMPANY', label: 'Company Access' },
					{ value: 'BRAND', label: 'Brand Access' },
					{ value: 'LOCATION', label: 'Location Access' },
					{ value: 'NONE', label: 'None' }
				]);
				break;
			case 'BRAND':
				setReportingPermissionsOptions([
					{ value: 'BRAND', label: 'Brand Access' },
					{ value: 'LOCATION', label: 'Location Access' },
					{ value: 'NONE', label: 'None' }
				]);
				break;
			case 'LOCATION':
				setReportingPermissionsOptions([
					{ value: 'LOCATION', label: 'Location Access' },
					{ value: 'NONE', label: 'None' }
				]);
				break;
			default:
				break;
		}
	}, []);

	useEffect(() => {
		let reportingFormCopy = reportingForm.get('userBusinessLevel');
		if (ObjectUtils.isArrayWithData(props.companyIds) && props.companyIds && props.companyIds[0] === 0) {
			reportingFormCopy.value = 'SUPER';
		} else if (ObjectUtils.isArrayWithData(props.companyIds) && props.companyIds && props.companyIds[0] !== 0) {
			reportingFormCopy.value = 'COMPANY';
		} else if (ObjectUtils.isArrayWithData(props.brandIds) && props.brandIds) {
			reportingFormCopy.value = 'BRAND';
		} else if (ObjectUtils.isArrayWithData(props.locationIds) && props.locationIds) {
			reportingFormCopy.value = 'LOCATION';
		} else {
			reportingFormCopy.value = 'NONE';
		}
		setReportingForm(reportingForm.clone().update(reportingFormCopy));
	}, [props.companyIds, props.locationIds, props.brandIds]);

	function updateReportingForm(control: RsFormControl) {
		setReportingForm(reportingForm.clone().update(control));
	}

	function renderCheckboxTree() {
		if (!ObjectUtils.isObject(treeData) || !treeData || reportingForm.get('userBusinessLevel').value === 'NONE')
			return;
		return (
			<CheckboxTree
				treeData={treeData}
				handleIds={props.handleIds}
				companyIds={props.companyIds}
				brandIds={props.brandIds}
				locationIds={props.locationIds}
				userBusinessLevel={reportingForm.get('userBusinessLevel').value as Model.UserBusinessLevel}
			/>
		);
	}

	function renderEditBusiness() {
		return (
			<Box className={'inputsContainer'}>
				<LabelSelect
					variant={'caption'}
					title={'Reporting Permissions'}
					control={reportingForm.get('userBusinessLevel')}
					updateControl={updateReportingForm}
					options={reportingPermissionsOptions}
				/>
				{renderCheckboxTree()}
			</Box>
		);
	}

	return (
		<div className={`rsUserReportingPermissions ${props.className || ''}`}>
			<Label variant={'h2'}>Business Access</Label>
			{renderEditBusiness()}
		</div>
	);
};

export default UserReportingPermissions;
