import { Service } from '../Service';
import { ObjectUtils } from '@bit/redsky.framework.rs.utils';
import packageJson from '../../../package.json';
import globalState, { getRecoilExternalValue } from '../../state/globalState';

export default class UploadService extends Service {
	uploadImage(file: File, data?: any, progress?: any): Promise<RedSky.RsResponseData<Api.Media.Res.Get>> {
		const user = getRecoilExternalValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
		if (!user) throw new Error('Must be logged in to do this');
		return new Promise((resolve, reject) => {
			let formData = new FormData();
			formData.append('file', file, file.name);
			for (let i in data) {
				if (!data[i]) continue;
				formData.append(i, data[i]);
			}
			if (file.type.includes('png')) {
				formData.append('keepTransparency', 'true');
			}

			let xhr = new XMLHttpRequest();
			let baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api/v1' : packageJson.uri;
			xhr.open('POST', baseURL + '/media/image', true);
			xhr.setRequestHeader('token', user.token);
			if (user.companyId) {
				xhr.setRequestHeader('company-id', user.companyId.toString());
			}
			xhr.setRequestHeader('Accept', 'application/json');
			xhr.responseType = 'json';
			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					let response: RedSky.RsResponseData<Api.Media.Res.Get>;
					if (xhr.responseType !== 'json') reject(xhr.responseText);
					response = ObjectUtils.smartParse(xhr.response);

					if (xhr.status < 200 || xhr.status > 299) reject(response);
					resolve(response);
				}
			};
			xhr.upload.onprogress = function (e) {
				if (progress) progress(Math.floor((e.loaded / e.total) * 100));
			};
			xhr.send(formData);
		});
	}
	uploadUserCsv(file: File, progress?: any): Promise<RedSky.RsResponseData<Api.Media.Res.Get>> {
		const user = getRecoilExternalValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
		if (!user) throw new Error('Must be logged in to do this');
		return new Promise((resolve, reject) => {
			let formData = new FormData();
			formData.append('file', file, file.name);
			let xhr = new XMLHttpRequest();
			let baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api/v1' : packageJson.uri;
			xhr.open('POST', baseURL + '/user/upload', true);
			xhr.setRequestHeader('token', user.token);
			if (user.companyId) {
				xhr.setRequestHeader('company-id', user.companyId.toString());
			}
			xhr.setRequestHeader('Accept', 'application/json');
			xhr.setRequestHeader('Accept', 'application/json');
			xhr.responseType = 'json';
			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					let response: RedSky.RsResponseData<Api.Media.Res.Get>;
					if (xhr.responseType !== 'json') reject(xhr.responseText);
					response = ObjectUtils.smartParse(xhr.response);

					if (xhr.status < 200 || xhr.status > 299) reject(response);
					resolve(response);
				}
			};
			xhr.upload.onprogress = function (e) {
				if (progress) progress(Math.floor((e.loaded / e.total) * 100));
			};
			xhr.send(formData);
		});
	}
}
