import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class AccommodationService extends Service {
	async getDetails(accommodationId: number): Promise<Api.Accommodation.Res.Details> {
		let res = await http.get<RsResponseData<Api.Accommodation.Res.Details>>(
			`accommodation/details?accommodationId=${accommodationId}`
		);
		return res.data.data;
	}

	async getPaged(data: any): Promise<Api.Accommodation.Res.AdminList> {
		let res = await http.get<any>('accommodation/list/admin', data);
		return res.data;
	}

	async createAccommodation(data: Api.Accommodation.Req.Create) {
		return await http.post<RsResponseData<Api.Accommodation.Res.Create>>('accommodation', data);
	}
	async updateAccommodation(data: Api.Accommodation.Req.Update) {
		return await http.put<RsResponseData<Api.Accommodation.Res.Update>>('accommodation', data);
	}

	async createAccommodationCategory(data: Api.AccommodationCategory.Req.Create) {
		return await http.post<RsResponseData<Api.AccommodationCategory.Res.Create>>('accommodation/category', data);
	}

	async updateAccommodationCategory(data: Api.AccommodationCategory.Req.Update) {
		return await http.put<RsResponseData<Api.AccommodationCategory.Res.Update>>('accommodation/category', data);
	}

	async deleteAccommodationCategory(id: number) {
		return await http.delete<{ data: number }>('accommodation/category', { id });
	}

	async createAccommodationLayout(data: Api.AccommodationLayout.Req.Create) {
		return await http.post<RsResponseData<Api.AccommodationLayout.Res.Create>>('accommodation/layout', data);
	}

	async updateAccommodationLayout(data: Api.AccommodationLayout.Req.Update) {
		return await http.put<RsResponseData<Api.AccommodationLayout.Res.Update>>('accommodation/layout', data);
	}

	async deleteAccommodationLayout(id: number) {
		return await http.delete<{ data: number }>('accommodation/layout', { id });
	}

	async createAccommodationLayoutRoom(data: Api.AccommodationLayoutRoom.Req.Create) {
		return await http.post<RsResponseData<Api.AccommodationLayoutRoom.Res.Create>>(
			'accommodation/layout/room',
			data
		);
	}

	async updateAccommodationLayoutRoom(data: Api.AccommodationLayoutRoom.Req.Update) {
		return await http.put<RsResponseData<Api.AccommodationLayoutRoom.Res.Update>>(
			'accommodation/layout/room',
			data
		);
	}

	async deleteAccommodationLayoutRoom(id: number) {
		return await http.delete<{ data: number }>('accommodation/layout/room', { id });
	}

	async getAllAmenities(): Promise<Api.Amenity.Res.Get[]> {
		const res = await http.get<RsResponseData<Api.Amenity.Res.Get[]>>('accommodation/amenity');
		return res.data.data;
	}

	async updateAmenity(data: Api.Amenity.Req.Update): Promise<Api.Amenity.Res.Update> {
		const res = await http.put<RsResponseData<Api.Amenity.Res.Update>>('accommodation/amenity', data);
		return res.data.data;
	}

	async deleteAmenity(data: Api.Amenity.Req.Delete): Promise<Api.Amenity.Res.Delete> {
		const res = await http.delete<RsResponseData<Api.Amenity.Res.Delete>>('accommodation/amenity', data);
		return res.data.data;
	}

	async createAmenity(data: Api.Amenity.Req.Create): Promise<Api.Amenity.Res.Create> {
		const res = await http.post<RsResponseData<Api.Amenity.Res.Create>>('accommodation/amenity', data);
		return res.data.data;
	}
}
