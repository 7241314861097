import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import * as React from 'react';
import './IconFeaturePopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Icon from '@bit/redsky.framework.rs.icon';
import { useEffect, useState } from 'react';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import LabelInput from '../../components/labelInput/LabelInput';
import ChooseIconPopup, { ChooseIconPopupProps } from '../chooseIconPopup/ChooseIconPopup';
import LabelButton from '../../components/labelButton/LabelButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { useMultiColoredIcon } from '../../customHooks/useMultiColoredIcon';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';

export interface IconFeaturePopupProps extends PopupProps {
	title: string;
	icon?: string;
	id?: number;
	onSave: (selectedIcon: string, title: string, id?: number) => void;
}

const IconFeaturePopup: React.FC<IconFeaturePopupProps> = (props) => {
	const [selectedIcon, setSelectedIcon] = useState<string>(props.icon || 'icon-01');
	const [isValid, setIsValid] = useState<boolean>(false);
	const [title, setTitle] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('title', props.title || 'Feature Text', [
				new RsValidator(RsValidatorEnum.REQ, 'Title is required'),
				new RsValidator(RsValidatorEnum.MAX, '20 character limit', 20)
			])
		])
	);

	useEffect(() => {
		async function checkFormIsValid() {
			let checkIsValid = await title.isValid();
			setIsValid(checkIsValid);
		}
		checkFormIsValid().catch(console.error);
	}, [title, selectedIcon]);

	function updateTitle(control: RsFormControl) {
		title.update(control);
		setTitle(title.clone());
	}

	async function save() {
		if (isValid) {
			props.onSave(selectedIcon, title.get('title').value.toString(), props.id);
			rsToastify.success('New icon added successfully', 'Success');
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsIconFeaturePopup'} borderRadius={'4px'} width={'550px'} padding={'25px'}>
				<Label variant={'h4'}>Create an Icon</Label>
				<Icon iconImg={'icon-close'} cursorPointer onClick={() => popupController.close(IconFeaturePopup)} />
				<Paper
					className={'featureTile'}
					height={'90px'}
					width={'344px'}
					borderRadius={'4px'}
					boxShadow
					padding={'0 25px'}
				>
					{useMultiColoredIcon(selectedIcon, 35)}
					<Label variant={'h2'}>{title.get('title').value}</Label>
				</Paper>
				<hr />
				<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
					<LabelInput
						title={'Label'}
						inputType={'text'}
						control={title.get('title')}
						updateControl={updateTitle}
					/>
					<LabelButton
						className={'addIconBtn'}
						look={'containedPrimary'}
						variant={'button'}
						label={'Add Icon'}
						onClick={() => {
							popupController.open<ChooseIconPopupProps>(ChooseIconPopup, {
								onSelect: (value) => {
									setSelectedIcon(value);
								}
							});
						}}
					/>
				</Box>
				<LabelButton
					className={'saveBtn'}
					look={'containedPrimary'}
					variant={'button'}
					label={'Save'}
					onClick={() => save()}
				/>
			</Paper>
		</Popup>
	);
};

export default IconFeaturePopup;
