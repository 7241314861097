import {
	StringUtils as BaseStringUtils,
	ObjectUtils as BaseObjectUtils,
	RegionUtils,
	WebUtils as BaseWebUtils,
	DateUtils as BaseDateUtils
} from '@bit/redsky.framework.rs.utils';
import moment from 'moment';

class WebUtils extends BaseWebUtils {
	/**
	 * Takes parameters and creates a pageQuery object
	 * @param page - must be a number
	 * @param perPage - must be a number
	 * @param sortOrder - one of a specific list of strings
	 * @param sortField - any string to sort on
	 * @param matchType - a list of specific strings
	 * @param filter - an array of column and value objects
	 */
	static createPageQueryObject(
		page: number = 1,
		perPage: number = 100,
		sortOrder: RedSky.StandardOrderTypes = 'ASC',
		sortField: string = 'name',
		matchType: RedSky.MatchTypes = 'like',
		filter: RedSky.FilterQueryValue[] = [{ column: 'name', value: '' }]
	): RedSky.PageQuery {
		return {
			pagination: { page, perPage },
			sort: { field: sortField, order: sortOrder },
			filter: { matchType, searchTerm: filter }
		};
	}
	/**
	 * Takes an object and stringifies any nested objects
	 * @param data - Must be typeof object
	 * @returns An object whose children are either numbers, strings, booleans, no nested objects
	 */
	static convertDataForUrlParams(data: any): any {
		let convertedData: any = {};
		for (let i in data) {
			if (typeof data[i] === 'object') {
				convertedData[i] = JSON.stringify(data[i]);
			} else {
				convertedData[i] = data[i];
			}
		}
		return convertedData;
	}

	static getAxiosErrorMessage(error: any): string {
		let errorResponse = '';
		if (error.response && error.response.data) {
			if (typeof error.response.data === 'object') errorResponse = JSON.stringify(error.response.data);
			else if (typeof error.response.data === 'string') errorResponse = error.response.data;
			else errorResponse = 'unknown error message';
		} else if (error.request) {
			errorResponse = error.request.message || error.request.statusText;
		} else {
			errorResponse = error.message;
		}
		return errorResponse;
	}

	/**
	 * Checks an thrown error object from an axios request for the standard RedSky Error Message
	 * @param error - Error object thrown via axios
	 * @param defaultMessage - A message to use incase there wasn't one given
	 * @returns The msg from the RsError object or the defaultMessage passed in
	 */
	static getRsErrorMessage(error: any, defaultMessage: string): string {
		let errorResponse = ObjectUtils.smartParse(WebUtils.getAxiosErrorMessage(error));
		if (typeof errorResponse !== 'object') return errorResponse;
		if ('msg' in errorResponse) return errorResponse.msg;
		else if ('err' in errorResponse) return errorResponse.err;
		return defaultMessage;
	}
}

class DateUtils extends BaseDateUtils {
	static displayUserDate(date: string | Date): string {
		let dateToReturn = new Date(date);
		let timeZoneOffset = dateToReturn.getTimezoneOffset() * 60000;
		dateToReturn.setTime(dateToReturn.getTime() + timeZoneOffset);
		return dateToReturn.toDateString();
	}

	static formatFilterDateForServer(date: moment.Moment | null, startOrEnd: 'start' | 'end'): string {
		if (date) {
			return date.format('YYYY-MM-DD');
		} else {
			if (startOrEnd === 'end') return moment().add(1, 'day').format('YYYY-MM-DD');
			else return moment().format('YYYY-MM-DD');
		}
	}
}

class ObjectUtils extends BaseObjectUtils {
	static areArraysEqual(array1: number[], array2: number[]): boolean {
		if (array1 === array2) return true;
		if (array1 == null || array2 == null) return false;
		if (array1.length !== array2.length) return false;

		for (let i = 0; i < array1.length; ++i) {
			if (array1[i] !== array2[i]) return false;
		}
		return true;
	}
}

class StringUtils extends BaseStringUtils {
	/**
	 * Takes a CamelCase String and spaces out the letters
	 * @param camelCaseString - string
	 * @returns a string that has spaces between the uppercase letters.
	 */
	static spaceOutCamelCaseString(camelCaseString: string): string {
		if (camelCaseString.includes('_')) {
			camelCaseString = camelCaseString.split('_')[0];
		}
		return camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');
	}

	static formatCountryCodePhoneNumber(phone: string) {
		let cleaned = ('' + phone).replace(/\D/g, '');
		let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			let intlCode = match[1] ? `+${match[1]} ` : '';
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		} else if (cleaned.length > 11) {
			return cleaned.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, '-');
		} else {
			return cleaned;
		}
	}
}

export { StringUtils, ObjectUtils, RegionUtils, WebUtils, DateUtils };

export const US_States = [
	{ value: 'AL', text: 'Alabama', selected: false },
	{ value: 'AK', text: 'Alaska', selected: false },
	{ value: 'AZ', text: 'Arizona', selected: false },
	{ value: 'AR', text: 'Arkansas', selected: false },
	{ value: 'CA', text: 'California', selected: false },
	{ value: 'CO', text: 'Colorado', selected: false },
	{ value: 'CT', text: 'Connecticut', selected: false },
	{ value: 'DE', text: 'Delaware', selected: false },
	{ value: 'DC', text: 'District of Columbia', selected: false },
	{ value: 'FL', text: 'Florida', selected: false },
	{ value: 'GA', text: 'Georgia', selected: false },
	{ value: 'HI', text: 'Hawaii', selected: false },
	{ value: 'ID', text: 'Idaho', selected: false },
	{ value: 'IL', text: 'Illinois', selected: false },
	{ value: 'IN', text: 'Indiana', selected: false },
	{ value: 'IA', text: 'Iowa', selected: false },
	{ value: 'KS', text: 'Kansas', selected: false },
	{ value: 'KY', text: 'Kentucky', selected: false },
	{ value: 'LA', text: 'Louisiana', selected: false },
	{ value: 'ME', text: 'Maine', selected: false },
	{ value: 'MD', text: 'Maryland', selected: false },
	{ value: 'MA', text: 'Massachusetts', selected: false },
	{ value: 'MI', text: 'Michigan', selected: false },
	{ value: 'MN', text: 'Minnesota', selected: false },
	{ value: 'MS', text: 'Mississippi', selected: false },
	{ value: 'MO', text: 'Missouri', selected: false },
	{ value: 'MT', text: 'Montana', selected: false },
	{ value: 'NE', text: 'Nebraska', selected: false },
	{ value: 'NV', text: 'Nevada', selected: false },
	{ value: 'NH', text: 'New Hampshire', selected: false },
	{ value: 'NJ', text: 'New Jersey', selected: false },
	{ value: 'NM', text: 'New Mexico', selected: false },
	{ value: 'NY', text: 'New York', selected: false },
	{ value: 'NC', text: 'North Carolina', selected: false },
	{ value: 'ND', text: 'North Dakota', selected: false },
	{ value: 'OH', text: 'Ohio', selected: false },
	{ value: 'OK', text: 'Oklahoma', selected: false },
	{ value: 'OR', text: 'Oregon', selected: false },
	{ value: 'PA', text: 'Pennsylvania', selected: false },
	{ value: 'RI', text: 'Rhode Island', selected: false },
	{ value: 'SC', text: 'South Carolina', selected: false },
	{ value: 'SD', text: 'South Dakota', selected: false },
	{ value: 'TN', text: 'Tennessee', selected: false },
	{ value: 'TX', text: 'Texas', selected: false },
	{ value: 'UT', text: 'Utah', selected: false },
	{ value: 'VT', text: 'Vermont', selected: false },
	{ value: 'VA', text: 'Virginia', selected: false },
	{ value: 'WA', text: 'Washington', selected: false },
	{ value: 'WV', text: 'West Virginia', selected: false },
	{ value: 'WI', text: 'Wisconsin', selected: false },
	{ value: 'WY', text: 'Wyoming', selected: false },
	{ value: 'AS', text: 'American Samoa', selected: false },
	{ value: 'GU', text: 'Guam', selected: false },
	{ value: 'MP', text: 'Northern Mariana Island', selected: false },
	{ value: 'PR', text: 'Puerto Rico', selected: false },
	{ value: 'VI', text: 'Virgin Islands', selected: false },
	{ value: 'AA', text: 'AA (Armed Forces Americas)', selected: false },
	{ value: 'AE', text: 'AE (Armed Forces Europe)', selected: false },
	{ value: 'AP', text: 'AP (Armed Forces Pacific)', selected: false }
];

export function addDeleteFilterQueryForTables(
	query: RedSky.FilterQueryValue[],
	filter: RedSky.FilterQueryValue[]
): RedSky.FilterQueryValue[] {
	const filterQuery: RedSky.FilterQueryValue[] = [...filter];
	if (!query) return [];
	for (let i in query) {
		let index = filterQuery.findIndex((el) => el.column === query[i].column);
		if (index !== -1) {
			query[i].value === '' ? filterQuery.splice(index, 1) : (filterQuery[index] = query[i]);
		} else {
			filterQuery.push(query[i]);
		}
	}
	return filterQuery;
}
