import React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label/dist/Label';

const ForbiddenPage: React.FC = () => {
	return (
		<Page>
			<Label variant={'h1'}>Forbidden. This has been reported to your superior!</Label>
		</Page>
	);
};

export default ForbiddenPage;
