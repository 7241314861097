import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class TierService extends Service {
	async create(data: Api.Tier.Req.Create): Promise<Api.Tier.Res.Get> {
		let response = await http.post<RsResponseData<Api.Tier.Res.Get>>('tier', data);
		return response.data.data;
	}

	async getTierFeatures(): Promise<Api.Tier.Res.GetFeatures[]> {
		let response = await http.get<RsResponseData<Api.Tier.Res.GetFeatures[]>>(`tier/features`);
		return response.data.data;
	}
}
