import * as React from 'react';
import { useEffect, useState } from 'react';
import './ManageUserPage.scss';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import Box from '../../components/box/Box';
import LabelData from '../../components/labelData/LabelData';
import LabelInput from '../../components/labelInput/LabelInput';
import Label from '@bit/redsky.framework.rs.label';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import router from '../../utils/router';
import UserService from '../../services/user/user.service';
import AddressCard from '../../components/addressCard/AddressCard';
import LinkButton from '../../components/linkButton/LinkButton';
import PaymentCard from '../../components/paymentCard/PaymentCard';
import LabelButton from '../../components/labelButton/LabelButton';
import LoadingPage from '../loadingPage/LoadingPage';
import { DateUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { OptionType } from '@bit/redsky.framework.rs.select';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import UserAddressService from '../../services/userAddress/userAddress.service';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import PaymentService from '../../services/payment/payment.service';
import UserReportingPermissions from '../../components/userReportingPermissions/UserReportingPermissions';
import UserTransactionList from '../../components/userTransactionList/UserTransactionList';

const ManageUserPage: React.FC = () => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const userService = serviceFactory.get<UserService>('UserService');
	const userAddressService = serviceFactory.get<UserAddressService>('UserAddressService');
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const [addressList, setAddressList] = useState<Api.User.Address[]>([]);
	const [paymentMethods, setPaymentMethods] = useState<Api.User.PaymentMethod[]>([]);
	const [selectedUser, setSelectedUser] = useState<Api.User.Res.Get>();
	const [refreshPage, setRefreshPage] = useState<number>(0);
	const [roleOptions, setRoleOptions] = useState<OptionType[]>([]);
	const [userBusinessIds, setUserBusinessIds] = useState<{
		companyIds?: number[];
		brandIds?: number[];
		brandLocationIds?: number[];
	}>({});
	const [form, setForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('userRoleId', 0, []),
			new RsFormControl('firstName', '', [new RsValidator(RsValidatorEnum.REQ, 'Must have a first name')]),
			new RsFormControl('lastName', '', [new RsValidator(RsValidatorEnum.REQ, 'Must have a last name')]),
			new RsFormControl('phone', '', [new RsValidator(RsValidatorEnum.REQ, 'Must have a phone number')]),
			new RsFormControl('primaryEmail', '', [
				new RsValidator(RsValidatorEnum.EMAIL, 'Must be a valid email address')
			]),
			new RsFormControl('allowEmailNotification', 0, [])
		])
	);

	const hasPermissionToEditUser = serviceFactory
		.get<PermissionService>('PermissionService')
		.hasPermission(PermissionList.USER_WRITE);

	const params = router.getPageUrlParams<{ userId: number }>([
		{ key: 'ui', default: 0, type: 'integer', alias: 'userId' }
	]);

	useEffect(() => {
		async function getUserDetails(id: number) {
			try {
				let userDetails = await userService.getUserById(id);
				setSelectedUser(userDetails);
				let copyForm = form.clone();
				let userRoleId = copyForm.get('userRoleId');
				userRoleId.value = userDetails.userRoleId;
				let firstName = copyForm.get('firstName');
				firstName.value = userDetails.firstName;
				let lastName = copyForm.get('lastName');
				lastName.value = userDetails.lastName;
				let phone = copyForm.get('phone');
				phone.value = userDetails.phone;
				let email = copyForm.get('primaryEmail');
				email.value = userDetails.primaryEmail;
				let notification = copyForm.get('allowEmailNotification');
				notification.value = Number(userDetails.allowEmailNotification);
				setForm(copyForm);
				setAddressList(userDetails.address);
				setPaymentMethods(userDetails.paymentMethods);
				let userRoles = userService.getUserRoles();
				setRoleOptions(
					userRoles.map((role) => {
						return { value: role.id, label: role.name };
					})
				);
			} catch (e) {
				console.error(e);
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Error retrieving user.'), 'Server Error');
			}
		}
		getUserDetails(params.userId).catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshPage]);

	async function deleteAddress(id: number) {
		if (!addressList) return;
		try {
			let response = await userAddressService.delete(id);
			setAddressList((prev) => prev.filter((address) => address.id !== id));
			if (response) rsToastify.success('Address successfully removed.', 'Delete Successful');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to delete address'), 'Unable To Delete');
		}
	}

	async function updateAddressToDefault(addressId: number) {
		let data = { id: addressId, isDefault: 1 };
		try {
			let response = await userAddressService.update(data);
			if (response) rsToastify.success('Address successfully updated.', 'Update Successful');
			let addresses = [...addressList];
			addresses = addresses.map((item) => {
				return { ...item, isDefault: item.id === addressId ? 1 : 0 };
			});
			setAddressList(addresses);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Address update failed, try again.'), 'Server Error');
		}
	}

	async function deletePaymentCard(id: number) {
		if (!user) return;
		popupController.open(SpinningLoaderPopup);
		try {
			await paymentService.delete(id);
			let newExistingCardList = [...paymentMethods];
			newExistingCardList = newExistingCardList.filter((item) => item.id !== id);
			setPaymentMethods(newExistingCardList);
			popupController.close(SpinningLoaderPopup);
		} catch (e: any) {
			popupController.close(SpinningLoaderPopup);
			console.error(e.message);
		}
	}

	async function setCardToPrimary(data: Api.Payment.Req.Update) {
		popupController.open(SpinningLoaderPopup);
		try {
			let res = await paymentService.update(data);
			let newExistingCardList = [...paymentMethods];
			newExistingCardList = newExistingCardList.map((item) => {
				return { ...item, isPrimary: item.id === res.id ? 1 : 0 };
			});
			setPaymentMethods(newExistingCardList);
			popupController.close(SpinningLoaderPopup);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to set as primary'), 'Server error');
			popupController.close(SpinningLoaderPopup);
		}
	}

	async function saveChanges() {
		if (!selectedUser) return;
		let isValid = await form.isValid();
		if (!isValid) {
			rsToastify.error('Please make sure everything is filled out', 'Missing Information');
			return;
		}
		if (
			typeof userBusinessIds?.brandIds !== 'undefined' &&
			userBusinessIds?.brandIds.length === 0 &&
			typeof userBusinessIds?.brandLocationIds !== 'undefined' &&
			userBusinessIds?.brandLocationIds.length === 0 &&
			typeof userBusinessIds?.companyIds !== 'undefined' &&
			userBusinessIds?.companyIds.length === 0
		) {
			rsToastify.error('Please select any business access types', 'Missing Information');
			return;
		}
		try {
			let userToUpdate: Api.User.Req.Update = form.toModel<Api.User.Req.Update>();
			if (!ObjectUtils.isEmptyObject(userBusinessIds)) {
				userToUpdate.businesses = userBusinessIds;
			}
			userToUpdate.id = selectedUser.id;
			let updatedUser = await userService.updateUser(userToUpdate);
			// If we are updating our own current user. Log them in again
			if (user && updatedUser.id === user.id) userService.loginUserByToken(user.token).catch(console.error);
			rsToastify.success('User Updated!', 'Success');
			setRefreshPage(refreshPage + 1);
			window.scrollTo(0, 0);
		} catch (e) {
			console.error(e);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Failed updating user'), 'Update Failed');
		}
	}

	function handleIds(userBusinessLevel: Model.UserBusinessLevel, ids: number[]) {
		if (userBusinessLevel === 'SUPER') {
			setUserBusinessIds({
				companyIds: [0]
			});
		} else if (userBusinessLevel === 'COMPANY') {
			setUserBusinessIds({
				companyIds: ids,
				brandIds: [],
				brandLocationIds: []
			});
		} else if (userBusinessLevel === 'BRAND') {
			setUserBusinessIds({
				companyIds: [],
				brandIds: ids,
				brandLocationIds: []
			});
		} else if (userBusinessLevel === 'LOCATION') {
			setUserBusinessIds({
				companyIds: [],
				brandIds: [],
				brandLocationIds: ids
			});
		}
	}

	function updateForm(control: RsFormControl) {
		setForm(form.clone().update(control));
	}

	async function deactivateAccount() {
		if (selectedUser) {
			try {
				await userService.deleteUser(selectedUser.id);
				rsToastify.success('User successfully deactivated.', 'Success!');
				setRefreshPage(refreshPage + 1);
			} catch (e) {
				rsToastify.error('Unable to deactivate this user.', 'Error!');
			}
		}
	}

	async function reactivateAccount() {
		if (selectedUser) {
			try {
				await userService.reactivateUser(selectedUser.id);
				rsToastify.success('User successfully reactivated.', 'Success!');
				setRefreshPage(refreshPage + 1);
			} catch (e) {
				rsToastify.error('Unable to reactivate this user.', 'Error!');
			}
		}
	}

	function renderPrimaryAddress() {
		let address: Api.User.Address | undefined;
		if (addressList.length < 1) return;
		if (addressList.length < 2) address = addressList[0];
		else {
			address = addressList.find((address) => address.isDefault === 1);
		}
		if (!address) return;
		else
			return (
				<AddressCard
					id={address.id}
					name={address.name}
					addressLine1={address.address1}
					addressLine2={address.address2}
					city={address.city}
					state={address.state}
					zip={address.zip}
					isDefault={!!address.isDefault || addressList.length <= 1}
					onDelete={() => {
						deleteAddress(address!.id).catch(console.error);
					}}
					country={address.country}
				/>
			);
	}

	function renderOtherAddresses() {
		if (addressList.length < 2) return;
		return addressList
			.filter((address) => address.isDefault !== 1)
			.map((address) => {
				return (
					<AddressCard
						key={address.id}
						id={address.id}
						name={address.name}
						addressLine1={address.address1}
						addressLine2={address.address2}
						city={address.city}
						state={address.state}
						zip={address.zip}
						isDefault={!!address.isDefault}
						onDelete={() => {
							deleteAddress(address.id).catch(console.error);
						}}
						onUseThisAddress={() => {
							updateAddressToDefault(address.id).catch(console.error);
						}}
						country={address.country}
					/>
				);
			});
	}

	function renderPaymentMethods() {
		if (paymentMethods.length < 1) return;
		return paymentMethods.map((method) => {
			return (
				<PaymentCard
					key={method.id}
					name={method.nameOnCard}
					cardType={method.type}
					lastFourCreditCard={method.last4.toString()}
					expDate={`${method.expirationMonth}/${method.expirationYear}`}
					selected={!!method.isPrimary}
					onDelete={() => {
						deletePaymentCard(method.id).catch(console.error);
					}}
					onSetPrimaryCard={() => {
						setCardToPrimary({ id: method.id, isPrimary: 1 }).catch(console.error);
					}}
				/>
			);
		});
	}

	return !selectedUser ? (
		<LoadingPage />
	) : (
		<Page className={'rsManageUserPage'}>
			<SubHeader
				header={`${selectedUser.firstName} ${selectedUser.lastName}`}
				buttonOptions={[
					{
						name: 'View Booking History',
						path: `/dashboard/user-list/manage-user/booking-history?ui=${params.userId}`
					},
					{
						name: 'View Order History',
						path: `/dashboard/user-list/manage-user/order-history?ui=${params.userId}`
					},
					{
						name: 'Manage Points',
						path: `/dashboard/user-list/manage-user/manage-points?ui=${selectedUser.id}`
					}
					// TODO add in when we know what this implies { name: 'Blacklist User', path: '/' }
				]}
				crumbs={[
					{ label: 'Dashboard', link: `/dashboard` },
					{ label: 'User List', link: `/dashboard/user-list` },
					{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${selectedUser.id}` }
				]}
			/>
			<Box className={'grid borderBottom'} display={'grid'}>
				<LabelInput
					title={'First Name'}
					control={form.get('firstName')}
					updateControl={updateForm}
					disabled={!hasPermissionToEditUser}
					inputType={'text'}
					placeholder={'First Name'}
				/>
				<LabelInput
					title={'Last Name'}
					inputType={'text'}
					control={form.get('lastName')}
					updateControl={updateForm}
					disabled={!hasPermissionToEditUser}
				/>
				<LabelInput
					title={'Phone'}
					inputType={'tel'}
					control={form.get('phone')}
					updateControl={updateForm}
					onChange={(value) => {
						let updatedPhone = form.get('phone');
						updatedPhone.value = value;
						setForm(form.clone().update(updatedPhone));
					}}
					initialValue={form.get('phone').value?.toString()}
					countryCodeEditable
					isPhoneInput
				/>
				<LabelInput
					title={'Email'}
					inputType={'text'}
					control={form.get('primaryEmail')}
					updateControl={updateForm}
					isEmailInput
				/>
				<LabelCheckbox
					value={''}
					isChecked={!!form.get('allowEmailNotification').value}
					text={'Receive e-mails with the latest promotions, offers, and Spire updates'}
					onSelect={() => {
						let notificationControl = form.getClone('allowEmailNotification');
						notificationControl.value = 1;
						updateForm(notificationControl);
					}}
					onDeselect={() => {
						let notificationControl = form.getClone('allowEmailNotification');
						notificationControl.value = 0;
						updateForm(notificationControl);
					}}
				/>
			</Box>
			<Box className={'borderBottom'}>
				<UserReportingPermissions
					handleIds={handleIds}
					companyIds={selectedUser.businesses?.companyIds}
					brandIds={selectedUser.businesses?.brandIds}
					locationIds={selectedUser.businesses?.brandLocationIds}
				/>
			</Box>
			<Box className={'grid borderBottom'} display={'grid'}>
				<LabelData
					title={'Current Points'}
					subTitle={StringUtils.addCommasToNumber(selectedUser.availablePoints || 0)}
					largeData
				/>
				<LabelData
					title={'Pending Points'}
					subTitle={StringUtils.addCommasToNumber(selectedUser.pendingPoints || 0)}
					largeData
				/>
				<LabelData
					title={'Lifetime Points'}
					subTitle={StringUtils.addCommasToNumber(selectedUser.lifeTimePoints || 0)}
					largeData
				/>
				<LabelData title={'Status'} subTitle={'Elite Member'} />
				<LabelSelect
					variant={'h2'}
					title={'User Role'}
					updateControl={(control) => {
						setForm(form.clone().update(control));
					}}
					control={form.get('userRoleId')}
					options={roleOptions}
				/>
				<LabelData title={'Member Number'} subTitle={selectedUser.accountNumber || selectedUser.id} />
				<LabelData title={'Member Since'} subTitle={DateUtils.displayDate(selectedUser.createdOn)} />
			</Box>
			<Box className="transactionSection borderBottom">
				<Label variant="h1">Points</Label>
				<UserTransactionList userId={params.userId} />
			</Box>
			<Box className={'borderBottom'}>
				<Label variant={'h1'}>Addresses</Label>
				<Box className={'addressCards'} margin={'25px 0'} display={'flex'}>
					<Box>
						<Label variant={'h2'}>Primary Address</Label>
						{renderPrimaryAddress()}
					</Box>
					<Box marginLeft={50}>
						<Label variant={'h2'}>Other Addresses</Label>
						<Box className={'otherAddresses'} display={'flex'}>
							{renderOtherAddresses()}
						</Box>
					</Box>
				</Box>
				<LabelButton
					className={'addNewAddressBtn'}
					look={'none'}
					variant={'button'}
					label={'Add new address'}
					onClick={() =>
						router.navigate(`/dashboard/user-list/manage-user/create-address?ui=${selectedUser.id}`)
					}
				/>
			</Box>
			<Box className={'borderBottom'}>
				<Label variant={'h1'}>Payment Methods</Label>
				<Box margin={'25px 0'} className={'paymentMethods'}>
					{renderPaymentMethods()}
				</Box>
				<LabelButton
					className={'addNewPaymentBtn'}
					look={'none'}
					variant={'button'}
					label={'Add new payment'}
					onClick={() => router.navigate(`/dashboard/user-list/manage-user/new-card?ui=${params.userId}`)}
				/>
			</Box>
			<Box className={'submitButtons'} display={'flex'}>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Save Changes'}
					onClick={saveChanges}
				/>
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Reset User Password'}
					onClick={async () => {
						try {
							await userService.requestPasswordByEmail(selectedUser.primaryEmail);
							rsToastify.success('Reset Password sent successfully', 'Success');
						} catch (e) {
							rsToastify.error('Something unexpected happened, could not reset password', 'Server error');
						}
					}}
				/>
				<LinkButton path={'/dashboard/user-list'} label={'Back to users'} />
			</Box>
			<LabelButton
				className={'deleteAccountButton'}
				look={'none'}
				variant={'caption'}
				label={selectedUser.permissionLogin ? 'Deactivate this Account' : 'Reactivate this Account'}
				onClick={selectedUser.permissionLogin ? deactivateAccount : reactivateAccount}
			/>
		</Page>
	);
};

export default ManageUserPage;

export function manageUserPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_READ]);
}
