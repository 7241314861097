import * as React from 'react';
import './AuditReportPopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Box from '../../components/box/Box';
import serviceFactory from '../../services/serviceFactory';
import { useState } from 'react';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../../utils/utils';
import SpireTable from '../../components/spireTable/SpireTable';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import LoadingPage from '../../pages/loadingPage/LoadingPage';
import UserPointService from '../../services/userPoint/userPoint.service';
import LabelButton from '../../components/labelButton/LabelButton';

export interface AuditLogPopupProps extends PopupProps {}

const AuditLogPopup: React.FC<AuditLogPopupProps> = (props) => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const pointService = serviceFactory.get<UserPointService>('UserPointService');
	const [auditLogList, setAuditLogList] = useState<Api.AuditLog[]>([]);
	const [sortField, setSortField] = useState<string>('createdOn');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [auditLogTotal, setAuditLogTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await pointService.getAuditLogByPage(pageQuery);
			setAuditLogList(res.data);
			setAuditLogTotal(res.total);
			setLoading(false);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			popupController.close(AuditLogPopup);
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	async function onDownloadCSV() {
		let hiddenElement: HTMLAnchorElement = document.createElement('a');
		hiddenElement.href = `/api/v1/user/auditlog/export?token=${user?.token || ''}`;
		hiddenElement.target = '_blank';
		hiddenElement.download = 'Exported CSV';
		hiddenElement.click();
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsAuditLogPopupReportPopup'}>
				<Box className={'titleContainer'}>
					<Label variant={'h2'}>Audit Log Report Summary</Label>
					<Box className={'exportButtonContainer'}>
						{/* <LabelButton
							look={'containedPrimary'}
							variant={'button'}
							label={'Export to CSV'}
							onClick={onDownloadCSV}
						/> */}
						<Icon
							className={'closeIcon'}
							iconImg={'icon-close'}
							cursorPointer
							onClick={() => popupController.close(AuditLogPopup)}
						/>
					</Box>
				</Box>
				{loading && <LoadingPage />}
				<SpireTable
					table={{
						placeholder: 'Search by first name',
						filterQuery: [
							{ column: 'id', value: '' },
							{ column: 'firstName', value: '', conjunction: 'OR' }
						]
					}}
					columns={[
						{
							id: 'createdOn',
							label: 'Date',
							align: 'left',
							className: 'AuditLogCell',
							sort: sortField === 'createdOn' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'DATE'
						},
						{
							id: 'firstName',
							label: 'firstName',
							align: 'left',
							className: 'AuditLogCell',
							sort: sortField === 'firstName' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'lastName',
							label: 'lastName',
							align: 'left',
							className: 'AuditLogCell',
							sort: sortField === 'lastName' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},

						{
							id: 'globalPointCost',
							label: 'globalPointCost',
							align: 'left',
							className: 'AuditLogCell',
							sort: sortField === 'globalPointCost' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'globalRedeemPointsPerDollar',
							label: 'globalRedeemPointsPerDollar',
							align: 'left',
							className: 'AuditLogCell',
							sort: sortField === 'globalRedeemPointsPerDollar' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						},
						{
							id: 'globalRewardPointsPerDollar',
							label: 'globalRewardPointsPerDollar',
							align: 'left',
							className: 'AuditLogCell',
							sort: sortField === 'globalRewardPointsPerDollar' ? sortOrder : 'DESC',
							filterType: 'NORMAL',
							cellType: 'TEXT'
						}
					]}
					data={auditLogList}
					total={auditLogTotal}
					sortField={sortField}
					onGetData={getData}
					rowOnClick={() => {}}
					changeSort={changeSort}
				/>
			</Paper>
		</Popup>
	);
};

export default AuditLogPopup;
