import * as React from 'react';
import { useEffect, useState } from 'react';
import './LocationDetailsPage.scss';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import LabelData from '../../components/labelData/LabelData';
import LabelInput from '../../components/labelInput/LabelInput';
import Box from '../../components/box/Box';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import serviceFactory from '../../services/serviceFactory';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import router from '../../utils/router';
import BrandService from '../../services/brand/brand.service';
import BrandLocationTransactionList from '../../components/brandLocationTransactionList/BrandLocationTransactionList';
import LabelButton from '../../components/labelButton/LabelButton';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import EditAddressPopup, { EditAddressPopupProps } from '../../popups/editAddressPopup/EditAddressPopup';

interface IBaseCountry {
	name: string;
	isoCode: string;
}

const LocationDetailsPage: React.FC = () => {
	const params = router.getPageUrlParams<{ locationId: number }>([
		{ key: 'li', default: 0, type: 'integer', alias: 'locationId' }
	]);
	const brandService = serviceFactory.get<BrandService>('BrandService');
	const [locationDetails, setLocationDetails] = useState<Api.Brand.Res.Location.Details>();
	const [isLocationDetailsFormActive, setIsLocationDetailsFormActive] = useState<boolean>(true);
	const [locationDetailsForm, setLocationDetailsForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('name', '', [new RsValidator(RsValidatorEnum.REQ, 'Name is required')]),
			new RsFormControl('address1', '', [new RsValidator(RsValidatorEnum.REQ, 'Address is required')]),
			new RsFormControl('address2', '', []),
			new RsFormControl('city', '', [new RsValidator(RsValidatorEnum.REQ, 'City is required')]),
			new RsFormControl('state', '', []),
			new RsFormControl('stateCode', ''),
			new RsFormControl('zip', '', [new RsValidator(RsValidatorEnum.REQ, 'Zip is required')]),
			new RsFormControl('country', '', [new RsValidator(RsValidatorEnum.REQ, 'Country is required')]),
			new RsFormControl('countryCode', ''),
			new RsFormControl('loyaltyStatus', 'active', []),
			new RsFormControl('isActive', 1, [])
		])
	);
	const [pointsForm, setPointsForm] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('pointsPerDollar', '', []), new RsFormControl('costPerPoint', '', [])])
	);

	useEffect(() => {
		(async () => {
			if (!params.locationId) return;
			try {
				if (!params.locationId) return;
				let res = await brandService.getLocationDetails(params.locationId);
				setLocationDetails(res);
				locationDetailsForm.get('name').value = res.name;
				locationDetailsForm.get('address1').value = res.address1;
				locationDetailsForm.get('address2').value = res.address2;
				locationDetailsForm.get('city').value = res.city;
				locationDetailsForm.get('state').value = res.state;
				locationDetailsForm.get('stateCode').value = res.stateCode;
				locationDetailsForm.get('zip').value = res.zip;
				locationDetailsForm.get('country').value = res.country;
				locationDetailsForm.get('countryCode').value = res.countryCode;
				locationDetailsForm.get('loyaltyStatus').value = res.loyaltyStatus;
				locationDetailsForm.get('isActive').value = Number(res.isActive);
				locationDetailsForm.updateInitialValues();
				setLocationDetailsForm(locationDetailsForm.clone());

				pointsForm.get('pointsPerDollar').value = res.pointsPerDollar || '';
				pointsForm.get('costPerPoint').value = res.costPerPoint || '';
				pointsForm.updateInitialValues();
				setPointsForm(pointsForm.clone());
			} catch (e) {
				rsToastify.error('Error getting location details');
			}
		})();
	}, []);

	function resetFormControl() {
		if (!locationDetails) return;
		locationDetailsForm.get('name').value = locationDetails.name;
		locationDetailsForm.get('address1').value = locationDetails.address1;
		locationDetailsForm.get('address2').value = locationDetails.address2;
		locationDetailsForm.get('city').value = locationDetails.city;
		locationDetailsForm.get('state').value = locationDetails.state;
		locationDetailsForm.get('stateCode').value = locationDetails.stateCode;
		locationDetailsForm.get('zip').value = locationDetails.zip;
		locationDetailsForm.get('country').value = locationDetails.country;
		locationDetailsForm.get('countryCode').value = locationDetails.countryCode;
		locationDetailsForm.get('loyaltyStatus').value = locationDetails.loyaltyStatus;
		locationDetailsForm.get('isActive').value = locationDetails.isActive;
		locationDetailsForm.updateInitialValues();
		setLocationDetailsForm(locationDetailsForm.clone());
	}

	function updatePointsForm(control: RsFormControl) {
		if (control.key == 'pointsPerDollar' || control.key == 'costPerPoint') {
			var t: any = control.value;
			control.value = t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 5) : t;
		}
		setPointsForm(pointsForm.clone().update(control));
	}

	function updateLocationDetailsForm(control: RsFormControl) {
		if (!control) return;
		setLocationDetailsForm(locationDetailsForm.clone().update(control));
	}

	function getPointRate() {
		let costPerPoint = pointsForm.get('costPerPoint').value;
		let pointsPerDollar = pointsForm.get('pointsPerDollar').value;

		if (costPerPoint === '' && pointsPerDollar === '') return undefined;

		if ((costPerPoint === '' && pointsPerDollar !== '') || (costPerPoint !== '' && pointsPerDollar === '')) {
			rsToastify.error(
				'Points Per Dollar and Cost Per Points must both have information.',
				'Missing Information!'
			);
		}
		return { costPerPoint: Number(costPerPoint), pointsPerDollar: Number(pointsPerDollar) };
	}

	async function saveLocationDetails() {
		if (!locationDetails) return;
		if (!(await locationDetailsForm.isValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}

		let locationDetailsChanges: Api.Brand.Req.Update = locationDetailsForm.toModel();
		let pointsChanges: Api.PointRate | undefined = getPointRate();

		if (pointsChanges?.costPerPoint === 0 || pointsChanges?.pointsPerDollar === 0) return;
		if (pointsChanges !== undefined) locationDetailsChanges.pointRate = pointsChanges;

		try {
			const res = await brandService.updateLocation({ ...locationDetailsChanges, id: params.locationId });
			setLocationDetails(res);
			pointsForm.updateInitialValues();
			locationDetailsForm.updateInitialValues();
			setLocationDetailsForm(locationDetailsForm.clone());
			setPointsForm(pointsForm.clone());
			rsToastify.success('Brand location successfully updated', 'Success!');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unknown server error has occurred.'), 'Error!');
		}
	}

	return (
		<Page className={'rsLocationDetailsPage'}>
			<SubHeader
				header={locationDetails?.name || ''}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Brand List', link: `/dashboard/brand-list` },
					{
						label: 'Brand Details',
						link: `/dashboard/brand-list/brand-details?bi=${locationDetails?.brandId}`
					},
					{
						label: 'Location Details',
						link: `/dashboard/brand-list/brand-details/location-details?li=${params.locationId}`
					}
				]}
			/>
			<div className={'pageWrapper'}>
				<Box className={'locationDetailsFormWrapper'}>
					<Box className={'idNameWrapper'}>
						<LabelData title={'Id'} subTitle={params.locationId} />
						<LabelInput
							title={'Name'}
							labelVariant={'h2'}
							inputType={'text'}
							control={locationDetailsForm.get('name')}
							updateControl={updateLocationDetailsForm}
							disabled={!isLocationDetailsFormActive}
						/>
					</Box>
					<div className={'addressWrapper'}>
						<Box className={'addressTitleWrapper'}>
							<Label variant={'h2'}>Address</Label>
							<LabelButton
								look={'containedPrimary'}
								variant={'button'}
								label={'Edit'}
								onClick={() => {
									if (!locationDetails) return;
									popupController.open<EditAddressPopupProps>(EditAddressPopup, {
										locationDetailsForm: locationDetailsForm,
										resetFormControl: resetFormControl,
										updateLocationDetailsForm: (formGroup) => {
											setLocationDetailsForm(formGroup);
										}
									});
								}}
							/>
						</Box>
						<Label variant={'h2'}>{locationDetailsForm.get('address1').value}</Label>
						<Label variant={'h2'}>{locationDetailsForm.get('address2').value}</Label>
						<Label variant={'h2'}>
							{locationDetailsForm.get('city').value}, {locationDetailsForm.get('state').value}{' '}
							{locationDetailsForm.get('zip').value} {locationDetailsForm.get('country').value}
						</Label>
					</div>
					<Box className={'statusWrapper'}>
						<LabelSelect
							title={'Loyalty Status'}
							variant={'h2'}
							options={[
								{ value: 'ACTIVE', label: 'Active' },
								{ value: 'PENDING', label: 'Pending' },
								{ value: 'FROZEN', label: 'Frozen' }
							]}
							control={locationDetailsForm.get('loyaltyStatus')}
							updateControl={updateLocationDetailsForm}
							isDisabled={!isLocationDetailsFormActive}
						/>
						<LabelSelect
							title={'Status'}
							variant={'h2'}
							options={[
								{ value: 1, label: 'Active' },
								{ value: 0, label: 'Inactive' }
							]}
							control={locationDetailsForm.get('isActive')}
							updateControl={updateLocationDetailsForm}
							isDisabled={!isLocationDetailsFormActive}
						/>
					</Box>
					<Box className={'pointsWrapper'}>
						<LabelInput
							title={'Points Per Dollar'}
							labelVariant={'h2'}
							inputType={'text'}
							control={pointsForm.get('pointsPerDollar')}
							updateControl={updatePointsForm}
							disabled={!isLocationDetailsFormActive}
						/>
						<LabelInput
							title={'Cost Per Point'}
							labelVariant={'h2'}
							inputType={'text'}
							control={pointsForm.get('costPerPoint')}
							updateControl={updatePointsForm}
							disabled={!isLocationDetailsFormActive}
						/>
						<Box marginTop={24}>
							<LabelButton
								className={'saveButton'}
								look={
									locationDetailsForm.isModified() || pointsForm.isModified()
										? 'containedPrimary'
										: 'containedSecondary'
								}
								disabled={!(locationDetailsForm.isModified() || pointsForm.isModified())}
								variant={'button'}
								label={'Save'}
								onClick={saveLocationDetails}
							/>
						</Box>
					</Box>
				</Box>
				<hr />
				<BrandLocationTransactionList
					locationId={params.locationId}
					locationName={locationDetailsForm.get('name').value.toString()}
					costPerPoint={locationDetails?.costPerPoint || 0}
					pointsAccrued={locationDetails?.pointAccrual || 0}
					pointRedeemed={locationDetails?.pointRedeemed || 0}
				/>
			</div>
		</Page>
	);
};

export default LocationDetailsPage;
