import * as React from 'react';
import './AmenityPopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import Box from '../../components/box/Box';
import { useEffect, useState } from 'react';
import LabelButton from '../../components/labelButton/LabelButton';
import IconTile from '../../components/iconTile/IconTile';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import AccommodationService from '../../services/accommodation/accommodation.service';
import serviceFactory from '../../services/serviceFactory';

export interface AmenityPopupProps extends PopupProps {
	amenityIds?: number[];
	onSave: (amenityIds: number[]) => void;
}

const AmenityPopup: React.FC<AmenityPopupProps> = (props) => {
	const accommodationService: AccommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const [amenityIds, setAmenityIds] = useState<number[]>(props.amenityIds || []);
	const [amenityIcons, setAmenityIcons] = useState<Model.Amenity[]>([]);

	useEffect(() => {
		async function getAllAmenities() {
			try {
				const newAmenities = await accommodationService.getAllAmenities();
				setAmenityIcons(newAmenities);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to find amenities.'), 'Error!');
			}
		}
		getAllAmenities().catch(console.error);
	}, []);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsAmenityPopup'} borderRadius={'4px'} width={'1000px'} padding={'25px'}>
				<Icon
					iconImg={'icon-close'}
					className={'closeIcon'}
					onClick={() => popupController.close(AmenityPopup)}
					size={21}
					color={'#003A76'}
					cursorPointer
				/>
				<Box className={'title'}>
					<Label variant={'h1'}>
						{ObjectUtils.isArrayWithData(props.amenityIds) ? 'Edit Amenities' : 'Select Amenities To Add'}
					</Label>
				</Box>
				<Box>
					<Box className={'experienceIconsContainer'}>
						{amenityIcons.map((item) => {
							return (
								<IconTile
									key={item.id}
									amenityId={item.id}
									amenityIds={amenityIds}
									icon={item.icon}
									title={item.title}
									onSelect={() => {
										let modifiedArray = amenityIds;
										modifiedArray.push(item.id);
										setAmenityIds(modifiedArray);
									}}
									onDeselect={() => {
										let modifiedArray = amenityIds;
										const amenityToRemove = amenityIds.findIndex((element) => element === item.id);
										modifiedArray.splice(amenityToRemove, 1);
										setAmenityIds(modifiedArray);
									}}
								/>
							);
						})}
					</Box>
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Save'}
						onClick={() => {
							if (ObjectUtils.isArrayWithData(amenityIds)) {
								props.onSave(amenityIds);
								popupController.close(AmenityPopup);
							} else {
								rsToastify.error('No amenities selected', 'Select an Amenity!');
							}
						}}
					/>
				</Box>
			</Paper>
		</Popup>
	);
};

export default AmenityPopup;
