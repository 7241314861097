import React, { useEffect, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import disableRoute from '../../customHooks/useMiddleware';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import './AddEditCampaignPage.scss';
import LabelInput from '../../components/labelInput/LabelInput';
import { SingleDatePicker } from 'react-dates';
import Box from '../../components/box/Box';
import CampaignService from '../../services/campaign/campaign.service';
import router from '../../utils/router';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import LabelButton from '../../components/labelButton/LabelButton';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import LoadingPage from '../loadingPage/LoadingPage';
import moment from 'moment';
import { DateUtils, WebUtils } from '../../utils/utils';
import SubHeader from '../../components/subHeader/SubHeader';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import CampaignSelect from './campaignSelect/CampaignSelect';
import Icon from '@bit/redsky.framework.rs.icon';
import ConfirmDeletePopup, { ConfirmDeletePopupProps } from '../../popups/confirmDeletePopup/ConfirmDeletePopup';

const AddEditCampaignPage: React.FC = () => {
	const campaignService = serviceFactory.get<CampaignService>('CampaignService');
	const params: any = router.getPageUrlParams<{ campaignId: number }>([
		{ key: 'ci', default: 0, type: 'integer', alias: 'campaignId' }
	]);

	const [loaded, setLoaded] = useState<boolean>(false);
	const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
	const [startOn, setStartOn] = useState<moment.Moment>(moment());
	const [endOn, setEndOn] = useState<moment.Moment>(moment());
	const [createdOn, setCreatedOn] = useState<Date | string>(new Date());
	const [actions, setActions] = useState<Api.Campaign.Action[]>([]);
	const [type, setType] = useState<string>('POINTS');
	const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>();
	const [actionOptions, setActionOptions] = useState<Model.Action[]>([]);
	const [description, setDescription] = useState<string>('');
	const [campaignForm, setCampaignForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('name', '', [new RsValidator(RsValidatorEnum.REQ, 'Name is required')]),
			new RsFormControl('maxReward', 0, []),
			new RsFormControl('totalActionPoints', 0, []),
			new RsFormControl('campaignReward', 0, []),
			new RsFormControl('rewardType', 'POINTS', [new RsValidator(RsValidatorEnum.REQ, 'Select a reward type.')])
		])
	);

	useEffect(() => {
		disableRoute.useMiddleware();
	}, []);

	useEffect(() => {
		async function getActions() {
			try {
				const res = await campaignService.getAllActions();
				setActionOptions(res);
				setOptionsLoaded(true);
				setLoaded(true);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unknown server error has occurred.'), 'Error!');
			}
		}
		async function getCampaign() {
			try {
				if (params.campaignId) {
					const res = await campaignService.getCampaignById(params.campaignId);
					setCampaignInfo(res);
				}
				setLoaded(true);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unknown server error has occurred.'), 'Error!');
			}
		}
		getActions().catch(console.error);
		getCampaign().catch(console.error);
	}, []);

	useEffect(() => {
		let actionPoints = actions.reduce((total, current) => {
			return total + current.actionCount * current.pointValue;
		}, 0);
		let totalActionPoints = campaignForm.get('totalActionPoints');
		totalActionPoints.value = actionPoints;
		campaignForm.update(totalActionPoints);
		setCampaignForm(campaignForm.clone());
	}, [actions]);

	function setCampaignInfo(res: Api.Campaign.Res.Update | Api.Campaign.Res.Create) {
		let name = campaignForm.get('name');
		let maxReward = campaignForm.get('maxReward');
		let campaignReward = campaignForm.get('campaignReward');
		name.value = res.name;
		maxReward.value = res.maxReward;
		campaignReward.value = res.completionPoints;
		campaignForm.update(name);
		campaignForm.update(maxReward);
		campaignForm.update(campaignReward);
		setCampaignForm(campaignForm.clone());

		setStartOn(moment(res.startOn));
		setEndOn(moment(res.endOn));
		setCreatedOn(res.createdOn);
		setDescription(res.description);
		setActions(res.actions);
		setType(res.type);
		setLoaded(true);
	}

	async function updateCampaignForm(control: RsFormControl) {
		setCampaignForm(campaignForm.clone().update(control));
	}

	async function validateCampaign(): Promise<boolean> {
		if (actions.length < 1) return false;
		let actionsChosen: boolean = true;
		actions.forEach((action) => {
			if (action.id === -1) {
				actionsChosen = false;
				return;
			}
		});
		return actionsChosen;
	}

	async function checkIsFormValid(): Promise<boolean> {
		let formIsValid = await campaignForm.isValid();
		setCampaignForm(campaignForm.clone());
		return formIsValid;
	}

	async function saveCampaign() {
		if (!(await validateCampaign())) {
			rsToastify.error('Add or fill in missing action information.', 'Action Required!');
			return;
		}
		if (!(await checkIsFormValid())) {
			rsToastify.error('Missing or incorrect information in form', 'Missing Information!');
			return;
		}
		setLoaded(false);
		setOptionsLoaded(false);
		const createMany: Api.CampaignAction.CreateMany[] = actions.map((action) => {
			return { actionId: action.id, actionCount: action.actionCount, pointValue: action.pointValue };
		});
		let completionPoints: number = parseInt(campaignForm.get('campaignReward').value.toString());
		let maxReward: number = parseInt(campaignForm.get('maxReward').value.toString());
		let res: Api.Campaign.Res.Update | Api.Campaign.Res.Create;
		if (maxReward === 0 && completionPoints === 0) {
			rsToastify.error(
				'Need either a "Max Action Point Allotment" or a "Campaign Point Allotment."',
				'Point value needed!'
			);
			return;
		}
		try {
			if (params.campaignId) {
				res = await campaignService.updateCampaign(
					params.campaignId,
					campaignForm.get('name').value.toString(),
					maxReward,
					type,
					DateUtils.clientToServerDate(startOn.toDate()),
					DateUtils.clientToServerDate(endOn.toDate()),
					createMany,
					completionPoints,
					1,
					0,
					0,
					'1,2,3',
					description
				);
				if (res) {
					await router.navigate('/dashboard/reward-list/campaigns');
					rsToastify.success('Campaign successfully modified', 'Success!');
					setCampaignInfo(res);
				}
			} else {
				res = await campaignService.createCampaign(
					campaignForm.get('name').value.toString(),
					maxReward,
					type,
					DateUtils.clientToServerDate(startOn.toDate()),
					DateUtils.clientToServerDate(endOn.toDate()),
					createMany,
					completionPoints,
					1,
					0,
					0,
					'1,2,3',
					description
				);
				if (res) {
					await router.navigate('/dashboard/reward-list/campaigns');
					rsToastify.success('Campaign successfully created', 'Success!');
					setCampaignInfo(res);
				}
			}
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			setLoaded(true);
			setOptionsLoaded(true);
		}
	}

	const deleteHandler = () => {
		popupController.open<ConfirmDeletePopupProps>(ConfirmDeletePopup, {
			onDelete: () => {
				deleteCampaign();
			}
		});
	};

	async function deleteCampaign() {
		try {
			await campaignService.deleteCampaign(params.campaignId);
			await router.navigate('/dashboard/reward-list/campaigns');
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function handleModifyActions(index: number, actionId: number) {
		let addedAction = actionOptions.find((action) => actionId === action.id);
		if (addedAction) {
			let tempActions: Api.Campaign.Action[] = [...actions];
			tempActions[index] = {
				...addedAction,
				campaignActionId: -1,
				actionCount: actions[index].actionCount
			};
			setActions(tempActions);
		}
	}

	function updateActionCount(index: number, value: number) {
		let campaignActionsCopy = [...actions];
		let actionToUpdate = campaignActionsCopy[index];
		actionToUpdate.actionCount = Math.max(value, 1);
		campaignActionsCopy.splice(index, 1, actionToUpdate);
		setActions(campaignActionsCopy);
	}

	function updateActionPoints(index: number, value: number) {
		let campaignActionsCopy = [...actions];
		let actionToUpdate = campaignActionsCopy[index];
		actionToUpdate.pointValue = Math.max(value, 1);
		campaignActionsCopy.splice(index, 1, actionToUpdate);
		setActions(campaignActionsCopy);
	}

	function renderCampaignActions() {
		return actions.map((action, index) => {
			return (
				<div className={'actionRepeat'} key={action.id}>
					<Box className={'campaignActionInfo'}>
						<CampaignSelect
							options={actionOptions}
							action={action}
							handleModifyActions={(actionId: number) => handleModifyActions(index, actionId)}
						/>
						<Box display={'flex'}>
							<LabelInput
								labelVariant={'h3'}
								title={'Repeat Action'}
								onChange={(value) => updateActionCount(index, value)}
								inputType={'number'}
								hasNoFormControl={true}
								initialValue={action.actionCount}
							/>
							<Box className={'toolTip'}>
								<Icon iconImg={'cms-icon-0357'} size={12} />
								<Box className={'toolTipText'}>
									<Label>
										Number of times a user can complete an action and still receive points for
										performing the action.
									</Label>
								</Box>
							</Box>
						</Box>
						<LabelInput
							labelVariant={'h3'}
							title={'Action Point Value'}
							inputType={'number'}
							onChange={(value) => updateActionPoints(index, value)}
							hasNoFormControl={true}
							initialValue={action.pointValue}
						/>
						<LabelButton
							look={'containedSecondary'}
							variant={'body1'}
							label={'Delete'}
							onClick={() => {
								let copyActions = [...actions];
								copyActions.splice(index, 1);
								setActions(copyActions);
							}}
						/>
					</Box>
					<hr className={'cardDivider'} />
				</div>
			);
		});
	}

	return !loaded && !optionsLoaded ? (
		<LoadingPage />
	) : (
		<Page className={'rsAddEditCampaignPage'}>
			<SubHeader
				header={`${!!params.campaignId ? 'Edit' : 'Create'} Campaign`}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Campaigns', link: '/dashboard/reward-list/campaigns' },
					{ label: 'Manage Campaign', link: '/dashboard/reward-list/campaigns/create-campaign' }
				]}
			/>
			<Box className={'campaignInfo'}>
				<LabelInput
					labelVariant={'h3'}
					title={'Campaign Name'}
					onChange={(value) => {}}
					inputType={'text'}
					control={campaignForm.get('name')}
					updateControl={updateCampaignForm}
				/>
				<Box display={'flex'}>
					<Box className={'labelDateGroup'} paddingRight={15}>
						<Label variant={'h3'}>Start Date</Label>
						<SingleDatePicker
							id={'campaignStartDate'}
							date={startOn ? startOn : null}
							onDateChange={(date) => {
								setStartOn(date ? date : moment(new Date()));
							}}
							focused={focusedInput === 'startDate'}
							onFocusChange={({ focused }) => {
								if (focused) {
									setFocusedInput('startDate');
								} else {
									setFocusedInput(null);
								}
							}}
							numberOfMonths={1}
						/>
					</Box>
					<Box className={'labelDateGroup'}>
						<Label variant={'h3'}>End Date</Label>
						<SingleDatePicker
							id={'campaignEndDate'}
							date={endOn ? endOn : null}
							onDateChange={(date) => setEndOn(date ? date : moment(new Date()))}
							focused={focusedInput === 'endDate'}
							onFocusChange={({ focused }) => {
								if (focused) {
									setFocusedInput('endDate');
								} else {
									setFocusedInput(null);
								}
							}}
							numberOfMonths={1}
						/>
					</Box>
				</Box>
				<Box>
					<Label variant={'h3'}>Created On</Label>
					<Label variant={'body1'} className={'readOnly'}>
						{DateUtils.displayDate(createdOn)}
					</Label>
				</Box>
			</Box>
			<hr className="cardDivider" />
			{renderCampaignActions()}
			<LabelButton
				look={'containedPrimary'}
				variant={'body1'}
				label={'Add An Action'}
				onClick={() =>
					setActions((list) => [
						...list,
						{
							id: 0,
							companyId: 0,
							brandId: 0,
							brandLocationId: 0,
							name: '',
							description: '',
							createdOn: '',
							modifiedOn: '',
							isActive: 1,
							type: '',
							pointValue: 0,
							campaignActionId: -1,
							actionCount: 0
						}
					])
				}
			/>
			<hr className={'cardDivider'} />
			<Box className={'bottomSection'} paddingTop={'20px'}>
				<Box className={'campaignPointsInfo'}>
					<Box display={'flex'} paddingBottom={'20px'}>
						<LabelInput
							className={'readOnly'}
							title={'Current Value Of All Action Points'}
							labelVariant={'h3'}
							inputType={'number'}
							control={campaignForm.get('totalActionPoints')}
							disabled={true}
						/>
						<Box className={'toolTip'}>
							<Icon iconImg={'cms-icon-0357'} size={12} />
							<Box className={'toolTipText'}>
								<Label>
									Total of all points available through completing actions. Changes reflected through
									modifying the actions above.
								</Label>
							</Box>
						</Box>
					</Box>
					<Box display={'flex'} paddingBottom={'20px'}>
						<LabelInput
							title={'Max Action Point Allotment'}
							labelVariant={'h3'}
							inputType={'number'}
							control={campaignForm.get('maxReward')}
							updateControl={updateCampaignForm}
						/>
						<Box className={'toolTip'}>
							<Icon iconImg={'cms-icon-0357'} size={12} />
							<Box className={'toolTipText'}>
								<Label>Max amount of points awarded to a user through completing actions.</Label>
							</Box>
						</Box>
					</Box>
					<Box display={'flex'}>
						<LabelInput
							title={'Campaign Point Allotment'}
							labelVariant={'h3'}
							inputType={'number'}
							control={campaignForm.get('campaignReward')}
							updateControl={updateCampaignForm}
						/>
						<Box className={'toolTip'}>
							<Icon iconImg={'cms-icon-0357'} size={12} />
							<Box className={'toolTipText'}>
								<Label>
									Points awarded to a user for completing a campaign in addition to any points they
									may receive for completing actions.
								</Label>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className={'campaignNotes'}>
					<LabelInput
						title={'Notes'}
						labelVariant={'h3'}
						onChange={(value) => setDescription(value)}
						inputType={'textarea'}
						initialValue={description}
					/>
				</Box>
			</Box>
			<Box className={'buttonGroup'} display={'flex'} paddingTop={20}>
				<LabelButton
					className={'campaignSave'}
					look={'containedPrimary'}
					variant={'body1'}
					label={'Save Campaign'}
					onClick={saveCampaign}
				/>
				{params.campaignId ? (
					<LabelButton
						look={'containedSecondary'}
						variant={'body1'}
						label={'Delete Campaign'}
						onClick={deleteHandler}
					/>
				) : null}
			</Box>
		</Page>
	);
};

export default AddEditCampaignPage;

export function manageEditCampaignsPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_CAMPAIGNS_READ, PermissionList.LOYALTY_CAMPAIGNS_WRITE]);
}
