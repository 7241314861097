import React, { useEffect, useState } from 'react';
import './ManageCampaignActionsPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import disableRoute from '../../customHooks/useMiddleware';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import { WebUtils } from '../../utils/utils';
import router from '../../utils/router';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpireTable, { FieldName } from '../../components/spireTable/SpireTable';
import ActionService from '../../services/action/action.service';

const ManageCampaignActionsPage: React.FC = () => {
	const actionService = serviceFactory.get<ActionService>('ActionService');
	const [sortField, setSortField] = useState<FieldName>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [total, setTotal] = useState<number | undefined>(0);
	const [actions, setActions] = useState<Api.Action.Res.Get[]>([]);

	useEffect(() => {
		disableRoute.useMiddleware();
	}, []);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const response = await actionService.getActionsByPage(pageQuery);
			setTotal(response.total);
			setActions(response.data);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function renderBrandName(data: any) {
		if (data.brand) {
			return data.brand.name;
		} else {
			return 'Any';
		}
	}

	function renderBrandLocation(data: any) {
		if (data.brandLocation) {
			return `${data.brandLocation.address1}, ${data.brandLocation.city}, ${data.brandLocation.state}`;
		} else {
			return 'Any';
		}
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/reward-list/campaigns/actions/create-action?ai=' + data.id).catch(console.error);
	}

	return (
		<Page className={'rsManageCampaignActionsPage'}>
			<SubHeader
				header="Manage Loyalty Campaign Actions"
				buttonOptions={[
					{ name: 'Create New Action', path: '/dashboard/reward-list/campaigns/actions/create-action' }
				]}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Campaigns', link: '/dashboard/reward-list/campaigns' },
					{ label: 'Action List', link: '/dashboard/reward-list/campaigns/action' }
				]}
			/>
			<SpireTable
				table={{
					placeholder: 'Action Name',
					filterQuery: [{ column: 'name', value: '' }]
				}}
				columns={[
					{
						id: 'name',
						label: 'Name',
						align: 'left',
						className: 'actionCellHeader nameHeader',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'description',
						label: 'Description',
						align: 'left',
						className: 'actionCellHeader descriptionHeader',
						sort: sortField === 'description' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'brand.name',
						label: 'Brand',
						align: 'left',
						className: 'actionCellHeader brandHeader',
						sort: sortField === 'brand' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'HANDLER',
						handler: renderBrandName
					},
					{
						id: 'brandLocation.address1',
						label: 'Brand Location',
						align: 'left',
						className: 'actionCellHeader brandLocationHeader',
						sort: sortField === 'brandLocation' ? sortOrder : 'DESC',
						filterType: 'MULTI_QUERY',
						cellType: 'HANDLER',
						handler: renderBrandLocation,
						multiFilterQuery: [
							{ column: 'brandLocation.name', value: '', conjunction: 'AND' },
							{ column: 'brandLocation.address1', value: '', conjunction: 'OR' },
							{ column: 'brandLocation.city', value: '', conjunction: 'OR' },
							{ column: 'brandLocation.state', value: '', conjunction: 'OR' }
						]
					},
					{
						id: 'createdOn',
						label: 'Creation Date',
						align: 'left',
						className: 'actionCellHeader createdOnHeader',
						sort: sortField === 'createdOn' ? sortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					}
				]}
				data={actions}
				total={total || 0}
				sortField={'name'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default ManageCampaignActionsPage;

export function manageCampaignActionsPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_CAMPAIGNS_READ]);
}
