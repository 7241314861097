import * as React from 'react';
import './LabelLink.scss';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import { Link } from '@bit/redsky.framework.rs.996';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';

interface LabelLinkProps {
	path: string;
	externalLink?: boolean;
	label: string;
	variant:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'subtitle1'
		| 'subtitle2'
		| 'body1'
		| 'body2'
		| 'caption'
		| 'button'
		| 'overline'
		| 'srOnly'
		| 'inherit';
	iconLeft?: string;
	iconRight?: string;
	iconSize?: number;
	iconColor?: string;
	className?: string;
	onClick?: () => void;
}

const LabelLink: React.FC<LabelLinkProps> = (props) => {
	const accessPages = useRecoilValue<Api.AccessPages>(globalState.userAccessPages);
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);

	const isDisableLink = (): boolean => {
		if (
			props.label === 'Create Reward' ||
			props.label === 'Manage Categories' ||
			props.label === 'Create Categories' ||
			props.label === 'Campaigns' ||
			props.label === 'Create Campaigns' ||
			props.label === 'Actions' ||
			props.label === 'Create Actions' ||
			props.label === 'Manage Pages'
		) {
			return true;
		} else {
			return false;
		}
	};

	const isAccessAbleLink = () => {
		if (!!user && user.userRoleId == 1 && !!accessPages && accessPages.view.length == 0) {
			return true;
		}
		if (accessPages.view.includes(props.path) || accessPages.edit.includes(props.path)) {
			return true;
		} else {
			return false;
		}
	};

	const renderClassName = () => {
		if (isDisableLink() === true || isAccessAbleLink() == false) {
			return `rsLabelLink ${props.className || ''} disablez`;
		}

		return `rsLabelLink ${props.className || ''}`;
	};

	return isDisableLink() || !isAccessAbleLink() ? (
		<>
			<div className={renderClassName()}>
				{!!props.iconLeft && (
					<Icon
						className={'iconLeft'}
						iconImg={props.iconLeft}
						size={props.iconSize || 16}
						color={props.iconColor || 'inherit'}
					/>
				)}
				<Label variant={props.variant}>
					<span title={isDisableLink() === true || isAccessAbleLink() == false ? 'Disabled' : ''}>
						{props.label}
					</span>
				</Label>
				{!!props.iconRight && (
					<Icon
						className={'iconRight'}
						iconImg={props.iconRight}
						size={props.iconSize || 16}
						color={props.iconColor || 'inherit'}
					/>
				)}
			</div>
		</>
	) : (
		<>
			<Link path={props.path} className={renderClassName()} external={props.externalLink} onClick={props.onClick}>
				{!!props.iconLeft && (
					<Icon
						className={'iconLeft'}
						iconImg={props.iconLeft}
						size={props.iconSize || 16}
						color={props.iconColor || 'inherit'}
					/>
				)}
				<Label variant={props.variant}>{props.label}</Label>
				{!!props.iconRight && (
					<Icon
						className={'iconRight'}
						iconImg={props.iconRight}
						size={props.iconSize || 16}
						color={props.iconColor || 'inherit'}
					/>
				)}
			</Link>
		</>
	);
};

export default LabelLink;
