import React from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import './DashboardPage.scss';
import Box from '../../components/box/Box';
import DashboardNavCard from '../../components/dashboardNavCard/DashboardNavCard';
import serviceFactory from '../../services/serviceFactory';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import globalState, { getRecoilExternalValue } from '../../state/globalState';
import { useRecoilValue } from 'recoil';
import Label from '@bit/redsky.framework.rs.label';

const DashboardPage: React.FC = () => {
	const permissionService = serviceFactory.get<PermissionService>('PermissionService');
	const accessPages = useRecoilValue<Api.AccessPages>(globalState.userAccessPages);
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);

	const userSubNavLinks: { title: string; path: string }[] = [
		{ path: '/dashboard/user-list/create-user', title: 'Create New User' },
		{ path: '/dashboard/manage-user-roles', title: 'Manage User Roles' }
	];

	const companySubNavLinks: { title: string; path: string }[] = [
		{ path: '/dashboard/company-list/create-company', title: 'Create New Company' }
	];

	const loyaltySubNavLinks: { title: string; path: string }[] = [
		{ path: '/dashboard/reward-list/create-reward', title: 'Create Reward' },
		{ path: '/dashboard/reward-list/category-list', title: 'Manage Categories' },
		{ path: '/dashboard/reward-list/category-list/create-category', title: 'Create Categories' },
		{ path: '/dashboard/reward-list/campaigns', title: 'Campaigns' },
		{ path: '/dashboard/reward-list/campaigns/create-campaign', title: 'Create Campaigns' },
		{ path: '/dashboard/reward-list/campaigns/actions', title: 'Actions' },
		{ path: '/dashboard/reward-list/campaigns/actions/create-action', title: 'Create Actions' },
		{ path: '/dashboard/reward-list/manage-reward-point', title: 'Points Admin' }
	];

	let destinationSubNavLinks: { title: string; path: string }[] = [
		{ path: '/dashboard/destination-list', title: 'Destinations' },
		{ path: '/dashboard/manage-pages', title: 'Manage Pages' },
		{ path: '/dashboard/brand-list', title: 'Brands' }
	];

	let regionSubNavLinks: { title: string; path: string }[] = [{ path: '/dashboard/region-list', title: 'Regions' }];

	const reviewSubNavLinks: { title: string; path: string }[] = [
		{ path: '/dashboard/review-list', title: 'View Reviews' }
	];

	const reportSubNavLinks: { title: string; path: string }[] = [
		{ path: '/dashboard/transaction-report', title: 'Transaction Report' },
		{ path: '/dashboard/manage-pending-point-report', title: 'Pending Point Report' },
		{ path: '/dashboard/campaign-audit-report', title: 'Campaign Audit Report' },
		{ path: '/dashboard/retail-revenue-report', title: 'Retail Revenue Report' },
		{ path: '/dashboard/point-campaign-cost-report', title: 'Point Campaign Cost Report' },
		{ path: '/dashboard/point-campaign-cost-report-for-destinations', title: 'Destination Campaign Report' }
	];

	const iconsSubNavLinks: { title: string; path: string }[] = [
		{ path: '/dashboard/experience-icons', title: 'Experience Icons' },
		{ path: '/dashboard/unit-amenity-icons', title: 'In Unit Amenity Icons' }
	];

	function renderCompaniesCard() {
		if (!permissionService.hasPermission(PermissionList.COMPANY_READ)) return null;
		if (!checkUrlInAccessPage('/dashboard/company-list')) return null;
		return (
			<DashboardNavCard
				mainPath={'/dashboard/company-list'}
				mainTitle={'Manage Companies'}
				subNavLinks={companySubNavLinks}
			/>
		);
	}

	function renderTransactionCard() {
		if (!permissionService.hasPermission(PermissionList.ADMINISTRATION_READ)) return null;
		if (!checkUrlInAccessPage('/dashboard/transaction-report')) return null;
		return (
			<DashboardNavCard
				mainTitle={'Manage Reports'}
				mainPath={'/dashboard/transaction-report'}
				subNavLinks={reportSubNavLinks}
			/>
		);
	}

	const checkUrlInAccessPage = (url: string) => {
		if (!!user && user.userRoleId == 1 && !!accessPages && accessPages.view.length == 0) {
			return true;
		}
		if (accessPages.view.includes(url) || accessPages.edit.includes(url)) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Page className="rsDashboardPage">
			{(accessPages.view && accessPages.view.length > 0) ||
			(accessPages.edit && accessPages.edit.length > 0) ||
			(!!user && user.userRoleId == 1 && !!accessPages && accessPages.view.length == 0) ? (
				<Box className="dashboardCards">
					{checkUrlInAccessPage('/dashboard/user-list') && (
						<DashboardNavCard
							mainPath={'/dashboard/user-list'}
							mainTitle={'Manage Users'}
							subNavLinks={userSubNavLinks}
						/>
					)}
					{checkUrlInAccessPage('/dashboard/reward-list') && (
						<DashboardNavCard
							mainPath={'/dashboard/reward-list'}
							mainTitle={'Manage Loyalty Rewards'}
							subNavLinks={loyaltySubNavLinks}
						/>
					)}
					{checkUrlInAccessPage('/dashboard/destination-list') && (
						<DashboardNavCard
							mainPath={'/dashboard/destination-list'}
							mainTitle={'Vendor Management'}
							subNavLinks={destinationSubNavLinks}
						/>
					)}
					{renderCompaniesCard()}
					{checkUrlInAccessPage('/dashboard/review-list') && (
						<DashboardNavCard
							mainTitle={'Manage User Reviews'}
							mainPath={'/dashboard/review-list'}
							subNavLinks={reviewSubNavLinks}
						/>
					)}
					{checkUrlInAccessPage('/dashboard/region-list') && (
						<DashboardNavCard
							mainTitle={'Manage Regions'}
							mainPath={'/dashboard/region-list'}
							subNavLinks={regionSubNavLinks}
						/>
					)}
					{checkUrlInAccessPage('/dashboard/experience-icons') && (
						<DashboardNavCard
							mainTitle={'Manage Icons'}
							mainPath={'/dashboard/experience-icons'}
							subNavLinks={iconsSubNavLinks}
						/>
					)}
					{renderTransactionCard()}
				</Box>
			) : (
				<Box className="noAccess">
					<Label variant="h3" textAlign="center">
						You have no access for any pages. <br />
						Please ask to admin to give you access of any pages.
					</Label>
				</Box>
			)}
		</Page>
	);
};

export default DashboardPage;
