import React, { useEffect, useState } from 'react';
import './AddEditCampaignActionPage.scss';
import serviceFactory from '../../services/serviceFactory';
import disableRoute from '../../customHooks/useMiddleware';
import CampaignService from '../../services/campaign/campaign.service';
import router from '../../utils/router';
import { Page } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import LoadingPage from '../loadingPage/LoadingPage';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import LabelButton from '../../components/labelButton/LabelButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import Box from '../../components/box/Box';
import { WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { OptionType } from '@bit/redsky.framework.rs.select';
import BrandService from '../../services/brand/brand.service';

const AddEditCampaignActionPage: React.FC = () => {
	const campaignService = serviceFactory.get<CampaignService>('CampaignService');
	const brandService = serviceFactory.get<BrandService>('BrandService');
	const params: any = router.getPageUrlParams<{ campaignId: number }>([
		{ key: 'ai', default: 0, type: 'integer', alias: 'actionId' }
	]);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [brandOptions, setBrandOptions] = useState<OptionType[]>([{ value: 0, label: 'Any' }]);
	const [brandLocationOptions, setBrandLocationOptions] = useState<OptionType[]>([{ value: 0, label: 'Any' }]);
	const [actionForm, setActionForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('name', '', [new RsValidator(RsValidatorEnum.REQ, 'You must have an action name')]),
			new RsFormControl('description', '', [new RsValidator(RsValidatorEnum.REQ, 'You must have a description')]),
			new RsFormControl('brandId', 0, [new RsValidator(RsValidatorEnum.REQ, 'Select a brand.')]),
			new RsFormControl('brandLocationId', 0, [new RsValidator(RsValidatorEnum.REQ, 'Select a brand location.')]),
			new RsFormControl('type', 'POINTS', [new RsValidator(RsValidatorEnum.REQ, 'Select a reward type.')])
		])
	);

	useEffect(() => {
		disableRoute.useMiddleware();
	}, []);

	useEffect(() => {
		async function getAction() {
			try {
				let brands = await brandService.getAllBrands();
				setBrandOptions([
					{ value: 0, label: 'Any' },
					...brands.map((brand) => {
						return { value: brand.id, label: brand.name };
					})
				]);
				if (params.actionId) {
					let res = await campaignService.getActionById(params.actionId);
					let name = actionForm.get('name');
					let description = actionForm.get('description');
					let brandId = actionForm.get('brandId');
					let brandLocation = actionForm.get('brandLocationId');

					name.value = res.name;
					description.value = res.description;
					brandId.value = res.brand?.id || 0;
					brandLocation.value = res.brandLocation?.id || 0;

					actionForm.update(name);
					actionForm.update(description);
					actionForm.update(brandId);
					actionForm.update(brandLocation);
					setActionForm(actionForm.clone());
				}
				setLoaded(true);
			} catch (e) {
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
			}
		}
		getAction().catch(console.error);
	}, []);

	useEffect(() => {
		async function getBrandLocations(brandId: number) {
			let allLocations = await brandService.getBrandLocations(brandId);
			setBrandLocationOptions([
				{ value: 0, label: 'Any' },
				...allLocations.map((location) => {
					return { value: location.id, label: location.address1 };
				})
			]);
		}
		if (actionForm.get('brandId').value !== 0) {
			getBrandLocations(actionForm.get('brandId').value as number).catch(console.error);
		} else {
			return;
		}
	}, [actionForm.get('brandId').value]);

	async function saveAction() {
		let isValid: boolean = await actionForm.isValid();
		if (!isValid) {
			rsToastify.error('Please make sure everything is filled out', 'Missing Information');
			return;
		}

		let updateActionObj: Api.Action.Req.Update = actionForm.toModel();
		updateActionObj.id = params.actionId;
		if (updateActionObj.brandId === 0) delete updateActionObj.brandId;
		if (updateActionObj.brandLocationId === 0) delete updateActionObj.brandLocationId;
		setLoaded(false);
		try {
			if (params.actionId) {
				//update
				let res = await campaignService.updateAction(updateActionObj);
				if (res) {
					rsToastify.success('Action Updated!', 'Success');
					await router.navigate('/dashboard/reward-list/campaigns/actions');
				}
			} else {
				//create
				let res = await campaignService.createAction(
					actionForm.get('name').value.toString(),
					actionForm.get('description').value.toString(),
					actionForm.get('type').value.toString(),
					0
				);
				if (res) {
					rsToastify.success('Action Created!', 'Success');
					await router.navigate('/dashboard/reward-list/campaigns/actions');
				}
			}
		} catch (e) {
			setLoaded(true);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	async function deleteAction() {
		let res = await campaignService.deleteAction(params.actionId);
		if (res) await router.navigate('/dashboard/reward-list/campaigns/actions');
	}

	async function updateActionInfoForm(control: RsFormControl) {
		setActionForm(actionForm.clone().update(control));
	}

	return !loaded ? (
		<LoadingPage />
	) : (
		<Page className={'rsAddEditCampaignActionPage'}>
			<SubHeader
				header="Create Action"
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Reward List', link: '/dashboard/reward-list' },
					{ label: 'Campaigns', link: '/dashboard/reward-list/campaigns' },
					{ label: 'Action List', link: '/dashboard/reward-list/campaigns/actions' },
					{ label: 'Manage Action', link: '/dashboard/reward-list/campaigns/actions/create-action' }
				]}
			/>
			<Box className={'actionSection'}>
				<LabelInput
					labelVariant={'h2'}
					title={'Action Name'}
					inputType={'text'}
					control={actionForm.get('name')}
					updateControl={updateActionInfoForm}
					onChange={() => {}}
				/>
				<LabelInput
					labelVariant={'h2'}
					title={'Description'}
					onChange={() => {}}
					inputType={'textarea'}
					control={actionForm.get('description')}
					updateControl={updateActionInfoForm}
				/>
			</Box>
			<hr className={'cardDivider'} />
			<Box className={'actionSection'}>
				<LabelSelect
					variant={'h2'}
					title={'Reward Type'}
					options={[{ value: 'POINTS', label: 'Points' }]}
					updateControl={updateActionInfoForm}
					control={actionForm.get('type')}
				/>
			</Box>
			<hr className={'cardDivider'} />
			<Box className={'actionSection'}>
				<LabelSelect
					variant={'h2'}
					title={'Brands'}
					control={actionForm.get('brandId')}
					options={brandOptions}
					updateControl={updateActionInfoForm}
				/>
				<LabelSelect
					variant={'h2'}
					title={'Brand Location'}
					options={brandLocationOptions}
					updateControl={updateActionInfoForm}
					control={actionForm.get('brandLocationId')}
				/>
			</Box>
			<div className={'buttonGroup'}>
				<LabelButton look={'containedPrimary'} variant={'body1'} label={'Save Action'} onClick={saveAction} />
				{params.actionId ? (
					<LabelButton
						look={'containedSecondary'}
						variant={'body1'}
						label={'Delete Action'}
						onClick={deleteAction}
					/>
				) : null}
			</div>
		</Page>
	);
};

export default AddEditCampaignActionPage;

export function manageCampaignActionPageGuard() {
	return serviceFactory
		.get<PermissionService>('PermissionService')
		.checkPermissions([PermissionList.LOYALTY_CAMPAIGNS_READ, PermissionList.LOYALTY_CAMPAIGNS_WRITE]);
}
