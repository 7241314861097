import HttpClient from '@bit/redsky.framework.rs.http';
import packageJson from '../../package.json';

export enum HttpStatusCode {
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	METHOD_NOT_ALLOWED = 405,
	ALREADY_EXISTS = 409,
	CONFLICT = 409,
	VERSION_OUT_OF_DATE = 418, // Technically this is the I'm a teapot code that was a joke.
	SERVER_ERROR = 500,
	SERVICE_UNAVAILABLE = 503,
	NETWORK_CONNECT_TIMEOUT = 599
}

const http = new HttpClient({
	// baseURL: process.env.NODE_ENV === 'development' ? '/api/v1' : packageJson.uri,
	baseURL: 'https://bookrentylmsapp1.salmondesert-305f8770.eastus.azurecontainerapps.io/api/v1',
	headers: {
		/*"company-id": process.env.NODE_ENV === 'development' ? 1 : null,*/
		'company-id': `1`,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		Accept: 'application/json, text/plain, */*',
		'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
		'admin-portal': `true`
	}
});

export default http;
