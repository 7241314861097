import React from 'react';
import './ReservationCard.scss';
import Paper from '../paper/Paper';
import Box from '../box/Box';
import Label from '@bit/redsky.framework.rs.label';
import { DateUtils, StringUtils } from '../../utils/utils';
import Icon from '@bit/redsky.framework.rs.icon';
import LinkButton from '../linkButton/LinkButton';

export interface reservationCardProps {
	reservationId: number;
	mainImg: string;
	arrivalDate: string | Date;
	departureDate: string | Date;
	reservationNumber: string;
	totalPaid: number;
	paidWithPoints: boolean;
	propertyType: string;
	rooms: number;
	beds: number;
	amenities: string[];
}

const ReservationCard: React.FC<reservationCardProps> = (props) => {
	function renderAmenityIcons() {
		return props.amenities
			.filter((item, index) => {
				return index < 4;
			})
			.map((item, index) => {
				return <Icon key={index} iconImg={item} size={22} />;
			});
	}
	return (
		<Paper className={'rsReservationCard'}>
			<img src={props.mainImg} />
			<Box className={'reservationDetails'}>
				<Box className={'row1'}>
					<Box className={''}>
						<Label variant={'caption'}>Reservation Date</Label>
						<Label variant={'body1'}>
							{DateUtils.displayUserDate(props.arrivalDate)} -{' '}
							{DateUtils.displayUserDate(props.departureDate)}
						</Label>
					</Box>
					<Box className={''}>
						<Label variant={'caption'}>Reservation Number</Label>
						<Label variant={'body1'}>{props.reservationNumber}</Label>
					</Box>
					<Box className={''}>
						<Label variant={'caption'}>Total Paid</Label>
						<Label variant={'body1'}>
							{props.paidWithPoints
								? StringUtils.addCommasToNumber(props.totalPaid) + ' Points'
								: '$' + StringUtils.formatMoney(props.totalPaid)}
						</Label>
					</Box>
				</Box>
				<Box className={'row2'}>
					<Box className={''}>
						<Label variant={'caption'}>Property Type</Label>
						<Label variant={'body1'}>{props.propertyType}</Label>
					</Box>
					<Box className={''}>
						<Label variant={'caption'}>Bedrooms</Label>
						<Label variant={'body1'}>{props.rooms}</Label>
					</Box>
					<Box className={''}>
						<Label variant={'caption'}>Beds</Label>
						<Label variant={'body1'}>{props.beds}</Label>
					</Box>
					<Box className={''}>
						<Label variant={'caption'}>Amenities</Label>
						<Label variant={'body1'}>{renderAmenityIcons()}</Label>
					</Box>
					<LinkButton
						path={`/dashboard/user-list/manage-user/booking-history/reservation?ri=${props.reservationId}`}
						label={'View Reservation Details'}
					/>
				</Box>
			</Box>
		</Paper>
	);
};
export default ReservationCard;
