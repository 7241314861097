import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { WebUtils } from '../../utils/utils';

export default class UserPointService extends Service {
	async create(data: Api.UserPoint.Req.Create) {
		return await http.post<RsResponseData<Api.UserPoint.Res.Create>>('userPoint/', data);
	}

	async getPointTransactionsByUserId(userId: number): Promise<Api.UserPoint.Res.Get[]> {
		const response = await http.get<RsResponseData<Api.UserPoint.Res.Get[]>>(`user/points?userId=${userId}`);
		return response.data.data;
	}

	async getAuditLogByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.AuditLog[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.AuditLog[]>>(
			'user/auditlog/reports',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async getUserTransactionsByPage(
		data: Api.User.Req.UserPointsByPage
	): Promise<RedSky.RsPagedResponseData<Api.User.Res.UserPoint[]>> {
		const res = await http.get<RedSky.RsPagedResponseData<Api.User.Res.UserPoint[]>>(`user/points/paged`, data);
		return res.data;
	}

	async getUserPointReport(
		pageQuery: RedSky.PageQuery
	): Promise<RedSky.RsPagedResponseData<Api.User.Res.UserPointReport[]>> {
		const res = await http.get<RedSky.RsPagedResponseData<Api.User.Res.UserPointReport[]>>(
			`userPoint/pending/points/report`,
			pageQuery
		);
		return res.data;
	}
}
