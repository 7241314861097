import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class OrdersService extends Service {
	async getById(id: number): Promise<Api.Order.Res.Get> {
		const response = await http.get<RsResponseData<Api.Order.Res.Get>>('order', { id });
		return response.data.data;
	}

	async getForUser(data: Api.Order.Req.User): Promise<Api.Order.Res.Get[]> {
		const response = await http.get<RsResponseData<Api.Order.Res.Get[]>>('order/user', data);
		return response.data.data;
	}

	async getByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Order.Res.Get[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Order.Res.Get[]>>('order/paged', pageQuery);
		return response.data;
	}

	async create(data: Api.Order.Req.Create): Promise<Api.Order.Res.Get> {
		const response = await http.post<RsResponseData<Api.Order.Res.Get>>('order', data);
		return response.data.data;
	}

	async update(data: Api.Order.Req.Update): Promise<Api.Order.Res.Get> {
		const response = await http.put<RsResponseData<Api.Order.Res.Get>>('order', data);
		return response.data.data;
	}

	async delete(id: number): Promise<number> {
		const response = await http.delete<RsResponseData<number>>('order', { id });
		return response.data.data;
	}
}
