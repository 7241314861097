import React, { useEffect, useState } from 'react';
import './BookingHistoryPage.scss';
import { Page } from '@bit/redsky.framework.rs.996';
import router from '../../utils/router';
import LinkButton from '../../components/linkButton/LinkButton';
import SubHeader from '../../components/subHeader/SubHeader';
import serviceFactory from '../../services/serviceFactory';
import ReservationService from '../../services/reservation/reservation.service';
import Select, { OptionType } from '@bit/redsky.framework.rs.select';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import { TablePagination } from '@bit/redsky.framework.rs.datatable';
import Footer from '../../components/footer/Footer';
import ReservationCard from '../../components/reservationCard/ReservationCard';
import Label from '@bit/redsky.framework.rs.label';
import Box from '../../components/box/Box';

const BookingHistoryPage: React.FC = () => {
	const reservationService = serviceFactory.get<ReservationService>('ReservationService');
	const params = router.getPageUrlParams<{ userId: number }>([
		{ key: 'ui', default: '', type: 'integer', alias: 'userId' }
	]);
	const [reservations, setReservations] = useState<Api.Reservation.Res.Get[]>([]);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(10);
	const [total, setTotal] = useState<number>(0);
	const [year, setYear] = useState<RsFormGroup>(new RsFormGroup([new RsFormControl('year', '', [])]));
	const [yearOptions, setYearOptions] = useState<OptionType[]>([]);

	useEffect(() => {
		if (!params.userId) {
			router.navigate('/dashboard/user-list').catch(console.error);
		}
	}, []);

	useEffect(() => {
		async function getItineraries() {
			const pageQuery: RedSky.PageQuery = {
				pagination: { page: pageNumber, perPage },
				filter: {
					matchType: 'exact',
					searchTerm: [{ column: 'userId', value: params.userId }]
				}
			};
			const reservations = await reservationService.getReservations(pageQuery);
			setReservations(reservations.data);
			setTotal(reservations.total);
		}
		getItineraries().catch(console.error);
	}, [year, pageNumber, perPage]);

	function renderPastReservations() {
		return reservations
			.filter((reservation) => {
				if (new Date(reservation.departureDate).getTime() < Date.now()) return true;
				return false;
			})
			.map((reservation) => {
				return (
					<ReservationCard
						key={reservation.id}
						reservationId={reservation.id}
						mainImg={reservation.accommodation.media[0].urls.large}
						arrivalDate={reservation.arrivalDate}
						departureDate={reservation.departureDate}
						reservationNumber={reservation.externalConfirmationId}
						totalPaid={
							reservation.paymentMethod
								? reservation.priceDetail.grandTotalCents
								: reservation.priceDetail.grandTotalPoints
						}
						paidWithPoints={!reservation.paymentMethod}
						propertyType={reservation.accommodation.propertyType}
						rooms={reservation.accommodation.roomCount}
						beds={reservation.accommodation.maxOccupantCount}
						amenities={reservation.accommodation.featureIcons}
					/>
				);
			});
	}

	function renderUpcomingReservations() {
		return reservations
			.filter((reservation) => {
				if (new Date(reservation.departureDate).getTime() >= Date.now()) return true;
				return false;
			})
			.map((reservation) => {
				return (
					<ReservationCard
						key={reservation.id}
						reservationId={reservation.id}
						mainImg={reservation.accommodation.media[0].urls.large}
						arrivalDate={reservation.arrivalDate}
						departureDate={reservation.departureDate}
						reservationNumber={reservation.externalConfirmationId}
						totalPaid={
							reservation.paymentMethod
								? reservation.priceDetail.grandTotalCents
								: reservation.priceDetail.grandTotalPoints
						}
						paidWithPoints={!reservation.paymentMethod}
						propertyType={reservation.accommodation.propertyType}
						rooms={reservation.accommodation.roomCount}
						beds={reservation.accommodation.maxOccupantCount}
						amenities={reservation.accommodation.featureIcons}
					/>
				);
			});
	}

	return (
		<Page className={'rsBookingHistoryPage'}>
			<LinkButton path={`/dashboard/user-list/manage-user?ui=${params.userId}`} label={'Back to User'} />
			<Select
				placeholder={'View by year'}
				control={year.get('year')}
				options={yearOptions}
				updateControl={(control) => setYear(year.clone().update(control))}
			/>
			<SubHeader
				header={'View Booking History'}
				crumbs={[
					{ label: 'Dashboard', link: `/dashboard` },
					{ label: 'User List', link: `/dashboard/user-list` },
					{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${params.userId}` },
					{
						label: 'Booking History',
						link: `/dashboard/user-list/manage-user/booking-history?ui=${params.userId}`
					}
				]}
			/>
			<Box className={'reservations'}>
				<Label variant={'h3'}>Upcoming Reservations</Label>
				{renderUpcomingReservations()}
				<Label variant={'h3'}>Past Reservations</Label>
				{renderPastReservations()}
			</Box>
			<TablePagination
				selectedRowsPerPage={perPage}
				rowsPerPageOptions={[10, 25, 50]}
				index={[(pageNumber - 1) * perPage + 1, Math.min(pageNumber * perPage, total)]}
				total={total}
				setSelectedPage={(page) => setPageNumber(page)}
				setPerPage={(perPage) => setPerPage(perPage)}
				currentPageNumber={pageNumber}
			/>
			<Footer />
		</Page>
	);
};

export default BookingHistoryPage;
