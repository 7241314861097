import React, { useEffect, useState } from 'react';
import { Page } from '@bit/redsky.framework.rs.996';
import SubHeader from '../../components/subHeader/SubHeader';
import Paper from '../../components/paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import SpireTable from '../../components/spireTable/SpireTable';
import serviceFactory from '../../services/serviceFactory';
import OrdersService from '../../services/orders/orders.service';
import router from '../../utils/router';

const OrderHistoryPage: React.FC = () => {
	const ordersService = serviceFactory.get<OrdersService>('OrdersService');
	const params = router.getPageUrlParams<{ userId: number }>([
		{ key: 'ui', default: 0, type: 'integer', alias: 'userId' }
	]);
	const [pendingOrders, setPendingOrders] = useState<Api.Order.Res.Get[]>([]);
	const [pendingTotal, setPendingTotal] = useState<number>(0);
	const [pendingSort, setPendingSort] = useState<string>('reward.name');
	const [pendingSortOrder, setPendingSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [completedOrders, setCompletedOrders] = useState<Api.Order.Res.Get[]>([]);
	const [completedTotal, setCompletedTotal] = useState<number>(0);
	const [completedSort, setCompletedSort] = useState<string>('reward.name');
	const [completedSortOrder, setCompletedSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');

	useEffect(() => {
		if (!params.userId) router.navigate('/dashboard/user-list').catch(console.error);
	}, []);

	async function getPendingOrders(pageQuery: RedSky.PageQuery) {
		const filtersToAdd: RedSky.FilterQueryValue[] = [
			{ column: 'userId', value: params.userId },
			{ column: 'status', value: 'PENDING' }
		];
		const orders: RedSky.RsPagedResponseData<Api.Order.Res.Get[]> = await queryOrders(pageQuery, filtersToAdd);
		setPendingOrders(orders.data);
		setPendingTotal(orders.total);
	}

	async function getCompletedOrders(pageQuery: RedSky.PageQuery) {
		const filtersToAdd: RedSky.FilterQueryValue[] = [
			{ column: 'userId', value: params.userId },
			{ column: 'status', value: 'COMPLETED' }
		];
		const orders: RedSky.RsPagedResponseData<Api.Order.Res.Get[]> = await queryOrders(pageQuery, filtersToAdd);
		setCompletedOrders(orders.data);
		setCompletedTotal(orders.total);
	}

	async function queryOrders(
		pageQuery: RedSky.PageQuery,
		filtersToAdd: RedSky.FilterQueryValue[]
	): Promise<RedSky.RsPagedResponseData<Api.Order.Res.Get[]>> {
		let searchFilter: RedSky.FilterQuery = pageQuery.filter || { matchType: 'like', searchTerm: [] };
		searchFilter.searchTerm = [...searchFilter.searchTerm, ...filtersToAdd];
		pageQuery.filter = searchFilter;
		return ordersService.getByPage(pageQuery);
	}

	function renderPendingTable() {
		return (
			<SpireTable
				table={{
					placeholder: 'Name',
					filterQuery: [
						{
							column: 'reward.name',
							value: ''
						}
					]
				}}
				columns={[
					{
						id: 'reward.name',
						label: 'Transaction',
						align: 'left',
						className: '',
						sort: pendingSort === 'reward.name' ? pendingSortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'name'
					},
					{
						id: 'createdOn',
						label: 'Ordered On',
						align: 'left',
						className: '',
						sort: pendingSort === 'createdOn' ? pendingSortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},
					{
						id: 'type',
						label: 'Type',
						align: 'left',
						className: '',
						sort: pendingSort === 'type' ? pendingSortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'reward.pointCost',
						label: 'Amount',
						align: 'left',
						className: '',
						sort: pendingSort === 'createdOn' ? pendingSortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'pointCost'
					}
				]}
				data={pendingOrders}
				total={pendingTotal}
				sortField={pendingSort}
				onGetData={getPendingOrders}
				rowOnClick={() => {}}
				changeSort={(sort, order) => {
					setPendingSort(sort);
					setPendingSortOrder(order);
				}}
			/>
		);
	}

	function renderCompletedTable() {
		return (
			<SpireTable
				table={{
					placeholder: 'Name',
					filterQuery: [
						{
							column: 'reward.name',
							value: ''
						}
					]
				}}
				columns={[
					{
						id: 'reward.name',
						label: 'Transaction',
						align: 'left',
						className: '',
						sort: completedSort === 'reward.name' ? completedSortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'name'
					},
					{
						id: 'createdOn',
						label: 'Ordered On',
						align: 'left',
						className: '',
						sort: completedSort === 'createdOn' ? completedSortOrder : 'DESC',
						filterType: 'SINGLE_DATE',
						filterName: 'createdOn',
						cellType: 'DATE'
					},
					{
						id: 'type',
						label: 'Type',
						align: 'left',
						className: '',
						sort: completedSort === 'type' ? completedSortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'reward.pointCost',
						label: 'Amount',
						align: 'left',
						className: '',
						sort: completedSort === 'createdOn' ? completedSortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					}
				]}
				data={completedOrders}
				total={completedTotal}
				sortField={completedSort}
				onGetData={getCompletedOrders}
				rowOnClick={() => {}}
				changeSort={(sort, order) => {
					setCompletedSort(sort);
					setCompletedSortOrder(order);
				}}
			/>
		);
	}

	return (
		<Page className={'rsOrderHistoryPage'}>
			<SubHeader
				header={'Order History'}
				crumbs={[
					{ label: 'Dashboard', link: `/dashboard` },
					{ label: 'User List', link: `/dashboard/user-list` },
					{ label: 'Manage Users', link: `/dashboard/user-list/manage-user?ui=${params.userId}` },
					{
						label: 'Order History',
						link: `/dashboard/user-list/manage-user/order-history?ui=${params.userId}`
					}
				]}
			/>
			<Paper>
				<Label variant={'h3'}>Pending Orders</Label>
				{renderPendingTable()}
				<Label variant={'h3'}>Completed Orders</Label>
				{renderCompletedTable()}
			</Paper>
		</Page>
	);
};

export default OrderHistoryPage;
