import * as React from 'react';
import './UserBadge.scss';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';

import Avatar from '@bit/redsky.framework.rs.avatar';

interface UserBadgeProps {
	userName: string;
	imageUrl?: string;
}

const UserBadge: React.FC<UserBadgeProps> = (props) => {
	return (
		<div className={'rsUserBadge'}>
			<Avatar widthHeight={40} image={props.imageUrl} name={props.userName} />
			<Label variant={'h4'}>{props.userName}</Label>
			<Icon iconImg={'icon-chevron-down'} size={12} color={'#fff'} />
		</div>
	);
};

export default UserBadge;
