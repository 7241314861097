import * as React from 'react';
import './Footer.scss';
import Box from '../box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import packageJson from '../../../package.json';

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
	return (
		<Box className={'rsFooter'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
			<div className={'divider'} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img src={require('../../images/spire-logo.png')} alt={'Company Logo'} width={17} />
				<div className="appVersion">Version: {packageJson.version}</div>
			</div>
			<Label variant={'caption'}>Spire &#169; {new Date().getFullYear()}, all rights reserved.</Label>
			<Box className={'socialMediaLinks'} display={'flex'}>
				<Icon
					iconImg={'icon-facebook'}
					size={21}
					color={'#004B98'}
					cursorPointer
					onClick={() => {
						window.open(' https://www.facebook.com/RentylResorts/', '_blank');
					}}
				/>
				<Icon
					iconImg={'icon-Twitter'}
					size={21}
					color={'#004B98'}
					cursorPointer
					onClick={() => {
						window.open('https://twitter.com/rentyl_resorts', '_blank');
					}}
				/>
				<Icon
					iconImg={'icon-linkedin'}
					size={21}
					color={'#004B98'}
					cursorPointer
					onClick={() => {
						window.open('https://www.linkedin.com/company/rentylresorts/', '_blank');
					}}
				/>
				<Icon
					iconImg={'icon-instagram'}
					size={21}
					color={'#004B98'}
					cursorPointer
					onClick={() => {
						window.open('https://www.instagram.com/rentylresorts/', '_blank');
					}}
				/>
			</Box>
		</Box>
	);
};

export default Footer;
