import React from 'react';
import './AddressCard.scss';
import Box from '../box/Box';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { Link } from '@bit/redsky.framework.rs.996';
import Paper from '../paper/Paper';
import LabelButton from '../labelButton/LabelButton';

export interface addressCardProps {
	className?: string;
	id: number;
	isDefault: boolean;
	name: string;
	addressLine1: string;
	addressLine2?: string;
	city: string;
	state: string;
	zip: string | number;
	onDelete: () => void;
	onUseThisAddress?: () => void;
	selected?: boolean;
	country?: string;
}

const AddressCard: React.FC<addressCardProps> = (props) => {
	return (
		<Paper
			className="rsAddressCard"
			height={'fit-content'}
			padding={'15px'}
			backgroundColor={props.selected ? '#fcfbf8' : '#ffffff'}
		>
			<Box className="personalInfoTop">
				<Box className="personalInfo">
					<Label className="addressName" variant={'h4'}>
						{props.name}
					</Label>
					<Label className="addressLineOne" variant={'subtitle2'}>
						{props.addressLine1}
					</Label>
					<Label className="addressLineTwo" variant={'subtitle2'}>
						{props.addressLine2}
					</Label>
					<Label className="addressCityStateZip" variant={'subtitle2'}>
						{props.city}, {props.state.toUpperCase()} {props.zip} {props.country}
					</Label>
				</Box>
				<Box className="addressActions">
					<LabelButton
						className="editLink"
						look={'none'}
						variant={'button'}
						label={'Delete'}
						onClick={props.onDelete}
					/>
				</Box>
			</Box>
			{!props.isDefault && (
				<LabelButton
					look={'containedPrimary'}
					variant={'button'}
					label={'Use This Address'}
					onClick={props.onUseThisAddress}
				/>
			)}
		</Paper>
	);
};
export default AddressCard;
