import React from 'react';
import Label from '@bit/redsky.framework.rs.label';
import LinkButton from '../../../components/linkButton/LinkButton';
import './CampaignDetailsCard.scss';
import { DateUtils } from '../../../utils/utils';
import { Box } from '@bit/redsky.framework.rs.996';

export interface CampaignDetailsCardProps {
	campaignDetails: Api.Campaign.Res.Get;
}

const CampaignDetailsCard: React.FC<CampaignDetailsCardProps> = (props) => {
	function getActions(): string {
		return props.campaignDetails.actions.map((action) => action.name).join(', ');
	}

	return (
		<Box className="rsCampaignDetailsCard">
			<Box className="cardHeader">
				<Box className={'cardName'}>
					<Label variant="h2">{props.campaignDetails.name}</Label>

					<LinkButton
						label="Manage Campaign"
						path={`/dashboard/reward-list/campaigns/create-campaign?ci=${props.campaignDetails.id}`}
					/>
				</Box>
				<Box className={'campaignActions'}>
					<Label variant="h4" className="actionLabel">
						Action
					</Label>

					<Label variant="body1" className="actionDescription">
						{getActions()}
					</Label>
				</Box>
			</Box>
			<hr className="cardDivider" />
			<Box className="campaignDetails">
				<Box className={'cardLeftSide'}>
					<Box display={'flex'} flexDirection={'column'}>
						<Label variant={'h3'}>Start Date</Label>
						<Label variant={'body1'}>
							{DateUtils.displayDate(
								props.campaignDetails.startOn ? props.campaignDetails.startOn : new Date()
							)}
						</Label>
					</Box>
					<Box display={'flex'} flexDirection={'column'}>
						<Label variant={'h3'}>End Date</Label>
						<Label variant={'body1'}>
							{DateUtils.displayDate(
								props.campaignDetails.endOn ? props.campaignDetails.endOn : new Date()
							)}
						</Label>
					</Box>
				</Box>
				<Box className={'cardRightSide'}>
					<Box className={'labelGroup'}>
						<Label variant={'h3'}>Campaign Completion Points</Label>
						<Label variant={'body1'}>{props.campaignDetails.completionPoints} Points</Label>
					</Box>
					<Box className={'labelGroup'}>
						<Label variant={'h3'}>Max Action Points</Label>
						<Label variant={'body1'}>{props.campaignDetails.maxReward} Points</Label>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default CampaignDetailsCard;
