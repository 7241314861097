import { Service } from '../Service';
import http from '../../utils/http';
import SparkMD5 from 'spark-md5';
import { WebUtils } from '../../utils/utils';
import UserService from '../user/user.service';
import serviceFactory from '../serviceFactory';
import globalState, { setRecoilExternalValue } from '../../state/globalState';

export default class CompanyService extends Service {
	userService!: UserService;
	start() {
		this.userService = serviceFactory.get<UserService>('UserService');
	}

	async getCompanyByPage(pageQuery: RedSky.PageQuery): Promise<RedSky.RsPagedResponseData<Api.Company.Res.Get[]>> {
		const response = await http.get<RedSky.RsPagedResponseData<Api.Company.Res.Get[]>>(
			'company/paged',
			WebUtils.convertDataForUrlParams(pageQuery)
		);
		return response.data;
	}

	async refreshUserCompany(user: Api.User.Res.AdminLogin) {
		const res = await http.get<RedSky.RsResponseData<Api.Company.Res.Get>>('/company', { id: user.companyId });
		setRecoilExternalValue<Api.Company.Res.Get | undefined>(globalState.company, res.data.data);
	}

	async refreshUserRoles() {
		const res = await http.get<RedSky.RsResponseData<Api.User.Res.Role[]>>('/user/roles');
		setRecoilExternalValue<Api.User.Res.Role[]>(globalState.userRoles, res.data.data);
	}

	async getCompanyDetails(): Promise<Api.Company.Res.GetCompanyAndClientVariables> {
		let res = await http.get<RedSky.RsResponseData<Api.Company.Res.GetCompanyAndClientVariables>>(
			`/company/company-and-variables`
		);
		return res.data.data;
	}

	async getCompanyById(companyId: number): Promise<Api.Company.Res.Get> {
		const res = await http.get<RedSky.RsResponseData<Api.Company.Res.Get>>('/company', { id: companyId });
		return res.data.data;
	}

	async getCompanyDetailsById(companyId: number): Promise<Api.Company.Res.Details> {
		const res = await http.get<RedSky.RsResponseData<Api.Company.Res.Details>>('/company/details', {
			id: companyId
		});
		return res.data.data;
	}

	async update(update: Api.Company.Req.Update): Promise<Api.Company.Res.Update> {
		const res = await http.put<RedSky.RsResponseData<Api.Company.Res.Update>>('/company', update);
		return res.data.data;
	}

	async create(create: Api.Company.Req.Create): Promise<Api.Company.Res.Create> {
		create.newAdminPassword = SparkMD5.hash(create.newAdminPassword);
		const res = await http.post<RedSky.RsResponseData<Api.Company.Res.Create>>('/company', create);
		return res.data.data;
	}

	async updateUnauthorizedPages(unauthorizedPages: Api.Company.Req.UpdateUnauthorizedPages): Promise<boolean> {
		const res = await http.put<RedSky.RsResponseData<boolean>>('/company/unauthorized-pages', unauthorizedPages);
		return res.data.data;
	}

	async getAvailablePages(): Promise<Api.Company.Res.GetAvailablePages[]> {
		const res = await http.get<RedSky.RsResponseData<Api.Company.Res.GetAvailablePages[]>>(
			'/company/available-pages'
		);
		return res.data.data;
	}
}
