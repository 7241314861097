import * as React from 'react';
import LabelSelect from '../../../components/labelSelect/LabelSelect';
import { useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { Box } from '@bit/redsky.framework.rs.996';

interface CampaignSelectProps {
	options: Model.Action[];
	handleModifyActions: (value: number) => void;
	action: Api.Campaign.Action;
}

const CampaignSelect: React.FC<CampaignSelectProps> = (props) => {
	const [actionForm, setActionForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('memberAction', props.action.id, [
				new RsValidator(RsValidatorEnum.REQ, 'Select a loyalty member action.')
			])
		])
	);

	useEffect(() => {
		if (actionForm.isModified()) {
			actionForm.isValid();
			setActionForm(actionForm.clone());
		}
	}, [actionForm]);

	return (
		<Box className={'rsCampaignSelect'} display={'flex'} justifyContent={'space-between'}>
			<LabelSelect
				title={'Loyalty Member Action'}
				variant={'h3'}
				options={props.options.map((option) => {
					return { value: option.id, label: option.name };
				})}
				updateControl={(control) => {
					setActionForm(actionForm.clone().update(control));
					props.handleModifyActions(control.value as number);
				}}
				control={actionForm.get('memberAction')}
			/>
		</Box>
	);
};

export default CampaignSelect;
