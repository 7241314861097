import * as React from 'react';
import './BrandListPage.scss';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import { useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { ObjectUtils, WebUtils } from '../../utils/utils';
import PermissionService, { PermissionList } from '../../services/permission/permission.service';
import SubHeader from '../../components/subHeader/SubHeader';
import SpireTable from '../../components/spireTable/SpireTable';
import Box from '../../components/box/Box';
import LabelButton from '../../components/labelButton/LabelButton';
import BrandsReportPopup, { BrandsReportPopupProps } from '../../popups/brandsReportPopup/BrandsReportPopup';
import router from '../../utils/router';
import BrandService from '../../services/brand/brand.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';

interface ManageBrandsPageProps {}

const BrandListPage: React.FC<ManageBrandsPageProps> = () => {
	const user = useRecoilValue<Api.User.Res.AdminLogin | undefined>(globalState.user);
	const brandService = serviceFactory.get<BrandService>('BrandService');
	const [brandList, setBrandList] = useState<Api.Brand.Res.Details[]>([]);
	const [sortField, setSortField] = useState<string>('name');
	const [sortOrder, setSortOrder] = useState<RedSky.StandardOrderTypes>('DESC');
	const [brandTotal, setBrandTotal] = useState<number>(0);

	async function getData(pageQuery: RedSky.PageQuery) {
		try {
			const res = await brandService.getByPage(pageQuery);
			setBrandList(res.data);
			setBrandTotal(res.total);
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unknown Error'), 'Server Error');
		}
	}

	function changeSort(field: string, order: RedSky.StandardOrderTypes) {
		setSortField(field);
		setSortOrder(order);
	}

	function handleTableClick(data: any) {
		router.navigate('/dashboard/brand-list/brand-details?bi=' + data.id).catch(console.error);
	}

	return (
		<Page className={'rsBrandListPage'}>
			<Box className={'subNavContainer'}>
				<SubHeader
					header={'Brands'}
					crumbs={[
						{ label: 'Dashboard', link: '/dashboard' },
						{ label: 'Brand List', link: `/dashboard/brand-list` }
					]}
				/>
				{ObjectUtils.isArrayWithData(user?.businessAccesses.companyIds) && (
					<LabelButton
						look={'containedPrimary'}
						variant={'button'}
						label={'Brands Report'}
						onClick={() => {
							router.navigate('/dashboard/brand-report').catch(console.error);
							// popupController.open<BrandsReportPopupProps>(BrandsReportPopup);
						}}
					/>
				)}
			</Box>
			<SpireTable
				table={{
					placeholder: 'Search by Name or Id',
					filterQuery: [
						{ column: 'name', value: '', conjunction: 'OR' },
						{ column: 'externalId', value: '', conjunction: 'OR' }
					]
				}}
				columns={[
					{
						id: 'externalId',
						label: 'ID',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'externalId' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'name',
						label: 'Name',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'company.name',
						label: 'Company Name',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'company.name' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'UN_NESTED_TEXT',
						nested: 'companyName'
					},
					{
						id: 'loyaltyStatus',
						label: 'Loyalty Status',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'loyaltyStatus' ? sortOrder : 'DESC',
						filterType: 'NORMAL',
						cellType: 'TEXT'
					},
					{
						id: 'isActive',
						label: 'Status',
						align: 'left',
						className: 'brandCell',
						sort: sortField === 'isActive' ? sortOrder : 'DESC',
						filterType: 'CHECKBOX',
						cellType: 'BOOLEAN',
						textOptions: {
							false: 'Inactive',
							true: 'Active'
						}
					}
				]}
				data={brandList}
				total={brandTotal}
				sortField={'name'}
				onGetData={getData}
				rowOnClick={handleTableClick}
				changeSort={changeSort}
			/>
		</Page>
	);
};

export default BrandListPage;

export function manageBrandsPageGuard() {
	return serviceFactory.get<PermissionService>('PermissionService').checkPermissions([PermissionList.USER_READ]);
}
