import * as React from 'react';
import './CheckboxTree.scss';
import Box from '../box/Box';
import LabelCheckbox from '../labelCheckbox/LabelCheckbox';
import { useEffect, useState } from 'react';
import { ObjectUtils } from '../../utils/utils';
import Accordion from '@bit/redsky.framework.rs.accordion';

interface CheckboxTreeProps {
	treeData: Api.UserBusiness.Res.Available;
	companyIds?: number[];
	brandIds?: number[];
	locationIds?: number[];
	handleIds: (userBusinessLevel: Model.UserBusinessLevel, ids: number[]) => void;
	userBusinessLevel: Model.UserBusinessLevel;
}

const CheckboxTree: React.FC<CheckboxTreeProps> = (props) => {
	const [companiesSelected, setCompaniesSelected] = useState<number[]>(props.companyIds || []);
	const [brandsSelected, setBrandsSelected] = useState<number[]>(props.brandIds || []);
	const [locationsSelected, setLocationsSelected] = useState<number[]>(props.locationIds || []);

	useEffect(() => {
		let ids: number[] = [];
		if (ObjectUtils.isArrayWithData(companiesSelected)) {
			ids = companiesSelected;
		} else if (ObjectUtils.isArrayWithData(brandsSelected)) {
			ids = brandsSelected;
		} else if (ObjectUtils.isArrayWithData(locationsSelected)) {
			ids = locationsSelected;
		}
		props.handleIds(props.userBusinessLevel, ids);
	}, [companiesSelected, brandsSelected, locationsSelected]);

	useEffect(() => {
		setCompaniesSelected([]);
		setBrandsSelected([]);
		setLocationsSelected([]);
	}, [props.userBusinessLevel]);

	useEffect(() => {
		setCompaniesSelected(props.companyIds || []);
		setBrandsSelected(props.brandIds || []);
		setLocationsSelected(props.locationIds || []);
	}, []);

	function resetCheckboxTree() {
		setCompaniesSelected(props.companyIds || []);
		setBrandsSelected(props.brandIds || []);
		setLocationsSelected(props.locationIds || []);
	}

	return (
		<Box className={'rsCheckboxTree'}>
			{props.treeData.companies &&
				props.treeData.companies?.map((company: Api.UserBusiness.Res.Available.Company) => {
					let isChecked = companiesSelected.includes(company.id) || props.userBusinessLevel === 'SUPER';
					return (
						<Box key={company.id} className={'checkboxTreeGrid'}>
							<LabelCheckbox
								value={company.id}
								text={company.name}
								onSelect={() => {
									if (companiesSelected.includes(company.id)) {
										return;
									} else {
										setCompaniesSelected([...companiesSelected, company.id]);
									}
								}}
								onDeselect={() => {
									let tempArray = companiesSelected;
									const index = tempArray.indexOf(company.id);
									if (index > -1) {
										tempArray.splice(index, 1);
									}
									setCompaniesSelected(tempArray);
									setLocationsSelected([]);
									setBrandsSelected([]);
								}}
								isDisabled={props.userBusinessLevel !== 'COMPANY'}
								isChecked={isChecked}
							/>
							{ObjectUtils.isArrayWithData(company.brands) && (
								<Accordion>
									{company.brands.map((brand: Api.UserBusiness.Res.Available.Brand) => {
										let isBrandChecked =
											companiesSelected.includes(company.id) ||
											brandsSelected.includes(brand.id) ||
											props.userBusinessLevel === 'SUPER';
										return (
											<Box className={'checkboxTreeGrid'} key={brand.id}>
												<LabelCheckbox
													value={brand.id}
													text={brand.name}
													onSelect={() => {
														if (brandsSelected.includes(brand.id)) {
															return;
														} else {
															setBrandsSelected([...brandsSelected, brand.id]);
														}
													}}
													onDeselect={() => {
														let tempArray = brandsSelected;
														const index = tempArray.indexOf(brand.id);
														if (index > -1) {
															tempArray.splice(index, 1);
														}
														setBrandsSelected([]);
														setBrandsSelected(tempArray);
														setLocationsSelected([]);
													}}
													isDisabled={props.userBusinessLevel !== 'BRAND'}
													isChecked={isBrandChecked}
												/>
												{ObjectUtils.isArrayWithData(brand.locations) && (
													<Accordion>
														{brand.locations.map(
															(
																brandLocation: Api.UserBusiness.Res.Available.Location
															) => {
																let isLocationChecked =
																	companiesSelected.includes(company.id) ||
																	brandsSelected.includes(brand.id) ||
																	locationsSelected.includes(brandLocation.id) ||
																	props.userBusinessLevel === 'SUPER';
																return (
																	<Box key={brandLocation.id}>
																		<LabelCheckbox
																			value={brandLocation.id}
																			text={brandLocation.name}
																			onSelect={() => {
																				if (
																					locationsSelected.includes(
																						brandLocation.id
																					)
																				) {
																					return;
																				} else {
																					setLocationsSelected([
																						...locationsSelected,
																						brandLocation.id
																					]);
																				}
																			}}
																			onDeselect={() => {
																				let tempArray = locationsSelected;
																				const index = tempArray.indexOf(
																					brandLocation.id
																				);
																				if (index > -1) {
																					tempArray.splice(index, 1);
																				}
																				setLocationsSelected(tempArray);
																			}}
																			isChecked={isLocationChecked}
																			isDisabled={
																				props.userBusinessLevel !== 'LOCATION'
																			}
																		/>
																	</Box>
																);
															}
														)}
													</Accordion>
												)}
											</Box>
										);
									})}
								</Accordion>
							)}
						</Box>
					);
				})}
			{!props.treeData.companies &&
				props.treeData.brands &&
				props.treeData.brands.map((brand: Api.UserBusiness.Res.Available.Brand) => {
					let isBrandChecked = brandsSelected.includes(brand.id);
					return (
						<Box className={'marginLeft checkboxTreeGrid'} key={brand.id}>
							<LabelCheckbox
								value={brand.id}
								text={brand.name}
								onSelect={() => {
									if (brandsSelected.includes(brand.id)) {
										return;
									} else {
										setBrandsSelected([...brandsSelected, brand.id]);
									}
								}}
								onDeselect={() => {
									let tempArray = brandsSelected;
									const index = tempArray.indexOf(brand.id);
									if (index > -1) {
										tempArray.splice(index, 1);
										setBrandsSelected(tempArray);
									}
								}}
								isDisabled={props.userBusinessLevel !== 'BRAND'}
								isChecked={isBrandChecked}
							/>
							{brand.locations && (
								<Accordion>
									{brand.locations.map((brandLocation: Api.UserBusiness.Res.Available.Location) => {
										let isLocationChecked =
											brandsSelected.includes(brand.id) ||
											locationsSelected.includes(brandLocation.id);
										return (
											<Box className={'marginLeft'} key={brandLocation.id}>
												<LabelCheckbox
													value={brandLocation.id}
													text={brandLocation.name}
													onSelect={() => {
														if (locationsSelected.includes(brandLocation.id)) {
															return;
														} else {
															setLocationsSelected([
																...locationsSelected,
																brandLocation.id
															]);
														}
													}}
													onDeselect={() => {
														let tempArray = locationsSelected;
														const index = tempArray.indexOf(brandLocation.id);
														if (index > -1) {
															tempArray.splice(index, 1);
														}
														setLocationsSelected(tempArray);
													}}
													isChecked={isLocationChecked}
													isDisabled={props.userBusinessLevel !== 'LOCATION'}
												/>
											</Box>
										);
									})}
								</Accordion>
							)}
						</Box>
					);
				})}
			{!props.treeData.companies &&
				!props.treeData.brands &&
				props.treeData.locations &&
				props.treeData.locations.map((brandLocation: Api.UserBusiness.Res.Available.Location) => {
					let isLocationChecked = locationsSelected.includes(brandLocation.id);
					return (
						<Box className={'marginLeft'} key={brandLocation.id}>
							<LabelCheckbox
								value={brandLocation.id}
								text={brandLocation.name}
								onSelect={() => {
									if (locationsSelected.includes(brandLocation.id)) {
										return;
									} else {
										setLocationsSelected([...locationsSelected, brandLocation.id]);
									}
								}}
								onDeselect={() => {
									let tempArray = locationsSelected;
									const index = tempArray.indexOf(brandLocation.id);
									if (index > -1) {
										tempArray.splice(index, 1);
									}
									setLocationsSelected(tempArray);
								}}
								isChecked={isLocationChecked}
								isDisabled={props.userBusinessLevel !== 'LOCATION'}
							/>
						</Box>
					);
				})}
		</Box>
	);
};

export default CheckboxTree;
