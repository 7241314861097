import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class FeatureService extends Service {
	async createFeature(data: Api.Feature.Req.Create) {
		return await http.post<RsResponseData<Api.Feature.Res.Create>>('feature', data);
	}

	async updateFeature(data: Api.Feature.Req.Update) {
		return await http.put<RsResponseData<Api.Feature.Res.Update>>('feature', data);
	}

	async deleteFeature(id: number) {
		return await http.delete<{ data: number }>('feature', { id });
	}
}
