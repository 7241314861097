import * as React from 'react';
import './ManageCompanyPagesPage.scss';
import disableRoute from '../../customHooks/useMiddleware';
import { Box, Page } from '@bit/redsky.framework.rs.996';
import { useEffect, useState } from 'react';
import LabelButton from '../../components/labelButton/LabelButton';
import SubHeader from '../../components/subHeader/SubHeader';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { StringUtils, WebUtils } from '../../utils/utils';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import serviceFactory from '../../services/serviceFactory';
import CompanyService from '../../services/company/company.service';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import Select, { OptionType } from '@bit/redsky.framework.rs.select';
import PageGuard = Model.PageGuard;

const ManageCompanyPagesPage: React.FC = () => {
	const companyService = serviceFactory.get<CompanyService>('CompanyService');
	const user = useRecoilValue<Api.User.Filtered | undefined>(globalState.user) as Api.User.Filtered;
	const [isActive, setIsActive] = useState<boolean>(true);
	const [allowSave, setAllowSave] = useState<boolean>(false);
	const [selectedPage, setSelectedPage] = useState<PageGuard>();
	const [pages, setPages] = useState<Model.PageGuard[]>();
	const [reRouteSelectOption, setReRouteSelectOption] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('reRoutePath', '', [])])
	);

	useEffect(() => {
		disableRoute.useMiddleware();
	}, []);

	useEffect(() => {
		async function getCompanyAndClientVariables() {
			try {
				let pages = await companyService.getAvailablePages();
				let res = await companyService.getCompanyDetails();
				mergePagesWithExistingVariables(res.unauthorizedPages, pages);
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'An unexpected server error has occurred'),
					'Server Error'
				);
			}
		}
		getCompanyAndClientVariables().catch(console.error);
	}, []);

	function mergePagesWithExistingVariables(
		companyUnauthorizedPages: Model.PageGuard[],
		availablePages: Model.PageGuard[]
	) {
		let newPages = availablePages.map((item) => {
			let existingUnauthorizedPage = companyUnauthorizedPages.find(
				(unAuthPages) => unAuthPages.route === item.route
			);
			if (!!existingUnauthorizedPage) return existingUnauthorizedPage;
			else return item;
		});
		setPages(newPages);
	}

	useEffect(() => {
		if (!isActive) return;
		save();
	}, [isActive]);

	useEffect(() => {
		if (reRouteSelectOption.isModified()) setAllowSave(true);
		else setAllowSave(false);
	}, [reRouteSelectOption]);

	function renderPagesList() {
		if (!pages) return;
		return pages.map((item, index) => {
			function renderClasses(): string {
				let classes = 'page';
				if (item.route === selectedPage?.route) classes += ' selected';
				if (!item.isActive) classes += ' red';
				return classes;
			}
			return (
				<div
					key={index}
					onClick={() => {
						setSelectedPage(item);
						setIsActive(!!item.isActive);
						setAllowSave(false);
						let updatedReRouteOption = reRouteSelectOption.get('reRoutePath');
						updatedReRouteOption.value = item.reRoute;
						reRouteSelectOption.updateInitialValues();
						setReRouteSelectOption(reRouteSelectOption.clone().update(updatedReRouteOption));
					}}
					className={renderClasses()}
				>
					{StringUtils.spaceOutCamelCaseString(item.page)}
				</div>
			);
		});
	}

	function renderRerouteOptions(): OptionType[] {
		if (!pages)
			return [
				{
					value: '',
					label: 'No Routes'
				}
			];
		return pages.map((item) => {
			return {
				value: item.route,
				label: StringUtils.spaceOutCamelCaseString(item.page)
			};
		});
	}

	function save() {
		if (!selectedPage || !pages) return;
		let newPages = [...pages];
		newPages = newPages.map((item) => {
			if (item.page === selectedPage.page) return selectedPage;
			else return item;
		});
		setPages(newPages);
		updateCompanyUnauthorizedPages(newPages).catch(console.error);
	}

	async function updateCompanyUnauthorizedPages(newPages: Model.PageGuard[]) {
		try {
			let toUpload: Model.PageGuard[] = newPages.filter((item) => !item.isActive);
			const res = await companyService.updateUnauthorizedPages({ unauthorizedPages: toUpload });
			if (res) rsToastify.success('Update successful', 'Success!');
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<Page className={'rsManageCompanyPagesPage'}>
			<SubHeader
				header={'Manage Pages'}
				crumbs={[
					{ label: 'Dashboard', link: '/dashboard' },
					{ label: 'Manage Pages', link: `/dashboard/manage-pages` }
				]}
			/>
			<div className={'content-wrapper'}>
				<div className={'columnOne'}>{renderPagesList()}</div>
				<div className={'columnTwo'}>
					{!!selectedPage && (
						<>
							<Box className={'pageData'} mb={15}>
								<Box display={'flex'} bgcolor={'whtie'}>
									<Label variant={'h2'}>
										{StringUtils.spaceOutCamelCaseString(selectedPage.page)}
									</Label>
									<LabelCheckbox
										value={''}
										text={'Is Active'}
										isChecked={isActive}
										onSelect={() => {
											setIsActive(true);
											setSelectedPage((prev) => {
												if (!prev) return;
												return { ...prev, isActive: 1, reRoute: '' };
											});
										}}
										onDeselect={() => {
											setIsActive(false);
											setSelectedPage((prev) => {
												if (!prev) return;
												return { ...prev, isActive: 0 };
											});
										}}
									/>
									<Box
										className={isActive ? 'reRouteBox' : 'reRouteBox show'}
										display={'flex'}
										alignItems={'center'}
									>
										<Box mr={10} className={'selectOptionBox'}>
											<Label variant={'h4'} mb={7}>
												Where do you want this page to re-route to?
											</Label>
											<Select
												control={reRouteSelectOption.get('reRoutePath')}
												options={renderRerouteOptions()}
												updateControl={(value) => {
													let updatedReRouteOption = reRouteSelectOption.get('reRoutePath');
													updatedReRouteOption.value = !!value.value
														? value.value.toString()
														: '';
													setReRouteSelectOption(
														reRouteSelectOption.clone().update(updatedReRouteOption)
													);

													setSelectedPage((prev) => {
														if (!prev) return;
														return {
															...prev,
															reRoute: !!value.value ? value.value.toString() : ''
														};
													});
												}}
											/>
										</Box>
										<LabelButton
											look={allowSave ? 'containedPrimary' : 'containedSecondary'}
											variant={'button'}
											label={'Save'}
											onClick={() => {
												save();
											}}
											disabled={!allowSave}
										/>
									</Box>
								</Box>
							</Box>
							<iframe
								src={`https://sand.spireloyalty.com${selectedPage.route[0] === '/' ? '' : '/'}${
									selectedPage.route
								}?token=${user.token}&di=1&ai=3`}
							/>
						</>
					)}
				</div>
			</div>
		</Page>
	);
};

export default ManageCompanyPagesPage;
