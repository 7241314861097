import * as React from 'react';
import './RoomTile.scss';
import Label from '@bit/redsky.framework.rs.label';
import Paper from '../paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';

interface RoomTileProps {
	title?: string;
	description?: string;
	onClick: () => void;
	onDelete: () => void;
}

const RoomTile: React.FC<RoomTileProps> = (props) => {
	return (
		<Paper className={'rsRoomTile'} borderRadius={'4px'} padding={'0 10px'} onClick={props.onClick}>
			<Box>
				<Label variant={'h3'}>{props.title}</Label>
				<Label variant={'body2'}>{props.description}</Label>
			</Box>
			<Icon
				iconImg={'icon-trash'}
				cursorPointer
				onClick={(event) => {
					props.onDelete();
					event?.stopPropagation();
				}}
			/>
		</Paper>
	);
};

export default RoomTile;
