import * as React from 'react';
import { Box } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';

interface ReviewRowContainerProps {
	title: string;
	value: string | number | Date | undefined;
}

const ReviewRowContainer: React.FC<ReviewRowContainerProps> = (props) => {
	return (
		<Box className={'rsReviewRowContainer rowContainer'}>
			<Box className={'reviewCellData'}>
				<Label variant={'body1'}>{props.title}</Label>
			</Box>
			<Box className={'reviewCellData'}>
				<Label variant={'body1'}>{props.value}</Label>
			</Box>
		</Box>
	);
};

export default ReviewRowContainer;
