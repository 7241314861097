import * as React from 'react';
import './ConfirmDeletePopup.scss';
import { Box, Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import LabelButton from '../../components/labelButton/LabelButton';

export interface ConfirmDeletePopupProps extends PopupProps {
	onDelete: () => void;
}

const ConfirmDeletePopup: React.FC<ConfirmDeletePopupProps> = (props) => {
	return (
		<Popup {...props}>
			<Paper className={'rsConfirmDeletePopup'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.close(ConfirmDeletePopup);
					}}
					cursorPointer
				/>
				<Label variant={'h2'} mb={40}>
					DELETE
				</Label>
				<Box display={'grid'} style={{ placeItems: 'center' }}>
					<Label variant={'h3'}>Are you sure you want to delete this? This cannot be undone.</Label>
					<Box display={'flex'} marginTop={30} width={250} justifyContent={'space-between'}>
						<LabelButton
							look={'containedPrimary'}
							variant={'button'}
							label={'Delete'}
							onClick={() => {
								props.onDelete();
								popupController.close(ConfirmDeletePopup);
							}}
						/>
						<LabelButton
							look={'containedSecondary'}
							variant={'button'}
							label={'Cancel'}
							onClick={() => {
								popupController.close(ConfirmDeletePopup);
							}}
						/>
					</Box>
				</Box>
			</Paper>
		</Popup>
	);
};

export default ConfirmDeletePopup;
