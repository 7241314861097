import { Router } from '@bit/redsky.framework.rs.996';
import { useEffect } from 'react';
import { LoginStatus } from '../customHooks/useLoginState';

class AdvancedRouter extends Router {
	constructor() {
		super({ animate: false });
	}
}

export function useLoadInitialPath(loginStatus: LoginStatus) {
	useEffect(() => {
		if (loginStatus === LoginStatus.UNKNOWN) return;
		if (loginStatus === LoginStatus.LOGGED_OUT && !router.getCurrentPath().includes('password-reset')) {
			router.navigate('/').catch(console.error);
			return;
		}
		router.tryToLoadInitialPath();
	}, [loginStatus]);
}

let router = new AdvancedRouter();
export default router;
(window as any).router = router;
