import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';

export default class MediaService extends Service {
	async deleteMedia(data: Api.Media.Req.Delete): Promise<RsResponseData<Api.Media.Req.Delete>> {
		return await http.delete<Api.Media.Req.Delete>('media', data);
	}

	get(id: number) {
		return http.get<RsResponseData<Api.Media.Res.Get>>('media', { id });
	}

	update(data: any) {
		return http.put<RsResponseData<Api.Media.Res.Get>>('media', data);
	}
}
